import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { Colors } from '../../styles/themeNew';
import { useState, useEffect } from 'react';

type Props = {
  title?: string | null;
  tooltip?: boolean;
  required?: boolean;
  value: number | string | null;
  placeholder?: string;
  mask?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  onlyNumbers?: boolean; // New prop: allow or disallow letters
};

export const InputInTable = (props: Props) => {
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState(props.value?.toString() || '');

  useEffect(() => {
    setInputValue(props.value === null ? '' : props.value?.toString() || '');
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (props.onlyNumbers) {
      newValue = newValue.replace(/[^0-9.-]/g, '');
    }

    // Ensure the value is within the min and max range
    if (props.min !== undefined || props.max !== undefined) {
      const numericValue = newValue === '' ? null : Number(newValue);
      if (numericValue !== null) {
        if (props.min !== undefined && numericValue < props.min) {
          newValue = props.min.toString();
        }
        if (props.max !== undefined && numericValue > props.max) {
          newValue = props.max.toString();
        }
      }
    }

    setInputValue(newValue);

    // Validate the input
    if (!props.mask) {
      const numericValue = newValue === '' ? null : Number(newValue);
      const isNumber = numericValue === null || !isNaN(numericValue);

      const isWithinRange =
        numericValue === null ||
        ((props.min === undefined || numericValue >= props.min) &&
         (props.max === undefined || numericValue <= props.max));

      setIsValid(isNumber && isWithinRange);
    }

    // Trigger the parent onChange event
    event.target.value = newValue;
    props.onChange(event);
  };

  return (
    <StyledContainer>
      {Boolean(props.title) && (
        <StyledTitleContainer>
          {props.title}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledTitleContainer>
      )}

      <StyledMainContainer>
        <InputMask
          mask={props.mask || ''}
          value={inputValue}
          onChange={handleChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
        >
          {(inputProps: any) => (
            <StyledInput
              placeholder={props.placeholder}
              disabled={props.disabled}
              onChange={handleChange}
              value={inputValue}
              isValid={isValid}
              readOnly={props.readOnly}
              min={props.min}
              max={props.max}
              {...inputProps}
            />
          )}
        </InputMask>

        {!isValid && inputValue && (
          <StyledErrorContainer>
            {props.errorMessage ||
              (props.min !== undefined && props.max !== undefined
                ? `Значение должно быть между ${props.min} и ${props.max}`
                : props.min !== undefined
                ? `Значение должно быть не меньше ${props.min}`
                : props.max !== undefined
                ? `Значение должно быть не больше ${props.max}`
                : 'Ошибка ввода данных')}
          </StyledErrorContainer>
        )}

        {props.tooltip && (
          <StyledIconContainer>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
            >
              <circle cx='10' cy='10' r='9.5' stroke='#3E6AFF' />
              <line
                x1='10.0859'
                y1='8.83203'
                x2='10.0859'
                y2='14.4987'
                stroke='#3E6AFF'
                strokeLinecap='round'
              />
              <circle cx='9.9974' cy='5.83333' r='0.833333' fill='#3E6AFF' />
            </svg>
          </StyledIconContainer>
        )}
      </StyledMainContainer>
    </StyledContainer>
  );
};

const StyledErrorContainer = styled.div`
  color: ${Colors.RED[500]};
  margin-top: 4px;
  font-size: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Colors.MONOCHROM[950]};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  gap: 4px;
`;

const StyledMainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 10px;
  font-weight: 400;
`;

const StyledInput = styled.input<{ isValid: boolean }>`
  height: 100%;
  margin: 0;
  padding: 10px 16px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  color: ${Colors.MONOCHROM[600]};
  font-size: 12px;
  line-height: 14px;
  outline: none;

  &::placeholder {
    color: ${Colors.MONOCHROM[300]};
  }

  &:not(:disabled):not(:readOnly):hover {
    background: ${Colors.MONOCHROM[50]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${Colors.MONOCHROM[300]};
  }
`;