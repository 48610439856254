import styled from 'styled-components';
import { Colors } from '../../styles/themeNew';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  checked: boolean;
  text?: string;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = (props: Props) => {
  const { checked, text, isDisabled, onChange, id, ...rest } = props;

  const svgFill = props.isDisabled ? '#D8DBDF' : '#3E6AFF';

  const uncheckedSvg = (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='22' height='22' fill='white' />
      <rect x='0.5' y='0.5' width='21' height='21' rx='2.5' stroke='#D8DBDF' />
    </svg>
  );

  const checkedSvg = (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='22' height='22' rx='3' fill={svgFill} />
      <path
        d='M7 10.7692C7 10.7692 9.83651 14 10.1578 14C10.4791 14 15 8 15 8'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <StyledContainer {...rest}>
      <HiddenCheckbox
        type='checkbox'
        id={id}
        checked={checked}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <SvgContainer>
        {props.checked ? checkedSvg : uncheckedSvg}
      </SvgContainer>

      {props.text && <StyledTextContainer>{props.text}</StyledTextContainer>}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  cursor: pointer;
`;

const StyledTextContainer = styled.div`
  color: ${Colors.MONOCHROM[400]};
  font-size: 16px;
  font-weight: 400;
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;