import styled from 'styled-components';

export const TWrapper = styled.div``;

export const TEditButton = styled.button<{ active?: boolean }>`
  width: max-content;
  padding: 0 10px;
  height: 20px;
  margin-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme,active }) => active ? theme.colors.white : theme.colors.blue[200]};
  background-color: ${({ theme,active }) => active ? theme.colors.blue[200] : theme.colors.white};

  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  
  border-radius: 10px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue[200] };
  border-width: 1px;

  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.product.primaryHover};
    color: ${({ theme }) => theme.colors.white };
    border-color: ${({ theme }) => theme.colors.product.primaryHover};
  }

  &:active, &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;

  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`;

export const THeader = styled.div`
  display: flex;
  align-items: start;
  margin: 16px 0 9px;
  
  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray[500]};
    width: 510px;
  }
  & > p:last-child {
    width: 176px;
    padding-left: 10px;
  }
`;

export const TIcon = styled.img<{size?:number}>`
  display: inline-block;
  width: ${({size}) => size ? size : 16 }px;
  height: ${({size}) => size ? size : 16 }px;
`;

export const TVisibilityToggle = styled.div<{ visible: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[400]};
  cursor: pointer;
  margin: 20px 0;
  display: flex;
  align-items: center;
  
  & ${TIcon} {
    margin-left: 6px;
    transform: rotate(${({ visible }) => visible ? 180 : 0}deg);
  }
`;

export const TFormWrapper = styled.div<{ column: boolean }>`
  position: relative;
  min-height: fit-content;

  div > label + div {
    label {
      padding: 8px 24px;
      margin: 0;
      :nth-child(2):not(:last-child){
        margin: 2px 0;
      }
      :nth-child(2){
        margin-top: 2px;
      }
      >:first-child{
        margin-bottom: 0px;
      }
    }
  }
  
  label {
    border: 1px solid ${({ theme }) => theme.colors.gray[400]};
    :first-child{
      margin-bottom: 0px;
    }
    :last-child{
      padding: 6px 24px;
    }
  }

  .implementer {
    min-width: 670px;
    max-width: 670px;
    margin: 20px 0;

    label {
      border: none;
      margin-bottom: 4px;
    }
    textarea{
      height: 64px;
      /* padding: 15px 15px 0 15px; */
      padding: 18px 30px 18px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      color: ${({theme}) => theme.colors.gray[500]};

      :focus-visible,:hover,:active {
        border: 1px solid ${({theme}) => theme.colors.gray[500]};
        outline: none;
      }
    }
  }
  .implementer {
    position: relative;
    min-height: fit-content;
    width: 670px;
    border: 1px solid ${({theme}) => theme.colors.gray[500]};
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.gray[500]};
    margin-top: 52px;
    font-weight: 400;
    padding: 18px 16px;

    b {
      font-weight: 600;
    }
    ::before {
      content: 'Исполнитель';
      position: absolute;
      top: -28px;
      left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({theme}) => theme.colors.gray[500]};
    }
  }
`;

export const TDoctorChanges = styled.ul`
  max-width: 670px;
`;

export const TButton = styled.button`
  width: 310px;
  height: 44px;
  border-radius: 10px;
  background: transparent;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  border: 2px solid ${({theme}) => theme.colors.blue[200]};
  color:${({theme}) =>theme.colors.blue[200]};

  cursor: pointer;
  &:hover:enabled {
    border: 2px solid ${({ theme }) => theme.colors.blue[300]};
    color: ${({ theme }) => theme.colors.blue[300]};
  }
  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.neutral.disable};
    border: 1px solid ${({ theme }) => theme.colors.neutral.disable};
  }
`;

export const TMarkerChanges = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 32px;
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }
  ul{
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 12px;
    :last-child {
      margin-top: 0;
      padding-top:0;
    }
  }
  li{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray[400]};
    list-style: none;
    margin-bottom: 0 !important;
    b{
      font-weight: 600;
      padding-right: 12px;
    }
  }
`;

export const TFinalMarkersList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 50px;
  flex-shrink: 0;
  flex-grow: 0;
  align-content: space-evenly;
  height: 120px;
  width:100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
  list-style: none;
  li{
    width: max-content;
  }
  li::before {
    content: "• ";
    color: ${({theme}) =>theme.colors.product.primaryActive};
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
`

export const TDateOfCompletion = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]};
  margin-bottom: 16px;
  display: inline-block;
`

export const TMarkersTitle = styled.p<{calorBalack?:boolean}>`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: ${({theme, calorBalack}) => !!calorBalack ? theme.colors.black[200] : theme.colors.gray[500]};
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`

export const TMessage = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]}
`