import { FC, useLayoutEffect, useMemo, useState } from 'react';
import { TBiomatContainer, TSection, TSectionHeader } from '../styled';
import {
  IConclusionData,
  IModulePatient,
  IMorphologyData,
} from '../../../../store/molProConclusion/model';
import {
  getBioMaterialSourceName,
} from '../../../../constants/bioMaterial';
import ReadOnlyInput from '../../../../components/shared/ReadOnlyInput';

interface IComponentProps {
  defaultData: IConclusionData | null;
  [index: string]: any;
}

export const Patient: FC<IComponentProps> = ({ defaultData }) => {
  const [patientData, setPatientData] = useState<IModulePatient>();
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());

  useLayoutEffect(() => {
    if (!!defaultData && defaultData?.modulePatient) {
      setPatientData(defaultData.modulePatient);
      setResetFormKey(Date.now());
    }
  }, [defaultData, setResetFormKey, setPatientData]);

  const [standartTNM, standartStage] = useMemo(() => {
    if (!patientData) return ['', ''];
    let {
      tnm,
      tnmStage,
      tnmNonApplicable,
      tnmUnknown,
      stageNonApplicable,
      stageUnknown,
    } = patientData;

    let TNM_Applicable = tnmNonApplicable ? 'Неприменимо' : '';
    let TNM_Unknown = tnmUnknown ? 'Не установлена' : '';

    let Stage_Applicable = stageNonApplicable ? 'Неприменимо' : '';
    let Stage_Unknown = stageUnknown ? 'Не установлена' : '';

    let TNM =
      TNM_Applicable || TNM_Unknown ? TNM_Applicable || TNM_Unknown : tnm;
    let TNMStage =
      Stage_Applicable || Stage_Unknown
        ? Stage_Applicable || Stage_Unknown
        : tnmStage?.replace('--', '');

    return [TNM, TNMStage];
  }, [patientData]);

  return (
    <>
      <TSection key={`${resertFormKey}_Patient`}>
        <TSectionHeader>Данные пациента</TSectionHeader>
        <ReadOnlyInput
          width={'49%'}
          label='Дата регистрации биоматериала'
          placeholder={'Дата регистрации не определена'}
        >
          {patientData?.bioAcceptDate ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput
          width={'49%'}
          label='№ амбулаторной карты направившего УЗ'
          placeholder={'Номер амбулаторной карты не определен'}
        >
          {patientData?.patientAmbulatoryCards ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput
          width={'100%'}
          label='ФИО пациента'
          placeholder={'ФИО пациента не определено'}
        >
          {patientData?.patientFullName ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput
          width={'49%'}
          label='Пол'
          placeholder={'Пол не определен'}
        >
          {patientData?.patientGender ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput
          width={'49%'}
          label='Дата рождения'
          placeholder={'Дата рождения не определена'}
        >
          {patientData?.patientBirthDay ?? '--'}
        </ReadOnlyInput>
      </TSection>
      <TSection key={`${resertFormKey}_Clinical`}>
        <TSectionHeader>Клинические данные</TSectionHeader>
        <ReadOnlyInput
          width={'100%'}
          label='Клинический диагноз'
          placeholder={'Клинический диагноз отсутствует'}
        >
          {patientData?.clinicalDiagnosis}
        </ReadOnlyInput>
        {(!!standartTNM || !!standartStage) && (
          <div>
            <ReadOnlyInput width={'49%'} label='Классификация TNM'>
              {standartTNM ?? ''}
            </ReadOnlyInput>
            <ReadOnlyInput width={'49%'} label='Стадия'>
              {standartStage ?? ''}
            </ReadOnlyInput>
          </div>
        )}
        <ReadOnlyInput width={'100%'} label='Дополнительные клинические данные'>
          {patientData?.additionalInfoNonApplicable ? "Непременимо" :
            patientData?.additionalInfoUnknown ? "Не установленно" :
              patientData?.additionalInfo ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput width={'100%'} label='Результаты предыдущих паталого-анатомических исследований'>
          {patientData?.previousAnalysisResultsNonApplicable ? "Непременимо" :
            patientData?.previousAnalysisResultsUnknown ? "Не установленно" :
              patientData?.previousAnalysisResults ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput width={'100%'} label='Предоперационное лечение'>
          {patientData?.preAmbulatoryCareNonApplicable ? "Непременимо" :
            patientData?.preAmbulatoryCareUnknown ? "Не установленно" :
              patientData?.preAmbulatoryCare ?? '--'}
        </ReadOnlyInput>
        <ReadOnlyInput
          width={'100%'}
          label='Направившее учреждение, отделение'
          placeholder='Направившее учреждение, отделение не определено'
        >
          {patientData?.referralCreator ?? '--'}
        </ReadOnlyInput>
      </TSection>
      <TSection key={`${resertFormKey}_Bio`}>
        <TSectionHeader>Материал для проведения исследований</TSectionHeader>
        <ReadOnlyInput
          width={'100%'}
          label='Тип материала'
          placeholder='Тип материала не определен'
        >
          {/*У меня почему ошибка при передечи patientData?.materialType*/}
          {getBioMaterialSourceName('surgery') ?? ''}
        </ReadOnlyInput>

        {!!patientData?.morphologyData &&
          (patientData?.morphologyData ?? [])?.map((bio: IMorphologyData) => {
            return (
              <TBiomatContainer
                key={bio.codeNumber + bio.cancerousCellPercentage}
              >
                <ReadOnlyInput
                  width={'49%'}
                  label='Парафиновый блок'
                  placeholder='Парафиновый блок не определен'
                >
                  {bio.codeNumber}
                </ReadOnlyInput>
                <ReadOnlyInput
                  width={'49%'}
                  label='Процент опухолевых клеток'
                  placeholder='Процент опухолевых клеток не определен'
                >{`${bio?.cancerousCellPercentage ?? '0'}%`}</ReadOnlyInput>
              </TBiomatContainer>
            );
          })}
        {!!patientData?.materialBlood && (
          <ReadOnlyInput
            width={'49%'}
            label='Кровь'
            placeholder='Номер крови не определен'
          >
            {patientData.materialBlood ?? '--'}
          </ReadOnlyInput>
        )}
      </TSection>
    </>
  );
};

export default Patient;
