import styled from 'styled-components';
import Input from '../../../components/shared/Input';
import { Colors } from '../../../styles/themeNew';

export const TReferralSideDataWr = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  input,
  textarea {
    color: ${({ theme }) => theme.colors.black[200]};
    margin-top: 10px;
  }
  label {
    line-height: 20px;
  }
`;

export const TCustomInput = styled(Input)<{
  width?: string;
  background?: string;
}>`
  width: ${({ width }) => (width ? width : '100%')};
  padding-bottom: 16px;
  & :read-only {
    cursor: default;
  }
  & input {
    padding: 18px;
    background-color: ${({ background }) => (background ? background : '#fff')};
  }
`;
export const TButtonWr = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 28px;
`;

export const TEditButton = styled.button<{ active?: boolean }>`
  width: max-content;
  padding: 0 10px;
  height: 20px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue[200]};
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  border: 0 !important;

  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.product.primaryHover};
  }

  &:active,
  &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;
  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`;

export const TPaymentWr = styled.div<{ label: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 112px;
  margin-bottom: 12px;
  box-sizing: border-box;
  ::after {
    content: 'Оплата';
    position: absolute;
    display: block;
    bottom: 72px;
    left: 0px;
    width: 98px;
    color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  & label {
    border: 1px solid ${({ theme }) => theme.colors.gray[500]};
    height: 62px;
  }
`;
export const TSpeedWr = styled.div<{ label: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 72px;
  margin-top: 30px;
  margin-bottom: 12px;
  box-sizing: border-box;
  ::after {
    content: 'Приоритет';
    position: absolute;
    display: block;
    bottom: 72px;
    left: 0px;
    width: 98px;
    color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  & label {
    border: 1px solid ${({ theme }) => theme.colors.gray[500]};
    height: 62px;
  }
`;
export const TDateOfCompletion = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.MONOCHROM[500]};
`;

export const TDateInfo = styled.span`
  font-weight: 600;
`;

export const TRowWr = styled.div<{ direction?: string }>`
  display: flex;
  justify-content: ${({ direction }) =>
    direction ? direction : 'space-around'};
  width: 100%;
  padding-bottom: 16px;
`;
