import { FC, useCallback, useMemo, useState } from 'react';
import {
  TWrapper,
  TLogo,
  TSmallLogo,
  TTitle,
  TMenuLink,
  TIconWrapper,
  TSmallTitle,
  TMenuWr,
  TMenuButton,
  TUserItemLinkWr,
  TMenuItemsContainer,
  TLine,
  TLine2,
  TMenuItemsContainer2,
  TMenuUserContainer,
} from './styled';
import { menuItems, MenuItemsType } from './constants';
import { ReactComponent as SignOutIcon } from '../../icons/sign-out.svg';
import { ReactComponent as SupportIcon } from '../../icons/support.svg';
import { ReactComponent as AnalyticIcon } from '../../icons/analytic.svg';

import { ROUTES } from '../../constants/routes';
import Button, { SIZE, VARIANT } from '../shared/Button';
import InfoModal from '../shared/InfoModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPermitViewResearch,
  getProfile,
  getTokens,
} from '../../store/auth/selectors';
import { useLocation } from 'react-router';
import { resetReferralSearchData } from '../../store/referral';
import { resetResearchSearchData } from '../../store/research';
import { fetchResearch } from '../../store/research/thunkActions';
import { closeMenu, isMenuOpenSelector, openMenu } from './redux/menu';

interface IMenu {
  onSignOut: () => void;
  isVerifiedUser: boolean;
}

const Menu: FC<IMenu> = ({ onSignOut, isVerifiedUser }) => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const ableToViewResearch = useSelector(checkPermitViewResearch);
  const path = useLocation();
  const tokens = useSelector(getTokens);

  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const isMenuOpen = useSelector(isMenuOpenSelector);

  const menuList = useMemo(() => {
    const menu = menuItems;
    if (!ableToViewResearch)
      return menu.filter(
        (value: MenuItemsType) => value.name !== 'Исследования'
      );
    return menu.map((menuItem: MenuItemsType) => {
      if (path.pathname.includes(menuItem.url)) {
        menuItem.isActive = true;
      } else menuItem.isActive = false;
      return menuItem;
    });
  }, [ableToViewResearch, path.pathname]);

  const onModalToggle = useCallback(() => {
    setShowSignOutModal(!showSignOutModal);
  }, [showSignOutModal]);

  const onSupportClick = () => void 0;

  const profileInfo = useMemo(() => {
    return (
      <>
        <TMenuUserContainer>
          <TTitle>
            {profile?.lastName} <br /> {profile?.firstName}{' '}
            {profile?.middleName}
          </TTitle>
          <TSmallTitle>
            {profile?.lastName?.[0]}
            {profile?.firstName?.[0]}
          </TSmallTitle>
        </TMenuUserContainer>
      </>
    );
  }, [profile]);

  const menuHandler = useCallback(
    (url: string) => {
      if (url === '/referral') {
        dispatch(resetReferralSearchData());
      }
      if (url === '/research') {
        if (tokens?.access)
          dispatch(
            fetchResearch(tokens?.access, { page: 1, pageSize: 10, tab: 'all' })
          );
        else dispatch(resetResearchSearchData());
      }
    },
    [dispatch, tokens?.access]
  );

  const handleMouseEnter = () => {
    dispatch(openMenu());
  };

  const handleMouseLeave = () => {
    dispatch(closeMenu());
  };

  return (
    <TWrapper
      isMenuOpen={isMenuOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TMenuWr>
        <TSmallLogo></TSmallLogo>
        <TLogo></TLogo>
        <TMenuItemsContainer>
          {isVerifiedUser &&
            menuList.map(({ icon, name, url, isActive }) => (
              <TMenuLink
                to={url}
                className={`${!!isActive ? 'activeUserItem' : ''}`}
                key={name}
                onClick={() => {
                  menuHandler(url)
                  dispatch(openMenu()); 
                }}
              >
                <TIconWrapper>{icon}</TIconWrapper>
                <span>{name}</span>
              </TMenuLink>
            ))}
          <TLine />
          <TMenuButton onClick={onSupportClick}>
            <TIconWrapper>
              <AnalyticIcon />
            </TIconWrapper>
            <span>Аналитика</span>
          </TMenuButton>
          <TMenuButton onClick={onSupportClick}>
            <TIconWrapper>
              <SupportIcon />
            </TIconWrapper>
            <span>Поддержка</span>
          </TMenuButton>
          <TMenuButton onClick={onModalToggle}>
            <TIconWrapper>
              <SignOutIcon />
            </TIconWrapper>
            <span>Выход</span>
          </TMenuButton>
        </TMenuItemsContainer>
      </TMenuWr>
      <TMenuItemsContainer2>
        {![
          `${ROUTES.PERSONAL_INFORMATION}`,
          `${ROUTES.UPDATE_PERSONAL_INFORMATION}`,
        ].includes(path.pathname) ? (
          <TUserItemLinkWr to={ROUTES.PERSONAL_INFORMATION}>
            {profileInfo}
          </TUserItemLinkWr>
        ) : (
          <>{profileInfo}</>
        )}
        <TLine2 />
      </TMenuItemsContainer2>

      <InfoModal
        title='Вы действительно хотите выйти из системы?'
        showModal={showSignOutModal}
        onModalToggle={onModalToggle}
        buttons={
          <>
            <Button
              size={SIZE.SMALL}
              variant={VARIANT.TRANSPARENT}
              onClick={onSignOut}
            >
              Выход
            </Button>
            <Button size={SIZE.SMALL} onClick={onModalToggle}>
              Отмена
            </Button>
          </>
        }
      />
    </TWrapper>
  );
};

export default Menu;
