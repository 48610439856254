import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { ClinicsService } from "../../../../../../services/Clinics";

import { Select } from "../../../../../../componentsNew/Select";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import {
  selectedPatientSelector,
  patientsToSelectSelector,
  selectedPatientConfirmedSelector,
  selectedPatientUlidSelector,
  setSelectedPatient,
  setSelectedPatientConfirmed,
  setPatientToSelectToggled,
  setPatientAmbulatoryCardToggled,
  setPatientAmbulatoryCardUntoggled,
  selectedAmbulatoryCardSelector,
  setSelectedAmbulatoryCardNumber,
  setSelectedAmbulatoryCardOrganizationId,
  setSelectedAmbulatoryCardOrganizationName,
} from "../../../../redux/patient";

import { Radio } from "../../../../../../componentsNew/Radio";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { InputWithRadioToggle } from "../../../../../../componentsNew/InputWithRadioToggle";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Description } from "../../../../../../componentsNew/Description";

import { Button } from "../../../../../../componentsNew/Button";

import { Colors } from "../../../../../../styles/themeNew";

import { getGenderName } from "../../../../../../constants/genders";

export const FoundRfProfile = () => {
  const httpClient = useHttpClient();
  const [clinics, setClinics] = useState<any[]>([]);

  const dispatch = useDispatch();

  const patientsToSelect = useSelector(patientsToSelectSelector);

  const selectedPatientUlid = useSelector(selectedPatientUlidSelector);
  const selectedPatient = useSelector(selectedPatientSelector);
  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);
  const isSelectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);

  const effect = async () => {
    const clinicsService = new ClinicsService(httpClient);
    const _clinics = await clinicsService.searchClinics();

    if (_clinics?.length) {
      setClinics(_clinics);
    }
  };

  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedPatientUlid && patientsToSelect && patientsToSelect?.length > 1) {
    return (
      <StyledContainer>
        <StyledTitleContainer>
          <Title level="2">Найдено несколько пациентов</Title>
        </StyledTitleContainer>

        <StyledFoundPatientListContainer>
          {patientsToSelect && patientsToSelect.map((_patient, index) => (
              <Radio
                key={index}
                selected={_patient.isSelected}
                text={`${_patient.idPassport} | ${_patient.fullName}`}
                onSelect={() => dispatch(setPatientToSelectToggled(index))}
              />
          ))}
        </StyledFoundPatientListContainer>

        <StyledSelectButtonContainer>
          <Button
            size="L"
            type="PRIMARY"
            text="Выбрать пациента"
            onClick={() => {
              dispatch(setSelectedPatient());
            }}
            color={Colors.GREEN[500]}
          />
        </StyledSelectButtonContainer>
      </StyledContainer>
    );
  } else {
    if (!selectedPatientUlid) {
      dispatch(setPatientToSelectToggled(0));
      dispatch(setSelectedPatient());
    };
  };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Пациент найден</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <Description>
          Для продолжения работы подтвердите профиль пациента. При необходимости
          добавьте амбулаторную карту
        </Description>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Серия и номер паспорта"
            required={false}
            placeholder=""
            value={selectedPatient?.idPassport || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Адрес регистрации"
            required={false}
            placeholder=""
            value={selectedPatient?.address || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Номер полиса ОМС"
            required={false}
            placeholder=""
            value={selectedPatient?.oms || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Input
            title="Номер СНИЛС"
            required={false}
            placeholder=""
            value={selectedPatient?.snils || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="ФИО"
            required={false}
            placeholder=""
            value={selectedPatient?.fullName || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Пол"
            required={false}
            placeholder=""
            value={getGenderName(selectedPatient?.gender) || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Datepicker
            required={false}
            title="Дата рождения"
            placeholder=""
            value={selectedPatient?.birthdate || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledAmbulatoryCardContainer>
        <StyledRowContainer style={{ marginBottom: "16px" }}>
          <Title level="3">Амбулаторная карта</Title>
        </StyledRowContainer>

        <StyledRowContainer style={{ marginBottom: "8px" }}>
          <Description>
            Убедитесь, что выбранная амбулаторная карта зарегистрирована в
            учреждении направителя
          </Description>
        </StyledRowContainer>

        {selectedPatient.ambulatoryCards && selectedPatient.ambulatoryCards.length > 0 && (
          <StyledAmbulatoryCardsContainer>
            {selectedPatient.ambulatoryCards.map((ambulatoryCard, index) => (
              <>
                <StyledRowContainer key={index}>
                  <StyledInputContainer2>
                    <InputWithRadioToggle
                      title="Номер амбулаторной карты"
                      required={false}
                      placeholder=""
                      value={ambulatoryCard.number || ""}
                      onChange={(event) => { }}
                      disabled={true}
                      onToggle={() => {
                        dispatch(setPatientAmbulatoryCardToggled(index));
                        dispatch(setSelectedAmbulatoryCardNumber(ambulatoryCard.number || ""));
                        dispatch(setSelectedAmbulatoryCardOrganizationId(ambulatoryCard.organizationId || 0));
                        dispatch(setSelectedAmbulatoryCardOrganizationName(ambulatoryCard.organizationName || ""));
                      }}
                      index={index}
                      isToggled={ambulatoryCard.isSelected}
                    />
                  </StyledInputContainer2>
                  <StyledInputContainer2>
                    <Input
                      required={false}
                      title="УЗ"
                      placeholder=""
                      value={ambulatoryCard?.organizationName || ""}
                      onChange={(event) => { }}
                      disabled={true}
                    />
                  </StyledInputContainer2>
                </StyledRowContainer>
              </>
            ))}
            <StyledRowContainer2>
              <StyledInputContainer2>
                <Input
                  title="Номер амбулаторной карты"
                  required={true}
                  placeholder="--"
                  value={selectedAmbulatoryCard?.number || ""}
                  onChange={(event) => {
                    dispatch(setPatientAmbulatoryCardUntoggled());
                    dispatch(setSelectedAmbulatoryCardNumber(event.target.value));
                  }}
                  disabled={isSelectedPatientConfirmed}
                />
              </StyledInputContainer2>

              <StyledInputContainer2>
                <Select
                  title="УЗ"
                  placeholder="--"
                  required={true}
                  options={clinics.map((clinic) => ({
                    label: clinic.name_local_rus,
                    value: String(clinic.id),
                    selected: `${clinic.id}` === `${selectedAmbulatoryCard.organizationId}`,
                  }))}
                  onSelect={(option: { label: string, value: string }) => {
                    dispatch(setPatientAmbulatoryCardUntoggled());
                    dispatch(setSelectedAmbulatoryCardOrganizationId(Number(option.value)));
                    dispatch(setSelectedAmbulatoryCardOrganizationName(option.label));
                  }}
                  disabled={isSelectedPatientConfirmed}
                />
              </StyledInputContainer2>
            </StyledRowContainer2>
          </StyledAmbulatoryCardsContainer>
        )}
      </StyledAmbulatoryCardContainer>

      {!isSelectedPatientConfirmed && (
        <StyledRowContainer>
          <Button
            type="PRIMARY"
            text="Подтвердить"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(true));
            }}
          />
        </StyledRowContainer>
      )}
      {Boolean(isSelectedPatientConfirmed) && (
        <StyledRowContainer>
          <Button
            type="SECONDARY"
            text="Редактировать"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(false));
            }}
          />
        </StyledRowContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledFoundPatientListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledAmbulatoryCardContainer = styled.div``;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledSelectButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledAmbulatoryCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
