import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';

import { fetchOrganizations } from './store/organizations/thunkActions';
import {
  getFromSessionStorage,
  resetSessionStorage,
} from './utils/sessionStorageHelpers';
import { getPermissions, getTokens } from './store/auth/selectors';
import { getOrganizations } from './store/organizations/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from './store/auth/thunkActions';
import { authorizationSuccess, setTokenExp } from './store/auth';
import { ROUTES } from './constants/routes';
import { useLocation } from 'react-router';
import { TAppContainer } from './styled';
import Menu from './components/menu';

import MolecularProfilingDetailsPage from './containers/MolecularProfilingDetailsPage';
import RestorePasswordRequestPage from './containers/RestorePasswordRequestPage';
import EmailVerificationPage from './containers/EmailVerificationPage';
import StartPersonalInfoPage from './containers/StartPersonalInfoPage';
import RestorePasswordPage from './containers/RestorePasswordPage';
// import CreateReferralPage from './containers/CreateReferralPage';
import PersonalInfoPage from './containers/PersonalInfoPage';
import ReferralPage from './containers/ReferralPage';
import SignUpPage from './containers/SignUpPage';
import LoginPage from './containers/LoginPage';
import { fromBinary } from './utils/stringHelpers';
import ResearchPage from './containers/ResearchPage';
import NoInternetConnection from './utils/NoInternetConnection';
import RefreshToken from './utils/RefreshToken';
import AlertMsg from './components/shared/AlertMsg';
import { parseJwt } from './utils/parseJwt';
import { resetLocalStorageData } from './utils/localStorageHelpers';
import { CreateReferralPageNew } from './containers/CreateReferralPageNew';
// import { MainLayout } from './componentsNew/MainLayout';

export function AnalyticDashboard() {
  window.open('https://gen-x.analytics.devnull.express/login/', '_blank') ||
    window.location.replace('https://gen-x.analytics.devnull.express/login/');
  window.location.reload();
  return null;
}

const App = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    let key = window.navigator.userAgent.replace(/[^0-9]/g, '');
    let data = getFromSessionStorage(key)[0];

    if (data) {
      const decoded = window.atob(data);
      const decriptData = JSON.parse(fromBinary(decoded));
      const token = parseJwt(decriptData?.tokens?.access);
      dispatch(setTokenExp(token?.exp));
      dispatch(authorizationSuccess(decriptData));
    }
  }, [dispatch]);

  const tokens = useSelector(getTokens);
  const permissions = useSelector(getPermissions);
  const organizations = useSelector(getOrganizations);

  const isAuth = useMemo(() => {
    if (tokens?.access && tokens?.refresh) return true;
    return false;
  }, [tokens?.access, tokens?.refresh]);

  const isGuest = useMemo(() => !!permissions?.guest, [permissions?.guest]);
  const isValidated = useMemo(
    () => !!permissions?.isValidated,
    [permissions?.isValidated]
  );
  const path = useLocation().pathname;

  const onSignOut = useCallback(() => {
    tokens?.refresh &&
      tokens?.access &&
      dispatch(signOut({ refresh: tokens.refresh, access: tokens.access }));
    resetSessionStorage();
    resetLocalStorageData();
  }, [tokens?.refresh, tokens?.access, dispatch]);

  useEffect(() => {
    !organizations.length &&
      tokens?.access &&
      dispatch(fetchOrganizations(tokens.access));
  }, [tokens?.access, dispatch, organizations]);

  function PrivateRoute() {
    if (!isAuth) return <Navigate to={ROUTES.SIGN_IN} />;

    if (
      isGuest &&
      !isValidated &&
      path !== ROUTES.UPDATE_PERSONAL_INFORMATION
    ) {
      return <Navigate to={ROUTES.UPDATE_PERSONAL_INFORMATION} />;
    }

    return (
      <TAppContainer>
        {isAuth && (
          <Menu
            isVerifiedUser={!isGuest && isValidated}
            onSignOut={onSignOut}
          />
        )}
        <Outlet />
        <NoInternetConnection />
        <RefreshToken />
        <AlertMsg />
      </TAppContainer>
    );
  }

  return (
    <Routes>
      <Route path={ROUTES.SIGN_IN} element={<LoginPage />} />
      <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
      <Route
        path={ROUTES.RESTORE_PASSWORD_REQUEST}
        element={<RestorePasswordRequestPage />}
      />
      <Route path={ROUTES.RESTORE_PASSWORD} element={<RestorePasswordPage />} />
      <Route
        path={ROUTES.EMAIL_VERIFICATION}
        element={<EmailVerificationPage />}
      />
      {/* <Route path={ROUTES.ADMIN} element={<AdminDashboard/>}/> */}

      <Route path='/' element={<PrivateRoute />}>
        <Route path={ROUTES.REFERRALS} element={<ReferralPage />} />
        <Route
          path={ROUTES.CREATE_REFERRAL}
          element={<CreateReferralPageNew />}
        />
        <Route
          path={ROUTES.UPDATE_PERSONAL_INFORMATION}
          element={<StartPersonalInfoPage />}
        />
        <Route
          path={ROUTES.PERSONAL_INFORMATION}
          element={<PersonalInfoPage />}
        />
        <Route
          path={ROUTES.MOLECULAR_PROFILING_DETAILS}
          element={<MolecularProfilingDetailsPage />}
        />
        <Route path={ROUTES.RESEARCH} element={<ResearchPage />} />
        <Route path={ROUTES.ANALYTICS} element={null} />
      </Route>

      {/*<Route path="/new-referral" element={<MainLayout />}>*/}
      {/*  <Route path="/new-referral" element={<CreateReferralPageNew />}/>*/}
      {/*</Route>*/}
    </Routes>
  );
};

export default App;
