import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import Input from '../../shared/Input';
import { SIZE, VARIANT } from '../../shared/Button';
import InputDayPicker from '../../shared/InputDayPicker';
import InputSelect from '../../shared/InputSelect';
import { getErrorMessage, validations } from '../../../utils/validations';
import { Department, Organization, Subdepartment } from '../../../store/organizations/model';
import { getOrganizationsSelectOptions } from '../../../store/organizations/selectors';
import { updateProfileType } from '../../../store/auth/thunkActions';
import { disableFutureDays } from '../../../utils/dateHelpers';
import {TButton, TForm} from './styled';
import {TButtonsGridContainer } from '../../../containers/StartPersonalInfoPage/styled';


interface IPicture {
  name: string;
  type: string;
  size: number;
  base64: string;
}

interface IPersonalInfoForm {
  formSubmit: (data: updateProfileType) => void;
  onPrintClick: () => void;
  errors?: Record<string, string[]>;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  dateOfBirth: string;
  organization: string | number;
  specialization: string;
  department: string | number;
  subdepartment: string | number;
  title: string;
  phoneNumber: string;
  disabledEdit: boolean;
  activeSendToPrint: boolean;
  organizationsList: Organization[];
  picture?: IPicture;
}



const PersonalInfoForm: FC<IPersonalInfoForm> = (
  {
    formSubmit,
    errors,
    firstName,
    lastName,
    email,
    middleName,
    dateOfBirth,
    organization,
    specialization,
    department,
    subdepartment,
    title,
    phoneNumber,
    disabledEdit,
    organizationsList,
    activeSendToPrint,
    onPrintClick
  },
) => {
  const { register, handleSubmit, formState, setValue, control, getValues, clearErrors } = useForm({ criteriaMode: "firstError", });

  const watchOrganizationFieldValue   = useWatch({ control, name: 'organization', defaultValue: organization  });
  const watchSubdepartmentFieldValue  = useWatch({ control, name: 'subdepartment',defaultValue: subdepartment });
  const watchDepartmentFieldValue     = useWatch({ control, name: 'department',   defaultValue: department});

  const organizationsSelectOptions = useSelector(getOrganizationsSelectOptions);

  const orgOptions = useMemo(() => {
    if(!organizationsSelectOptions || !organizationsSelectOptions?.length) return [{ value: '', label: '--' }];
    return organizationsSelectOptions.filter((option: { value: number; label: string; }) => !['Иное УЗ','Неизвестно'].includes(option?.label))
  },[organizationsSelectOptions])

  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>();

  const formattedDate = useMemo(() => dateOfBirth ? new Date(dateOfBirth) : null, [dateOfBirth]);

  // const [imageBase64, setImageBase64] = useState<IPicture>();

  const subdepartamentDataAndLabel:{ label: string; data: { value: string; label: string; }[] }  = useMemo(() => {
    if(!watchDepartmentFieldValue) return {label:'',data:[{ value: '', label: '--' }]};
    
    const selectedDepartament = selectedOrganization?.organizationDepartment?.find((value: Department) => value.id === watchDepartmentFieldValue);

    if(!selectedDepartament) return {label:'',data:[{ value: '', label: '--' }]};

    if(selectedDepartament?.nameOfficial === 'CONSULTATIVE_POLYCLINIC_DEPARTMENT') return {label:'Кабинет № *',data:[{ value: '', label: '--' }]};
    
    if(selectedDepartament?.nameOfficial === 'REPUBLICAN_MOLECULAR_GENETIC_LABORATORY'){
      let subdepartamentOptions = selectedDepartament.subdepartment.map((subdep: Subdepartment) => (
        {'value':subdep?.nameLocalRus ?? '', 'label':subdep?.nameLocalRus ?? { value: '', label: '--' } }
        ))
      return {label:'Лабораторное отделение',data:subdepartamentOptions};
    }

    return {label:'',data:[{ value: '', label: '--' }]};
  },[watchDepartmentFieldValue,selectedOrganization]);

  const departmentOptions = useMemo(() => {
    return selectedOrganization?.organizationDepartment.map(
      ({ id, nameLocalRus }) => ({ value: id, label: nameLocalRus }),
    ) ?? []
  }, [selectedOrganization]);

  useEffect(() => {
    if(Object.keys(formState.errors).length){
      setTimeout(() => clearErrors(),3000);
    }
  }, [formState.errors,clearErrors]);

  useLayoutEffect(() => {
    register('firstName', { value: firstName });
    register('lastName', { value: lastName });
    register('email', { value: email });
  },[register,firstName,lastName,email]);

  //set new avatar to form
  // useEffect(() => {
  //   let getPicture = getValues('picture');
  //   if (!getPicture && !!imageBase64) register('picture');
  //   if (!!imageBase64) setValue('picture', imageBase64);
  //
  // }, [imageBase64, setValue, getValues, register])

  const showDepartmentField = useMemo(() => {
    let orgID = getValues('organization') ?? +organization;

    const foundOrganization = organizationsList.find((item) => item.id === orgID);

    setSelectedOrganization(foundOrganization);

    return !!foundOrganization?.organizationDepartment?.length;
    // eslint-disable-next-line
  }, [watchOrganizationFieldValue, organization, organizationsList, getValues]);


  const showDepartment = useMemo(() => {
    let departmentObj = selectedOrganization?.organizationDepartment.find((item) => item.id === +watchDepartmentFieldValue)
    return departmentObj?.nameLocalRus ?? '';
  }, [selectedOrganization, watchDepartmentFieldValue]);


  // const handlePicture = useCallback((e) => {
  //   // get the files
  //   let file = e.target.files[0];
  //   let readyFile;
  //
  //   // Make new FileReader
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     // Make a fileInfo Object
  //     let fileInfo = {
  //       name: file.name,
  //       type: file.type,
  //       size: file.size,
  //       base64: reader?.result?.toString() ?? ''
  //     };
  //     readyFile = fileInfo;
  //
  //     if (readyFile) {
  //       setImageBase64(readyFile)
  //     }
  //   }
  //
  // }, [setImageBase64]);


  return (
    <TForm onSubmit={handleSubmit(formSubmit)}>
      {/*<TPictureBlock>*/}
      {/*  <TBlockUploadPhoto>*/}
      {/*    <TPhotoBlock>*/}
      {/*      <TBlockPhoto>*/}
      {/*        {imageBase64?.name || picture ? <TPhoto key={(imageBase64?.name || picture?.name) ?? 'keyPhoto'} src={imageBase64?.base64 || picture?.base64} alt="ProfileImage" /> : <Camera />}*/}
      {/*      </TBlockPhoto>*/}
      {/*    </TPhotoBlock>*/}
      {/*    <TInput type="file" multiple={false} id="actual-btn" hidden onChange={handlePicture} />*/}
      {/*    <TLabelBlock>*/}
      {/*      <TLabel>*/}
      {/*        {!disabledEdit && <label htmlFor="actual-btn" style={{ cursor: 'pointer' }}>Выберите фото</label>}*/}
      {/*        {imageBase64?.size && imageBase64.size > 900_000 && <small>Изображиние слишком объемное</small>}*/}
      {/*      </TLabel>*/}
      {/*    </TLabelBlock>*/}
      {/*  </TBlockUploadPhoto>*/}
      {/*</TPictureBlock>*/}
      <Input
        bordered
        type='text'
        marginTop={4}
        label='Фамилия *'
        disabled
        placeholder='Ваша фамилия'
        defaultValue={lastName}
        // errorText={getErrorMessage(formState.errors, 'lastName', errors)}
        // {...register('lastName', { ...validations.lastName, value: lastName })}
        // error={!!formState.errors.lastName || !!(errors && errors['lastName'])}
        // onBlur={async ({target}) => {
        //   setValue('lastName',target.value.trim());
        //   await trigger("lastName");
        // }}
      />
      <Input
        {...register('firstName', { ...validations.firstName, value: firstName })}
        label='Имя *'
        marginTop={4}
        placeholder='Ваше имя'
        disabled
        // errorText={getErrorMessage(formState.errors, 'firstName', errors)}
        // error={!!formState.errors.firstName || !!(errors && errors['firstName'])}
        // onBlur={async ({target}) => {
        //   setValue('firstName',target.value.trim());
        //   await trigger("firstName");
        // }}
        type='text'
        bordered
        defaultValue={firstName}
      />
      <Input
        {...register('middleName', { ...validations.firstName,required:false,value: middleName })}
        onBlur={({target}) => {
          setValue('middleName',target.value.trim())
        }}
        defaultValue={middleName}
        disabled={disabledEdit}
        error={!!(errors && errors['middleName'])}
        errorText={getErrorMessage(formState.errors, 'middleName', errors)}
        placeholder={disabledEdit ? 'Отчество не указано' : 'Ваше отчество'}
        label='Отчество'
        marginTop={4}
        type='text'
        bordered
      />
      <Input
        bordered
        disabled
        type='text'
        label='Личный email *'
        defaultValue={email}
        marginTop={4}
      />
      <Controller
        control={control}
        name='phoneNumber'
        defaultValue={phoneNumber}
        rules={validations.phoneNumber}
        render={({ field: { onChange, value, ref } }) => (
          <InputMask
            value={value}
            onChange={onChange}
            disabled={disabledEdit}
            mask='+375 (99) 999 99 99'
          >
            {(inputProps: any) => (
              <Input
                {...inputProps}
                bordered
                ref={ref}
                type='text'
                marginTop={4}
                label='Личный телефон *'
                disabled={disabledEdit}
                errorText={getErrorMessage(formState.errors, 'phoneNumber', errors)}
                error={!!formState.errors.phoneNumber || !!(errors && errors['phone'])}
                placeholder={disabledEdit ? 'Номер телефона не указан' : '+ 375 (_ _)  _ _ _   _ _   _ _'}
              />
            )}
          </InputMask>
        )}
      />
      <Controller
        control={control}
        name='dateOfBirth'
        rules={{
          required: false
        }}
        defaultValue={formattedDate}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <InputDayPicker
            bordered
            isBirthDate
            date={value}
            marginTop={4}
            error={invalid}
            id='dateOfBirth'
            onChange={onChange}
            label='Дата рождения'
            disabled={disabledEdit}
            popperPlacement='left-start'
            filterDate={disableFutureDays}
            placeholderText={disabledEdit ? 'Дата рождения не указана' : 'Выберите дату рождения'}
          />
        )}
      />
      {disabledEdit
        ? <Input
          bordered
          disabled
          type='text'
          marginTop={4}
          label='Учреждение здравоохранения *'
          placeholder='Учреждение не указано'
          defaultValue={selectedOrganization?.nameLocalRus}
        />
        : <Controller
          control={control}
          name='organization'
          defaultValue={organization}
          rules={validations.organization}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <InputSelect
              bordered
              value={value}
              error={invalid}
              placeholder='Выберите учреждение'
              label='Учреждение здравоохранения *'
              options={orgOptions}
              onChange={(value) => {
                setValue('specialization',undefined);
                setValue('title',undefined);
                setValue('department',undefined);
                setValue('subdepartment',undefined);
                return onChange(value)
              }}
              isSearchable={true}
              marginTop={4}
            />
          )}
        />
      }
      <Input
        bordered
        type='text'
        marginTop={4}
        label='Специализация *'
        disabled={disabledEdit}
        placeholder={disabledEdit ? 'Специализация не указана' : 'Хирургия'}
        errorText={getErrorMessage(formState.errors, 'specialization', errors)}
        error={!!formState.errors.specialization || !!(errors && errors['specialization'])}
        {...register('specialization', { ...validations.specialization, value: specialization })}
      />
      <Input
        bordered
        type='text'
        marginTop={4}
        label='Должность *'
        defaultValue={title}
        disabled={disabledEdit}
        {...register('title', { ...validations.title, value: title })}
        errorText={getErrorMessage(formState.errors, 'title', errors)}
        error={!!formState.errors.title || !!(errors && errors['title'])}
        placeholder={disabledEdit ? 'Должность не указана' : 'Врач-торакальный хирург'}
      />
      {!!showDepartmentField && disabledEdit &&
        <>
          <Input
            bordered
            disabled
            type='text'
            marginTop={4}
            label='Отделение *'
            defaultValue={showDepartment}
            placeholder='Отделение не выбрано'
          />

          <Input
            bordered
            disabled
            type='text'
            marginTop={4}
            label={!!subdepartamentDataAndLabel?.label ? subdepartamentDataAndLabel?.label : 'Лабораторное отделение или кабинет *'}
            defaultValue={watchSubdepartmentFieldValue}
            placeholder='Лабораторное отделение или кабинет не указан'
          />
        </>
      }

      {!!departmentOptions.length && !disabledEdit &&
        <>
          <Controller
            name='department'
            control={control}
            defaultValue={department}
            rules={validations.department}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <InputSelect
                bordered
                value={value}
                error={invalid}
                label='Отделение *'
                options={departmentOptions}
                placeholder={!value ? 'Выберите отделение' : ''}
                onChange={(value) => {
                  setValue('subdepartment',undefined);
                  return onChange(value)}}
                isSearchable={true}
                marginTop={4}
              />
            )}
          />
          {!!subdepartamentDataAndLabel?.label &&
            <Controller
              control={control}
              name='subdepartment'
              defaultValue={subdepartment}
              rules={{ ...validations.subdepartment, value: subdepartment }}
              render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                if(subdepartamentDataAndLabel?.label === 'Лабораторное отделение'){
                  return (
                    <InputSelect
                      bordered
                      value={value}
                      error={invalid}
                      placeholder={!value ? 'Выберите лабораторное отделение' : ''}
                      label='Лабораторное отделение *'
                      options={subdepartamentDataAndLabel?.data ?? []}
                      onChange={(value) => onChange(value)}
                      isSearchable={true}
                      marginTop={4}
                    />
                  )
                }
                return (
                <Input
                  bordered
                  type='number'
                  marginTop={4}
                  label={subdepartamentDataAndLabel?.label}
                  defaultValue={subdepartment}
                  placeholder='Укажите номер кабинета'
                  errorText={getErrorMessage(formState.errors, 'subdepartment', errors)}
                  {...register('subdepartment', { ...validations.subdepartment, value: subdepartment })}
                  error={!!formState.errors.subdepartment || !!(errors && errors['subdepartment'])}
                />)
              }
              }
            />
          }
        </>
      }
    <TButtonsGridContainer direction='end'>
      <TButton size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} type='submit' disabled={disabledEdit}>Сохранить</TButton>
      <TButton size={SIZE.SMALL} variant={VARIANT.DEFAULT} type='button' onClick={() => onPrintClick()} disabled={!activeSendToPrint}>Распечатать</TButton>
    </TButtonsGridContainer>
    </TForm>
  );
};

export default PersonalInfoForm;
