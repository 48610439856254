export const methodsForUrl: { [index: number]: string } = {
  1: 'ihc',
  2: 'fish',
  3: 'pcr',
  4: 'ss',
  5: 'fa',
};

export const OptionValue: { [index: string]: string } = {
  null: 'Метод не удалось определить',
  undefined: 'Метод не удалось определить',
  '': 'Метод не удалось определить',
  PCR: 'ПЦР',
  'Sanger sequencing': 'Секвениров-е по Сэнгеру',
  IHC: 'ИГХ',
  FISH: 'FISH',
  'Fragment analysis': 'Фрагментный анализ',
};

export const statusLocale: { [index: string]: any } = {
  New: { rus: 'Новое', code: 'New' },
  Complete: { rus: 'Завершено', code: 'COMPLETE' },
  Cancelled: { rus: 'Отменено', code: 'CANCELLED' },
  Cancel: { rus: 'Отменено', code: 'CANCELLED' },
  'In Progress': { rus: 'В процессе', code: 'IN_PROGRESS' },
};