import styled from 'styled-components';
import Input from '../../components/shared/Input';
import Tooltip from '../../components/shared/Tooltip';
import Table from '../../components/shared/Table';
import InputDayPicker from '../../components/shared/InputDayPicker';
import InputSelect from '../../components/shared/InputSelect';
import { Colors } from '../../styles/themeNew';

function getColorCircleStatus(status: string, theme: any) {
  switch (status) {
    case 'bio_pending':
      return theme.colors.orange[100];
    case 'morphology_pending':
      return theme.colors.orange[200];
    case 'markers_validation_pending':
      return theme.colors.alert.notification;
    case 'analysis_pending':
      return theme.colors.blue[200];
    case 'conclusion_pending':
      return theme.colors.product.primaryActive;
    case 'referral_pending':
      return theme.colors.alert.checkedRadioButton;
    case 'referral_completed':
      return theme.colors.green[100];
    case 'New':
      return theme.colors.orange[100];
    case 'IN_PROGRESS':
      return theme.colors.blue[200];
    case 'COMPLETE':
      return theme.colors.green[100];
    case 'CANCELLED':
      return theme.colors.alert.warning;
  }
  return theme.colors.alert.warning;
}

export const THeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
`;

export const TFilterWr = styled.div`
  box-sizing: border-box;
  margin: 0 20px;
  // width: fit-content;
  width: 96.9%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  background: #ffffff;
  // border-radius: 10px;
  align-self: self-start;
  margin-bottom: 8px;
  border-bottom: 1px solid ${Colors.MONOCHROM[200]};
`;

export const TFilterItem = styled.div<{ isActive?: boolean }>`
  box-sizing: border-box;
  padding: 8px 36px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 2px solid transparent;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? Colors.BRAND[500] : 'transparent')};
  cursor: pointer;
  background: linear-gradient(to right, #5ab7eb 50%, midnightblue, #666666 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ isActive }) =>
    isActive ? Colors.BRAND[500] : Colors.MONOCHROM[950]};
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  white-space: nowrap;

  :hover {
    background-position: 0 100%;
    -webkit-text-fill-color: ${Colors.BRAND[500]};
  }
`;

export const TPriorityField = styled.span<{ isCommon: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ isCommon, theme }) =>
    isCommon ? theme.colors.gray[500] : theme.colors.alert.warning};
`;

export const TStatusWr = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TIndicator = styled.div<{ status?: string; margin?: number }>`
  background-color: ${({ status, theme }) =>
    status ? getColorCircleStatus(status, theme) : theme.colors.alert.warning};
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: ${({ margin }) => (margin ? `${margin}px` : `18px`)};
`;

export const TLeftBlock = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  & > * {
    margin-right: 32px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const TBackLink = styled.div`
  position: absolute;
  top: 33px;
  left: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray[600]};
  width: max-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  & > *:first-child {
    margin: -3px 3px 0;
    font-size: 24px;
    line-height: 14px;
  }
`;

export const TRightBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 575px;
  gap: 12px;
  height: 46px;
`;

export const TInput = styled(Input)<{ disabled?: boolean; bordered?: boolean }>`
  width: 100%;
  & > div > input {
    padding-top: 11px;
    padding-bottom: 11px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  }
  & > div > div {
    right: 4px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const TLink = styled.a`
  position: absolute;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  color: #c1c1c1;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 6px;
`;

export const TTooltip = styled(Tooltip)`
  padding: 8px 12px !important;
  width: max-content;
  max-width: 303px;
  font-family: 'Inter', sans-serif;
`;

export const CustomTooltip = styled(Tooltip)`
  padding: 8px 12px !important;
  box-sizing: border-box !important;
  width: max-content;
  font-family: 'Inter', sans-serif;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #3f434d !important;
  text-align: start !important;
  max-width: 232px;
`;

export const TTable = styled(Table)`
  padding: 0 20px;
  box-sizing: border-box;
  div:first-of-type(div) {
    background-color: #f1f0ff;
  }
`;

export const TStatusTitle = styled.span<{ isSmallStatus?: boolean }>`
  display: inline-block;
  width: ${({ isSmallStatus }) => (isSmallStatus ? '105px' : '')};
  @media (max-width: 1440px) {
    display: ${({ isSmallStatus }) => (isSmallStatus ? 'inline-block' : '')};
    text-overflow: ${({ isSmallStatus }) => (isSmallStatus ? 'ellipsis' : '')};
    overflow: ${({ isSmallStatus }) => (isSmallStatus ? 'hidden' : '')};
    width: ${({ isSmallStatus }) => (isSmallStatus ? '105px' : '')};
  }
`;

export const EmptyReferralMessageBlock = styled.div`
  margin-left: 44px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[200]};
  width: 100%;

  & > span {
    color: ${({ theme }) => theme.colors.product.primaryActive};
  }
`;

export const TSearchOptions = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.BRAND[500]};
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.BRAND[500]};
  cursor: pointer;
  padding: 0 12px;
  gap: 8px;
`;

export const TSearchOptionsText = styled.p``;

export const TSearchOptionsInputsWr = styled.div`
  width: 100%;
`;

export const TIcon = styled.div<{
  size?: number;
  active?: boolean;
  fill?: string;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? size : 16)}px;
  height: ${({ size }) => (size ? size : 16)}px;
  path {
    fill: ${({ theme, fill }) => (fill ? fill : theme.colors.violet[300])};
  }
  ::before {
    content: '';
    position: ${({ active }) => (active ? 'absolute' : 'none')};
    top: -25px;
    border-radius: 50%;
    right: -25px;
    width: ${({ active }) => (active ? '16px' : '0')};
    height: ${({ active }) => (active ? '16px' : '0')};
    z-index: 4;
  }
`;

export const TCloseIcon = styled(TIcon)`
  cursor: pointer;
`;

export const TSearchOptionsArea = styled.div<{ open: boolean }>`
  position: fixed;
  right: ${({ open }) => (open ? `0px` : `-400px`)};
  transition: right 600ms cubic-bezier(0, 1, 1, 1);
  max-height: 80vh;
  will-change: right;
  justify-content: flex-end;
  top: 80px;
  width: 358px;
  min-height: 544px;
  overflow: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 4;
  padding: 22px 16px;
  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background-color: ${Colors.OTHER.white};

  div {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
  div > input {
    border: 1px solid ${Colors.MONOCHROM[200]};
    margin-top: 5px;
  }

  div > input:focus {
    border: 1px solid ${Colors.BRAND[400]};
    margin-top: 5px;
  }
`;

export const TSearchOptionsAreaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TSearchOptionsAreaHeaderText = styled.div<{
  paddingTop?: number;
  paddingBottom?: number;
  disabled?: boolean;
}>`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray[400] : Colors.MONOCHROM[950]};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : 0)}px;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : 0}px;
`;

export const TFilterDetailsWr = styled.form`
  position: relative;
  display: block;
  width: 100%;
  margin-top: 24px;
`;

export const TFilterDetails = styled.details<{ disabled?: boolean }>`
  margin: 8px 0;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid transparent;
  box-sizing: border-box;

  summary {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    svg {
      transform: rotate(0deg);
      transition: transform 0.2s ease-in-out;
    }
  }

  &[open] > summary {
    border-color: ${Colors.BRAND[400]};
    svg {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
    border-color: ${({ theme }) => theme.colors.gray[300]};
    background-color: transparent;

    summary {
      background-position: 49%;
      list-style: none;
    }
  }
`;

export const TFilterTitle = styled.summary`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 11px 12px;
  box-sizing: border-box;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.product.primaryHover} 33%,
    ${({ theme }) => theme.colors.gray[400]} 33%,
    ${({ theme }) => theme.colors.gray[400]} 66%,
    #2d2d2d 66%,
    #2d2d2d 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${Colors.MONOCHROM[950]};

  :hover {
    border-color: ${Colors.BRAND[400]};
  }
  list-style-type: none;
  outline: none;

  details[open] {
    display: none;
  }
`;

export const TFilterResultList = styled.ul`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  padding: 0 14px;
  margin: 0;
  margin-top: 8px;
`;

export const TFilterResultItem = styled.li`
  color: ${Colors.BRAND[400]};
`;

export const TFilterOptions = styled.div<{ isOpen?: boolean; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.violet[300]};
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? `308px` : `-100%`)};
  transition: right 600ms cubic-bezier(0, 1, 1, 1) 300ms;
  will-change: right;
  bottom: ${({ height }) => (height ? `calc(50vh - ${height / 2}px)` : `45vh`)};
  box-sizing: border-box;
  z-index: 3;
  .react-datepicker__tab-loop,
  .react-datepicker__tab-loop_start {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 0;
  }
  div > input {
    border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  }
`;

export const TFilterOptionsInFlow = styled.div<{
  isOpen?: boolean;
  height?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  margin-top: 5px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid ${Colors.BRAND[500]};
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  right: ${({ isOpen }) => (isOpen ? `308px` : `-100%`)};
  transition: right 600ms cubic-bezier(0, 1, 1, 1) 300ms;
  will-change: right;
  bottom: ${({ height }) => (height ? `calc(50vh - ${height / 2}px)` : `45vh`)};
  box-sizing: border-box;
  z-index: 3;
  .react-datepicker__tab-loop,
  .react-datepicker__tab-loop_start {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 0;
  }
  div > input {
    border: 1px solid ${Colors.MONOCHROM[200]};
    height: 50px;
  }
`;

export const TFilterButton = styled.button<{
  isMain?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.BRAND[500]};
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 7px 25px;
  background-color: ${({ isMain }) =>
    isMain ? Colors.BRAND[500] : 'transparent'};
  color: ${({ isMain }) => (isMain ? Colors.OTHER.white : Colors.BRAND[500])};
  width: 157px;
  height: 30px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  :disabled {
    background-color: ${({ isMain }) =>
      isMain ? Colors.MONOCHROM[200] : 'transparent'};
    color: ${({ isMain }) =>
      isMain ? Colors.MONOCHROM[400] : Colors.MONOCHROM[300]};
    border: 1px solid ${Colors.MONOCHROM[200]};
  }
`;

export const TButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 358px;
  margin-top: 40px;
`;

export const TWarningMsg = styled.div`
  margin-top: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.alert.warning};
`;

export const TInputDayPicker = styled(InputDayPicker)`
  width: 100%;
  input {
    padding: 16px 11px;
  }
`;

export const TInputSelect = styled(InputSelect)`
  width: 280px;
  [class$='control'] {
    padding: 20px 16px;
  }
  [class$='ValueContainer'] {
    margin-bottom: 2px;
    align-items: center;
  }
  [class$='singleValue'] {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const TCheckboxWr = styled.div`
  display: block;
  position: relative;
  width: fit-content;
  overflow-y: scroll;
  max-height: 225px;
  width: 100%;
  color: ${Colors.MONOCHROM[500]};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${Colors.MONOCHROM[200]};
    border-radius: 6px;
  }

  .container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :hover {
      color: ${({ theme }) => theme.colors.product.primaryActive};
    }
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fdfdfd;
    border: 1px solid ${Colors.MONOCHROM[200]};
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  /* .container:hover input ~ .checkmark {
    background-color: #7A78E9;
  } */

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${Colors.BRAND[500]};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.twoColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 310px;
    width: 400px;
  }

  &.gridColumns {
    display: grid;

    grid-gap: 5px 3px;
    grid-auto-flow: column;
    grid-template-rows: repeat(12, 1fr);
    grid-auto-columns: minmax(22px, auto);

    label {
      min-width: 140px;
    }
    & .container {
      margin-bottom: 0;
    }
  }
`;

export const ControllerWrapper = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;

export const TFilterButtonReset = styled.button`
  border: none;
  background: transparent;
  color: ${Colors.BRAND[500]};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-left: auto;
  margin-right: 0;
`;
