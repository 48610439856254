import { FC, useCallback, useState } from 'react';
import {
  Table,
  Thead,
} from '../../../../../components/shared/BiomaterialTable/BiomaterialTable';
import Textarea from '../../../../../components/shared/Textarea';
import { ISubBlock } from '../../../../../store/molecularProfiling/model';
import {
  TDescriptionWr,
  TGlassMessage,
  TInfoTextWrapper,
  TTableWr,
  TTBodyMorphological,
} from '../../styled';
import InfoModal from '../../../../../components/shared/InfoModal';
import Button, { SIZE, VARIANT } from '../../../../../components/shared/Button';
import MorphTableRow from './MorphTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { getMolecularProfilingLoading } from '../../../../../store/molecularProfiling/selectors';
import { EditButtonsWrapper, PrimaryButton, SecondaryButton } from '../../../styled';
import { shortTableHeaders } from '../../data';
import {
  commentarySelector,
  getMorphCharacteristicState,
  setCommentary,
  setSubBlocks,
} from '../../../redux/morphCharacteristic';
import { getTokens } from '../../../../../store/auth/selectors';
import { postNewBioMaterialSubBlocksUpdate } from '../../../../../store/molecularProfiling/thunkActions';
interface Props {
  isEdit: boolean;
  subBlockList: ISubBlock[];
  doctorName: string;
  hasEditMode: boolean;
  hasPermit: boolean;
  setIsEdit: (value: boolean) => void;
  confirmSend: any;
}

const MorphTable: FC<Props> = ({
  isEdit,
  subBlockList,
  doctorName,
  hasEditMode,
  hasPermit,
  setIsEdit,
  confirmSend,
}) => {
  const MolecularProfilingLoading = useSelector(getMolecularProfilingLoading);
  const tokens = useSelector(getTokens);
  const commentary = useSelector(commentarySelector);
  const morphCharacteristicState = useSelector(getMorphCharacteristicState);

  const dispatch = useDispatch();

  const [isShowConfirm, setShowConfirm] = useState<boolean>(false);
  const [reqSubmitted, setReqSbmitted] = useState<boolean>(false);

  const onCloseConfirmModal = useCallback(() => setShowConfirm(false), []);

  const handleAddNewComment = (comment: string) =>
    dispatch(setCommentary(comment.trim()));

  const handleSubmit = () => {
    setIsEdit(false);
    if (!morphCharacteristicState) return;

    const data = subBlockList
      .map((block) => {
        if (!block.id) return undefined;
        return {
          ...block,
          quality:
            morphCharacteristicState.qualities[block.id] || block.quality,
          priority:
            morphCharacteristicState.priorities[block.id] || block.priority,
          reviewed: true,
        };
      })
      .filter((block): block is ISubBlock => block !== undefined);

    dispatch(setSubBlocks(data));
    if (tokens?.access) {
      dispatch(postNewBioMaterialSubBlocksUpdate(tokens?.access, data as any));
    }
  };

  return (
    <>
      {!!subBlockList?.length && (
        <TInfoTextWrapper>
          <TGlassMessage>
            {'2. Результаты морфологической характеристики'}
          </TGlassMessage>
        </TInfoTextWrapper>
      )}
      <TTableWr>
        {!!subBlockList?.length && (
          <Table>
            <Thead>
              {shortTableHeaders.map((item: any) => (
                <th className='violet' key={item.id}>
                  {item.title}
                </th>
              ))}
            </Thead>
            <TTBodyMorphological>
              {subBlockList.map((sub: ISubBlock) => (
                <MorphTableRow
                  key={sub.id}
                  isEdit={isEdit}
                  subBlock={sub}
                  priorityOptionsLength={subBlockList.length}
                  subBlockList={subBlockList}
                />
              ))}
            </TTBodyMorphological>
          </Table>
        )}

        <TDescriptionWr column={true}>
          <Textarea
            key={`commentary_${isEdit}`}
            name='commentary'
            bordered={true}
            label={'Комментарий'}
            className={`description nothovered ${
              commentary?.length > 999 ? 'error' : ''
            }`}
            placeholder={'Введите текст'}
            disabled={!isEdit}
            defaultValue={commentary}
            onChange={({ target }) => handleAddNewComment(target.value)}
            error={commentary?.length > 999}
            maxLength={1000}
            height={100}
          />

          {doctorName && !hasEditMode && (
            <div className='implementer'>
              <b>{`${doctorName?.split(',')[0]}`.trim()}</b>,{' '}
              {`${doctorName?.split(',')[1]}`.trim()}
            </div>
          )}
        </TDescriptionWr>
        {hasPermit && hasEditMode && (
          <EditButtonsWrapper>
            <SecondaryButton disabled={!isEdit} onClick={handleSubmit}>
              Сохранить
            </SecondaryButton>
            <SecondaryButton disabled={isEdit} onClick={() => setIsEdit(true)}>
              Редактировать
            </SecondaryButton>
            <PrimaryButton
              disabled={isEdit}
              onClick={() => setShowConfirm(true)}
            >
              Завершить характеристику
            </PrimaryButton>
          </EditButtonsWrapper>
        )}
      </TTableWr>

      <InfoModal
        showModal={isShowConfirm}
        onModalToggle={onCloseConfirmModal}
        isWarning={true}
        title={'Хотите завершить морфологическую характеристику?'}
        hasCloseButton
        buttons={
          <>
            <Button
              size={SIZE.SMALL}
              disabled={!!MolecularProfilingLoading || reqSubmitted}
              variant={VARIANT.TRANSPARENT}
              onClick={onCloseConfirmModal}
            >
              Нет
            </Button>
            <Button
              size={SIZE.SMALL}
              disabled={!!MolecularProfilingLoading || reqSubmitted}
              onClick={() => {
                setReqSbmitted(true);
                confirmSend();
              }}
            >
              Да
            </Button>
          </>
        }
      />
    </>
  );
};

export default MorphTable;
