import { forwardRef, useLayoutEffect, useMemo } from "react";
import { format } from 'date-fns';
import { TModuleDefaultHeader, TModuleHeader, TModuleMarkers, TModulePatient, TPageContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentLocalName, getOrganizationLocalName } from "../../../store/organizations/selectors";
import { getTokens } from "../../../store/auth/selectors";
import { fetchMarkers } from "../../../store/markers/thunkActions";
// import MarkerRow from "./MarkerRow";

interface IConclusionToPrint {
  referral: any;
  [index: string]: any;
}

const ReferralToPrint = forwardRef<HTMLDivElement, IConclusionToPrint>((props, ref) => {
  let data = props.referral;

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);

  // const highPriorityMarkers = useSelector(getHighPriorityMarkers);
  // const lowPriorityMarkers = useSelector(getLowPriorityMarkers);
  // const [hasLowPriorityMarkers, setHasLowPryorityMarkers] = useState<boolean>(false);

  useLayoutEffect(() => {
    let localizationId = data?.diagnosis?.localization
    if (localizationId && tokens?.access) {
      tokens?.access && dispatch(fetchMarkers(tokens?.access, localizationId));
    }
  }, [data?.diagnosis?.localization, dispatch, tokens?.access]);

  const doctorProfile = data?.doctorReferral?.userProfile;
  const organizationLocalName = useSelector(getOrganizationLocalName(doctorProfile?.organization));
  const departmentLocalName = useSelector(getDepartmentLocalName(doctorProfile?.organization,doctorProfile?.department));
  const organizationCustom = data?.doctorReferral?.userProfile?.organizationCustom;

  const referralNumber = useMemo(() => data?.referralId ?? '--', [data]);


  const fullDoctorName = useMemo(() => {
    let doctorProfile = data?.doctorReferral?.userProfile;
    if (!doctorProfile) return '';
    let firstName = doctorProfile['firstName'] ?? '';
    let middleName = doctorProfile['middleName'] ?? '';
    let lastName = doctorProfile['lastName'] ?? '';
    return `${lastName} ${firstName?.[0]?.toUpperCase()}. ${middleName ? `${middleName?.[0]?.toUpperCase()}.` : ''}`.trim();

  }, [data?.doctorReferral?.userProfile]);

  const createdDate = useMemo(() => {
    if (!data?.dateOfAcquisition || !data?.dateCreated) return '';
    let date = data?.dateOfAcquisition || data?.dateCreated;
    return format(new Date(date), 'dd.MM.yyyy')
  }, [data?.dateCreated,data?.dateOfAcquisition]);

  const payment = useMemo(() => {
    if (!data?.paymentType) return '';
    if (data?.paymentType === 'Free') return 'бесплатно';
    return 'платно';
  }, [data?.paymentType]);

  const patient = useMemo(() => {
    let { gender = '', firstName = '', middleName = '', lastName = '', dateOfBirth = '' } = data?.patient ?? {};
    let pGender = (!!gender && (gender === 'Male' || gender === 'Мужчина') ? 'мужской' : 'женский') ?? '--'
    let pName = `${lastName} ${firstName} ${middleName}`.trim();
    let pBirthDay = dateOfBirth ? (dateOfBirth ?? '') && format(new Date(dateOfBirth), 'dd.MM.yyyy') : '--';
    return { gender: pGender, name: pName, birthDay: pBirthDay }
  }, [data?.patient]);

  const [clinicalDiagnosis, TNM_TNMStage] = useMemo(() => {
    let {icd_10,description,tCategory,tSub,nCategory,nSub,mCategory,mSub,stage,tnmNonApplicable,tnmUnknown,stageNonApplicable,stageUnknown} = data?.diagnosis?.clinical;

    let diagnosis = `${(icd_10 ?? '').split(' ')?.[0] ?? ''} ${description ?? ''}`;
    let TNM_Applicable = tnmNonApplicable ? 'неприменимо' : '';
    let TNM_Unknown = tnmUnknown ? 'не установлена' : '';
    let TNM = TNM_Applicable || TNM_Unknown ? TNM_Applicable || TNM_Unknown : `${tCategory ?? ''}${tSub ?? ''}${nCategory ?? ''}${nSub ?? ''}${mCategory ?? ''}${mSub ?? ''}`;

    let Stage_Applicable = stageNonApplicable ? 'неприменимо' : '';
    let Stage_Unknown = stageUnknown ? 'не установлена' : '';
    let TNMStage = Stage_Applicable || Stage_Unknown ? Stage_Applicable || Stage_Unknown : (stage ?? '')?.replace('--','');
    let TNMResult = '';
    if(TNM)      TNMResult = `, стадия: ${TNM}`;
    if(TNMStage) TNMResult += `, стадия${TNM ? '' : ': стадия'} ${TNMStage}`;
    
    return [diagnosis, TNMResult];
  }, [data]);

  const patientAmbulatoryCards = useMemo(() => {
    return data?.ambulatoryCard?.number ?? '--'
  }, [data]);

  const [blockNumbers] = useMemo(() => {
    // let materialType: string = '--';
    let blockNumbers: string[] = [];
    // let bloodNumber: string = '';

    let biologicalMaterial = data?.biologicalMaterial?.filter((item: any) => item.type === "block") ?? []
    // let blood = data?.biologicalMaterial?.filter((item: any) => item.type === "Blood")?.[0] ?? ''

    // materialType = biologicalMaterial?.[0]?.source?.toLowerCase() ?? '';
    // bloodNumber = !!blood?.material?.bloodId ? blood?.material?.bloodId.toString().padStart(9, "0") : ''

    for (let mainBlock of biologicalMaterial) {
      for (let block of mainBlock?.material?.blockCypher ?? []) {
        if (block?.original) {
          blockNumbers.push(block.code)
        }
      }
    }
    return [ blockNumbers]
  }, [data?.biologicalMaterial]);

  // const [drugSelectedList, markerSelectedList] = useMemo(() => {
  //   console.log(data.referralMarkers)
  //   let defaultSelectedMarkers = data?.referralMarkers
  //   console.log(defaultSelectedMarkers)
  //   const drugNameList = defaultSelectedMarkers
  //     ?.filter((value: any) => {
  //       if (value?.markerPriority?.priority === 'Low') setHasLowPryorityMarkers(true)
  //       return !!value?.markerPriority?.active
  //     })
  //     ?.map((data: any) => data?.markerPriority?.drugOrder?.drugName);
  //
  //
  //   // @ts-ignore
  //   const uniqueDrugNameList = [...new Set(drugNameList)]?.map((DName) => DName);;
  //
  //   const markerNameList = defaultSelectedMarkers?.map((data: any) => data?.markerPriority?.marker?.name);
  //   // @ts-ignore
  //   const uniqueMarkerNameList = [...new Set(markerNameList)]?.map((MName) => MName);
  //
  //   return [uniqueDrugNameList, uniqueMarkerNameList];
  //
  // }, [data?.referralMarkers, setHasLowPryorityMarkers]);

  interface ReferralMarker {
    id: number;
    marker: string;
    therapy: string[];
  }


  return <TPageContainer ref={ref}>
      <TModuleDefaultHeader>
        <caption className="capitalize">
          Направление на молекулярно-биологическое тестирование по определению чувствительности к химиопрепаратам
        </caption>
        <tbody>
          <tr>
            <th>
              <ul>
                <li>ФГАОУ ВО Первый МГМУ им. И.М. Сеченова Минздрава России (Сечинский университет)</li>
                <li>119048, Москва г, Трубецкая ул, дом №8, строение 2</li>
              </ul>
            </th>
            <th>
              <ul>
                {/*<li>Приложение 11 к приказу</li>*/}
                {/*<li>Министерства здравоохранения</li>*/}
                {/*<li>Республики Беларусь</li>*/}
                {/*<li>28.09.2007 №787</li>*/}
                {/*<li>Форма № 210/у-07</li>*/}
              </ul>
            </th>
          </tr>
        </tbody>
      </TModuleDefaultHeader>
      <TModulePatient>
        <TModuleHeader>№: {data?.createdReferralId ?? referralNumber}</TModuleHeader>
        <ol>
          <li><span>Ф.И.О. пациента: </span> {patient.name}</li>
          <li><span>Число, месяц, год рождения: </span> {patient.birthDay}</li>
          <li><span>Пол: </span> {patient.gender}</li>
          <li><span>Направившее учреждение, отделение: </span> {!!organizationCustom ? organizationCustom : `${organizationLocalName}${!!departmentLocalName ? `, ${departmentLocalName}` : ''}`}</li>
          <li><span>№ амбулаторной карты направившего учреждения: </span> {patientAmbulatoryCards}</li>
          <li><span>Клинический диагноз: </span> {clinicalDiagnosis}</li>
          {!!TNM_TNMStage && <li><span>TNM</span>{TNM_TNMStage}</li>}
          <li><span>Вид биологического материала: </span> парафиновый блок</li>
          <li><span>Материал для проведения исследования: </span>
            <ul>
              {blockNumbers?.map((blkNumber: string) => <li key={blkNumber}>парафиновый блок № {blkNumber}</li>) ?? ''}
            </ul>
          </li>
          <li><span>Оказание услуг: </span> {payment ?? '--'}</li>
        </ol>
      </TModulePatient>
      <TModuleMarkers>
        <thead>
          <tr>
            <th>Маркер</th>
            <th>Лекарственные средства</th>

          </tr>
        </thead>
        <tbody>
        {data?.referralMarkers?.map((markerObj: ReferralMarker) => (
          <tr key={markerObj.id}>
            <td >{markerObj.marker}</td>
            <td>
              {markerObj.therapy.join(', ')}
            </td>

          </tr>
        ))}
        </tbody>

      </TModuleMarkers>
      <TModuleHeader>Ф.И.О. врача: {fullDoctorName}</TModuleHeader>
      <TModuleHeader>Дата выдачи (дд.мм.гггг): {createdDate}</TModuleHeader>
    </TPageContainer>
})

export default ReferralToPrint;