import { FC } from 'react';
import { format } from 'date-fns';
import arrowSelectIcon from '../../../../../icons/details-arrow.svg';
import successStatusIcon from '../../../../../icons/done-green.svg';
import { TDateOfCompletion, TExecutor } from '../../styled';
import {
  TDetails,
  TDetailsContent,
  TSummary,
  TSummaryContentWithCheckbox,
  TSummaryIcon,
  TSummaryIconWrapper,
} from '../../../../../components/shared/Details/styled';
import { InfoRowWrapper } from '../../../styled';
import PreviousIHKTable from './PreviousIHKTable';
import { Checkbox } from '../../../../../componentsNew/Checkbox';

interface IComponentProps {
  Experiment: any;
  defaultExecutorOptions?: any;
  isSelectedOptions?: number[];
  handleChecked?: (id: number) => void;
  ExperimentIndex?: number;
  disbledLastExperements?: boolean;
  examMethodName: string;
  examExecutors: any;
  markerName: string;
}

const PreviousExperiment: FC<IComponentProps> = ({
  Experiment,
  defaultExecutorOptions,
  ExperimentIndex,
  isSelectedOptions,
  handleChecked,
  disbledLastExperements,
}) => {
  const handleSummaryChecked = (id: number) => {
    handleChecked?.(id)
  }

  const dateOfCompletion = Experiment?.dateCompleted
    ? format(new Date(Experiment.dateCompleted), 'dd.MM.yyyy - HH:mm:ss')
    : '';
  return (
    <TDetails
      open={
        disbledLastExperements ? !!Experiment?.acceptance : !ExperimentIndex
      }
    >
      <TSummary>
        <TSummaryContentWithCheckbox>
          <Checkbox
            checked={
              isSelectedOptions?.includes(Experiment.id) ||
              !!Experiment?.acceptance
            }
            onChange={({ target }) => handleSummaryChecked(+(target as HTMLInputElement).id)}
            id={`${Experiment.id}`}
            isDisabled={disbledLastExperements}
          />
          Попытка №{Experiment?.number}
        </TSummaryContentWithCheckbox>
        <TSummaryIconWrapper>
          <TSummaryIcon src={successStatusIcon} />
          <TSummaryIcon className='arrow' src={arrowSelectIcon} />
        </TSummaryIconWrapper>
      </TSummary>

      <TDetailsContent>
        <InfoRowWrapper direction='space-between'>
          {dateOfCompletion && (
            <TDateOfCompletion>
              Дата завершения: {dateOfCompletion}
            </TDateOfCompletion>
          )}
        </InfoRowWrapper>

        {/* {(Experiment?.clone || Experiment?.stainIntensity) && ( */}
          <PreviousIHKTable Experiment={Experiment} />
        {/* )} */}

        <TExecutor disabled>
          {defaultExecutorOptions?.[Experiment.executor]?.fullName ??
            'Исполнитель не определен'}
          ,{defaultExecutorOptions?.[Experiment.executor]?.title}
        </TExecutor>
      </TDetailsContent>
    </TDetails>
  );
};

export default PreviousExperiment;
