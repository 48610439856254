import styled from 'styled-components';
import { Colors } from '../../styles/themeNew';
import { Icon } from '../Icon';
import Popup from '../../containers/CreateReferralPageNew/components/ClinicalData/components/TnmClassification/Popup';
import { useState } from 'react';

type Props = {
  title?: string;
  required?: boolean;
  placeholder?: string;
  value: string;
  infoicon?: boolean;
  popupText?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  noBorder?: boolean;
  error?: boolean; 
  errorText?: string; 
  maxLength?: number; 
  height?: string;
  smallVariant?: boolean; 
  readOnly?: boolean;
  disabled?: boolean;
};

export const Textarea = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.maxLength && event.target.value.length > props.maxLength) {
      return; 
    }
    props.onChange(event);
  };

  const VariantStyledTextArea = props.smallVariant
    ? StyledTextAreaSmall
    : StyledTextarea;

  return (
    <StyledContainer>
      {props.title && (
        <StyledLabel htmlFor='textarea'>
          {props.title}{' '}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
          {props.infoicon && (
            <StyledContainerInfo
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => setIsVisible(false)}
            >
              <Icon type={isVisible ? 'info-colored' : 'info'} />
              <Popup visible={isVisible} position='right'>
                {props.popupText}
              </Popup>
            </StyledContainerInfo>
          )}
        </StyledLabel>
      )}

      <StyledMainContainer>
        <VariantStyledTextArea
          id='textarea'
          name='textarea_name'
          placeholder={props?.placeholder || ''}
          value={props.value}
          onChange={handleChange}
          noBorder={props.noBorder} 
          error={props.error} 
          maxLength={props.maxLength} 
          height={props.height} 
          readOnly={props.readOnly}
          disabled={props.disabled} 
        />
        {props.error && props.errorText && (
          <StyledErrorText>{props.errorText}</StyledErrorText>
        )}
        {props.maxLength && (
          <StyledCharCount>
            {props.value.length}/{props.maxLength}
          </StyledCharCount>
        )}
      </StyledMainContainer>
    </StyledContainer>
  );
};

// Styled components
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledMainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  gap: 4px;
`;

const StyledTextarea = styled.textarea<{
  noBorder?: boolean;
  error?: boolean;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : '100%')};
  margin: 0;
  padding: 16px;
  border-radius: 6px;
  border: ${({ noBorder, error }) =>
    noBorder
      ? 'none'
      : error
      ? `1px solid ${Colors.RED[500]}`
      : `1px solid ${Colors.MONOCHROM[200]}`};
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  color: ${Colors.MONOCHROM[950]};
  font-size: 16px;
  font-weight: 400;
  resize: none;

  &::placeholder {
    color: ${Colors.MONOCHROM[400]};
  }

  &:focus {
    outline: none;
    border-color: ${({ error }) =>
      error ? Colors.RED[500] : Colors.BRAND[500]};
  }

  &:disabled {
    background-color: ${Colors.MONOCHROM[100]}; // Example, adjust as needed
    cursor: not-allowed;
  }
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;

const StyledContainerInfo = styled.div`
  position: relative;
  padding-top: 4px;

  :hover {
    cursor: pointer;
  }
`;

const StyledErrorText = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  margin-top: 4px;
`;

const StyledCharCount = styled.div`
  color: ${Colors.MONOCHROM[400]};
  font-size: 12px;
  text-align: right;
  margin-top: 4px;
`;

const StyledTextAreaSmall = styled(StyledTextarea)`
  font-size: 12px;
`;
