import { FC, useMemo } from 'react';
import { ISubBlock } from '../../../../../store/molecularProfiling/model';
import { getQualityLabel, getSingsViolationLabel } from '../../utils';
import { Select } from '../../../../../componentsNew/Select';
import { useDispatch, useSelector } from 'react-redux';
import { setQualityForRow, setPriorityForRow, qualityForRowSelector, priorityForRowSelector } from '../../../redux/morphCharacteristic';
import { defaultOptionsPriority, optionsQuality } from '../../data';
import { MorphInfo, StyledMorphRow } from '../styled';
import { IAppState } from '../../../../../store';

type IComponentProps = {
  subBlock: ISubBlock;
  isEdit: boolean;
  priorityOptionsLength: number;
  subBlockList: ISubBlock[];
};

const MorphTableRow: FC<IComponentProps> = ({ subBlock, isEdit, priorityOptionsLength, subBlockList }) => {
  const dispatch = useDispatch();

  const quality = useSelector((state: IAppState) => qualityForRowSelector(subBlock.id as number)(state));
  const priority = useSelector((state: IAppState) => priorityForRowSelector(subBlock.id as number)(state));
  const handleChangeQuality = (value: string) => {
    if(!subBlock.id) return;

    dispatch(setQualityForRow({ rowId: subBlock.id, quality: value }));
  };

  const handleChangePriority = (value: number) => {
    if(!subBlock.id) return;

    dispatch(setPriorityForRow({ rowId: subBlock.id, priority: value }));
  };

  const updatedQualityOptions = useMemo(() => {
    const selectedValue = quality || String(subBlock.quality);
    return optionsQuality.map((option) => ({
      ...option,
      selected: option.value === selectedValue,
    }));
  }, [quality, subBlock.quality]);

  const selectedPriorities = useSelector((state: IAppState) =>
    subBlockList.map((sub) => priorityForRowSelector(sub.id as number)(state))
  );

  const updatedPriorityOptions = useMemo(() => {
    const limitedOptions = defaultOptionsPriority
      .slice(0, priorityOptionsLength + 1)
      .filter(option => !selectedPriorities.includes(Number(option.value)) || option.value === String(priority));
    return limitedOptions.map((option) => ({
      ...option,
      selected: option.value === String(priority || subBlock.priority),
    }));
  }, [priority, subBlock.priority, priorityOptionsLength, selectedPriorities]);

  return (
    <StyledMorphRow>
      <td>{subBlock.cypher}</td>
      <td>{subBlock?.packagingSafety ? 'Да' : 'Нет'}</td>
      <td>{subBlock?.cancerousCellPercentage ?? '--'}</td>
      <td>
        {subBlock?.singsViolation || subBlock?.singsViolation !== 'none'
          ? getSingsViolationLabel(subBlock?.singsViolation)
          : '--'}
      </td>
      <td>
        {isEdit ? (
          <Select
            required
            placeholder='--'
            options={updatedQualityOptions}
            onSelect={(e) => handleChangeQuality(e.value)}
            size='small'
          />
        ) : (
          <MorphInfo>
            {getQualityLabel(quality || subBlock.quality) || '--'}
          </MorphInfo>
        )}
      </td>
      <td>
        {isEdit ? (
          <Select
            required
            placeholder='--'
            options={updatedPriorityOptions}
            onSelect={(e) => handleChangePriority(Number(e.value))}
            size='small'
          />
        ) : (
          <MorphInfo>{priority || subBlock.priority || '--'}</MorphInfo>
        )}
      </td>
    </StyledMorphRow>
  );
};

export default MorphTableRow;