import {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TFormContainer } from './styled';
import { validations } from '../../../utils/validations';
import InputSelect from '../../shared/InputSelect';
import Textarea from '../../shared/Textarea';
import { Localization } from '../../../store/localizations/model';
import TNMBlock from './TNMBlock';
import { getFromSessionStorage } from '../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../constants/sessionStorageKeys';
import MKB_10 from "../../../containers/CreateReferralPage/steps/ClinicalData/MKB_10/MKB_10";

const { CLINICAL } = SESSION_STORAGE_KEYS;

interface IClinicalDataForm {
  localizationsData: Localization[] | null;
  items: any;
  mkb10Value: string,
  setMkb10Value:  Dispatch<SetStateAction<string>>;
  filteredMKB10: string[],
  showNoMatchError: boolean | undefined,
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const ClinicalDataForm: FC<IClinicalDataForm> = ({ localizationsData , items, mkb10Value, setMkb10Value, filteredMKB10, showNoMatchError, isOpen, setIsOpen}) => {

  const { control, setValue, clearErrors, register, formState } = useFormContext();

  useEffect(() => {
      const [localization, icd_10, description, tCategory, tSub, nCategory, nSub, mCategory, mSub, stage, stageSub, histological, tnmNonApplicable, tnmUnknown, stageNonApplicable, stageUnknown ] = getFromSessionStorage(
        CLINICAL.localization, CLINICAL.icd_10, CLINICAL.description, CLINICAL.tCategory, CLINICAL.tSub, CLINICAL.nCategory,
        CLINICAL.nSub, CLINICAL.mCategory, CLINICAL.mSub, CLINICAL.stage, CLINICAL.stageSub, CLINICAL.histological, 
        CLINICAL.tnmNonApplicable, CLINICAL.tnmUnknown, CLINICAL.stageNonApplicable, CLINICAL.stageUnknown
      );

      if (localization) {
        setValue('localization', +localization);
        setValue('icd_10', icd_10);
        setValue('description', description);
        setValue('tCategory', tCategory);
        setValue('tSub', tSub);
        setValue('nCategory', nCategory);
        setValue('nSub', nSub);
        setValue('mCategory', mCategory);
        setValue('mSub', mSub);
        setValue('stage', stage);
        setValue('stageSub', stageSub);
        setValue('histological', histological);

        setValue('tnmNonApplicable', tnmNonApplicable);
        setValue('tnmUnknown', tnmUnknown);
        setValue('stageNonApplicable', stageNonApplicable);
        setValue('stageUnknown', stageUnknown);
        setTimeout(() => clearErrors(), 1);
      }
      if(icd_10){
        setMkb10Value(icd_10);
        setIsOpen(false);
      }
    }, [ clearErrors, setValue, setMkb10Value, setIsOpen ] );


  const localizationOptions = useMemo(() =>
  localizationsData ?  localizationsData.map(({ id, typeRus }) => ({ value: id, label: typeRus })) : [], [localizationsData]);

  return (
    <TFormContainer>
      <Controller
        control={control}
        name='localization'
        rules={validations.localization}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <InputSelect
            onChange={(value) => onChange(value)}
            value={value}
            options={localizationOptions}
            placeholder='Локализация'
            label='Локализация *'
            error={invalid}
          />
        )}
      />
      <MKB_10
        items={items}
        mkb10Value={mkb10Value}
        setMkb10Value={setMkb10Value}
        filteredMKB10={filteredMKB10}
        showNoMatchError={showNoMatchError}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <Textarea
        {...register('description', validations.clinical)}
        label='Клинический диагноз *'
        error={!!formState.errors.description}
        placeholder={'Диагноз, указанный направителем, должен включать стадию, \n' +
          'информацию о проведенном лечении и иные релевантные данные\n'}
        maxLength={999}
      />
      <TNMBlock label='Классификация TNM *' />
      <Textarea
        height={110}
        {...register('histological', validations.histological)}
        label='Гистологическое заключение *'
        placeholder={'Свободный ввод.'}
        error={!!formState.errors.histological}
        maxLength={999}
      />
    </TFormContainer>
  );
};

export default ClinicalDataForm;
