import styled from "styled-components";

import { useEffect } from 'react';

import { Button } from "../../../../componentsNew/Button";
import { currentStepSelector, setCurrentStep } from "../../redux/currentStep";
import { useDispatch, useSelector } from "react-redux";
import { laboratorySelector } from "../../redux/laboratory";
import { selectedPatientConfirmedSelector } from "../../redux/patient";
import { setCreateQuestionModalActive } from "../../redux/common";
import { researchTypeSelector } from "../../redux/researchType";
import { clinicalDataSelector } from "../../redux/clinicalData";
import { bioMaterialRegistrationSelector } from "../../redux/biomaterialRegistration";
import {
  dateSelector as referrerDateSelector,
  selectedDoctorSelector,
} from "../../redux/referrer";
import { 
  REFERRAL_STEPS,
  REFERRAL_STEP__RESEARCH_TYPE,
  REFERRAL_STEP__LABORATORY,
  REFERRAL_STEP__PATIENT,
  REFERRAL_STEP__REFERRER,
  REFERRAL_STEP__CLINICAL_DATA,
  REFERRAL_STEP__BIO_MATERIAL_REGISTRATION,
  REFERRAL_STEP__MARKERS
} from "../../constants";

export const ControlButtons = () => {
  const dispatch = useDispatch();

  const currentStep = useSelector(currentStepSelector);
  const researchType = useSelector(researchTypeSelector);
  const selectedLaboratory = useSelector(laboratorySelector);
  const selectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);
  const selectedDoctor = useSelector(selectedDoctorSelector);
  const date = useSelector(referrerDateSelector);
  const clinicalData = useSelector(clinicalDataSelector);
  const biomaterialRegistrationData = useSelector(bioMaterialRegistrationSelector);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  const onBack = () => {
    const currentStepIndex = REFERRAL_STEPS.findIndex(
      (step) => step.code === currentStep.code
    );

    if (currentStepIndex >= 0) {
      const previousStep = REFERRAL_STEPS.find(
        (_, index) => index === currentStepIndex - 1
      );

      if (previousStep) {
        dispatch(
          setCurrentStep({
            code: previousStep.code,
            name: previousStep.name,
          })
        );
      }
    }
  };

  const onCancel = () => { };

  const onContinue = async () => {
    if (currentStep.code === REFERRAL_STEP__MARKERS) {
      dispatch(setCreateQuestionModalActive(true));
      return;
    }

    const currentStepIndex = REFERRAL_STEPS.findIndex(
      (step) => step.code === currentStep.code
    );

    if (currentStepIndex >= 0) {
      const nextStep = REFERRAL_STEPS.find((_, index) => index === currentStepIndex + 1);

      if (nextStep) {
        dispatch(
          setCurrentStep({
            code: nextStep.code,
            name: nextStep.name,
          })
        );
      }
    }
  };

  const checkContinueButtonDisabled = () => {
    if (currentStep.code === REFERRAL_STEP__RESEARCH_TYPE && !researchType) {
      return true;
    }

    if (currentStep.code === REFERRAL_STEP__LABORATORY && selectedLaboratory && !selectedLaboratory.id) {
      return true;
    }

    if (currentStep.code === REFERRAL_STEP__PATIENT && !selectedPatientConfirmed) {
      return true;
    }

    if (currentStep.code === REFERRAL_STEP__REFERRER &&
      !(date && (
        selectedDoctor.ulid || (
          selectedDoctor.userProfile.firstName && selectedDoctor.userProfile.lastName && selectedDoctor.userProfile.middleName
        )
      ))) {
      return true;
    }

    if (currentStep.code === REFERRAL_STEP__CLINICAL_DATA && (
      !clinicalData.selectedLocalization ||
      !clinicalData.selectedDiagnosis ||
      !clinicalData.clinicalDiagnosis ||
      ((!clinicalData.tnmNonApplicable && !clinicalData.tnmUnknown) && (
        !clinicalData.t ||
        !clinicalData.tSub ||
        !clinicalData.n ||
        !clinicalData.nSub ||
        !clinicalData.m ||
        !clinicalData.mSub
      )) ||
      ((!clinicalData.stageNonApplicable && !clinicalData.stageUnknown) && (
        !clinicalData.stage ||
        !clinicalData.sub
      )) ||
      ((!clinicalData.additionalClinicalDataNonApplicable && !clinicalData.additionalClinicalDataUnknown) && (
        !clinicalData.additionalClinicalData
      )) ||
      ((!clinicalData.previousAnalysisResultsNonApplicable && !clinicalData.previousAnalysisResultsUnknown) && (
        !clinicalData.previousAnalysisResults
      )) ||
      ((!clinicalData.preAmbulatoryCareNonApplicable && !clinicalData.preAmbulatoryCareUnknown) && (
        !clinicalData.preAmbulatoryCare
      ))
    )){
      return true;
    }

    if (currentStep.code === REFERRAL_STEP__BIO_MATERIAL_REGISTRATION && (
       !biomaterialRegistrationData.organization ||
       !biomaterialRegistrationData.bioSource ||
       !biomaterialRegistrationData.sampleType ||
       !biomaterialRegistrationData.methodOfAcquisition ||
       !biomaterialRegistrationData.dateOfAcquisition ||
       !(biomaterialRegistrationData.blocks[0]?.organ && biomaterialRegistrationData.blocks[0]?.cypher) ||
       biomaterialRegistrationData.blocks.some((block) => block.slides.some((slide) => !slide.cypher))
    )) {
      return true;
    }

    return false;
  };

  return (
    <StyledControlButtonsWrapper>
      <StyledControlButtonsContainer>
        <StyledControlButtonContainer>
          <Button text="Назад" type="SECONDARY" onClick={onBack} />
        </StyledControlButtonContainer>

        <StyledControlButtonContainer>
          <Button text="Отмена" type="SECONDARY" onClick={onCancel} />
        </StyledControlButtonContainer>

        <StyledControlButtonContainer>
          <Button
            text="Далее"
            type="PRIMARY"
            onClick={onContinue}
            disabled={checkContinueButtonDisabled()}
          />
        </StyledControlButtonContainer>
      </StyledControlButtonsContainer>
    </StyledControlButtonsWrapper>
  );
};

const StyledControlButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* position: sticky; */
  /* bottom: 0; */
  /* height: 200px; */
  background-color: white;

  padding: 32px 0 64px 0;
  margin-top: 200px;

  width: 696px;

  position: fixed;
  bottom: 0;
  z-index: 2;
`;

const StyledControlButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
`;

const StyledControlButtonContainer = styled.div`
  width: calc(100% / 3);
`;
