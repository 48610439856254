import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

export const TIconButton = styled.button<{ invert?: boolean }>`
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 6px 6px 2px 6px;
  background-color: ${Colors.BRAND[500]};
  border: 2px solid ${Colors.BRAND[500]};
  
  &:hover {
    cursor: pointer;
  }
  // &:disabled {
  //   cursor: default;
  //   background-color: ${({ theme, invert }) => invert ? 'transparent' : theme.colors.neutral.disable};
  //   border-color: ${({ theme, invert }) => invert ? theme.colors.gray[200] : theme.colors.product.primaryActive};
  // }
`;

export const TIconButtonLight = styled(TIconButton)`
  background-color: ${Colors.OTHER.white};
  border-color: ${Colors.BRAND[500]};
`;