import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../icons/check.svg';
import { ReactComponent as CheckSmallIcon } from '../../../icons/check-small.svg';
import checkIcon from '../../../icons/check.svg';

const TCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 7px;
  left: 6px;
`;

const TSmallCheckIcon = styled(CheckSmallIcon)`
  position: absolute;
  top: 4px;
  left: 3px;
`;

const TLabelWr = styled.div`
  line-height: 26px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
`;

const TFakeCheckbox = styled.span<{ smallSize?: boolean }>`
  cursor: pointer;
  width: ${({ smallSize }) => smallSize ? 14 : 22}px;
  height: ${({ smallSize }) => smallSize ? 14 : 22}px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: ${({ smallSize }) => smallSize ? 3 : 5}px;
  position: relative;
`;

const TRoot = styled.label<{ checked?: boolean; reverse?: boolean, disabled?: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
  ${({ reverse }) => reverse && 'justify-content: space-between;'}
  position: relative;
  align-items: center;
  width: max-content;
  color: ${({ theme, checked, disabled }) => checked && !disabled ? theme.colors.black[200] : theme.colors.gray[400]};
  
  & ${TLabelWr} {
    margin: ${({ reverse }) => reverse ? '0 16px 0 0' : '0 0 0 16px'};
    display: block;
  }
  
  & > div {
    display: flex;
  }
  
  & > div > ${TFakeCheckbox} > ${TCheckIcon}, & > div > ${TFakeCheckbox} > ${TSmallCheckIcon} {
    display: ${({ checked }) => checked ? 'block' : 'none'};
  }
`;

const TInput = styled.input.attrs({ type: "checkbox" })`
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;

  &:disabled + ${TFakeCheckbox} {
    cursor: default;
  }
 
  &:checked + ${TFakeCheckbox} {
    border-color: ${({ theme }) => theme.colors.product.primaryActive};
    background-color: ${({ theme }) => theme.colors.product.primaryActive};
  }

  &:checked:hover + ${TFakeCheckbox} {
    border-color: ${({ theme }) => theme.colors.product.primaryActive};
    background-color: ${({ theme }) => theme.colors.product.primaryActive};
  }

  &:checked:disabled + ${TFakeCheckbox} {
    cursor: default;
    border-color: ${({ theme }) => theme.colors.neutral.disable};
    background-color: ${({ theme }) => theme.colors.neutral.disable};
  }

  &:indeterminate + ${TFakeCheckbox} {
    border-color: ${({ theme }) => theme.colors.neutral.disable};
    background-color: ${({ theme }) => theme.colors.product.primaryActive};
    position: relative;
  }

  &:indeterminate + ${TFakeCheckbox}:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 22px;
    
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  &:indeterminate:disabled + ${TFakeCheckbox} {
    cursor: default;
    border-color: ${({ theme }) => theme.colors.neutral.disable};
    background-color: ${({ theme }) => theme.colors.neutral.disable};
  }
`;

export { TInput, TFakeCheckbox, TLabelWr, TRoot, TCheckIcon, TSmallCheckIcon };
