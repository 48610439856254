import { AxiosInstance } from "axios";

export type CreateIhcReferralApiRequestData = {
  referral_type: string;
  laboratory: number;
  patient: {
    ulid: string;
    country: number;
    address: string;
    gender: string;
    last_name: string;
    first_name: string;
    middle_name: string;
    id_passport: string;
    oms_number: string;
    snils_number: string;
    date_of_birth: string;
  };
  ambulatory_card: {
    organization_id: number;
    number: string;
  };
  doctor_referral: {
    ulid: string;
    user_profile: {
      last_name: string;
      first_name: string;
      middle_name: string;
    };
  };
  referral_date: string;
  diagnosis: {
    localization_id: number;
    icd_10: number;
    description: string;
    t_category: string;
    t_sub: string;
    n_category: string;
    n_sub: string;
    m_category: string;
    m_sub: string;
    tnm_non_applicable: boolean;
    tnm_unknown: boolean;
    stage: string;
    stage_non_applicable: boolean;
    stage_unknown: boolean;
    additional_info: string;
    additional_info_non_applicable: boolean;
    additional_info_unknown: boolean;
    previous_analysis_results: string;
    previous_analysis_results_non_applicable: boolean;
    previous_analysis_results_unknown: boolean;
    pre_ambulatory_care: string;
    pre_ambulatory_care_non_applicable: boolean;
    pre_ambulatory_care_unknown: boolean;
  }

  bio: {
    organization: number;
    bio_source: string;
    method_of_acquisition: string;
    sample_type: string;
    date_of_acquisition: string;
    blocks: Array<{
      organ: string;
      cypher: string;
      slides: Array<{
        organ: string;
        cypher: string;
      }>;
    }>;
  };
  markers: Array<number>;
};

export type CreateIhcReferralApiResponseData = {
  success: boolean;
  referral: string;
};

export class ReferralsService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async createIhcReferral(
    props: CreateIhcReferralApiRequestData
  ): Promise<CreateIhcReferralApiResponseData | undefined> {
    const url = `registry/referrals/`;
    return await (
      await this.httpClient?.post<CreateIhcReferralApiResponseData>(
        url,
        props
      )
    )?.data;
  }
}
