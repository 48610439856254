import React, { FC, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrowSelectIcon from '../../../../../icons/details-arrow.svg';
import { ISubBlock } from '../../../../../store/molecularProfiling/model';
import {
  optionsCategory,
  optionsCommon,
  optionsSingsViolation,
} from './../../data';
import {
  BlockContainer,
  BlockContent,
  BlockHeader,
  BlockIcon,
  BlockIconWrapper,
  Container,
  StyledInput,
  Title,
} from '../styled';
import { Select } from '../../../../../componentsNew/Select';
import { getSingsViolationLabel } from '../../utils';
import { IAppState } from '../../../../../store';
import {
  setDifficultyCategoryForRow,
  setPackagingSafetyForRow,
  setSampleContaminationForRow,
  setCancerousCellPercentageForRow,
  setSingsViolationForRow,
  setNecroticTissueForRow,
  difficultyCategoryForRowSelector,
  packagingSafetyForRowSelector,
  sampleContaminationForRowSelector,
  cancerousCellPercentageForRowSelector,
  singsViolationForRowSelector,
  necroticTissueForRowSelector,
  morphCharacteristicBlocksSelector,
  setSubBlocks,
} from '../../../redux/morphCharacteristic';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { postNewBioMaterialSubBlocksUpdate } from '../../../../../store/molecularProfiling/thunkActions';
import { getTokens } from '../../../../../store/auth/selectors';
import { BlockItem, ButtonContainer, EditButton, Label, RequiredMark, SaveButton } from '../../../styled';

interface Props {
  subBlockList: ISubBlock[];
  hasEditMode: boolean;
}

interface BlockFormProps {
  block: ISubBlock;
  hasEditMode: boolean;
}

const BlockForm: FC<BlockFormProps> = React.memo(({ block, hasEditMode }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const tokens = useSelector(getTokens);
  const morphCharacteristicBlocks = useSelector(
    morphCharacteristicBlocksSelector(block)
  );

  const difficultyCategory = useSelector((state: IAppState) =>
    difficultyCategoryForRowSelector(block.id!)(state)
  );
  const packagingSafety = useSelector((state: IAppState) =>
    packagingSafetyForRowSelector(block.id!)(state)
  );
  const sampleContamination = useSelector((state: IAppState) =>
    sampleContaminationForRowSelector(block.id!)(state)
  );
  const cancerousCellPercentage = useSelector((state: IAppState) =>
    cancerousCellPercentageForRowSelector(block.id!)(state)
  );
  const singsViolation = useSelector((state: IAppState) =>
    singsViolationForRowSelector(block.id!)(state)
  );
  const necroticTissue = useSelector((state: IAppState) =>
    necroticTissueForRowSelector(block.id!)(state)
  );

  const handleChange = (
    action: (payload: { rowId: number; value: any }) => void,
    value: any
  ) => {
    if (!block.id) return;
    dispatch(action({ rowId: block.id, value }));
  };

  const handleChangeNumber = (
    action: (payload: { rowId: number; value: number }) => void,
    value: string
  ) => {
    const num = Number(value);
    if (!isNaN(num) && num >= 0 && num <= 100) {
      dispatch(action({ rowId: block.id!, value: num }));
    }
  };

  const handleSubmit = () => {
    setIsEdit(false);
    if (!morphCharacteristicBlocks) return;

    const data = [
      {
        ...block,
        ...morphCharacteristicBlocks,
      },
    ];
    dispatch(setSubBlocks({ subBlockId: block.id, newData: data[0] }));
    if (tokens?.access) {
      dispatch(postNewBioMaterialSubBlocksUpdate(tokens?.access, data as any));
    }
  };

  const updatedOptionsCategory = useMemo(() => {
    const selectedValue =
      difficultyCategory || String(block.difficultyCategory);
    return optionsCategory.map((option) => ({
      ...option,
      selected: option.value === selectedValue,
    }));
  }, [difficultyCategory, block.difficultyCategory]);

  const updatedSafetyOptions = useMemo(() => {
    const selectedValue = packagingSafety ?? block.packagingSafety;
    return optionsCommon.map((option) => ({
      ...option,
      selected: option.value === String(selectedValue),
    }));
  }, [packagingSafety, block.packagingSafety]);

  const updatedContaminationOptions = useMemo(() => {
    const selectedValue = sampleContamination ?? block.sampleContamination;
    return optionsCommon.map((option) => ({
      ...option,
      selected: option.value === String(selectedValue),
    }));
  }, [sampleContamination, block.sampleContamination]);

  const updatedSingsViolationOptions = useMemo(() => {
    const selectedValue = singsViolation || block.singsViolation;
    return optionsSingsViolation.map((option) => ({
      ...option,
      selected: option.value === selectedValue,
    }));
  }, [singsViolation, block.singsViolation]);

  const fields = [
    {
      label: 'Категория сложности ',
      value: difficultyCategory || block.difficultyCategory || '--',
      options: updatedOptionsCategory,
      action: setDifficultyCategoryForRow,
      type: 'select',
    },
    {
      label: 'Сохранность упаковки ',
      value: packagingSafety ? 'Да' : 'Нет',
      options: updatedSafetyOptions,
      action: setPackagingSafetyForRow,
      type: 'select',
    },
    {
      label: 'Загрязненность образца ',
      value: sampleContamination ? 'Да' : 'Нет',
      options: updatedContaminationOptions,
      action: setSampleContaminationForRow,
      type: 'select',
    },
    {
      label: 'Опухолевые клетки, % ',
      value: cancerousCellPercentage ?? '',
      action: setCancerousCellPercentageForRow,
      type: 'input',
    },
    {
      label: 'Признаки нарушения фиксации ',
      value:
        getSingsViolationLabel(singsViolation || block.singsViolation) || '--',
      options: updatedSingsViolationOptions,
      action: setSingsViolationForRow,
      type: 'select',
    },
    {
      label: 'Доля некротизированной ткани, % ',
      value: necroticTissue ?? '',
      action: setNecroticTissueForRow,
      type: 'input',
    },
  ];

  return (
    <BlockContent>
      {fields.map((field, index) => (
        <BlockItem key={index}>
          <Label>
            {field.label}
            <RequiredMark>*</RequiredMark>
          </Label>
          {isEdit ? (
            field.type === 'select' ? (
              <Select
                required
                placeholder='Выберите значение'
                options={field.options || []}
                onSelect={(e) => handleChange(field.action, e.value)}
                size='medium'
              />
            ) : (
              <StyledInput
                type='text'
                value={field.value}
                onChange={(e) =>
                  handleChangeNumber(
                    field.action as ActionCreatorWithPayload<{
                      rowId: number;
                      value: number;
                    }>,
                    e.target.value
                  )
                }
                min='0'
                max='100'
                placeholder='Введите число'
              />
            )
          ) : (
            <StyledInput type='text' value={field.value} readOnly />
          )}
        </BlockItem>
      ))}
      {hasEditMode && (
        <ButtonContainer>
          <SaveButton disabled={!isEdit} onClick={handleSubmit}>
            Сохранить
          </SaveButton>
          <EditButton disabled={isEdit} onClick={() => setIsEdit(true)}>
            Редактировать
          </EditButton>
        </ButtonContainer>
      )}
    </BlockContent>
  );
});

const CollapsibleForm: FC<Props> = ({ subBlockList, hasEditMode }) => {
  const [openBlock, setOpenBlock] = useState<number | null>(null);

  const toggleBlock = useCallback((id: number) => {
    setOpenBlock((prev) => (prev === id ? null : id));
  }, []);

  return (
    <Container>
      <Title>1. Характеристики блоков, взятых для исследования</Title>
      {subBlockList.map((block) => (
        <BlockContainer key={block.id}>
          <BlockHeader
            onClick={() => block.id && toggleBlock(block.id)}
            isOpen={openBlock === block.id}
          >
            {block.cypher}
            <BlockIconWrapper>
              <BlockIcon
                src={arrowSelectIcon}
                $isOpen={openBlock === block.id}
              />
            </BlockIconWrapper>
          </BlockHeader>
          {openBlock === block.id && (
            <BlockForm block={block} hasEditMode={hasEditMode} />
          )}
        </BlockContainer>
      ))}
    </Container>
  );
};

export default React.memo(CollapsibleForm);
