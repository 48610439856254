import React from 'react';

type ArrowRightIconProps = {
    color?: string; // Accept a color prop
  };

export function ArrowRight({ color = "#25272C" }: ArrowRightIconProps): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 4.5L15.75 12L8.25 19.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}


