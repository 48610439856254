export const REFERRAL_STEP__RESEARCH_TYPE = "RESEARCH_TYPE";
export const REFERRAL_STEP__LABORATORY = "LABORATORY";
export const REFERRAL_STEP__PATIENT = "PATIENT";
export const REFERRAL_STEP__REFERRER = "REFERRER";
export const REFERRAL_STEP__CLINICAL_DATA = "CLINICAL_DATA";
export const REFERRAL_STEP__BIO_MATERIAL_REGISTRATION = "BIOMATERIAL_REGISTRATION";
export const REFERRAL_STEP__MARKERS = "MARKERS";

export const REFERRAL_STEPS: Array<{ code: string; name: string }> = [
  {
    code: REFERRAL_STEP__RESEARCH_TYPE,
    name: "Тип направления",
  },
  {
    code: REFERRAL_STEP__LABORATORY,
    name: "Выбор лаборатории",
  },
  {
    code: REFERRAL_STEP__PATIENT,
    name: "Пациент",
  },
  {
    code: REFERRAL_STEP__REFERRER,
    name: "Направитель",
  },
  {
    code: REFERRAL_STEP__CLINICAL_DATA,
    name: "Клинические данные",
  },
  {
    code: REFERRAL_STEP__BIO_MATERIAL_REGISTRATION,
    name: "Регистрация биоматериала",
  },
  {
    code: REFERRAL_STEP__MARKERS,
    name: "Маркеры",
  },
];
