import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDetailsContent } from '../../../../../../components/shared/Details/styled';
import { getProfile, getTokens } from '../../../../../../store/auth/selectors';
import { TExecutor, TSectionTitle } from '../../../styled';
import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
} from '../../../../../../componentsNew/Table/styled';
import { Colors } from '../../../../../../styles/themeNew';
import { Select } from '../../../../../../componentsNew/Select';
import {
  BlockItem,
  EditButtonsWrapper,
  InfoRowWrapper,
  Label,
  PrimaryButton,
  RequiredMark,
  SecondaryButton,
  TableCellWithInput,
} from '../../../../styled';
import {
  antibodyCloneSelector,
  commentarySelector,
  percentageTumorCellsSelector,
  positivityThresholdSelector,
  protocolSelector,
  researchResultSelector,
  scoreSelector,
  setAntibodyClone,
  setCommentary,
  setPercentageTumorCells,
  setPositivityThreshold,
  setProtocol,
  setResearchResult,
  setScore,
} from '../../../../redux/analisysNew';
import { Input } from '../../../../../../componentsNew/Input';
import { InputInTable } from '../../../../../../componentsNew/InputInTable';
import { Textarea } from '../../../../../../componentsNew/Textarea';
import { IExamExecutors } from '../../../model';
import { IAnalisysByMethod } from "../../../../../../store/analysis/model";
import { format } from "date-fns";
import {
  fetchAnalysisByBluprint,
  patchBioExperementCreate,
  resetAnalysisByBluprint
} from "../../../../../../store/analysis/thunkActions";

interface IComponentProps {
  markerName: string;
  examExecutors: IExamExecutors;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  flowStepsActiveIndex: number;
}

interface ISelectOptions {
  value: string | number;
  label: string;
  id?: number;
  selected?: boolean;
}

const ExpperimentTable: FC<IComponentProps> = ({ markerName, examExecutors, analisysByMethod, flowStepsActiveIndex }) => {
  const profile = useSelector(getProfile);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const tokens = useSelector(getTokens);

  const antibodyClone = useSelector(antibodyCloneSelector);
  const protocol = useSelector(protocolSelector);
  const score = useSelector(scoreSelector);
  const positivityThreshold = useSelector(positivityThresholdSelector);
  const percentageTumorCells = useSelector(percentageTumorCellsSelector);
  const researchResult = useSelector(researchResultSelector);
  const commentary = useSelector(commentarySelector);
  const [ihsResultDataOptions, setIhsResultDataOptions] = useState<ISelectOptions[]>([]);
  const [result, setResult] = useState<number|null>(null);
  const dispatch = useDispatch();

  const handleAntibodyCloneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setAntibodyClone(e.target.value));
  };

  const handleProtocolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProtocol(e.target.value));
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setScore(Number(e.target.value)));
  };

  const handlePositivityThresholdChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPositivityThreshold(Number(e.target.value)));
  };

  const handlePercentageTumorCellsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPercentageTumorCells(Number(e.target.value)));
  };

  const handleResearchResultChange = (value: number) => {
    setResult(value);
    dispatch(setResearchResult(value));
    setIhsResultDataOptions(ihsResultDataOptions.map(option => ({
      ...option,
      selected: option.value === value,
    })));
  };

  const handleCommentaryChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(setCommentary(e.target.value));
  };

  const handleSave = () => {
    setIsEdit(false);
  };

  const [executorfullName, executorTitle] = useMemo(() => {
    if (examExecutors?.blueprintExecutors?.length) {
      let data = examExecutors?.blueprintExecutors?.[0];
      let {
        firstName = '',
        lastName = '',
        middleName = '',
        specialization = '',
      } = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }
    if (!profile) return ['', ''];
    let {
      firstName = '',
      lastName = '',
      middleName = '',
      title = '',
    } = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName, title];
  }, [profile, examExecutors]);

  const resetFormData = useCallback(() => {
    dispatch(setAntibodyClone(''));
    dispatch(setProtocol(''));
    dispatch(setScore(null));
    dispatch(setPositivityThreshold(null));
    dispatch(setPercentageTumorCells(null));
    dispatch(setResearchResult(null));
    dispatch(setCommentary(''));
    setResult(null);
    setIhsResultDataOptions(prevOptions =>
      prevOptions.map(option => ({
        ...option,
        selected: false
      }))
    );
  }, [dispatch]);

  const submit = useCallback(async () => {
    const dataForSend = {
      clone: antibodyClone,
      protocol: protocol,
      score: score,
      positivity: positivityThreshold,
      tumor_cell_level: percentageTumorCells,
      notes: commentary,
      bio_acquired: true,
      result: result,
      completed: true,
      date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      analysis: analisysByMethod?.id,
      lab_assistant: analisysByMethod?.executor,
      number: flowStepsActiveIndex
    }
    if (tokens?.access) {
      await dispatch(patchBioExperementCreate(tokens?.access, dataForSend, 'ihc'));
    }
    resetFormData();
    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'ihc', analisysByMethod?.blueprint)), 100);
  }, [antibodyClone, protocol, score, positivityThreshold, percentageTumorCells, commentary, result, analisysByMethod?.id, analisysByMethod?.executor, flowStepsActiveIndex, tokens?.access, dispatch, resetFormData, analisysByMethod?.blueprint]);

  useLayoutEffect(() => {
    const ihsResultData = analisysByMethod?.markerIhc?.markerIhcResults || [];
    const transformedOptions = ihsResultData.map((item: { id: string; content: string }) => ({
      value: item.id,
      label: item.content,
      id: item.id,
      selected: false,
    }));
  
    setIhsResultDataOptions(transformedOptions);
  }, [analisysByMethod]);


  return (
    <TDetailsContent>
      <InfoRowWrapper direction={'space-between'}>
        <TSectionTitle width='100%' color={Colors.MONOCHROM[900]}>
          Результаты исследования биоматериала методом ИГХ
        </TSectionTitle>
      </InfoRowWrapper>

      <BlockItem>
        <Label>
          Клон антител
          <RequiredMark>*</RequiredMark>
        </Label>
        <Input
          value={antibodyClone}
          onChange={handleAntibodyCloneChange}
          placeholder='--'
          required
          readOnly={!isEdit}
          size={'small'}
        />
      </BlockItem>

      <BlockItem>
        <Label>
          Протокол (система детекции)
          <RequiredMark>*</RequiredMark>
        </Label>
        <Input
          value={protocol}
          onChange={handleProtocolChange}
          placeholder='--'
          required
          readOnly={!isEdit}
          size={'small'}
        />
      </BlockItem>
      <Table>
        <TableBody>
          <TableHeaderRow>
            <TableHeaderCell>Маркер</TableHeaderCell>
            <TableHeaderCell>Шкала (score)</TableHeaderCell>
            <TableHeaderCell>Порог позитивности, %</TableHeaderCell>
            <TableHeaderCell>Процент опухолевых клеток, %</TableHeaderCell>
            <TableHeaderCell>Результат исследования</TableHeaderCell>
          </TableHeaderRow>
          <tr>
            <TableCell>{markerName}</TableCell>
            <TableCellWithInput>
              <InputInTable
                value={score}
                onChange={handleScoreChange}
                placeholder='0-100'
                readOnly={!isEdit}
                min={0}
                max={100}
                onlyNumbers
              />
            </TableCellWithInput>
            <TableCellWithInput>
              <InputInTable
                value={positivityThreshold}
                onChange={handlePositivityThresholdChange}
                placeholder='0-100'
                readOnly={!isEdit}
                max={100}
                min={0}
                onlyNumbers
              />
            </TableCellWithInput>
            <TableCellWithInput>
              <InputInTable
                value={percentageTumorCells}
                onChange={handlePercentageTumorCellsChange}
                placeholder='0-100'
                readOnly={!isEdit}
                max={100}
                min={0}
                onlyNumbers
              />
            </TableCellWithInput>
            <TableCell>
              {isEdit ? (
                <Select
                  required
                  placeholder='--'
                  options={ihsResultDataOptions.map(option => ({
                    ...option,
                    value: String(option.value),
                    selected: option.selected ?? false,
                  }))}
                  onSelect={(option) =>
                    handleResearchResultChange(Number(option.value))
                  }
                  size='small'
                />
              ) : (
                <span>
                  {
                    ihsResultDataOptions.find(
                      (option) => option.value === researchResult
                    )?.label
                  }
                </span>
              )}
            </TableCell>
          </tr>
          <TableHeaderRow>
            <TableHeaderCell colSpan={5}>
              Клинико-лабораторное заключение
            </TableHeaderCell>
          </TableHeaderRow>
          <tr>
            <td colSpan={5}>
              <Textarea
                key={`commentary_${isEdit}`}
                placeholder={
                  isEdit
                    ? 'И/или введите текст в ручную'
                    : 'Введите дополнительные сведения при необходимости...'
                }
                value={commentary}
                onChange={handleCommentaryChange}
                error={commentary?.length > 999}
                maxLength={1000}
                height={'100px'}
                noBorder
                smallVariant
                readOnly={!isEdit}
              />
            </td>
          </tr>
        </TableBody>
      </Table>

      {!!executorfullName && (
        <TExecutor>
          {executorfullName ?? 'Исполнитель не определен'}
          {executorTitle ? `, ${executorTitle}` : ''}
        </TExecutor>
      )}

      <EditButtonsWrapper>
        <SecondaryButton disabled={!isEdit} onClick={handleSave}>
          Сохранить
        </SecondaryButton>
        <SecondaryButton disabled={isEdit} onClick={() => setIsEdit(true)}>
          Редактировать
        </SecondaryButton>
        <PrimaryButton disabled={isEdit} onClick={submit}>
          Завершить попытку
        </PrimaryButton>
      </EditButtonsWrapper>
    </TDetailsContent>
  );
};

export default ExpperimentTable;
