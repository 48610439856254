import styled from "styled-components";

import { MainContent } from "../../componentsNew/MainContent";
import { Title } from "../../componentsNew/Title";

import { useBackgroundData } from "./hooks/useBackgroundData";

import { CreateReferralPageNewSteps as Steps } from "./components/Steps";
import { Content } from "./components/Content";
import { ControlButtons } from "./components/ControlButtons";


export const CreateReferralPageNew = () => {
  useBackgroundData();

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="1">Создание направления</Title>
      </StyledTitleContainer>

      <MainContent>
        <Steps />
        <StyledContentContainer>
          <Content />
          <ControlButtons />
        </StyledContentContainer>

      </MainContent>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 6vw 24px;
  margin: 0 auto;

  /* min-height: calc(100vh + 148px); */
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const StyledContentContainer = styled.div`
  width: 696px;
  margin: 0 auto;
`;