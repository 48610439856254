import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../../../store';
import { ISubBlock } from '../../../store/molecularProfiling/model';

export interface MorphCharacteristicBlocksData {
  difficultyCategories: Record<number, string>;
  packagingSafety: Record<number, boolean>;
  sampleContamination: Record<number, boolean>;
  cancerousCellPercentage: Record<number, number>;
  singsViolation: Record<number, string>;
  necroticTissue: Record<number, number>;
}

interface MorphCharacteristicState extends MorphCharacteristicBlocksData {
  morphCharacteristicBlocks: MorphCharacteristicBlocksData | null;
  qualities: Record<number, string>;
  priorities: Record<number, number>;
  subBlocks: ISubBlock[];
  commentary: string;
}

const initialState: MorphCharacteristicState = {
  difficultyCategories: {},
  packagingSafety: {},
  sampleContamination: {},
  cancerousCellPercentage: {},
  singsViolation: {},
  necroticTissue: {},
  morphCharacteristicBlocks: null,
  qualities: {},
  priorities: {},
  subBlocks: [],
  commentary: '',
};

const morphCharacteristic = createSlice({
  name: 'morphCharacteristic',
  initialState,
  reducers: {
    setDifficultyCategoryForRow: (
      state,
      action: PayloadAction<{ rowId: number; value: string }>
    ) => {
      state.difficultyCategories[action.payload.rowId] = action.payload.value;
    },
    setPackagingSafetyForRow: (
      state,
      action: PayloadAction<{ rowId: number; value: boolean }>
    ) => {
      state.packagingSafety[action.payload.rowId] = action.payload.value;
    },
    setSampleContaminationForRow: (
      state,
      action: PayloadAction<{ rowId: number; value: boolean }>
    ) => {
      state.sampleContamination[action.payload.rowId] = action.payload.value;
    },
    setCancerousCellPercentageForRow: (
      state,
      action: PayloadAction<{ rowId: number; value: number }>
    ) => {
      state.cancerousCellPercentage[action.payload.rowId] =
        action.payload.value;
    },
    setSingsViolationForRow: (
      state,
      action: PayloadAction<{ rowId: number; value: string }>
    ) => {
      state.singsViolation[action.payload.rowId] = action.payload.value;
    },
    setNecroticTissueForRow: (
      state,
      action: PayloadAction<{ rowId: number; value: number }>
    ) => {
      state.necroticTissue[action.payload.rowId] = action.payload.value;
    },
    setQualityForRow: (
      state,
      action: PayloadAction<{ rowId: number; quality: string }>
    ) => {
      state.qualities[action.payload.rowId] = action.payload.quality;
    },
    setPriorityForRow: (
      state,
      action: PayloadAction<{ rowId: number; priority: number }>
    ) => {
      state.priorities[action.payload.rowId] = action.payload.priority;
    },
    setSubBlocks: (
      state,
      action: PayloadAction<
        | ISubBlock[]
        | { subBlockId: number | undefined; newData: Partial<ISubBlock> }
      >
    ) => {
      if (Array.isArray(action.payload)) {
        state.subBlocks = action.payload;
      } else {
        const { subBlockId, newData } = action.payload;
        state.subBlocks = state.subBlocks.map((subBlock) =>
          subBlock.id === subBlockId ? { ...subBlock, ...newData } : subBlock
        );
      }
    },
    setCommentary: (state, action: PayloadAction<string>) => {
      state.commentary = action.payload;
    },
    resetBlockForm: () => initialState,
  },
});

export const {
  setDifficultyCategoryForRow,
  setPackagingSafetyForRow,
  setSampleContaminationForRow,
  setCancerousCellPercentageForRow,
  setSingsViolationForRow,
  setNecroticTissueForRow,
  setQualityForRow,
  setPriorityForRow,
  setSubBlocks,
  setCommentary,
  resetBlockForm,
} = morphCharacteristic.actions;

export const morphCharacteristicReducer = morphCharacteristic.reducer;

export const getMorphCharacteristicState = (state: IAppState) =>
  state.molecularProfilingDetails.morphCharacteristic;

export const difficultyCategoryForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.difficultyCategories[rowId] || ''
  );

export const packagingSafetyForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.packagingSafety[rowId] || false
  );

export const sampleContaminationForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.sampleContamination[rowId] || false
  );

export const cancerousCellPercentageForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.cancerousCellPercentage[rowId] || 0
  );

export const singsViolationForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.singsViolation[rowId] || ''
  );

export const necroticTissueForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.necroticTissue[rowId] || 0
  );

export const qualityForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.qualities[rowId] || ''
  );

export const priorityForRowSelector = (rowId: number) =>
  createSelector(
    getMorphCharacteristicState,
    (state) => state.priorities[rowId] || 0
  );

export const morphCharacteristicBlocksSelector = (block: ISubBlock) =>
  createSelector(getMorphCharacteristicState, (state) => {
    if (!block.id) return;
    return {
      difficultyCategory:
        state.difficultyCategories[block.id] ||
        block.difficultyCategory ||
        undefined,
      packagingSafety: state.packagingSafety[block.id] ?? block.packagingSafety,
      sampleContamination:
        state.sampleContamination[block.id] ?? block.sampleContamination,
      cancerousCellPercentage:
        state.cancerousCellPercentage[block.id] ??
        block.cancerousCellPercentage,
      singsViolation:
        state.singsViolation[block.id] || block.singsViolation || '',
      necroticTissue: state.necroticTissue[block.id] ?? block.necroticTissue,
    };
  });

export const subBlocksSelector = createSelector(
  getMorphCharacteristicState,
  (state) => state.subBlocks
);

export const commentarySelector = createSelector(getMorphCharacteristicState, state => state.commentary);
