import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import { ReferralsService } from "../../../services/Referrals";
import { researchTypeSelector } from "../redux/researchType";
import { laboratorySelector } from "../redux/laboratory";
import { selectedPatientSelector, selectedAmbulatoryCardSelector } from "../redux/patient";
import { dateSelector as referrerDateSelector, selectedDoctorSelector } from "../redux/referrer";
import { clinicalDataSelector } from "../redux/clinicalData";
import { bioMaterialRegistrationSelector } from "../redux/biomaterialRegistration";
import { selectedMarkersSelector } from "../redux/markers";
import {
  setCreatedReferralNumber,
  setCreateSuccessModalActive,
  setCreateReferralErrors,
} from "../redux/common";

export const useCreate = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const referralsService = new ReferralsService(httpClient);

  const researchType = useSelector(researchTypeSelector);
  const laboratory = useSelector(laboratorySelector);
  const selectedPatient = useSelector(selectedPatientSelector);
  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);
  const selectedDoctor = useSelector(selectedDoctorSelector);
  const referrerDate = useSelector(referrerDateSelector);
  const clinicalData = useSelector(clinicalDataSelector);
  const biomaterialRegistrationData = useSelector(bioMaterialRegistrationSelector);
  const markers = useSelector(selectedMarkersSelector);

  const createIhcReferral = async () => {
    if (!laboratory?.id) {
      console.log('CreateIhcReferral: laboratory not found')
      return;
    }

    if (!selectedPatient) {
      console.log('CreateIhcReferral: patient not found')
      return;
    }

    if (!selectedAmbulatoryCard) {
      console.log('CreateIhcReferral: ambulatory card not found')
      return;
    }

    if (!selectedDoctor) {
      console.log('CreateIhcReferral: doctor not found')
      return;
    }

    const result = await referralsService.createIhcReferral({
      referral_type: researchType.code || "",
      laboratory: laboratory?.id,
      patient: {
        ulid: selectedPatient?.ulid || "",
        country: selectedPatient?.countryId || 112,
        id_passport: selectedPatient?.idPassport || "",
        address: selectedPatient?.address || "",
        gender: selectedPatient?.gender || "",
        last_name: selectedPatient?.lastName || "",
        first_name: selectedPatient?.firstName || "",
        middle_name: selectedPatient?.patronymic || "",
        oms_number: selectedPatient?.oms || "",
        snils_number: selectedPatient?.snils || "",
        date_of_birth: selectedPatient?.birthdate || "",
      },
      ambulatory_card: {
        organization_id: selectedAmbulatoryCard.organizationId as number,
        number: selectedAmbulatoryCard.number as string,
      },
      doctor_referral: {
        ulid: selectedDoctor.ulid,
        user_profile: {
          last_name: selectedDoctor.userProfile.lastName || "",
          first_name: selectedDoctor.userProfile.firstName || "",
          middle_name: selectedDoctor.userProfile.middleName || "",
        }
      },
      referral_date: referrerDate as string,
      diagnosis: {
        localization_id: clinicalData.selectedLocalization?.value
          ? parseInt(clinicalData.selectedLocalization?.value, 10)
          : 0,
        icd_10: clinicalData.selectedDiagnosis?.value
          ? parseInt(clinicalData.selectedDiagnosis?.value, 10)
          : 0,
        description: clinicalData.clinicalDiagnosis || "",
        t_category: clinicalData.t?.value || "",
        t_sub: clinicalData.tSub?.value || "",
        n_category: clinicalData.n?.value || "",
        n_sub: clinicalData.nSub?.value || "",
        m_category: clinicalData.m?.value || "",
        m_sub: clinicalData.mSub?.value || "",
        tnm_non_applicable: clinicalData.tnmNonApplicable,
        tnm_unknown: clinicalData.tnmUnknown,
        stage: clinicalData.stage?.value || "",
        stage_non_applicable: clinicalData.stageNonApplicable,
        stage_unknown: clinicalData.stageUnknown,
        additional_info: clinicalData.additionalClinicalData || "",
        additional_info_non_applicable: clinicalData.additionalClinicalDataNonApplicable,
        additional_info_unknown: clinicalData.additionalClinicalDataUnknown,
        previous_analysis_results: clinicalData.previousAnalysisResults || "",
        previous_analysis_results_non_applicable: clinicalData.previousAnalysisResultsNonApplicable,
        previous_analysis_results_unknown: clinicalData.previousAnalysisResultsUnknown,
        pre_ambulatory_care: clinicalData.preAmbulatoryCare || "",
        pre_ambulatory_care_non_applicable: clinicalData.preAmbulatoryCareNonApplicable,
        pre_ambulatory_care_unknown: clinicalData.preAmbulatoryCareUnknown,
      },
      bio: {
        organization: biomaterialRegistrationData?.organization || 1,
        bio_source: biomaterialRegistrationData?.bioSource || "",
        method_of_acquisition: biomaterialRegistrationData?.methodOfAcquisition || "",
        sample_type: biomaterialRegistrationData?.sampleType || "",
        date_of_acquisition: biomaterialRegistrationData?.dateOfAcquisition || "",
        blocks: biomaterialRegistrationData?.blocks?.map((block) => ({
          organ: block.organ,
          cypher: block.cypher,
          slides: block.slides.map((slide) => ({
            organ: block.organ,
            cypher: slide.cypher,
          })),
        })) || [],
    },
      markers: markers.map((marker) => marker.id),
    });

    if (result?.success) {
      dispatch(setCreateSuccessModalActive(true));
      dispatch(setCreatedReferralNumber(result?.referral));
    } else {
      dispatch(setCreateReferralErrors(result));
    };
  };

  return {
    createIhcReferral,
  };
};
