import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  TWrapper,
  TDataWr,
  TUsernameBlock,
  TAvatarWr,
  TName,
  TUserData,
  TUserDataBock,
  TButtonContainer,
} from './styled';
import UserPhoto from '../../images/user-photo.png';
import UserDoctorPhoto from '../../images/doctor.png';
import UserGenetistPhoto from '../../images/genetist.png';
import UserMorphologPhoto from '../../images/morpholog.png';
import UserRegistrantPhoto from '../../images/registrant.png';
import InfoBlock from './InfoBlock';
import Button, { SIZE, VARIANT } from '../../components/shared/Button';
import InfoModal from '../../components/shared/InfoModal';
import { ReactComponent as SuccessModalIcon } from '../../icons/success-modal.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileUpdated, getTokens, getUser } from '../../store/auth/selectors';
import { Profile } from '../../store/auth/model';
import { getOrganizations } from '../../store/organizations/selectors';
import { format } from 'date-fns';
import { putChangeProfile } from '../../store/auth/thunkActions';
import { updateProfileReset } from '../../store/auth';
import SupportContactComponent from "../../components/shared/SupportContactComponent/SupportContactComponent";

const PermitNameMap:{[index: string]: any;} = {
  localAdmin: 'Локал-админ',
  isSuperAdmin: 'Супер-админ',
  guest: 'Гость',
  registrar: 'Регистратор',
  doctor: 'Направитель',
  advisory: 'Советник',
  signatory: 'Подписант',
  labAssistant: 'Лаборант',
  morphologistBasic: 'Морфолог базовый',
  morphologistExecutor: 'Морфолог-исполнитель',
  geneticistExecutor: 'Генетик-исполнитель',
  geneticistCoordinator: 'Координатор'
}

const PersonalInfoPage: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const tokens = useSelector(getTokens);
  const isProfileUpdated = useSelector(getProfileUpdated);
  const organizations = useSelector(getOrganizations);
  const [showModal, setShowModal] = useState(false);

  const userData = useMemo(() => {
    const profile: Profile | undefined = user?.profile;
    //roles
    const isSuperAdmin = user?.isStaff;
    let roles:string[] = [];
    const permissions = user?.userRegistryPermissions;
    if(permissions){
      for( let permit in permissions){
        let permitValue = permissions[`${permit}`];
        let roleName = PermitNameMap[permit];
        if(roleName && permitValue === true) roles.push(roleName);
      }
      if(isSuperAdmin) roles.push(PermitNameMap['isSuperAdmin']);
    }
    roles = roles.sort();

    //profile data
    const {ulid,dateOfBirth, department:departmentID, firstName, lastName, middleName, organization:organizationID, phoneNumber, specialization, subdepartment:subdepartmentID, title} = profile  ?? {};
    const email = user?.user ?? '--';

    //organization and departament
    const userOrganization = organizations?.find(item => item.id === organizationID);
    const userOrganizationEmail = userOrganization?.email ?? '--';
    const userOrganizationDepartament = userOrganization?.organizationDepartment?.find(item => item.id === departmentID);
    const userOrganizationSubdepartment = userOrganizationDepartament?.subdepartment?.find(item => item.id === subdepartmentID);

    //user Avatar
    let userAvatar = UserDoctorPhoto;
    if(roles.includes('Направитель')) userAvatar = UserRegistrantPhoto;
    if(roles.find((value:string) => value.includes('Морфолог'))) userAvatar = UserMorphologPhoto;
    if(roles.find((value:string) => value.includes('Генетик'))) userAvatar = UserGenetistPhoto;
    if(isSuperAdmin || roles.includes('Администратор') || roles.includes('Пользователь')) userAvatar = UserPhoto;

    //birth date
    let birthDate
    if(!dateOfBirth) birthDate = '--';
    else birthDate = `${format(new Date(dateOfBirth), 'dd.MM.yyyy')} г`;

    return { ulid,email, userAvatar, firstName, lastName, middleName, 
      phoneNumber, specialization, title, roles,
      organizationID,departmentID,subdepartmentID,
      dateOfBirth,birthDate, userOrganizationEmail,
      organization:userOrganization?.nameLocalRus ?? '--', 
      department:userOrganizationDepartament?.nameLocalRus ?? '--', 
      subdepartment:userOrganizationSubdepartment?.nameLocalRus ?? '--', 
    }
  },[user,organizations]);


  const onModalToggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const sendChangeUserDataRequest = useCallback(() => {
    const formattedData = {
      "ulid": userData.ulid ?? '',
      "firstName": userData.firstName ?? '',
      "middleName": userData.middleName ?? '',
      "lastName": userData.lastName ?? '',
      "dateOfBirth": userData.dateOfBirth ?? '',
      "phoneNumber": userData.phoneNumber ?? '',
      "specialization": userData.specialization ?? '',
      "organization": !isNaN(Number(userData?.organizationID))  ? Number(userData?.organizationID) ?? '' : '',
      "department": !isNaN(Number(userData?.departmentID)) ? Number(userData?.departmentID)  ?? '' : '',
      "subdepartment": userData?.subdepartmentID ?? '',
      "title": userData.title ?? '',
      "roleField": userData.roles ?? [],
  }


  userData?.ulid && tokens?.access && dispatch(putChangeProfile(userData?.ulid, tokens?.access, formattedData));
  },[tokens?.access, dispatch,userData]);

  useEffect(() => {
    if(isProfileUpdated){
      setShowModal(true);
      dispatch(updateProfileReset());
    }

  },[isProfileUpdated,setShowModal,dispatch]);

  return (
    <TWrapper>
      {/* <TTitle>Данные профиля</TTitle> */}
      <TDataWr>
        <TUsernameBlock>
          <TAvatarWr src={userData?.userAvatar} alt='user' />
          <TName>{userData.firstName} {userData.lastName}</TName>
        </TUsernameBlock>
        <TUserData>
          <TUserDataBock>
            <InfoBlock title='Фамилия' value={userData?.lastName ? userData?.lastName : '--'}/>
            <InfoBlock title='Имя' value={userData?.firstName ? userData?.firstName : '--'}/>
            <InfoBlock title='Отчество' value={userData?.middleName ? userData?.middleName : '--'}/>
            <InfoBlock title='Дата рождения' value={userData?.birthDate ? userData?.birthDate : '--'} />
            <InfoBlock title='Личный телефон' value={!!userData?.phoneNumber ? userData?.phoneNumber : '--'}/>
            <InfoBlock title='Личный email' value={!!userData?.email ? userData?.email : '--'}/>
            <InfoBlock title='Роль' value={userData?.roles?.length ? userData.roles.join(', ') : '--'}/>
          </TUserDataBock>
          <TUserDataBock>
            <InfoBlock title='Учреждение здравоохранения' value={(userData?.organization ? userData?.organization : '--')?.toString()}/>
            <InfoBlock title='Отделение' value={userData?.department?.toString() ? userData?.department?.toString() : '--'} />
            <InfoBlock title='Лабораторное отделение / Кабинет' value={(userData?.subdepartment ? userData?.subdepartment : '--')?.toString()}/>
            <InfoBlock title='Специализация' value={userData?.specialization ? userData?.specialization : '--'}/>
            <InfoBlock title='Должность' value={userData?.title ? userData?.title : '--'}/>
            <InfoBlock title='Корпоративный email' value={userData?.userOrganizationEmail ? userData?.userOrganizationEmail : '--'}/>
          </TUserDataBock>
        </TUserData>
        <TButtonContainer>
        <Button variant={VARIANT.TRANSPARENT} onClick={sendChangeUserDataRequest}>Отправить запрос на изменение данных</Button>
      </TButtonContainer>
        <SupportContactComponent/>
      </TDataWr>

      <InfoModal
        title='Ваш запрос принят. Администратор свяжется с вами
        в ближайшее время'
        showModal={showModal}
        onModalToggle={onModalToggle}
        icon={<SuccessModalIcon />}
        buttons={<Button size={SIZE.SMALL} onClick={onModalToggle}>OK</Button>}
      />
    </TWrapper>
  );
}

export default PersonalInfoPage;