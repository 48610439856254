import styled from 'styled-components';
import Input from '../../../components/shared/Input';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { Colors } from '../../../styles/themeNew';

export const TCustomInput = styled(Input)<{
  width?: string;
  background?: string;
  disabled?: boolean;
}>`
  width: ${({ width }) => (width ? width : '100%')};
  padding-bottom: 16px;
  position: relative;
  & > div > div {
    display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
    right: 0;
    top: -2px;
  }

  & :read-only {
    cursor: default;
  }

  & input {
    padding: 12px 16px;
  }
`;

export const TEmptyPersonList = styled.div<{ marginTop?: number }>`
  width: 743px;
  padding: 10px 0px 10px 10px;
  color: ${({ theme }) => theme.colors.violet[100]};
  border: 1px solid ${({ theme }) => theme.colors.violet[100]};
  border-radius: 10px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
`;

export const TFormInputWr = styled.div`
  width: 100%;
`;

export const TErrorList = styled.ul`
  width: 756px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`;

export const TPersonFormWr = styled.div`
  width: 100%;

  @-webkit-keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }
    20% {
      display: block;
      opacity: 0.2;
    }
    40% {
      display: block;
      opacity: 0.3;
    }
    60% {
      display: block;
      opacity: 0.5;
    }
    80% {
      display: block;
      opacity: 0.9;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }
    20% {
      display: block;
      opacity: 0.2;
    }
    40% {
      display: block;
      opacity: 0.3;
    }
    60% {
      display: block;
      opacity: 0.5;
    }
    80% {
      display: block;
      opacity: 0.9;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  ul.errors-container {
    list-style: none;
    opacity: 1;
    li {
      color: ${({ theme }) => theme.colors.alert.notification};
      pointer-events: none;

      display: block;
      -webkit-animation-name: fadeIn;
      -webkit-animation-duration: 1s;
      animation-name: fadeIn;
      animation-duration: 1s;
    }
  }

  .enter {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: height 0ms 400ms, opacity 400ms 0ms;
  }
  .enter-active {
    opacity: 1;
    height: auto;
    transition: height 0ms 0ms, opacity 600ms 0ms;
  }
  .exit {
    opacity: 1;
    height: auto;
    transition: height 0ms 0ms, opacity 600ms 0ms;
  }
  .exit-active {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: height 0ms 400ms, opacity 400ms 0ms;
  }
`;

export const TPersonFormSelectWr = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TPersonFormButtonGroupWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: end;
`;

export const TPersonFormButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const TPersonFormButton = styled.button<{
  disabled?: boolean;
}>`
  background-color: ${({ disabled }) =>
    disabled ? Colors.MONOCHROM[200] : Colors.GREEN[500]};
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? Colors.MONOCHROM[400] : Colors.OTHER.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const TPersonFormButtonSecondary = styled(TPersonFormButton)<{
  marginBottom?: number;
  disabled?: boolean;
}>`
  background-color: ${({ disabled }) =>
    disabled ? Colors.MONOCHROM[200] : 'transparent'};
  border: 1px solid ${Colors.GREEN[500]};
  color: ${({ disabled }) =>
    disabled ? Colors.MONOCHROM[400] : Colors.GREEN[500]};
`;

export const TPersonFormManualSelectBtnWr = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 22px;
  span {
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.MONOCHROM[500]};
    margin-left: 12px;
  }
`;

export const TPersonFormManualInputWr = styled.form`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  width: 100%;
`;

export const TPersonList = styled.ul<{
  marginTop?: number;
  active?: boolean;
  hiddenSroll?: boolean;
}>`
  overflow: ${({ hiddenSroll }) => (hiddenSroll ? `scroll` : 'hidden')};
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 6px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  margin: 24px 0 8px;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #efeeff;
  }

  ::-webkit-scrollbar-thumb {
    background: #b7b5f1;
    box-shadow: inset 0 0 1px #efeeff;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-color: #b7b5f1 #efeeff;
`;

export const TPersonListElement = styled.li<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.MONOCHROM[950]};
  border: 1px solid ${Colors.MONOCHROM[200]};
  padding: 12px 16px 12px 12px;
  border-radius: 6px;
  margin-bottom: 8px;
`;

export const CrossIconWrapper = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

export const TInvertPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.gray[400]};
  }
`;
