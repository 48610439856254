import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/shared/Loader';
import { getProfile, getTokens } from '../../../store/auth/selectors';
import {
  IStepReferral,
  ISubBlock,
} from '../../../store/molecularProfiling/model';
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import MorphTable from './components/table/MorphTable';
import CollapsibleForm from './components/form/CollapsibleForm';
import {
  TDate,
  TDateOfCompletion,
  TMorphologicalCharacteristicWrapper,
  TRowWr,
} from './styled';
import {
  createNextStep,
  patchMorphologicalDataConfirm,
  postMorphologicalCharacteristicCreate,
} from '../../../store/molecularProfiling/thunkActions';
import {
  getMolecularProfilingErrors,
  getMolecularProfilingLoading,
} from '../../../store/molecularProfiling/selectors';
import { addAlert } from '../../../store/notifyAlerts/thunkActions';
import { AlertTypes } from '../../../store/notifyAlerts/model';
import {
  resetMolecularProfiling,
  resetSavedMolProCharacteristic,
} from '../../../store/molecularProfiling';
import {
  getMorphCharacteristicState,
  setSubBlocks,
  subBlocksSelector,
} from '../redux/morphCharacteristic';
import { REFERRAL_STATUS__MORPHOLOGY_PENDING } from '../../../constants/referral';

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

export interface IMorphOptionsList {
  value: number;
  label: string;
  startCode: string;
  endCode?: any;
  year: number;
  cancerousCellPercentage: number | null;
  priority: number;
  quality: string;
  disabled?: boolean;
  review?: boolean;
  originBlkockData?: any;
  [key: string | number]: any;
}

export interface ISelectedBlockOption {
  value: string;
  label: string;
  block: number;
  originBlkockData: any;
  blockArray: string[];
}

export interface ISubBlockSelect {
  originBlockId: number;
  originalBlockCode: string;
  cypher: string;
  new: boolean;
  selected: boolean;
}

interface ISublist {
  [key: string]: ISubBlockSelect;
}

export interface IBlockMap {
  [key: string]: ISublist;
}

export interface IFormStep {
  rangeOfBlocksDone: boolean;
  rangeOfBlocksSaved: boolean;
  blockCandidatesDone: boolean;
  blockCandidatesSaved: boolean;
  resultsOfMorphCharDone: boolean;
  resultsOfMorphCharSaved: boolean;
  [key: string]: boolean;
}

const MorphologicalCharacteristic: FC<IComponentProps> = (data) => {
  const dispatch = useDispatch();

  const { id: referralULID } = useParams();
  const tokens = useSelector(getTokens);

  const profile = useSelector(getProfile);
  const doctorsList = useSelector(getServiceUsers);
  const subBlocks = useSelector(subBlocksSelector);
  const morphCharacteristicState = useSelector(getMorphCharacteristicState);

  const molecularProfilingErrors: Record<string, string[] | string> | null =
    useSelector(getMolecularProfilingErrors);

  const MolecularProfilingLoading = useSelector(getMolecularProfilingLoading);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(
    () => setLoading(!!MolecularProfilingLoading),
    [MolecularProfilingLoading, setLoading]
  );

  const hasEditMode = useMemo(() => {
    return data.status === REFERRAL_STATUS__MORPHOLOGY_PENDING;
  }, [data]);

  const [isEdit, setEdit] = useState<boolean>(false);
  const [dateOfCompletion, setDateOfCompletion] = useState<string>(''); //Date when complited

  useEffect(() => {
    if (!data?.biologicalMaterial?.length) {
      dispatch(setSubBlocks([]));
      return;
    }
    const subBlocks = data.biologicalMaterial
      .map(
        (item: { material: { blockSublocks: ISubBlock[] } }) =>
          item.material.blockSublocks[0]
      )
      .filter((subBlock: ISubBlock | undefined) => subBlock !== undefined);

    dispatch(setSubBlocks(subBlocks));
  }, [data?.biologicalMaterial, dispatch]);

  const doctorWhoCompleted = useMemo(() => {
    if (!data?.stepReferral || !data?.stepReferral?.length) return '';
    let bioComliteObj: IStepReferral = data.stepReferral.find(
      (step: IStepReferral) => step.status === 'morphology_done'
    );
    let { dateSaved, userUlid } = bioComliteObj ?? {};
    let doctorWhoCompleted = '';
    let doctorName;
    // if (savedByDoctor) {
    //   doctorName = doctorsList?.find(
    //     (item) => item.ulid === savedByDoctor
    //   )?.userProfile;
    // }
    if (userUlid) {
      doctorName = doctorsList?.find(
        (item) => item.ulid === userUlid
      )?.userProfile;
    }

    if (doctorName) {
      let {
        firstName = '',
        lastName = '',
        middleName = '',
        title = '',
      } = doctorName;
      doctorWhoCompleted =
        `${lastName} ${firstName} ${middleName}`.trim() + ', ' + title;
    }

    if (!bioComliteObj && !doctorWhoCompleted) return '';
    if (!bioComliteObj && doctorWhoCompleted) return doctorWhoCompleted;

    //Completion date
    let completionDate = dateSaved
      ? format(new Date(dateSaved), 'dd.MM.yyyy - HH:mm:ss')
      : '';
    if (completionDate) setDateOfCompletion(completionDate);

    return doctorWhoCompleted;
  }, [data, doctorsList, setDateOfCompletion]);

  useEffect(() => {
    if (molecularProfilingErrors && molecularProfilingErrors?.length) {
      dispatch(
        addAlert(
          AlertTypes.ERROR,
          'Ошибка',
          JSON.stringify(molecularProfilingErrors, null, 2)
        )
      );
    }
  }, [molecularProfilingErrors, dispatch]);

  const confirm = () => {
    if (!tokens?.access || !referralULID) return;
    setLoading(true);

    const subblockUlids = subBlocks
      .map((block) => block.bioUlid)
      .filter((bioUlid): bioUlid is string => bioUlid !== undefined);

    const payload = {
      type: 'molecularProfiling',
      referral_ulid: data?.ulid,
      subblock_ulids: subblockUlids,
      recommendations: morphCharacteristicState.commentary,
      morphologist_ulid: profile?.ulid ?? '',
    };

    dispatch(postMorphologicalCharacteristicCreate(tokens?.access, payload));
    dispatch(
      createNextStep(tokens.access, {
        referral: data?.id,
        status: 'morphology_done',
      })
    );
    setTimeout(() => {
      dispatch(resetSavedMolProCharacteristic());
      dispatch(resetMolecularProfiling());
    }, 2000);
    dispatch(patchMorphologicalDataConfirm(tokens?.access, referralULID));
    setLoading(false);
  };

  return (
    <TMorphologicalCharacteristicWrapper>
      <TRowWr direction={'space-between'}>
        <TDateOfCompletion>
          <span>Дата создания в системе: </span>
          <TDate>{dateOfCompletion}</TDate>{' '}
        </TDateOfCompletion>
      </TRowWr>
      <Loader enabled={loading}>
        <CollapsibleForm subBlockList={subBlocks} hasEditMode={hasEditMode} />
        <MorphTable
          key={`MorphTable_${subBlocks?.length}`}
          isEdit={isEdit}
          subBlockList={subBlocks}
          doctorName={!!doctorWhoCompleted ? doctorWhoCompleted : ''}
          confirmSend={confirm}
          hasPermit={!!data?.ableToUdateREFERRAL?.updateMorphology}
          hasEditMode={hasEditMode}
          setIsEdit={setEdit}
        />
      </Loader>
    </TMorphologicalCharacteristicWrapper>
  );
};

export default MorphologicalCharacteristic;