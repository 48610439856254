import { FC } from 'react';
import { TIconButton, TIconButtonLight } from './styled';

interface IIconSquareButton {
  disabled?: boolean;
  invert?: boolean;
  isLightMode?: boolean;
  className?: string;
  onClick?: (e: any) => void;
  type?: "reset" | "button" | "submit";
}

const IconSquareButton: FC<IIconSquareButton> = ({ children, invert, isLightMode, ...restProps }) => {
  const ButtonComponent = isLightMode ? TIconButtonLight : TIconButton;
  return (
    <ButtonComponent invert={invert} {...restProps}>
      {children}
    </ButtonComponent>
  );
};

export default IconSquareButton;