import styled from 'styled-components';
import Table from '../../components/shared/Table';
import Input from '../../components/shared/Input';
import { Colors } from '../../styles/themeNew';

export const TLeftBlock = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-right: 32px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const TBackLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray[600]};
  width: max-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-top: 8px;

  & > *:first-child {
    margin: -3px 3px 0;
    font-size: 24px;
    line-height: 14px;
  }
`;

export const TTable = styled(Table)`
  padding: 0 20px;
  box-sizing: border-box;
`;

export const TRightBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  width: 100%;
  column-gap: 12px;
`;

export const TInput = styled(Input)<{
  disabled?: boolean;
  bordered?: boolean;
  width?: number;
}>`
  & > div > input {
    padding: 8px 16px 8px 48px;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
    border: 1px solid ${Colors.MONOCHROM[200]};
  }
  & > div > div {
    right: 4px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    svg {
      path,
      circle {
        stroke: ${({ theme, disabled }) =>
          disabled ? theme.colors.gray[300] : Colors.MONOCHROM[600]};
      }
    }
  }
`;

export const EmptyResearchMessageBlock = styled.div`
  margin-left: 44px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[200]};
  width: 100%;
`;
