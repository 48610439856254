import { ReactNode } from 'react';
import { ReactComponent as ReferralIcon } from '../../icons/referral.svg';
// import { ReactComponent as NotificationIcon } from '../../icons/notification.svg';
import { ReactComponent as ResearchIcon } from '../../icons/research.svg';
import { ROUTES } from '../../constants/routes';

export type MenuItemsType = {
  icon: ReactNode,
  name: string,
  url: string,
  isActive?: boolean,
}

export const menuItems: MenuItemsType[] = [
  {
    icon: <ReferralIcon />,
    name: 'Направления',
    url: ROUTES.REFERRALS,
  },
  {
    icon: <ResearchIcon />,
    name: 'Исследования',
    url: ROUTES.RESEARCH,
  },
  // {
  //   icon: <AccountIcon />,
  //   name: 'Пациенты',
  //   url: ROUTES.PATIENTS,
  // },
  //временно закоментировано
  // {
  //   icon: <NotificationIcon />,
  //   name: 'Уведомления',
  //   url: ROUTES.NOTIFICATIONS,
  // },
  // {
  //   icon: <AnalyticIcon />,
  //   name: 'Аналитика',
  //   url: ROUTES.ANALYTICS,
  // },
  // {
  //   icon: <CalendarIcon />,
  //   name: 'Календарь',
  //   url: ROUTES.CALENDAR,
  // },
  // {
  //   icon: <InfoIcon />,
  //   name: 'Информация',
  //   url: ROUTES.INFO,
  // },
  // {
  //   icon: <LogIcon />,
  //   name: 'Журнал',
  //   url: ROUTES.LOG,
  // },
]