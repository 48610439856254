import { StepState } from '../../../../componentsNew/Steps/types/Step';
import { ANALISYS_STEPS_DATA, ANALISYS_STEPS_STATUSES } from '../data';

export class AnalisysStepStateChecker {
  static getAttempt1(stepCurrentIndex: number) {
    if (stepCurrentIndex === ANALISYS_STEPS_STATUSES.attempt_1) {
      return StepState.SELECTED;
    }
    if (stepCurrentIndex > ANALISYS_STEPS_STATUSES.attempt_1)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getAttempt2(stepCurrentIndex: number) {
    if (stepCurrentIndex === ANALISYS_STEPS_STATUSES.attempt_2)
      return StepState.SELECTED;

    if (stepCurrentIndex > ANALISYS_STEPS_STATUSES.attempt_2)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getAttempt3(stepCurrentIndex: number) {
    if (stepCurrentIndex === ANALISYS_STEPS_STATUSES.attempt_3)
      return StepState.SELECTED;

    if (stepCurrentIndex > ANALISYS_STEPS_STATUSES.attempt_3)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getResearchCompleted(stepCurrentIndex: number) {
    if (stepCurrentIndex === ANALISYS_STEPS_STATUSES.research_completed)
      return StepState.SELECTED;

    if (stepCurrentIndex > ANALISYS_STEPS_STATUSES.research_completed)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }
}

export const analysisStepsWithState = (stepCurrentIndex: number) => {
  return [
    {
      ...ANALISYS_STEPS_DATA[0],
      state: AnalisysStepStateChecker.getAttempt1(stepCurrentIndex),
    },
    {
      ...ANALISYS_STEPS_DATA[1],
      state: AnalisysStepStateChecker.getAttempt2(stepCurrentIndex),
    },
    {
      ...ANALISYS_STEPS_DATA[2],
      state: AnalisysStepStateChecker.getAttempt3(stepCurrentIndex),
    },
    {
      ...ANALISYS_STEPS_DATA[3],
      state: AnalisysStepStateChecker.getResearchCompleted(stepCurrentIndex),
    },
  ];
};
