import { AxiosInstance } from "axios";

export type ApiLocalization = {
  id: number;
  type_eng: string;
  type_rus: string;
  description: string;
};

export class LocalizationsService {
  private httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async searchLocalizations(researchType: string): Promise<Array<ApiLocalization> | undefined> {
    const url = `registry/localizations/?referral_type=${researchType}`;

    return await (
      await this.httpClient?.get<Array<ApiLocalization>>(url)
    )?.data;
  }
}
