import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  Table,
  TBody,
  Thead,
} from '../../../components/shared/BiomaterialTable';
import { FLOW_STEPS_STATUSES } from '../../../fixtures/StepsMolecularProfilingPage';
import applyIcon from '../../../icons/apply.svg';
import editIcon from '../../../icons/edit-pencil.svg';
import cancelIcon from '../../../icons/cross-red.svg';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { fetchServiceUsers } from '../../../store/serviceUsers/thunkActions';
import { fetchMethodTypeList } from '../../../store/markers/thunkActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPermitViewExperement,
  getTokens,
  getUser,
} from '../../../store/auth/selectors';
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import {
  Examination,
  IBiologicalMaterial,
  IStepReferral,
} from '../../../store/molecularProfiling/model';
import defaultTheme from '../../../styles/theme';
import {
  TIndicator,
  TMenuIcon,
  TRowWR,
  TWrapper,
  customStylesOptions,
  TSendingErrorMessage,
  InputWrapper,
  TMenuIconWrapper,
} from './styled';
import {
  fetchRegistryMarkerList,
  postExaminationBlueprintsNumberUpdate,
  putExaminationBlueprintsUpdate, // IMPORTANT
} from '../../../store/molecularProfiling/thunkActions';
import {
  resetMolecularProfiling,
  updateExaminationBlueprintsFailure,
} from '../../../store/molecularProfiling';
import { getMolecularProfilingErrors } from '../../../store/molecularProfiling/selectors';
import { Link, useParams } from 'react-router-dom';
import { TDateOfCompletion } from '../MarkersValidation/styled';
import { UserRegistryPermissions } from '../../../store/auth/model';
import NoDataToShow from '../NoDataToShow';
import { format } from 'date-fns';
import { methodsForUrl, statusLocale } from './data';
import { Input } from '../../../componentsNew/Input';
import {
  ButtonContainer,
  EditButton,
  InfoRowWrapper,
  SaveButton,
  // SecondaryButton,
} from '../styled';
import Tooltip from '../../../components/shared/Tooltip';
import Select from 'react-select';

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

export interface IBlueprint {
  id: number;
  bluprintNumber?: string;
  examination: number;
  marker: string;
  bio?: any;
  method?: any;
  executor: string;
  status: string;
  code: string;
}

function shortName(fName: string, lName: string, mName: string) {
  let lastName = lName ? lName[0].toUpperCase() + '.' : '';
  let midName = mName ? mName[0].toUpperCase() + '.' : '';
  return `${fName} ${lastName} ${midName}`;
}

const AnalysisExecution: FC<IComponentProps> = (data) => {
  const {
    control,
    reset,
    getValues,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { fields, append } = useFieldArray({ control, name: 'examination' });

  //Permissions
  const ableToViewExperement = useSelector(checkPermitViewExperement);

  const dispatch = useDispatch();
  const { id: referralULID } = useParams();
  const tokens = useSelector(getTokens);
  const currentUser = useSelector(getUser);
  const getErrors = useSelector(getMolecularProfilingErrors);
  const doctorsList = useSelector(getServiceUsers);

  const [isEditNumber, setIsEditNumber] = useState(false);
  const [blueprints, setBlueprints] = useState<IBlueprint[]>([]);
  const [rowIndexEdit, setEdit] = useState<boolean | number>(false);
  const [requestError, setRequestError] = useState<null | any>();
  const [isSaveExamination, setSaveExamination] = useState<boolean>(false);

  const isMedicalReportReady = useMemo(() => {
    const examinationBlueprints =
      data?.examinationReferral?.examinationBlueprints;
    let cancellation = data?.statusCancellation;
    if (!examinationBlueprints?.length || cancellation) return false;

    return examinationBlueprints.every(
      (blueprint: Examination) =>
        !!blueprint?.status &&
        ['Cancelled', 'Complete'].includes(blueprint.status)
    );
  }, [data]);

  const tableHeaders: { id?: number; title: string }[] = useMemo(() => {
    let ableToEdit = data?.ableToUdateREFERRAL?.updateExamination;
    let headers = [
      { id: 1, title: 'Номер' },
      { id: 2, title: 'Маркeр' },
      // { id: 3, title: 'Биоматериал' },
      // { id: 4, title: 'Метод' },
      { id: 5, title: 'Исполнитель' },
      { id: 6, title: 'Статус' },
    ];
    if (ableToEdit && !isMedicalReportReady) headers.push({ id: 7, title: '' });
    return headers;
  }, [data?.ableToUdateREFERRAL?.updateExamination, isMedicalReportReady]);

  useEffect(() => {
    if (isSaveExamination) {
      setSaveExamination(false);

      if (!!getErrors) {
        setRequestError(getErrors);
        setTimeout(() => {
          setRequestError(null);
          setEdit(false);
          dispatch(resetMolecularProfiling());
        }, 3000);
      } else {
        dispatch(resetMolecularProfiling());
      }
    }
  }, [getErrors, reset, dispatch, setEdit, setRequestError, isSaveExamination]);

  //load doctors list if this stage is complite
  useLayoutEffect(() => {
    if (!data?.status) return;
    if (FLOW_STEPS_STATUSES[data.status] === 4 && tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 })); //0 - all saved data
      dispatch(fetchMethodTypeList(tokens?.access));
      dispatch(fetchRegistryMarkerList(tokens?.access));
    }
  }, [data, dispatch, tokens]);

  useLayoutEffect(() => {
    if (!data?.status) return;

    if (data?.referralMarkers) {
      if (data?.examinationReferral?.examinationBlueprints) {
        const rawData = data?.examinationReferral?.examinationBlueprints;
        const Blueprints = rawData.map((blueprint: any) => {
          let bluprintNumber = `${blueprint.id}`
            .padStart(6, '0')
            .replace(/(?=(?:.{3})*$)/g, ' ');
          return {
            ...blueprint,
            bluprintNumber,
          };
        });
        setBlueprints(Blueprints ?? []);
        setNumberExamination(data?.examinationReferral?.code);
      }
    }
  }, [data, setBlueprints]);

  const dateOfCompletion = useMemo(() => {
    if (!data?.stepReferral || !data?.stepReferral?.length) return '';
    let bioComliteObj: IStepReferral = data.stepReferral.find(
      (step: IStepReferral) => step.status === 'analysis_done'
    );
    if (!bioComliteObj) return '';

    let { dateSaved } = bioComliteObj;

    //Completion date
    return format(new Date(dateSaved), 'dd.MM.yyyy - HH:mm:ss');
  }, [data]);

  const [executorOptions, defaultExecutorOptions] = useMemo(() => {
    if (!doctorsList) return [];
    const currentUserPermit: UserRegistryPermissions | undefined =
      currentUser?.userRegistryPermissions;
    const isCurrentUserExecutor =
      currentUserPermit?.morphologistExecutor ||
      currentUserPermit?.geneticistExecutor;

    //find geneticist Executor and morphologist Executor
    let options: any[] = [];
    let staffUlidArr: string[] = [];

    let optionsDefaultList = doctorsList
      .filter((user: any) => {
        let userPermissions = user?.userRegistryPermissions ?? {};
        if (user?.isStaff) staffUlidArr.push(user.ulid);

        return (
          userPermissions?.morphologistExecutor ||
          userPermissions?.geneticistExecutor ||
          userPermissions?.geneticistCoordinator ||
          user?.isStaff
        );
      })
      .map((genetic: any) => {
        let { firstName, lastName, middleName } = genetic.userProfile;
        let label = shortName(lastName, firstName, middleName);

        return { value: genetic.ulid, label };
      })
      ?.sort(
        (
          s1: { value: string; label: string },
          s2: { value: string; label: string }
        ) => s1.label.localeCompare(s2.label)
      );

    if (isCurrentUserExecutor) {
      options = optionsDefaultList.filter(
        (option: { value: string; label: string }) =>
          option.value === currentUser?.ulid
      );
    }

    if (!isCurrentUserExecutor) {
      options = optionsDefaultList;
    }

    let defaultExecutorMap: { [index: string]: any } = {};

    optionsDefaultList.forEach((option: any) => {
      defaultExecutorMap[option.value] = option.label;
    });

    options = options.filter(
      (option: { value: string; label: string }) =>
        !staffUlidArr.includes(option.value)
    );

    return [options, defaultExecutorMap];
  }, [doctorsList, currentUser]);

  const handleEditClick = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    handlerEditRow(index);
  };

  const handleApplyClick = async (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    const formData = getValues(); // Получаем все данные формыconsole.log('formData:', formData);
    const examData = formData.examination[index];
    if (!examData.executor || !examData.executor.value) {
      dispatch(updateExaminationBlueprintsFailure('Исполнитель не выбран'));
      return;
    }
    await handleSaveExamination(formData);
  };

  const handlerEditRow = useCallback(
    (rowIndex: number) => {
      if (rowIndex === rowIndexEdit) {
        // reset({}, { keepValues: false });
        return setEdit(false);
      }
      setEdit(rowIndex);
      // reset({}, { keepValues: false });
    },
    [rowIndexEdit, setEdit]
  );

  const [numberExamination, setNumberExamination] = useState<string>('');
  //send examination to server

  const getIdByMethodName = (name: string): number | undefined => {
    const methodNameLower = name.toLowerCase();
    const found = Object.entries(methodsForUrl).find(
      ([_, value]) => value === methodNameLower
    );
    return found ? Number(found[0]) : undefined;
  };

  const handleSaveExamination = useCallback(
    async (formData: any) => {
      if (rowIndexEdit === false) return;

      let minPriorityBlock = null;
      const biologicalMaterial: IBiologicalMaterial[] = data.biologicalMaterial;

      // Логика поиска минимального приоритета блока
      for (let bio of biologicalMaterial) {
        for (let blockSublocks of bio?.material?.blockSublocks ?? []) {
          if (!blockSublocks.reviewed) continue;

          let priority = blockSublocks?.priority;
          if (
            minPriorityBlock === null ||
            priority < minPriorityBlock.priority
          ) {
            minPriorityBlock = blockSublocks;
          }
        }
      }

      // Получаем данные конкретной строки
      const examData = formData.examination[rowIndexEdit as number];
      if (!examData) {
        console.error('Нет данных для строки с индексом:', rowIndexEdit);
        return;
      }

      const bio = minPriorityBlock?.bioUlid
        ? [minPriorityBlock.bioUlid]
        : undefined;
      const marker = examData.marker;
      const method = getIdByMethodName(data.type);
      const executor = examData.executor?.value; // Берем значение из Select
      const updateId = examData.examination;
      const trigger_new_blueprint = false;

      if (tokens?.access) {
        try {
          await dispatch(
            putExaminationBlueprintsUpdate(tokens.access, updateId, {
              bio,
              marker,
              method,
              executor,
              trigger_new_blueprint,
            })
          );
          setSaveExamination(true);
          setEdit(false); // Сбрасываем редактирование после сохранения
        } catch (e) {
          console.error('Ошибка при сохранении:', e);
        }
      }
    },
    [
      rowIndexEdit,
      data.biologicalMaterial,
      data.type,
      tokens?.access,
      dispatch,
      setEdit,
    ]
  );

  useEffect(() => {
    if (!blueprints || !blueprints?.length || fields.length) return;

    for (let blueprint of blueprints) {
      append({
        id: blueprint.id,
        bio: blueprint.bio,
        marker: blueprint.marker,
        method: blueprint.method,
        status: blueprint.status,
        executor: blueprint.executor,
        examination: blueprint.id,
        bluprintNumber: blueprint.bluprintNumber,
        code: blueprint.code,
      });
    }
  }, [blueprints, fields, append]);

  const linkToExamination = useCallback(
    (num: string, meth: number, mark: string) => {
      let examinationPath =
        parseInt(num.trim().replace(' ', '')) +
        '_' +
        methodsForUrl[meth] +
        '_' +
        mark.toLowerCase().replace('/', '#');
      return `/molecular-profiling/detail/${referralULID}/${examinationPath}`;
    },
    [referralULID]
  );

  const customFilter = (option: any, searchText: any) => {
    if (searchText.match('\\[|\\]|\\\\|\\^|\\$|\\.|\\||\\?|\\*|\\+|\\(|\\)'))
      return false;
    if (!searchText) return true;
    const template = `^${searchText}`;
    return option.data.label.match(template);
  };

  const handleSubmitNumber = async () => {
    if (!tokens) return;
    setIsEditNumber(false);

    await dispatch(
      postExaminationBlueprintsNumberUpdate(
        tokens?.access,
        data.examinationReferral?.id,
        numberExamination
      )
    );

    setSaveExamination(true);
  };

  return !!fields?.length ? (
    <>
      <InputWrapper>
        <Tooltip
          id='hint'
          place='top'
          overridePosition={({ left, top }) => ({ left: left, top: top + 30 })}
        >
          Введите номер, присвоенный лабораторией.
          <br />
          Если его нет, то введите номер направления
          <br />
          на данное исследование.
        </Tooltip>
        <div data-for={'hint'} data-tip=''>
          <Input
            value={numberExamination}
            onChange={(event) => {
              setNumberExamination(event.target.value);
            }}
            title={'Номер исследования'}
            required
            readOnly={!isEditNumber}
            placeholder='--'
          />
        </div>
        {!!requestError && (
          <TSendingErrorMessage>
            Ошибка! {requestError?.error}
          </TSendingErrorMessage>
        )}
      </InputWrapper>
      <ButtonContainer marginBottom={'32px'}>
        <SaveButton disabled={!isEditNumber} onClick={handleSubmitNumber}>
          Сохранить
        </SaveButton>
        <EditButton
          disabled={isEditNumber}
          onClick={() => setIsEditNumber(true)}
        >
          Редактировать
        </EditButton>
      </ButtonContainer>
      <TWrapper>
        <InfoRowWrapper direction='start'>
          {!!dateOfCompletion && (
            <TDateOfCompletion>{`Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
          )}
        </InfoRowWrapper>
        <Table>
          <Thead>
            {tableHeaders?.map((item: { id?: number; title: string }) => (
              <th className='violet' key={item.id}>
                {item.title}
              </th>
            ))}
          </Thead>
          <TBody>
            {fields.map((field, index) => {
              let exam = getValues(`examination.${index}`);
              let examErrors = errors?.examination?.[index];
              return (
                <tr key={field.id + `${index}`}>
                  <td className='left'>
                    {exam.status === 'New' || !ableToViewExperement ? (
                      exam.code || '--'
                    ) : (
                      <Link
                        to={linkToExamination(
                          exam.bluprintNumber,
                          exam.method,
                          exam.marker
                        )}
                      >
                        {exam.code || '--'}
                      </Link>
                    )}
                  </td>
                  <td className='left'>{exam.marker}</td>
                  <td className={examErrors?.executor ? 'error left' : 'left'}>
                    {rowIndexEdit !== index &&
                      (defaultExecutorOptions?.[exam?.executor] ?? '--')}
                    {rowIndexEdit === index && (
                      <Controller
                        control={control}
                        name={`examination.${index}.executor` as const}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={async (e) => {
                              clearErrors(`examination.${index}.executor`);
                              await trigger([
                                `examination.${index}.bio`,
                                `examination.${index}.method`,
                              ]);
                              return onChange(e);
                            }}
                            selected={value}
                            options={executorOptions}
                            classNamePrefix='select'
                            placeholder={'--'}
                            isOptionDisabled={(option: any) => option.disabled}
                            noOptionsMessage={() => 'нет опций'}
                            styles={customStylesOptions(defaultTheme)}
                            filterOption={(option, value) =>
                              customFilter(option, value)
                            }
                          />
                        )}
                      />
                    )}
                  </td>
                  <td className='left'>
                    <TRowWR>
                      <TIndicator
                        className={statusLocale[exam.status].code}
                      ></TIndicator>
                      <p>{statusLocale[exam.status].rus ?? '--'}</p>
                    </TRowWR>
                  </td>
                  {data?.ableToUdateREFERRAL?.updateExamination &&
                    !isMedicalReportReady && (
                      <td style={{ width: '78px' }}>
                        <TMenuIconWrapper>
                          {rowIndexEdit === index && (
                            <>
                              <TMenuIcon
                                src={applyIcon}
                                disabled={exam.status !== 'New'}
                                onClick={(event: React.MouseEvent) =>
                                  handleApplyClick(event, index)
                                }
                              />
                              &nbsp;
                            </>
                          )}
                          <TMenuIcon
                            src={rowIndexEdit !== index ? editIcon : cancelIcon}
                            disabled={exam.status !== 'New'}
                            onClick={(event: React.MouseEvent) =>
                              handleEditClick(event, index)
                            }
                          />
                        </TMenuIconWrapper>
                      </td>
                    )}
                </tr>
              );
            })}
          </TBody>
        </Table>
        {/* {data?.ableToUdateREFERRAL?.updateExamination &&
          !isMedicalReportReady && (
            <SecondaryButton
              onClick={() => {handleSubmit(data => handleSaveExamination(data))}}
              disabled={!!errors?.examination || rowIndexEdit === false}
            >
              Сохранить изменения
              {!!requestError && (
                <TSendingErrorMessage>
                  {' '}
                  <i>Ошибка!</i> {requestError?.error}{' '}
                </TSendingErrorMessage>
              )}
            </SecondaryButton>
          )} */}
      </TWrapper>
    </>
  ) : (
    <NoDataToShow description='Исследования не проводились' />
  );
};

export default AnalysisExecution;
