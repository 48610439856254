import { FC } from 'react';
import { IAnalisysByMethod } from '../../../../../../store/analysis/model';
import {
  TDetails,
  TSummary,
  TSummaryContentWithCheckbox,
  TSummaryIcon,
  TSummaryIconWrapper,
} from '../../../../../../components/shared/Details/styled';
import arrowSelectIcon from '../../../../../../icons/details-arrow.svg';
import currentStatusIcon from '../../../../../../icons/attention.svg';
import defaultStatusIcon from '../../../../../../icons/done-gray.svg';
import { IExamExecutors } from '../../../model';
import ExpperimentTable from './ExpperimentTable';
import { Checkbox } from '../../../../../../componentsNew/Checkbox';

interface IComponentProps {
  flowStepsActiveIndex: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  isActive: boolean;
  defaultValues?: any;
  examExecutors: IExamExecutors;
}

const ExperimentIHC: FC<IComponentProps> = ({
  flowStepsActiveIndex,
  analisysByMethod,
  isActive,
  defaultValues,
  examExecutors,
}) => {

  return (
    <TDetails open={isActive || !!defaultValues?.acceptance}>
      <TSummary>
        <TSummaryContentWithCheckbox>
          <Checkbox checked={false} onChange={() => {}} />
          Попытка №{defaultValues?.number ?? flowStepsActiveIndex.index}
        </TSummaryContentWithCheckbox>
        <TSummaryIconWrapper>
          <TSummaryIcon
            src={
              !Object?.keys(defaultValues ?? {})?.length
                ? currentStatusIcon
                : defaultStatusIcon
            }
          />
          <TSummaryIcon className='arrow' src={arrowSelectIcon} />
        </TSummaryIconWrapper>
      </TSummary>
      <ExpperimentTable
        markerName={analisysByMethod?.markerIhc?.name}
        examExecutors={examExecutors}
        analisysByMethod={analisysByMethod}
        flowStepsActiveIndex={flowStepsActiveIndex.index}
      />
    </TDetails>
  );
};

export default ExperimentIHC;
