import { forwardRef, useCallback, useRef } from 'react';
import {
  TRoot,
  TLabelWr,
  TFakeRadio,
  TInput,
  CustomTooltip,
  IconWrapper,
} from './styled';
import React from 'react';

interface IconType {
  active: React.ReactNode;
  unActive: React.ReactNode;
}

interface RadioProps {
  selected: string;
  defaultChecked?: boolean;
  value: string;
  id?: string;
  label?: string | null;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  width?: string;
  activeColor?: string;
  error?: boolean;
  icon?: IconType;
  tooltip_id?: string;
  tooltipData?: React.ReactNode;
}

const Radio = forwardRef<HTMLInputElement | null, RadioProps>(
  (
    {
      label,
      className,
      id,
      selected,
      defaultChecked,
      onChange,
      value,
      disabled,
      width,
      error,
      activeColor,
      icon,
      tooltip_id,
      tooltipData,
      ...rest
    },
    ref
  ) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    const isActive = selected === value;

    const [isIconHovered, setIsIconHovered] = React.useState(false);

    const tooltipId = tooltip_id ? `${tooltip_id}-tooltip` : undefined;

    const onChangeHandler = useCallback(() => {
      if (onChange) {
        onChange(value);
      }
    }, [onChange, value]);

    const handleIconHover = useCallback(() => {
      setIsIconHovered((prev) => !prev);
    }, []);

    return (
      <TRoot
        error={error}
        width={width}
        disabled={disabled}
        active={isActive}
        className={className}
        htmlFor={id}
      >
        <TInput
          activeColor={activeColor}
          type='radio'
          value={value}
          ref={resolvedRef}
          defaultChecked={defaultChecked}
          onChange={onChangeHandler}
          disabled={disabled}
          checked={isActive}
          {...rest}
        />
        <TFakeRadio />
        {label && <TLabelWr icon={!!icon}>{label}</TLabelWr>}

        {icon && tooltipId && (
          <CustomTooltip
            id={tooltipId}
            place='right'
            overridePosition={({ left, top }) => ({ left: left + 20, top })}
          >
            {tooltipData}
          </CustomTooltip>
        )}

        {icon && (
          <IconWrapper
            onMouseEnter={handleIconHover}
            onMouseLeave={handleIconHover}
            data-for={tooltipId}
            data-tip=''
          >
            {isIconHovered ? icon.active : icon.unActive}
          </IconWrapper>
        )}
      </TRoot>
    );
  }
);

export default Radio;
