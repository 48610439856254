import { StepState } from '../../../componentsNew/Steps/types/Step';
import { FLOW_STEPS_STATUSES } from '../../../fixtures/StepsMolecularProfilingPage';

export class RefferalStepStateChecker {
  static getBioMaterilReceipt(stepCurrentIndex: number) {
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.bio_pending)
      return StepState.SELECTED;

    if (stepCurrentIndex > FLOW_STEPS_STATUSES.bio_pending)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getMorphologicalCharacterization(stepCurrentIndex: number) {
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.morphology_pending)
      return StepState.SELECTED;

    if (stepCurrentIndex > FLOW_STEPS_STATUSES.morphology_pending)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getResearchImplementation(stepCurrentIndex: number) {
    const currentStep =
      FLOW_STEPS_STATUSES.analysis_absent ||
      FLOW_STEPS_STATUSES.analysis_in_progress ||
      FLOW_STEPS_STATUSES.analysis_pending;

    if (stepCurrentIndex === currentStep) return StepState.SELECTED;

    if (stepCurrentIndex > currentStep) return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getConclusionFormation(stepCurrentIndex: number) {
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.conclusion_pending)
      return StepState.SELECTED;

    if (stepCurrentIndex > FLOW_STEPS_STATUSES.conclusion_pending)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getReferralClosed(stepCurrentIndex: number) {
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.referral_completed)
      return StepState.SELECTED;

    if (stepCurrentIndex > FLOW_STEPS_STATUSES.referral_completed)
      return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }
}
