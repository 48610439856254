import { OptionType } from '../../../componentsNew/Select';

export interface IFormStep {
  rangeOfBlocksDone: boolean;
  rangeOfBlocksSaved: boolean;
  blockCandidatesDone: boolean;
  blockCandidatesSaved: boolean;
  resultsOfMorphCharDone: boolean;
  resultsOfMorphCharSaved: boolean;
  [key: string]: boolean;
}

interface ITableHeader {
  id?: number;
  title: string;
  class?: string;
}

export const optionsCategory: OptionType[] = [
  { value: '1', label: '1', selected: false },
  { value: '2', label: '2', selected: false },
  { value: '3', label: '3', selected: false },
  { value: '4', label: '4', selected: false },
  { value: '5', label: '5', selected: false },
];

export const optionsSingsViolation = [
  { value: 'crumbling', label: 'Крошение', selected: false },
  { value: 'uneven_coloring', label: 'Неравномерность окрашивания', selected: false },
  { value: 'thick_cut', label: 'Толстый срез', selected: false },
  { value: 'fuzzy_structures', label: 'Нечеткие структуры', selected: false },
  { value: 'none', label: 'Не выявлены', selected: false },
];

export const optionsCommon = [
  { value: 'true', label: 'Да', selected: false },
  { value: 'false', label: 'Нет', selected: false },
]

export const shortTableHeaders: ITableHeader[] = [
  { id: 1, title: 'Номер блока' },
  { id: 2, title: 'Сохранность упаковки' },
  { id: 3, title: 'Опухолевые клетки, %' },
  { id: 4, title: 'Признаки нарушения фиксации, проводки, окрашивания' },
  { id: 5, title: 'Пригодность' },
  { id: 6, title: 'Приоритет кандидата' },
];

export const defaultOptionsPriority = [
  { value: '0', label: '--', selected: false },
  { value: '1', label: '1', selected: false },
  { value: '2', label: '2', selected: false },
  { value: '3', label: '3', selected: false },
  { value: '4', label: '4', selected: false },
  { value: '5', label: '5', selected: false },
];

export const optionsQuality = [
  { value: 'good', label: 'Пригоден', selected: false },
  { value: 'bad', label: 'Непригоден', selected: false },
];

export const defaultSteps: IFormStep = {
  rangeOfBlocksDone: false,
  rangeOfBlocksSaved: false,
  blockCandidatesDone: false,
  blockCandidatesSaved: false,
  resultsOfMorphCharDone: false,
  resultsOfMorphCharSaved: false,
};