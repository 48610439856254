import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from '../../../store/auth/selectors';
import { format } from 'date-fns';
import {
  createNextStep,
  patchMolecularProfilingDataConfirm,
  postMolecularProfilingComment,
} from '../../../store/molecularProfiling/thunkActions';
import { Table, TBody } from '../../../components/shared/BiomaterialTable';
import { EditButtonsWrapper, PrimaryButton, SecondaryButton } from '../styled';
import {
  TBioMaterialDataWr,
  TDescriptionWr,
  THeader,
  TTableWr,
} from './styled';
import { useLocation } from 'react-router';
import SlideRow from './ui/SlideRow';
import CodeTableCell from './ui/CodeTableCell';
import { FLOW_STEPS_STATUSES } from '../../../fixtures/StepsMolecularProfilingPage';
import Textarea from '../../../components/shared/Textarea';
import { fetchServiceUsers } from '../../../store/serviceUsers/thunkActions';
import {
  getBioMaterialSourceName,
  getMethodOfAcquisitionName,
  getBioSampleTypeName,
} from '../../../constants/bioMaterial';
import ReadOnlyInput from '../../../components/shared/ReadOnlyInput';

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

interface ITableHeader {
  id?: number;
  title: string;
  class?: string;
  colSpan?: number | undefined;
  rowSpan?: number | undefined;
}

const wideTableHeaders: ITableHeader[] = [
  { id: 1, title: 'Орган', class: 'maxWidth20', rowSpan: 2 },
  { id: 2, title: 'Блоки', class: 'violet noBorderRadius maxWidth50' },
  { id: 3, title: 'Гист. стекла', class: 'orange maxWidth50' },
];

const BioMaterialData: FC<IComponentProps> = (data) => {
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const path = useLocation();

  const [commentary, setCommentary] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const biologicalMaterial = useMemo(
    () =>
      data?.biologicalMaterial?.filter(
        (item: any) => item.type === 'block'
      )?.[0] ?? {},
    [data]
  );
  const referralULD = useMemo(
    () => path.pathname.split('/').slice(-2)[0],
    [path]
  );
  const currentStatus = useMemo(
    () => FLOW_STEPS_STATUSES[data?.status],
    [data?.status]
  );

  const handleAcceptBiomaterials = useCallback(() => {
    if (tokens?.access) {
      dispatch(patchMolecularProfilingDataConfirm(tokens.access, data?.ulid));
      if (commentary)
        dispatch(
          postMolecularProfilingComment(
            tokens.access,
            data.id,
            'morphology',
            commentary
          )
        );
      setTimeout(
        () =>
          dispatch(
            createNextStep(tokens.access, {
              referral: data.id,
              status: 'bio_done',
            })
          ),
        1000
      );
    }
  }, [dispatch, data, tokens?.access, commentary]);

  const tadleData = useMemo(() => {
    let blocks;
    const isBioPendigStatus = currentStatus === 1;
    const headers = wideTableHeaders;

    const blocksFiltered = data?.biologicalMaterial?.filter(
      (item: any) => item.type === 'block'
    );

    if (isBioPendigStatus && blocksFiltered) {
      blocks = blocksFiltered.map((block: any) => {
        return {
          organ: block.material.blockSlides[0]?.organ ?? '',
          block: block.material.blockCypher[0],
          slide: block.material.blockSlides,
        };
      });
    }
    if (!isBioPendigStatus && blocksFiltered) {
      blocks = blocksFiltered.map((block: any) => {
        return {
          organ: block.material.blockSlides[0]?.organ ?? '',
          block: block.material.blockCypher,
          slide: block.material.blockSlides,
        };
      });
    }

    if (blocks && blocks.length) return { headers, blocks };
    return { headers };
  }, [data, currentStatus]);

  //is it possible to edit this tab
  const isEditDisabled = useMemo(() => {
    if (!data) return;
    let isRefferalCanceled = data?.statusCancellation;
    let isMorphologyPending = currentStatus > 1;

    return isRefferalCanceled || isMorphologyPending;
  }, [data, currentStatus]);

  //apply saved changes
  useEffect(() => {
    if (FLOW_STEPS_STATUSES[data.status] > 1 && tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
      let comment =
        data?.referralCommentary.find(
          (comment: any) => comment.stage === 'morphology'
        )?.text ?? '';
      if (comment) setCommentary(comment);
    }
  }, [
    referralULD,
    dispatch,
    tokens?.access,
    data,
    setCommentary,
  ]);

  const handleAddNewComment = (comment: string) =>
    setCommentary(comment.trim());

  const dateOfAcquisition = useMemo(() => {
    if (!biologicalMaterial?.dateOfAcquisition) return '';
    return format(new Date(biologicalMaterial.dateOfAcquisition), 'dd.MM.yyyy');
  }, [biologicalMaterial?.dateOfAcquisition]);

  return (
    <TBioMaterialDataWr>
      <THeader>Блоки и Гист. стекла</THeader>
      <ReadOnlyInput width={'100%'} label='Тип материала'>
        {getBioMaterialSourceName(biologicalMaterial?.source) ?? ''}
      </ReadOnlyInput>
      <TTableWr>
        <Table>
          <thead>
            <tr>
              {tadleData?.headers.map((item: ITableHeader) => (
                <th
                  key={item.id}
                  colSpan={item?.colSpan}
                  rowSpan={item?.rowSpan}
                  className={item.class}
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <TBody>
            {tadleData?.blocks &&
              currentStatus > 1 &&
              tadleData.blocks.map((row: any, index: number) => {
                let block = row.block;
                let slide = row.slide[0].slideCypher;

                let mainBlockSlide = (
                  <tr key={index}>
                    <td>{++index + '. ' + row.organ}</td>
                    <CodeTableCell color={'lightViolet'} block={block[0]} />
                    <CodeTableCell color={'lightOrange'} slide={slide[0]} />
                  </tr>
                );
                if (row.slide.length >= 1)
                  return row.slide.map((item: any, index: number) => {
                    if (!index) return mainBlockSlide;
                    let slide = item.slideCypher;
                    return (
                      <tr key={item.ulid}>
                        <td className='wrapWords maxWidth20'></td>
                        <td></td>
                        <CodeTableCell color={'lightOrange'} slide={slide[0]} />
                      </tr>
                    );
                  });
                return mainBlockSlide;
              })}
            {tadleData?.blocks &&
              currentStatus === 1 &&
              tadleData.blocks.map((row: any, index: number) => {
                let block = row.block;
                let slide = row.slide[0].slideCypher[0];
                let rowData = (
                  <SlideRow
                    key={(block?.code || slide?.code) + index}
                    row={row}
                    index={index}
                    block={block}
                    slide={slide}
                  />
                );

                if (row.slide.length > 1)
                  return row.slide.map((item: any, slideIndex: number) => {
                    if (!slideIndex) return rowData;
                    let slide = item.slideCypher[0];
                    return (
                      <tr key={item.ulid + slide.code}>
                        <td className='wrapWords maxWidth20'></td>
                        <td></td>
                        <CodeTableCell slide={slide} color={'lightOrange'} />
                      </tr>
                    );
                  });
                return rowData;
              })}
            {!tadleData?.blocks && (
              <tr>
                <td>--</td>
                <td>--</td>
                <td>--</td>
              </tr>
            )}
          </TBody>
        </Table>
      </TTableWr>
      <ReadOnlyInput width={'100%'} label='Способ получения'>
        {getMethodOfAcquisitionName(biologicalMaterial?.methodOfAcquisition) ??
          ''}
      </ReadOnlyInput>
      <ReadOnlyInput width={'100%'} label='Тип образца'>
        {getBioSampleTypeName(biologicalMaterial?.sampleType) ?? ''}
      </ReadOnlyInput>
      <ReadOnlyInput width={'100%'} label='Дата забора'>
        {dateOfAcquisition ?? ''}
      </ReadOnlyInput>
      <TDescriptionWr column={true}>
        <Textarea
          key={`commentary_${isEdit}`}
          name='commentary'
          bordered={true}
          label={'Комментарий'}
          className={`description nothovered ${
            commentary?.length > 999 ? 'error' : ''
          }`}
          placeholder={'Введите текст'}
          disabled={!isEdit}
          defaultValue={commentary}
          onChange={({ target }) => handleAddNewComment(target.value)}
          error={commentary?.length > 999}
          maxLength={1000}
          height={100}
        />
      </TDescriptionWr>

      {data?.ableToUdateREFERRAL?.updateBiologicalMaterial &&
        !isEditDisabled && (
          <EditButtonsWrapper>
            <SecondaryButton
              disabled={!isEdit || commentary?.length > 999}
              onClick={() => setIsEdit(false)}
            >
              Сохранить
            </SecondaryButton>
            <SecondaryButton disabled={isEdit} onClick={() => setIsEdit(true)}>
              Редактировать
            </SecondaryButton>
            <PrimaryButton onClick={handleAcceptBiomaterials} disabled={isEdit}>
              Подтвердить наличие блоков
            </PrimaryButton>
          </EditButtonsWrapper>
        )}
    </TBioMaterialDataWr>
  );
};

export default BioMaterialData;
