// BIO MATERIAL TYPE

export const BIO_MATERIAL_TYPE__BLOCK = 'block';
export const BIO_MATERIAL_TYPE__GLASS = 'glass';

export const BIO_MATERIAL_TYPES = [
  {
    code: BIO_MATERIAL_TYPE__BLOCK,
    name: 'Парафиновый блок + Гист. стекло',
  },
]

export type BioMaterialTypeCode = typeof BIO_MATERIAL_TYPES[number]['code'];

export function getBioMaterialTypeName(code: BioMaterialTypeCode | null): string | undefined {
  if (!code) return undefined;
  const source = BIO_MATERIAL_TYPES.find(s => s.code === code);
  return source ? source.name : undefined;
}

// BIO MATERIAL SOURCE

export const BIO_MATERIAL_SOURCE__SURGERY = "surgery";
export const BIO_MATERIAL_SOURCE__BIOPSY = "biopsy";

export const BIO_MATERIAL_SOURCES = [
  {
    code: BIO_MATERIAL_SOURCE__SURGERY,
    name: "Операционный",
  },
  {
    code: BIO_MATERIAL_SOURCE__BIOPSY,
    name: "Биопсийный",
  },
] as const;

export type BioMaterilaSourceCode = typeof BIO_MATERIAL_SOURCES[number]['code'];

export function getBioMaterialSourceName(code: BioMaterilaSourceCode | null): string | undefined {
  if (!code) return undefined;
  const source = BIO_MATERIAL_SOURCES.find(s => s.code === code);
  return source ? source.name : undefined;
}

// METHOD OF ACQUISITION

export const METHOD_OF_ACQUISITION_SURGICAL_BIOPSY = 'surgical_biopsy';
export const METHOD_OF_ACQUISITION_SURGICAL_MATERIAL = 'surgical_material';
export const METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS = 'spontaneously_separated_tissue_fragments';
export const METHOD_OF_ACQUISITION_ENDOSCOPIC_BIOPSY = 'endoscopic_biopsy';
export const METHOD_OF_ACQUISITION_PUNCTURE_BIOPSY = 'puncture_biopsy';
export const METHOD_OF_ACQUISITION_ASPIRATION_BIOPSY = 'aspiration_biopsy';
export const METHOD_OF_ACQUISITION_MICISIONAL_BIOPSY = 'micisional_biopsy';


export const METHOD_OF_ACQUISITION = [
  {
    code: METHOD_OF_ACQUISITION_SURGICAL_BIOPSY,
    name: "Операционная биопсия",
  },
  {
    code: METHOD_OF_ACQUISITION_SURGICAL_MATERIAL,
    name: "Операционный материал",
  },
  {
    code: METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS,
    name: "Самопроизвольно отделившиеся фрагменты тканей",
  },
  {
    code: METHOD_OF_ACQUISITION_ENDOSCOPIC_BIOPSY,
    name: "Эндоскопическая биопсия",
  },
  {
    code: METHOD_OF_ACQUISITION_PUNCTURE_BIOPSY,
    name: "Пункционная биопсия",
  },
  {
    code: METHOD_OF_ACQUISITION_ASPIRATION_BIOPSY,
    name: "Аспирационная биопсия",
  },
  {
    code: METHOD_OF_ACQUISITION_MICISIONAL_BIOPSY,
    name: "Инцизионная биопсия",
  }
] as const;

export type MethodOfAcquisitionCode = typeof METHOD_OF_ACQUISITION[number]['code'];

export function getMethodOfAcquisitionName(code: MethodOfAcquisitionCode | null): string | undefined {
  if (!code) return undefined;
  const source = METHOD_OF_ACQUISITION.find(s => s.code === code);
  return source ? source.name : undefined;
}

// BIO SAMPLE TYPE
export const BIO_SAMPLE_TYPE__PRIMARY = 'primary';
export const BIO_SAMPLE_TYPE__METASTASIS = 'metastasis';


export const BIO_SAMPLE_TYPES = [
  {
    code: BIO_SAMPLE_TYPE__PRIMARY,
    name: 'Первичная опухоль',
  },
  {
    code: BIO_SAMPLE_TYPE__METASTASIS,
    name: 'Метастаз',
  },
] as const;

export type BioSampleTypeCode = typeof BIO_SAMPLE_TYPES[number]['code'];

export function getBioSampleTypeName(code: BioSampleTypeCode | null): string | undefined {
  if (!code) return undefined;
  const source = BIO_SAMPLE_TYPES.find(s => s.code === code);
  return source ? source.name : undefined;
}