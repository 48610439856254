import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { laboratoriesSelector, setLaboratories } from "../redux/laboratory";
import { LaboratoriesService } from "../../../services/Laboratories";
import { useHttpClient } from "../../../hooks/useHttpClient";
import { getTokens } from "../../../store/auth/selectors";


export const useLaboratories = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const tokens = useSelector(getTokens);

  const laboratories = useSelector(laboratoriesSelector);

  const effect = async () => {
    const laboratoriesService = new LaboratoriesService(httpClient);

    const laboratories = await laboratoriesService.searchLaboratories();

    if (laboratories?.length) {
      dispatch(setLaboratories(laboratories));
    }
  };

  useEffect(() => {
    if (!tokens?.access) {
      return;
    }

    effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens?.access]);

  return { laboratories };
};
