import { FC } from 'react';
import { TooltipProps } from 'react-tooltip';
import { TTooltip } from './styled';
import theme from '../../../styles/theme';

interface ITooltipProps extends TooltipProps {
  maxWidth?: string;
}

const Tooltip: FC<ITooltipProps> = (props) => {
  const {
    place = 'left',
    border = false,
    backgroundColor = theme.colors.white,
    textColor = theme.colors.monochrom[800],
    borderColor = theme.colors.gray[400],
    effect = 'solid',
    maxWidth = 'max-content',
  } = props;

  return (
    <TTooltip
      {...props}
      border={border}
      textColor={textColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      place={place}
      effect={effect}
      globalEventOff='click'
      maxWidth={maxWidth}
    />
  );
};

export default Tooltip;
