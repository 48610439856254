import { FC } from 'react';
import {
  Table,
  TableBody,
  TableHeaderRow,
  TableHeaderCell,
  TableCell,
} from '../../../../../componentsNew/Table/styled';
// import { MarkerReference } from '../../../../../store/analysis/model';
import { Colors } from '../../../../../styles/themeNew';
import {
  InfoRowWrapper,
  BlockItem,
  Label,
  RequiredMark,
} from '../../../styled';
import ReadOnlyInput from '../../../../../components/shared/ReadOnlyInput';
import { ExperimentContent } from '../../styled';

interface Props {
  Experiment: any;
}

const PreviousIHKTable: FC<Props> = ({ Experiment }) => {
  const conclusion =
    Experiment?.conclusionMap?.[Experiment.conclusion] === 'No conclusion.'
      ? '--'
      : String(
          Experiment.notes ??
            Experiment?.conclusionMap?.[Experiment.conclusion] ??
            '--'
        );

  return (
    <ExperimentContent>
      <BlockItem>
        <Label>
          Клон антител <RequiredMark>*</RequiredMark>
        </Label>
        <ReadOnlyInput>{Experiment?.clone || '--'}</ReadOnlyInput>
      </BlockItem>

      <BlockItem>
        <Label>
          Протокол (система детекции) <RequiredMark>*</RequiredMark>
        </Label>
        <ReadOnlyInput>{Experiment?.protocol || '--'}</ReadOnlyInput>
      </BlockItem>

      <InfoRowWrapper direction='space-between'>
        <h3
          style={{
            width: '100%',
            color: Colors.MONOCHROM[900],
            textAlign: 'center',
          }}
        >
          Результаты исследования биоматериала методом ИГХ
        </h3>
      </InfoRowWrapper>

      <Table>
        <TableBody>
          <TableHeaderRow>
            <TableHeaderCell>Маркер</TableHeaderCell>
            <TableHeaderCell>Шкала (score)</TableHeaderCell>
            <TableHeaderCell>Порог позитивности, %</TableHeaderCell>
            <TableHeaderCell>Процент опухолевых клеток, %</TableHeaderCell>
            <TableHeaderCell>Результат исследования</TableHeaderCell>
          </TableHeaderRow>
          <tr>
            <TableCell>{Experiment?.name ?? '--'}</TableCell>
            <TableCell>
              {Experiment?.score ?? '--'}
            </TableCell>
            <TableCell>{!!Experiment?.positivity ? Experiment?.positivity : '--'}</TableCell>
            <TableCell>{!!Experiment?.tumorCellLevel ? Experiment?.tumorCellLevel : '--'}</TableCell>
            <TableCell>
              {Experiment?.resultsMap?.[Experiment?.result] ?? '--'}
            </TableCell>
          </tr>
          <TableHeaderRow>
            <TableHeaderCell colSpan={5}>
              Клинико-лабораторное заключение
            </TableHeaderCell>
          </TableHeaderRow>
          {Experiment?.notes?.length > 0 && (
            <tr>
              <TableCell colSpan={5}>{conclusion}</TableCell>
            </tr>
          )}
        </TableBody>
      </Table>
    </ExperimentContent>
  );
};

export default PreviousIHKTable;
