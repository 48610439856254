import React from "react";
import styled from "styled-components";
import {BellIcon} from "./icons/Bell";

export const CreateQuestionModal = ({ onConfirm, onCancel }:  {
    onConfirm: () => Promise<void>;
    onCancel: React.Dispatch<React.SetStateAction<void>>;
}) => {
  return (
      <ModalContainer>
        <ModalContent>
          <IconContainer>
            <BellIcon />
          </IconContainer>
          <ModalText>
            Удалить созданную запись будет невозможно. Создать направление?
          </ModalText>
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Нет</Button>
            <ConfirmButton onClick={() => onConfirm()}>Да</ConfirmButton>
          </ButtonContainer>
        </ModalContent>
      </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 100;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 360px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-bottom: 20px;
`;

const ModalText = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  flex: 1;

  &:hover {
    background: #f0f0f0;
  }
`;

const ConfirmButton = styled(Button)`
  background: #3e6aff;
  color: #fff;
  border: none;

  &:hover {
    background: #2a53c7;
  }
`;
