export const reasonCancelidation: { [index: string]: string } = {
  noNeeded: 'Нет необходимости в исследовании',
  noReagents: 'Нет реагентов',
  other: 'Иное',
};

export const methodsFromUrl: { [index: string]: string } = {
  pcr: 'ПЦР',
  ss: 'Секвенирования по Сэнгеру',
  ihc: 'ИГХ',
  fish: 'FISH',
  fa: 'Фрагментного анализа',
};

export const ANALISYS_STEPS_DATA: Array<{ code: string; name: string }> = [
  {
    code: 'ATTEMPT_1',
    name: 'Попытка №1',
  },
  {
    code: 'ATTEMPT_2',
    name: 'Попытка №2',
  },
  {
    code: 'ATTEMPT_3',
    name: 'Попытка №3',
  },
  {
    code: 'RESEARCH_COMPLETED',
    name: 'Исследование завершено',
  },
];

interface IANALISYS_STEPS_STATUSES {
  attempt_1: number;
  attempt_2: number;
  attempt_3: number;
  research_completed: number;
}

export const ANALISYS_STEPS_STATUSES: IANALISYS_STEPS_STATUSES = {
  attempt_1: 1,
  attempt_2: 2,
  attempt_3: 3,
  research_completed: 4,
};
