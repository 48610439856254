import { FC, useMemo } from "react";
import { MarkerReference } from "../../../../../store/analysis/model";
import { TExpWR, THeaderTable, THeaderTableRow, THeaderTableRowContent, THeaderTableRowTitle, TLabTable } from "../styled";
import { checkPermitViewExperement } from "../../../../../store/auth/selectors";
import { useSelector } from "react-redux";

interface IAdvisor {
  advisorUlid: string
  fullName: string
  specialization: string
}
interface IComponentProps {
  experements: any[];
  blueprintId: number;
  referralID: string;
  executorMap: any;
  goToExperement: (
    experimentID:number,
    experimentMethod:string,
    experimentMarker:string) => void;
  advisors?:IAdvisor[];
  [index: string]: any;
  code?: string;
}

const headers = ['Маркер', 'Референсные значения', 'Интенсивность окрашивания', 'Окрашенных клеток, %','Результат исследования']
const cloneHeaders = ['Маркер', 'Шкала (score)', 'Порог позитивности, %',	'Процент опухолевых клеток, %','Результат исследования']
export const Ihc: FC<IComponentProps> = ({blueprintId, experements, executorMap, goToExperement, code }) => {
  //Permissions
  const ableToViewExperiment = useSelector(checkPermitViewExperement);
  //Formeted experimen number
  const experimentNumber = useMemo(() => blueprintId?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' '),[blueprintId]);
  return <>
    {experements?.map(exp => (
      <TExpWR key={(exp?.id + blueprintId) + 'experement'}>
        <THeaderTable>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исследование: </THeaderTableRowTitle>
            {ableToViewExperiment ? <THeaderTableRowContent className="link" onClick={() => goToExperement(blueprintId,'ihc',exp?.name)}>{code}</THeaderTableRowContent> : <div>{experimentNumber}</div>}
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исполнитель: </THeaderTableRowTitle>
            <THeaderTableRowContent>{`${executorMap?.[exp?.executor]?.fullName}, ${executorMap?.[exp?.executor]?.title}`}</THeaderTableRowContent>
          </THeaderTableRow>
          {/*<THeaderTableRow>*/}
          {/*  <THeaderTableRowTitle>Другие: </THeaderTableRowTitle>*/}
          {/*  <THeaderTableRowContent>*/}
          {/*    <div>{`${executorMap?.[exp?.labAssistant]?.fullName}, ${executorMap?.[exp?.labAssistant]?.title}`}</div>*/}
          {/*    {advisors?.map((adv:IAdvisor) => <div key={adv?.advisorUlid}>{adv?.fullName}, {adv?.specialization}</div>)}</THeaderTableRowContent>*/}
          {/*</THeaderTableRow>*/}
        </THeaderTable>
        <TLabTable>
          <tbody>
            <tr className="head violet itemsTopRadius">
              {!exp?.clone && headers?.map((header: string) => <td key={header} >{header}</td>)}
              {exp?.clone && cloneHeaders?.map((header: string) => <td key={header} >{header}</td>)}
            </tr>
          </tbody>
          {!exp?.clone && <tbody>
            <tr>
              <td>{exp?.name ?? '--'}</td>
              <td>{exp?.markerIhcReferences?.map((reference: MarkerReference) => <div key={reference?.id}>{reference.content}</div>) ?? '--'}</td>
              <td>{!!exp?.stainIntensity ? exp?.stainIntensity : '--'}</td>
              <td>{!!exp?.cellStainPercentage ? exp?.cellStainPercentage : '--'}</td>
              <td>{exp?.result ?? '--'}</td>
            </tr>
            {!!exp?.conclusion && (<tr>
              <td colSpan={5} className={`head violet`}>Клинико-лабораторное заключение</td>
            </tr>)}
            {!!exp?.conclusion && (
              <tr>
                <td colSpan={5} className={`${!exp?.notes && 'bottomRadiusR'}`}>
                  {exp?.conclusion ?? '--'}
                </td>
              </tr>
            )}
            {!!exp?.notes && <tr>
              <td className="bottomRadiusR" colSpan={5}>{exp?.notes}</td>
            </tr>}
          </tbody>}
          {exp?.clone && <tbody>
            <tr>
              <td>{exp?.name ?? '--'}</td>
              <td>{exp?.score ?? '--'}</td>
              <td>{!!exp?.positivity ? exp?.positivity : '--'}</td>
              <td>{!!exp?.tumorCellLevel ? exp?.tumorCellLevel : '--'}</td>
              <td>{exp?.result ?? '--'}</td>
            </tr>
            {/*<tr>*/}
            {/*  <td colSpan={2} className={`head violet ${!exp?.conclusion && 'bottomRadiusL'}`}>Референсные значения</td>*/}
            {/*  <td colSpan={3} >*/}
            {/*    {exp?.markerIhcReferences?.map((reference: MarkerReference) => <div style={{margin:'0px'}} key={reference.content}>{reference.content}</div>) ?? '--'}*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {!!exp?.conclusion && (<tr>
              <td colSpan={5} className={`head violet`}>Клинико-лабораторное заключение</td>
            </tr>)}
            {!!exp?.notes && <tr>
              <td className="bottomRadiusR" colSpan={5}>{exp?.notes}</td>
            </tr>}
          </tbody>}
        </TLabTable>
      </TExpWR>)) ?? null}</>
}

export default Ihc;