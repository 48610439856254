import styled from "styled-components";

import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  additionalClinicalDataSelector,
  setAdditionalClinicalData,
  setAdditionalClinicalDataNonApplicable,
  additionalClinicalDataNonApplicableSelector,
  setAdditionalClinicalDataUnknown,
  additionalClinicalDataUnknownSelector,
} from "../../../../redux/clinicalData";
import Checkbox from "../../../../../../components/shared/Checkbox";

export const AdditionalClinicalData = () => {
  const dispatch = useDispatch();

  const additionalClinicalData = useSelector(additionalClinicalDataSelector);

  const additionalClinicalDataNonApplicable = useSelector(additionalClinicalDataNonApplicableSelector);
  const additionalClinicalDataUnknown = useSelector(additionalClinicalDataUnknownSelector);

  const handleAdditionalClinicalDataNonApplicableChange = () => {
    if (additionalClinicalDataNonApplicable) {
      dispatch(setAdditionalClinicalDataNonApplicable(false));
    } else {
      dispatch(setAdditionalClinicalDataNonApplicable(true));
      dispatch(setAdditionalClinicalDataUnknown(false));
      dispatch(setAdditionalClinicalData(""));
    }
  };

  const handleAdditionalClinicalDataUnknownChange = () => {
    if (additionalClinicalDataUnknown) {
      dispatch(setAdditionalClinicalDataUnknown(false));
    } else {
      dispatch(setAdditionalClinicalDataUnknown(true));
      dispatch(setAdditionalClinicalDataNonApplicable(false));
      dispatch(setAdditionalClinicalData(""));
    }
  };

  const popupText = "Основные симптомы; оперативное, гормональное, лучевое лечение; результаты инструментальных и лабораторных исследований";

  return (
    <StyledContainer>
      <Textarea
        title="Дополнительные клинические сведения"
        required={true}
        placeholder="Свободный ввод"
        value={additionalClinicalData || ""}
        infoicon={true}
        popupText={popupText}
        onChange={(event) => {
          dispatch(setAdditionalClinicalData(event.target.value));
        }}
        disabled={additionalClinicalDataNonApplicable || additionalClinicalDataUnknown}
      />
      <TCheckboxWr>
        <TCheckbox checked={additionalClinicalDataNonApplicable} label='Неприменимо' onChange={handleAdditionalClinicalDataNonApplicableChange} />
        <TCheckbox checked={additionalClinicalDataUnknown} label='Не установлена' onChange={handleAdditionalClinicalDataUnknownChange} />
      </TCheckboxWr>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
`
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;
