import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { format } from 'date-fns';

import { IAnalisysByMethod, IResultOptions, Kit, MarkerFishExperiment, MarkerReference, SaveExperimentUpdate } from '../../../../../../store/analysis/model';
import { getProfile, getTokens } from '../../../../../../store/auth/selectors';
import { fetchAnalysisByBluprint, patchBioExperementUpdate, patchExperimentUpdate, resetAnalysisByBluprint } from '../../../../../../store/analysis/thunkActions';

import Textarea from "../../../../../../components/shared/Textarea";
import { Table, TBody } from "../../../../../../components/shared/BiomaterialTable";
import defaultTheme from "../../../../../../styles/theme";
import { TEditButton } from "../../../../MarkersValidation/styled";
import { TDetailsContent } from "../../../../../../components/shared/Details/styled";
import { TWrapper, TExecutor, TButtonWrapper, CreateExperimentMainButton, customStylesOptions, TSectionTitle, TCustomInput, TBioAcquired, TOtherExecutors, TDateOfCompletion } from "../../../styled";
import { ReactComponent as BlockingIcon }  from '../../../../../../icons/blocking-icon.svg';
import { TBlockFieldButton } from "./styles";
import Tooltip from "../../../../../../components/shared/Tooltip";
import { getAndOverWriteEperement } from '../../../../../../store/molProConclusion/thunkActions';
import InfoModal from "../../../../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../../../../components/shared/Button";
import { ReactComponent as WarnIcon } from '../../../../../../icons/warn-red-circle.svg';
import { IBlueprintAdvisors, IExamExecutors } from '../../../model';
import { InfoRowWrapper } from '../../../../styled';

interface IComponentProps {
  flowStepsActiveIndex: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  isActive:boolean;
  hasPermit:boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  labAssistantMap?:{ [index: string]: {fullname:string, specialization:string} } | undefined;
  experementBioSteps: JSX.Element | JSX.Element[];
  referralULID:string;
  defaultExecutorOptions?: any;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string;
}

const regexManualInputPattern = new RegExp(/[^0-9]/g );
const reangeReasonStatus = [3,11,'Не выполнялось','Неинформативно'];

const TablelFISHReange: FC<IComponentProps> = ({ referralULID,isReferralComplite, flowStepsActiveIndex, 
  analisysByMethod,isActive,hasPermit, defaultValues, ableToUpdate, labAssistantMap, experementBioSteps, examExecutors }) => {
  const { register, control, watch, handleSubmit, formState: { errors },  reset, setError,clearErrors, setValue,getValues} = useForm();
  const watchExamResult = watch('examResult', { value: 0, label: '--' });
  

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [dataAttempt, setDataAttempt] = useState<MarkerFishExperiment>();
  const [reagentKitOptions, setReagentKitOptions] = useState<ISelectOptions[]>([]);
  const [conclusionOptions, setConclusionOptions] = useState<{ [index: number]: ISelectOptions[] }>({});
  const [resultOptions, setResultOptions] = useState<ISelectOptions[]>([]);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [resetNumberFields, setResetNumberFields] = useState<number>(Date.now());
  const [notInformativeSample, setNotInformativeSample] = useState<boolean>(false);

  const setDefaultValues = useCallback(() => {
    if (!!defaultValues && Object.keys(defaultValues)?.length) {

      let { resultMap,conclusionMap,kitMap,kit,rearrangement = {} } = defaultValues ?? {};
      let {cancerCellCount,cancerCellNucleusRearrangedCount,splitSignalNucleusCount,isolatedSignalNucleusCount,rearrangedCellPercentage,conclusion} = rearrangement
      let kitValues = {value:kit,label:kitMap?.[kit]}
      let resultValues = {value:rearrangement?.result,label:resultMap[rearrangement?.result ?? 0]}
      let conclusionValues = { value: +conclusion, label: conclusionMap?.[+conclusion] }
      let examConclusionManual = !(+conclusion) ? conclusion : ''

      const defaults: { [index: string]: any; } = {
        reagentKits: kitMap && kit ? kitValues : undefined,
        examResult: resultMap && !!rearrangement?.result ? resultValues : undefined, 
        examConclusion: !!rearrangement?.conclusion && conclusionMap ? conclusionValues : undefined,
        description: rearrangement?.notes ?? '',
        cancerCellCount,cancerCellNucleusRearrangedCount,splitSignalNucleusCount,
        isolatedSignalNucleusCount,rearrangedCellPercentage,examConclusionManual
      }

      reset({ ...defaults });
      setResetFormKey(Date.now())
      return true;
    }
    return false;
  }, [defaultValues, setResetFormKey, reset]);
 

  useLayoutEffect(() => {
    if(!analisysByMethod?.markerFish?.analysisFishExperiments && !flowStepsActiveIndex) return;
    //Curent atempt
    let attempt:MarkerFishExperiment = analisysByMethod?.analysisFishExperiments?.filter((fishData:MarkerFishExperiment) => +fishData.number === +flowStepsActiveIndex)?.[0];
    if(attempt) setDataAttempt(attempt);
    //Kits, zonds
    const KitOptions:ISelectOptions[] = analisysByMethod?.markerFish?.kit?.map((kit:Kit) => ({value:kit.id,label:kit.name})) ?? [];
    if(KitOptions?.length) setReagentKitOptions(KitOptions);
    //Conclusions & Results
    const ConclusionOptionsMap:{ [index: number]: ISelectOptions[] } = {};
    const ResultOptions: ISelectOptions[] = [];

    let examResultList: IResultOptions[] = analisysByMethod?.markerFish?.results?.map((data: IResultOptions) => data) ?? [];

    for(let examResult of examResultList){
      ResultOptions.push({value:examResult.id,label:examResult.content});

      for(let conclusion of examResult.results) {
        if(!ConclusionOptionsMap?.[conclusion.id]) ConclusionOptionsMap[conclusion.id] = [{value:conclusion.id,label:conclusion.content}]
        else ConclusionOptionsMap[conclusion.id].push({value:conclusion.id,label:conclusion.content});
      }
    }

    if(Object.keys(ConclusionOptionsMap)?.length) setConclusionOptions(ConclusionOptionsMap);
    if(ResultOptions?.length) setResultOptions(ResultOptions);
    setDefaultValues()

  },[flowStepsActiveIndex,setDataAttempt,setReagentKitOptions,setConclusionOptions,
    setResultOptions,analisysByMethod,setDefaultValues]);

  //handle edit tab button
  const handleEditTable = useCallback(async () => {
    // if(!isActive || !hasPermit) return;
    if (!hasPermit) return;
    if (isEdit) {
      if(!setDefaultValues()){
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setNotInformativeSample(false);
    setEdit(true)
  }, [isEdit, setEdit,reset,setResetFormKey,hasPermit,setNotInformativeSample,setDefaultValues]);

  const [executorfullName, executorTitle] = useMemo(()=> {
    if(examExecutors?.blueprintExecutors?.length){
      let data = examExecutors?.blueprintExecutors?.[0]
      let {firstName = '', lastName = '',middleName = '',specialization = ''} = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }
    if(!profile) return ['','']
    let {firstName = '', lastName = '',middleName = '',title = ''} = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName,title];
  },[profile,examExecutors]);

  const onSubmitTable = useCallback(async (data:any) => {
    const experementID = dataAttempt?.id;
    const {cancerCellCount,cancerCellNucleusRearrangedCount,splitSignalNucleusCount,
      isolatedSignalNucleusCount, rearrangedCellPercentage,description, examResult, examConclusionManual,reagentKits} = data;

    const dataForSend = {
      completed: true,
      date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      lab_assistant: dataAttempt?.labAssistant,
      kit: reagentKits?.value,
      rearrangement:{
          experiment:experementID,
          cancer_cell_count: isNaN(cancerCellCount) ? null : +cancerCellCount,
          cancer_cell_nucleus_rearranged_count: isNaN(cancerCellNucleusRearrangedCount) ? null : +cancerCellNucleusRearrangedCount,
          split_signal_nucleus_count: isNaN(splitSignalNucleusCount) ? null : +splitSignalNucleusCount,
          isolated_signal_nucleus_count: isNaN(isolatedSignalNucleusCount) ? null : +isolatedSignalNucleusCount,
          rearranged_cell_percentage: isNaN(rearrangedCellPercentage) ? null : +rearrangedCellPercentage,
          result: +examResult?.value,
          conclusion: (examConclusionManual || conclusionOptions?.[examResult?.value ?? '']?.[0]?.label) ?? null,
          notes:description
      }};

    if(!defaultValues && tokens?.access && experementID){
      await handleEditTable()
      await dispatch(patchBioExperementUpdate(tokens?.access, experementID, dataForSend,'fish'));
    }  
    if(!!defaultValues && tokens?.access && ableToUpdate) {
      let data:{acceptance:boolean; [index:string]: any} = {...dataForSend,acceptance: true, number:defaultValues?.number}
      delete data['date_completed']
      delete data['completed']
      delete data['rearrangement']['experiment']
      data['rearrangement']['id'] = defaultValues?.rearrangement?.id ?? null;
      let dataToUpdate:SaveExperimentUpdate = data
      await dispatch(patchExperimentUpdate(tokens?.access, 'fish', defaultValues.id, dataToUpdate));
      await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
    }

    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'fish', analisysByMethod?.blueprint)), 100);
    setEdit(false);

  },[tokens?.access,dispatch,dataAttempt,handleEditTable,analisysByMethod,conclusionOptions,defaultValues,ableToUpdate,referralULID]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let errorCount = 0
      if(name === 'cancerCellCount'){
        clearErrors('cancerCellCount');
        let fildValue = value?.['cancerCellCount'];
        let errorMessage = '';

        if(!fildValue) errorMessage = 'Заполните обязательное поле';
        if(fildValue && fildValue < 50)   errorMessage = 'Значение должно быть больше 50';
        if(fildValue && fildValue > 500)  errorMessage = 'Значение должно быть меньше 500';

        !!errorMessage && setError('cancerCellCount', { type: 'custom', message: errorMessage });
        !!errorMessage && (errorCount = errorCount +1);
      }
      
      if(name === 'cancerCellNucleusRearrangedCount'){
        clearErrors('cancerCellNucleusRearrangedCount');
        let fildValue = value?.['cancerCellNucleusRearrangedCount'];
        let errorMessage = '';
        if(!fildValue) errorMessage = 'Заполните обязательное поле';
        if(fildValue && fildValue < 1)   errorMessage = 'Значение должно быть больше 1';
        if(fildValue && fildValue > 500)  errorMessage = 'Значение должно быть меньше 500';

        !!errorMessage && setError('cancerCellNucleusRearrangedCount', { type: 'custom', message: errorMessage });
        !!errorMessage && (errorCount = errorCount +1);
      }

      if(name === 'splitSignalNucleusCount'){
        clearErrors('splitSignalNucleusCount');
        let fildValue = value?.['splitSignalNucleusCount'];
        let errorMessage = '';
        if(!fildValue) errorMessage = 'Заполните обязательное поле';
        if(fildValue && fildValue < 0)   errorMessage = 'Значение должно быть больше 0';
        if(fildValue && fildValue > 500)  errorMessage = 'Значение должно быть меньше 500';

        !!errorMessage && setError('splitSignalNucleusCount', { type: 'custom', message: errorMessage });
        !!errorMessage && (errorCount = errorCount +1);
      }

      if(name === 'isolatedSignalNucleusCount'){
        clearErrors('isolatedSignalNucleusCount');
        let fildValue = value?.['isolatedSignalNucleusCount'];
        let errorMessage = '';
        if(!fildValue) errorMessage = 'Заполните обязательное поле';
        if(fildValue && fildValue > 500)  errorMessage = 'Значение должно быть меньше 500';

        !!errorMessage && setError('isolatedSignalNucleusCount', { type: 'custom', message: errorMessage });
        !!errorMessage && (errorCount = errorCount +1);
      }

      if(value?.['cancerCellCount'] ){
        clearErrors('rearrangedCellPercentage');
        let errorMessage = 'Значение не может быть больше 100%';
        const oldResult = value?.['rearrangedCellPercentage'];
        const A = +value?.['splitSignalNucleusCount'];
        const B = +value?.['isolatedSignalNucleusCount'];
        const C = +value?.['cancerCellCount'];

        const result = +(((A + B)/C)*100)?.toFixed(1);
        
        !isNaN(result) && Math.trunc(+result) !== +oldResult && setValue('rearrangedCellPercentage', Math.trunc(+result));
        if(!isNaN(result) && result > 100) setError('rearrangedCellPercentage', { type: 'custom', message: errorMessage });
      }

      if(errorCount && value?.['rearrangedCellPercentage']){
        setValue('rearrangedCellPercentage', undefined);
      }

  });
    return () => subscription.unsubscribe();
  }, [watch,setError,clearErrors, setValue]);

  const manualInputHandler = useCallback(({value}:any) => {
    return (value?.trimLeft() ?? value)?.replace(regexManualInputPattern, "")
  },[]);


  const notInformativeSampleHandler = useCallback(() => {
    setNotInformativeSample(!notInformativeSample)
  },[notInformativeSample, setNotInformativeSample]);


  const clearNumberFields = useCallback(()=>{
    setValue('cancerCellCount',undefined);
    setValue('cancerCellNucleusRearrangedCount',undefined);
    setValue('splitSignalNucleusCount',undefined);
    setValue('isolatedSignalNucleusCount',undefined);
    setValue('rearrangedCellPercentage',undefined);
    setResetNumberFields(Date.now());
    clearErrors();
    notInformativeSampleHandler();
  },[setValue,setResetNumberFields,clearErrors,notInformativeSampleHandler]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(() => setShowConfirmModal(!showConfirmModal), [setShowConfirmModal,showConfirmModal]);

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(new Date(defaultValues?.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [defaultValues]);

  return (
      <TDetailsContent>
        {!isReferralComplite ? (
        <InfoRowWrapper direction={'space-between'}>
            {!dateOfCompletion ? 
            <TSectionTitle width="60%" color={dataAttempt?.bioAcquired || isActive ? "#7A78E9" : "#777"}>Результаты исследования биоматериала методом FISH</TSectionTitle> : 
            <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
            }
            {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? <TEditButton disabled={!dataAttempt?.bioAcquired} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton> : hasPermit && <TEditButton disabled={!dataAttempt?.bioAcquired || !isActive} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>}
        </InfoRowWrapper>
        ) : (
          <InfoRowWrapper direction="flex-start">
            <TSectionTitle width="60%" color="#7A78E9">Результаты исследования биоматериала методом FISH</TSectionTitle>
          </InfoRowWrapper>
        )}
        <TWrapper onSubmit={handleSubmit(onSubmitTable)} key={resertFormKey}>
          <Table>
            <TBody>
              <tr>
                <td colSpan={2} className="head violet leftTopRadius">Реагенты (наборы, зонды)</td>
                <td colSpan={4} className={!!errors?.['reagentKits'] ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'reagentKits'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={reagentKitOptions}
                        placeholder={'--'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('reagentKits')}
                      />
                    )}
                  />
                </td>
              </tr>
            </TBody>
            {/* header */}
            <TBody>
              <tr className="borderTop violet">
                <td className="head flex-content">Маркер</td>
                <td className="head flex-content"><div>Кол-во проанализ. ядер опухолевых клеток </div><div>[50 - 500]</div></td>
                <td className="head flex-content"><div>Кол-во ядер опухолевых клеток без реаранжировки </div><div>[1 - 500]</div></td>
                <td className="head flex-content"><div>Кол-во ядер с BA-сигналом </div><div>[0 - 500]</div></td>
                <td className="head flex-content"><div>Кол-во ядер с изолированным сигналом </div><div>[0 - 500]</div></td>
                <td className="head flex-content noRadius"><div>Ядер опухолевых клеток c реаранжировкой</div><div>%</div></td>
              </tr>
            </TBody>
            <TBody key={resetNumberFields}>
              <tr>
                <td>{analisysByMethod?.markerFish?.name ?? "--"}</td>
                <td className="no-padding-cell"><Tooltip className="tooltip" id="form-tooltip" place="top" overridePosition={({ left, top }) => ({ left: left, top: top })}>
                    {!notInformativeSample ? 'Используйте в случае неинформативности образца' : 'Образец неинформативен'}
                  </Tooltip><TCustomInput
                  {...register('cancerCellCount', {
                    required: !notInformativeSample,
                    validate: (value:number) => {
                      if(notInformativeSample) return true;
                      return value >= 50 && value <= 500}
                  })}
                  type='text'
                  onChange={({target}) => !notInformativeSample && setValue('cancerCellCount',target.value)}
                  onKeyUp={async ({target}) => !notInformativeSample && setValue('cancerCellCount',await manualInputHandler(target))}
                  placeholder='--'
                  disabled={!isEdit || notInformativeSample}
                  error={!!errors?.['cancerCellCount']}
                  iconRight={<TBlockFieldButton active={notInformativeSample} data-for="form-tooltip" data-tip=""><BlockingIcon/></TBlockFieldButton>}
                  onRightIconClick={() => clearNumberFields()}
                  defaultValue={getValues('cancerCellCount')}
                />{notInformativeSample && 
                <TBlockFieldButton 
                  onClick={() => clearNumberFields()} 
                  active={notInformativeSample} 
                  show={notInformativeSample} 
                  data-for="form-tooltip" 
                  data-tip="">
                  <BlockingIcon/>
                </TBlockFieldButton>}</td>

                <td className="no-padding-cell"><TCustomInput
                  {...register('cancerCellNucleusRearrangedCount', {
                    required: !notInformativeSample,
                    validate: (value:number) => {
                      if(notInformativeSample) return true;
                      if(isNaN(value)) return false;
                      return value >= 1 && value <= 500}
                  })}
                  type='text'
                  onChange={({target}) => setValue('cancerCellNucleusRearrangedCount',target.value)}
                  onKeyUp={async ({target}) => setValue('cancerCellNucleusRearrangedCount',await manualInputHandler(target))}
                  placeholder='--'
                  disabled={!isEdit || notInformativeSample}
                  error={!!errors?.['cancerCellNucleusRearrangedCount']}
                  defaultValue={getValues('cancerCellNucleusRearrangedCount')}
                /></td>

                <td className="no-padding-cell"><TCustomInput
                  {...register('splitSignalNucleusCount', {
                    required: !notInformativeSample,
                    validate: (value:number) => {
                      if(notInformativeSample) return true;
                      if(isNaN(value)) return false;
                      return value >= 0 && value <= 500}
                  })}
                  type='text'
                  placeholder='--'
                  onChange={({target}) => setValue('splitSignalNucleusCount',target.value)}
                  onKeyUp={async ({target}) => setValue('splitSignalNucleusCount',await manualInputHandler(target))}
                  disabled={!isEdit || notInformativeSample}
                  error={!!errors?.['splitSignalNucleusCount']}
                  defaultValue={getValues('splitSignalNucleusCount')}
                /></td>

                <td className="no-padding-cell"><TCustomInput
                  {...register('isolatedSignalNucleusCount', {
                    required: !notInformativeSample,
                    validate: (value:number) => {
                      if(notInformativeSample) return true;
                      if(isNaN(value)) return false;
                      return value >= 0 && value <= 500}
                  })}
                  type='text'
                  placeholder='--'
                  onChange={({target}) => setValue('isolatedSignalNucleusCount',target.value)}
                  onKeyUp={async ({target}) => setValue('isolatedSignalNucleusCount',await manualInputHandler(target))}
                  disabled={!isEdit || notInformativeSample}
                  error={!!errors?.['isolatedSignalNucleusCount']}
                  defaultValue={getValues('isolatedSignalNucleusCount')}
                /></td>

                <td className="no-padding-cell"><TCustomInput
                  {...register('rearrangedCellPercentage',{
                    // validate: (value:number) => value >= 0 && value <= 100
                  })}
                  defaultValue={getValues('rearrangedCellPercentage') ?? '--'}
                  placeholder='--'
                  disabled={true}
                  error={!!errors?.['rearrangedCellPercentage']}
                /></td>

              </tr>
            </TBody>

            <TBody>
              <tr>
                <td colSpan={2} className="head violet leftRadius">Референсные значения</td>
                <td colSpan={4}>
                  {analisysByMethod?.markerFish?.references?.map((ref:MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="head violet leftRadius">Результат исследования</td>
                <td colSpan={4} className={!!errors?.['examResult'] ? 'error' : ''}>
                <Controller
                    control={control}
                    name={'examResult'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(e) => {
                          setValue('examConclusion',conclusionOptions?.[value?.value]?.[0]);
                          setValue('examConclusionManual',undefined);
                          return onChange(e)}}
                        selected={value}
                        options={resultOptions}
                        placeholder={'--'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('examResult')}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="head violet leftRadius">Клинико-лабораторное заключение</td>
              </tr>
              <tr>
                <td colSpan={6} className={!!errors?.['examConclusionManual'] ? 'error' : ''}>
                  {!reangeReasonStatus?.includes(watchExamResult?.label) ? (
                    conclusionOptions?.[watchExamResult?.value]?.[0]?.label ?? ''
                  ) : (
                    <Textarea
                      {...register('examConclusionManual', {
                        maxLength: 999,
                        required: (conclusionOptions?.[watchExamResult?.value]?.[0]?.label ?? '')?.toLowerCase() === 'иное'
                      })}
                      placeholder={'Текст клинико-лабораторного заключения'}
                      className='conclusionDescription'
                      defaultValue=''
                      height={100}
                      readOnly={!isEdit}
                      disabled={!isEdit}
                      maxLength={300}
                    />
                  )}
                </td>
              </tr>
              {!reangeReasonStatus?.includes(watchExamResult?.label) && ( <tr>
                <td colSpan={6} className={!!errors?.['description'] ? 'error' : ''}>
                  <Textarea
                    {...register('description', {
                      maxLength: 999,
                      required: (conclusionOptions?.[watchExamResult?.value]?.[0]?.label ?? '')?.toLowerCase() === 'иное'
                    })}
                    placeholder={'Дополните заключение вручную, \nесли это требуется\n'}
                    className='conclusionDescription'
                    defaultValue={getValues('description') ?? ''}
                    height={100}
                    readOnly={!isEdit}
                    disabled={!isEdit}
                    maxLength={300}
                  />
                </td>
              </tr>)}
            </TBody>
          </Table>
          {(!isActive || !!defaultValues) && !!executorfullName && <TExecutor>
          <b>{executorfullName ?? 'Исполнитель не определен'}</b>
          {executorTitle ? `, ${executorTitle}` : ''}
        </TExecutor>}
        {defaultValues?.labAssistant && (<TBioAcquired height={'84px'}>
          {experementBioSteps}
          <span>Материал подготовил: 
            <b> {labAssistantMap?.[defaultValues?.labAssistant]?.fullname ?? 'Исполнитель не определен'}</b>
            {`, ${labAssistantMap?.[defaultValues?.labAssistant]?.specialization}`}
          </span>
        </TBioAcquired>)}
        {(!isActive || !!defaultValues) && !!examExecutors?.blueprintAdvisory?.length && <TOtherExecutors height='fit-content'>
            {examExecutors?.blueprintAdvisory?.map((advisor: IBlueprintAdvisors) => (
              <div key={`advisorFISH_Reange${advisor?.fullName}`}><b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
              {advisor?.specialization ? `, ${advisor?.specialization}` : ''}</div>
            ))}
          
        </TOtherExecutors>}

        {!isReferralComplite && <TButtonWrapper>
          {hasPermit && isActive && <Button size={SIZE.SMALL} variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE} type="submit" disabled={!isEdit}>
            Завершить попытку
          </Button>}
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate && <CreateExperimentMainButton type="button" onClick={onModalToggle} disabled={!isEdit}>
            Подтвердить изменения
          </CreateExperimentMainButton>}
          </TButtonWrapper>}
          <InfoModal
            title='Внесенные изменения будут отображены в заключении. '
            showModal={showConfirmModal}
            isWarning={true}
            icon={<WarnIcon />}
            onModalToggle={onModalToggle}
            buttons={<>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onModalToggle}>Вернуться</Button>
              <Button  size={SIZE.SMALL} type="submit" onClick={handleSubmit(data => onSubmitTable(data))}>Продолжить</Button>
            </>}
          />
        </TWrapper>

      </TDetailsContent>
  )
}

export default TablelFISHReange;