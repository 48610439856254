import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Select } from "../../../../../../componentsNew/Select";
import {
  diagnosesSelector,
  selectedDiagnosisSelector,
  selectedLocalizationSelector,
  setDiagnoses,
  setSelectedDiagnosis,
} from "../../../../redux/clinicalData";
import { useEffect } from "react";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import { DiagnosesService } from "../../../../../../services/Diagnoses";
import { ApiDiagnosis } from "../../../../../../services/Diagnoses";

type FlattenedDiagnosis = {
  id: number;
  label: string;
  value: string;
  level: number;
};

const flattenDiagnoses = (diagnoses: ApiDiagnosis[], level: number = 0): FlattenedDiagnosis[] => {
  return diagnoses.flatMap(diagnosis => {
    const current = {
      id: diagnosis.id,
      label: `${diagnosis.code} ${diagnosis.code ? '|' : ''} ${diagnosis.name}`,
      value: String(diagnosis.id),
      level: level,
    };

    const children = flattenDiagnoses(diagnosis.nested_list, level + 1);

    return [current, ...children];
  });
};


export const MkbDiagnosis = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const diagnoses = useSelector(diagnosesSelector);
  const selectedDiagnosis = useSelector(selectedDiagnosisSelector);

  const selectedLocalization = useSelector(selectedLocalizationSelector);

  const effect = async () => {
    if (!selectedLocalization?.value) {
      return;
    }

    const diagnosesService = new DiagnosesService(httpClient);

    const _diagnoses = await diagnosesService.searchDiagnoses({
      localization_id: parseInt(selectedLocalization?.value, 10),
    });

    if (_diagnoses?.length) {
      dispatch(setDiagnoses(_diagnoses));
    }
  };

  const flattenedDiagnoses = flattenDiagnoses(diagnoses);

  useEffect(() => {
    effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocalization]);

  return (
    <StyledRowContainer>
      <StyledSelectContainer>
        <Select
          title="Диагноз по МКБ-10"
          required
          placeholder="Диагноз по МКБ-10"
          disabled={selectedLocalization === null}
          options={flattenedDiagnoses.map(diagnosis => ({
            label: diagnosis.label,
            value: diagnosis.value,
            selected: diagnosis.value === selectedDiagnosis?.value,
            style: {
              paddingLeft: `${diagnosis.level * 20}px`, // Indent based on level
            }
          }))}
          onSelect={option => dispatch(setSelectedDiagnosis(option))}
        />
      </StyledSelectContainer>
    </StyledRowContainer>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledSelectContainer = styled.div`
  width: 100%;
`;
