import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Radio } from "../../../../componentsNew/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  researchTypeSelector,
  setResearchType,
} from "../../redux/researchType";
import { 
  RESEARCH_TYPE__FISH,
  RESEARCH_TYPE__IHC,
  RESEARCH_TYPE__NGS,
  RESEARCH_TYPE__FA,
  RESEARCH_TYPE__PCR,
  RESEARCH_TYPE__SS,
  getResearchTypeName,
  getResearchTypeMarkersUrl,
} from "../../../../constants/referral";

export const ResearchType = () => {
  const dispatch = useDispatch();

  const researchType = useSelector(researchTypeSelector);

  const onResearchTypeSelect = (selectedResearchType: any) => {
    dispatch(setResearchType(selectedResearchType));
  };

  const researchTypes = [
    {
      code: RESEARCH_TYPE__IHC,
      name: getResearchTypeName(RESEARCH_TYPE__IHC) || '',
      selected: researchType.code === RESEARCH_TYPE__IHC,
      disabled: false,
      markersUrl: getResearchTypeMarkersUrl(RESEARCH_TYPE__IHC) || 'ihc',
    },
    {
      code: RESEARCH_TYPE__PCR,
      name: getResearchTypeName(RESEARCH_TYPE__PCR) || '',
      selected: researchType.code === RESEARCH_TYPE__PCR,
      disabled: false,
      markersUrl: getResearchTypeMarkersUrl(RESEARCH_TYPE__PCR) || 'molecular-profiling',
    },
    {
      code: RESEARCH_TYPE__FA,
      name: getResearchTypeName(RESEARCH_TYPE__FA) || '',
      selected: researchType.code === RESEARCH_TYPE__FA,
      disabled: false,
      markersUrl: getResearchTypeMarkersUrl(RESEARCH_TYPE__FA) || 'molecular-profiling',
    },
    {
      code: RESEARCH_TYPE__SS,
      name: getResearchTypeName(RESEARCH_TYPE__SS) || '',
      selected: researchType.code === RESEARCH_TYPE__SS,
      disabled: false,
      markersUrl: getResearchTypeMarkersUrl(RESEARCH_TYPE__SS) || 'molecular-profiling',
    },
    {
      code: RESEARCH_TYPE__FISH,
      name: getResearchTypeName(RESEARCH_TYPE__FISH) || '',
      selected: researchType.code === RESEARCH_TYPE__FISH,
      disabled: false,
      markersUrl: getResearchTypeMarkersUrl(RESEARCH_TYPE__FISH) || 'fish',
    },
    {
      code: RESEARCH_TYPE__NGS,
      name: getResearchTypeName(RESEARCH_TYPE__NGS) || '',
      selected: researchType.code === RESEARCH_TYPE__NGS,
      disabled: false,
      markersUrl: getResearchTypeMarkersUrl(RESEARCH_TYPE__NGS) || 'ngs',
    },
  ];

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Тип направления</Title>
      </StyledTitleContainer>

      <StyledListContainer>
        {researchTypes.map((researchType) => (
          <Radio
            key={researchType.code}
            selected={researchType.selected}
            text={researchType.name}
            disabled={researchType.disabled}
            onSelect={() => onResearchTypeSelect(researchType)}
          />
        ))}
      </StyledListContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  /* padding: 32px 24px; */
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
