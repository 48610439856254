import Select from 'react-select';
import styled from 'styled-components';
import { defaultThemeType } from '../../../styles/theme';
import { Colors } from '../../../styles/themeNew';

const customStyles = (theme: defaultThemeType, props?: Record<string, unknown>) => ({
  control: (base: any, { isDisabled }: { isDisabled: boolean }) => ({
    ...base,
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
    boxShadow: 'none',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    padding: '18px 22px 18px 16px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    borderRadius: '6px',
    border: `1px solid ${
      props?.error ? theme.colors.alert.warning : props?.bordered ? Colors.MONOCHROM[200] : 'transparent'
    }`,
    height: '60px',
    backgroundColor: '#fff',
    'div[class$="singleValue"]': {
      color: isDisabled ? Colors.MONOCHROM[200] : Colors.MONOCHROM[200],
    },
    '&:hover': {
      borderColor: isDisabled ? Colors.MONOCHROM[200] : Colors.BRAND[500],
    },
  }),
  option: (base: any, { isSelected }: { isSelected: boolean }) => ({
    ...base,
    textAlign: 'left',
    backgroundColor: theme.colors.white,
    border: 'none',
    color: isSelected ? Colors.BRAND[500] : Colors.MONOCHROM[950],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    padding: '8px 14px 8px 12px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      color: Colors.BRAND[500],
    },
    '&:active': {
      backgroundColor: theme.colors.white,
    },
  }),
  menu: (base: any) => ({
    ...base,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: '6px',
    background: theme.colors.white,
    zIndex: 12,
  }),
  menuList: (base: any) => ({
    ...base,
    borderRadius: '6px',
    background: theme.colors.white,
    padding: 0,
    maxHeight: '224px',
  }),
  dropdownIndicator: (base: any, { selectProps: { menuIsOpen } }: { selectProps: { menuIsOpen: boolean } }) => ({
    ...base,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out',
    textAlign: 'center',
    marginTop: '-5px',
    padding: 0
  }),
  container: (base: any) => ({
    ...base,
    position: 'relative',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    color: theme.colors.gray[200],
  }),
  indicatorsContainer: () => ({}),
});

const TSelect = styled(Select)<{ isDisabled?: boolean; isSearchable?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? '#777777' : '#1A1A22')};
  margin: 0;

  [class$='ValueContainer'] {
    margin-top: -5px; 
    align-items: center;
  }

  [class$='singleValue'] {
    font-size: 16px;
    line-height: 16px;
  }
`;

const TLabel = styled.p<{ marginTop?: number }>`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-weight: 500;
  margin-bottom: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '12px')};
`;

export { customStyles, TSelect, TLabel };
