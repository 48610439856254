import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

export const TTable = styled.table`
  width: 100%;

  border-spacing: 0;
  border: 1px solid ${Colors.MONOCHROM[200]};
  font-size: 12px;
  line-height: 14px;

  .blue {
    background-color: ${({ theme }) => theme.colors.blue[50]};
  }

  .violet {
    background-color: ${({ theme }) => theme.colors.violet[100]};
  }

  .lightViolet {
    background-color: ${({ theme }) => theme.colors.violet[50]};
  }

  .orange {
    background-color: ${({ theme }) => theme.colors.orange[50]};
  }

  .lightOrange {
    background-color: ${({ theme }) => theme.colors.orange[25]};
  }

  .head.noRadius {
    border-radius: 0px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .mb40 {
    margin-bottom: 40px;
  }

  th {
    padding: 16px 20px;
  }

  th,
  td {
    position: relative;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to bottom, transparent, #cecece, transparent)
      1 100%;
    border-right: none;
    text-align: left !important;

    :first-child {
      border-left: none;
    }

    ::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        transparent,
        #cecece 50%,
        transparent
      );
    }
  }
`;

export const TThead = styled.thead`
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.MONOCHROM[950]};
  background-color: ${Colors.BRAND[100]};

  .violet,
  th.violet {
    background-color: ${Colors.BRAND[100]};
  }
  .wrapWords {
    word-wrap: normal;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
  }
  .maxWidth10 {
    width: 10%;
    min-width: 10%;
    max-width: 10%;
  }
  .maxWidth20 {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
  .maxWidth30 {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
  }
  .maxWidth40 {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
  }
  .maxWidth50 {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
`;

export const TTBody = styled.tbody`
  box-sizing: border-box;
  position: relative;
  tr {
    height: 52px;
    width: 100%;
  }
  td {
    padding: 20px 16px;
  }
  tr:last-of-type {
    td::before {
      background-image: none;
    }
  }
  tr:not(:last-of-type) td {
    border-bottom-right-radius: 0;
  }
  tr.error,
  td.error {
    border: 1px solid ${({ theme }) => theme.colors.alert.warning};
    > div:first-child {
      border-color: transparent;
    }
  }
  td.noRadius {
    border-radius: 0px;
  }
  .wrapWords {
    word-wrap: normal;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
  }
  .maxWidth10 {
    width: 10%;
    min-width: 10%;
    max-width: 10%;
  }
  .maxWidth20 {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
  .maxWidth30 {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
  }
  .maxWidth40 {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
  }
  .maxWidth50 {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }

  td.leftTopRadius {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
  }
  td.rigthTopRadius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
  }
  td.leftBottomRadius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 10px;
  }
  td.rigthBottomRadius {
    border-top-right-radius: 0;
  }
`;
