export const REFERRAL_STATUS__BIO_PENDING = 'bio_pending';
export const REFERRAL_STATUS__MORPHOLOGY_PENDING = 'morphology_pending';
export const REFERRAL_STATUS__MARKERS_VALIDATION_PENDING = 'markers_validation_pending';
export const REFERRAL_STATUS__ANALYSIS_PENDING = 'analysis_pending';
export const REFERRAL_STATUS__CONCLUSION_PENDING = 'conclusion_pending';
export const REFERRAL_STATUS__REFERRAL_PENDING = 'referral_pending';
export const REFERRAL_STATUS__REFERRAL_CANCELED = 'referral_canceled';
export const REFERRAL_STATUS__REFERRAL_COMPLETED = 'referral_completed';

export const REFERRAL_STATUS:{[index: string]: string;} = {
  REFERRAL_STATUS__BIO_PENDING: 'Прием биоматериала',
  REFERRAL_STATUS__MORPHOLOGY_PENDING: 'Морфологическая характеристика',
  REFERRAL_STATUS__MARKERS_VALIDATION_PENDING: 'Валидация маркeров',
  REFERRAL_STATUS__ANALYSIS_PENDING: 'Реализация исследований',
  REFERRAL_STATUS__CONCLUSION_PENDING: 'Формирование заключения',
  REFERRAL_STATUS__REFERRAL_PENDING: 'Направление закрыто',
  REFERRAL_STATUS__REFERRAL_CANCELED: 'Направление отменено',
  REFERRAL_STATUS__REFERRAL_COMPLETED: 'Направление закрыто',
};

export const RESEARCH_TYPE__MOLECULAR_PROFILING = 'molecularProfiling';
export const RESEARCH_TYPE__IHC = 'IHC';
export const RESEARCH_TYPE__NGS = 'NGS';
export const RESEARCH_TYPE__FISH = 'FISH';
export const RESEARCH_TYPE__PCR = 'PCR';
export const RESEARCH_TYPE__SS = 'SS';
export const RESEARCH_TYPE__FA = 'FA';

export const RESEARCH_TYPES = [
  {
    code: RESEARCH_TYPE__MOLECULAR_PROFILING,
    name: 'Молекулярно-генетическое исследование',
    name_short: 'МГИ',
    markers_url: 'molecular-profiling',
  },
  {
    code: RESEARCH_TYPE__IHC,
    name: 'Иммуногистохимическое исследование',
    name_short: 'ИГХ',
    markers_url: 'ihc'
  },
  {
    code: RESEARCH_TYPE__NGS,
    name: 'NGS',
    name_short: 'NGS',
    markers_url: 'ngs'
  },
  {
    code: RESEARCH_TYPE__FISH,
    name: 'FISH',
    name_short: 'FISH',
    markers_url: 'fish'
  },
  {
    code: RESEARCH_TYPE__PCR,
    name: 'ПЦР',
    name_short: 'ПЦР',
    markers_url: 'molecular-profiling'
  },
  {
    code: RESEARCH_TYPE__SS,
    name: 'Секвенирование по Сэнгеру',
    name_short: 'CC',
    markers_url: 'molecular-profiling'
  },
  {
    code: RESEARCH_TYPE__FA,
    name: 'Фрагментный анализ',
    name_short: 'ФФ',
    markers_url: 'molecular-profiling'
  }
] as const;

export type ResearchTypeCode = typeof RESEARCH_TYPES[number]['code'];

export function getResearchTypeName(code: ResearchTypeCode | null): string | undefined {
  if (!code) return undefined;
  const researchType = RESEARCH_TYPES.find(r => r.code === code);
  return researchType ? researchType.name : '';
}

export function getResearchTypeNameShort(code: ResearchTypeCode | null): string | undefined {
  if (!code) return undefined;
  const researchType = RESEARCH_TYPES.find(r => r.code === code);
  return researchType ? researchType.name_short : '';
}

export function getResearchTypeMarkersUrl(code: ResearchTypeCode | null): string | undefined {
  if (!code) return undefined;
  const researchType = RESEARCH_TYPES.find(r => r.code === code);
  return researchType ? researchType.markers_url : '';
}
