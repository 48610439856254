import { format } from 'date-fns';
import { Referral } from '../../../../../store/referral/model';
import { getResearchTypeNameShort } from '../../../../../constants/referral';
import { TIndicator, TStatusTitle, TStatusWr } from '../../../../../containers/ReferralPage/styled';


const STATUS:{[index: string]: string;} = {
  bio_pending: 'Прием биоматериала',
  morphology_pending: 'Морфологическая характеристика',
  markers_validation_pending: 'Валидация маркeров',
  analysis_pending: 'Реализация исследований',
  conclusion_pending: 'Формирование заключения',
  referral_pending: 'Направление закрыто',
  referral_canceled: 'Направление отменено',
  referral_completed: 'Направление закрыто',

};

export const columnsReferral = () => ([
  {
    header: <div>Номер</div>,
    renderer: (item: Referral) => item.referralId,
    id: 1,
  },
  {
    header: <div>Тип</div>,
    // @ts-ignore
    renderer: (item: Referral) => getResearchTypeNameShort(item.type),
    id: 2,
  },
  {
    header: <div>Дата</div>,
    renderer: (item: Referral) => item?.dateOfAcquisition ? format(new Date(item.dateOfAcquisition), 'dd.MM.yyyy') : item.dateCreated ? format(new Date(item.dateCreated), 'dd.MM.yyyy') : '',
    id: 3,
  },
  {
    header: 'Статус',
    renderer: (item: Referral) => {
      let allExaminationDone = item?.examinationReferral?.status === 'Complete' && item?.status === 'analysis_pending';
      let status:string = 'bio_pending';
      if(allExaminationDone) status = 'conclusion_pending'
      else status = item.status.toString();

      return (<>
        <TStatusWr data-for={`status-table-tooltip-${item.referralId}`} data-tip=''>
          {item?.statusCancellation ? <TIndicator/> : <TIndicator status={status} margin={8}/> }
          <TStatusTitle isSmallStatus>{item?.statusCancellation ? STATUS['referral_canceled'] : STATUS[status]}</TStatusTitle>
        </TStatusWr>
      </>
    )},
    id: 4,
  },
]);
