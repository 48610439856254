import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Select } from "../../../../../../componentsNew/Select";
import Checkbox from "../../../../../../components/shared/Checkbox";

import {
  setStage,
  setSub,
  stageSelector,
  subSelector,
  setStageNonApplicable,
  stageNonApplicableSelector,
  setStageUnknown,
  stageUnknownSelector,
} from "../../../../redux/clinicalData";

export const Stage = () => {
  const dispatch = useDispatch();

  const _stage = useSelector(stageSelector);
  const _stageSub = useSelector(subSelector);

  const stage = ["I", "II", "III", "IV"];
  const subs = ["a", "b", "c", "-"];

  const stageNonApplicable = useSelector(stageNonApplicableSelector);
  const stageUnknown = useSelector(stageUnknownSelector);

  const handleStageNonApplicableChange = () => {
    if (stageNonApplicable) {
      dispatch(setStageNonApplicable(false));
    } else {
      dispatch(setStageNonApplicable(true));
      dispatch(setStageUnknown(false));
      dispatch(setStage(null));
      dispatch(setSub(null));
    }
  };
  
  const handleStageUnknownChange = () => {
    if (stageUnknown) {
      dispatch(setStageUnknown(false));
    } else {
      dispatch(setStageUnknown(true));
      dispatch(setStageNonApplicable(false));
      dispatch(setStage(null));
      dispatch(setSub(null));
    }
  };

  return (
    <StyledContainer>
      <StyledRowContainer>
        <StyledInputContainer>
          <Select
            title="Стадия"
            required={true}
            placeholder="Stage"
            options={stage.map((s) => ({
              label: s,
              value: s,
              selected: s === _stage?.value,
            }))}
            onSelect={(option) => {
              dispatch(setStage(option))
            }}
            disabled={stageNonApplicable || stageUnknown}
          />
        </StyledInputContainer>

        <StyledInputContainer>
        <Select
              placeholder="sub"
              options={subs.map((sub) => ({
                label: sub,
                value: sub,
                selected: sub === _stageSub?.value,
              }))}
              onSelect={(option) => {
                dispatch(setSub(option));
              }}
              disabled={stageNonApplicable || stageUnknown}
            />
        </StyledInputContainer>
      </StyledRowContainer>
      <TCheckboxWr>
        <TCheckbox checked={stageNonApplicable} label='Неприменимо' onChange={handleStageNonApplicableChange} />
        <TCheckbox checked={stageUnknown} label='Не установлена' onChange={handleStageUnknownChange} />
      </TCheckboxWr>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
`;

const StyledInputContainer = styled.div`
  width: 50%;
`;

export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
` 
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;