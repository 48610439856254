import { AxiosInstance } from "axios";

export type ApiCountries = {};

export class CountriesService {
  httpClient: AxiosInstance | null = null;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async getCountries(): Promise<Array<ApiCountries>> {
    const url = `registry/countries/`;

    return await (
      await this.httpClient?.get(url)
    )?.data;
  }
}
