import { combineReducers } from "@reduxjs/toolkit";
import { analisysNewReducer } from './analisysNew';
import { morphCharacteristicReducer } from './morphCharacteristic';
import { consiliumReducer } from './consilium';

export const molecularProfilingDetailsReducer = combineReducers({
  analisysNew: analisysNewReducer,
  morphCharacteristic: morphCharacteristicReducer,
  consilium: consiliumReducer,
});
