import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { format } from 'date-fns';

import {
  fetchAnalysisByBluprint,
  patchExperimentUpdate,
  postSaveExperiment,
  resetAnalysisByBluprint,
} from '../../../../../store/analysis/thunkActions';
import {
  checkPermitCreateFAExperement,
  getProfile,
  getTokens,
} from '../../../../../store/auth/selectors';
import arrowSelectIcon from '../../../../../icons/details-arrow.svg';
import currentStatusIcon from '../../../../../icons/attention.svg';
import defaultStatusIcon from '../../../../../icons/done-gray.svg';
import { TEditButton } from '../../../MarkersValidation/styled';
import Textarea from '../../../../../components/shared/Textarea';
import {
  Table,
  TBody,
} from '../../../../../components/shared/BiomaterialTable';
import defaultTheme from '../../../../../styles/theme';
import {
  TCheckbox,
  TDetails,
  TDetailsContent,
  TSummary,
  TSummaryContent,
  TSummaryIcon,
} from '../../../../../components/shared/Details/styled';
import {
  TWrapper,
  TExecutor,
  TButtonWrapper,
  CreateExperimentMainButton,
  customStylesOptions,
  TOtherExecutors,
  TDateOfCompletion,
} from '../../styled';
import {
  IAnalisysByMethod,
  MarkerGene,
  MarkerConclusion,
  Result,
  SaveAnalysisExperiment,
  SaveExperimentUpdate,
} from '../../../../../store/analysis/model';
import { getAndOverWriteEperement } from '../../../../../store/molProConclusion/thunkActions';
import InfoModal from '../../../../../components/shared/InfoModal';
import Button, { SIZE, VARIANT } from '../../../../../components/shared/Button';
import { ReactComponent as WarnIcon } from '../../../../../icons/warn-red-circle.svg';
import { IBlueprintAdvisors, IExamExecutors } from '../../model';
import { InfoRowWrapper } from '../../../styled';

interface IExecutorOptions {
  value: string;
  label: string;
  disabled: boolean;
  title: string;
  fullName: string;
}

interface IComponentProps {
  flowStepsActiveIndex: any;
  // isSelectedOptions: number[];
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  executorOptions?: IExecutorOptions[];
  isActive: boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string | number;
  disabled?: boolean;
  microsatellite_fa?: number;
  gene_pcr?: number;
}

const ExperimentFA: FC<IComponentProps> = ({
  referralULID,
  isReferralComplite,
  flowStepsActiveIndex,
  analisysByMethod,
  isActive,
  defaultValues,
  ableToUpdate,
  examExecutors,
}) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
    resetField,
    setValue,
    getValues,
  } = useForm();

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  //Permissions
  const ableToCreateFAExperement = useSelector(checkPermitCreateFAExperement);

  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [conclusionKey, setConclusionKey] = useState<number>(Date.now());

  const [conclusionOptions, setConclusionOptions] = useState<ISelectOptions[]>(
    []
  );
  //mutations result options by mutation name
  const [faResultOptions, setFaResultOptions] = useState<{
    [index: string]: ISelectOptions[];
  }>({ undefined: [{ label: '--', value: '', disabled: true }] });

  const [isConclusionDisabled, setConclusionDisabled] = useState<boolean>(true);

  const watchShowManualConclusion = watch('conclusionResult');

  const [executorfullName, executorTitle] = useMemo(() => {
    if (examExecutors?.blueprintExecutors?.length) {
      let data = examExecutors?.blueprintExecutors?.[0];
      let {
        firstName = '',
        lastName = '',
        middleName = '',
        specialization = '',
      } = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }

    if (!profile) return ['', ''];

    let {
      firstName = '',
      lastName = '',
      middleName = '',
      title = '',
    } = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName, title];
  }, [profile, examExecutors]);

  const analyticalSensFa = useMemo(() => {
    if (!analisysByMethod?.markerFa) return;
    const markerFa = analisysByMethod?.markerFa;
    const minSense = markerFa?.analyticalSensMin;
    const maxSense = markerFa?.analyticalSensMax;
    //analytical sensitivity options
    let analyticalSensOptions: ISelectOptions[] = [];
    for (let index = minSense; !(index > maxSense); index++) {
      analyticalSensOptions.push({
        label: index.toString(),
        value: index,
        disabled: false,
      });
    }

    return analyticalSensOptions;
  }, [analisysByMethod?.markerFa]);

  const setDefaultValues = useCallback(() => {
    if (!!defaultValues && Object.keys(defaultValues)?.length) {
      let {
        analyticalSensitivity: sens,
        notes,
        conclusion,
        experimentRuns = [],
      } = defaultValues ?? {};
      // let conclusionResult = conclusion?.length ? conclusion?.split(';\u2004')?.map((text:string) => ({value:text, label:text})) : [];
      let conclusionResult = conclusion?.length
        ? conclusion
            ?.split('\n\r')
            ?.map((text: string) => ({ value: text, label: text }))
        : [];
      const defaults: { [index: string]: any } = {
        analyticalSensitivity: sens
          ? { label: sens?.toString(), value: sens, disabled: false }
          : undefined,
        description: notes ?? '',
        conclusionResult,
      };

      for (let exp of experimentRuns) {
        defaults[`microsatellite:${exp.microsatelliteFaId}`] = {
          value: exp.resultFa,
          label: exp.resultFa,
          disabled: false,
          microsatellite_fa: exp.microsatelliteFaId,
        };
      }

      reset({ ...defaults });

      setResetFormKey(Date.now());
      setConclusionDisabled(!ableToUpdate);
      return true;
    }

    return false;
  }, [
    defaultValues,
    setResetFormKey,
    reset,
    setConclusionDisabled,
    ableToUpdate,
  ]);

  useLayoutEffect(() => {
    if (!analisysByMethod?.markerFa?.markerFaConclusions) return;

    const ConclusionList: ISelectOptions[] = [];
    const markerConclusions: MarkerConclusion[] =
      analisysByMethod.markerFa.markerFaConclusions;
    const uniqOptionNames = new Set<string>();

    // conclusions by default
    const savedConclusion = analisysByMethod?.conclusion ?? '';
    const defaultResults: ISelectOptions[] = [];

    //collect all available options
    let NormalizedSC = savedConclusion?.replaceAll(/[" ", ;]/g, '\u2004');

    for (let conclusion of markerConclusions) {
      // let content       = `${conclusion.content.replaceAll(' ',"\u2004")};\u2004 `;
      let content = conclusion.content;
      let NormalizedC = conclusion.content; //?.replaceAll(' ',"\u2004");

      if (uniqOptionNames.has(content)) continue;
      else uniqOptionNames.add(content);

      let conclusionOption = { value: content, label: content };
      if (NormalizedSC && NormalizedSC?.includes(NormalizedC))
        defaultResults.push(conclusionOption);
      ConclusionList.push(conclusionOption);
    }

    setConclusionOptions(ConclusionList);

    //mutations result options by mutation name
    if (!analisysByMethod?.markerFa?.microsatelliteFa) return;
    let faOptionsMap: { [index: string]: ISelectOptions[] } = {};
    let satellite: MarkerGene[] = analisysByMethod?.markerFa?.microsatelliteFa;
    if (!satellite?.length) return;

    for (let sat of satellite) {
      if (!sat?.name) continue;
      let microsatellite_fa = sat.id;

      let mutationOptions: ISelectOptions[] =
        sat?.results?.map((result: Result) => ({
          value: result.name,
          label: result.name,
          disabled: false,
          microsatellite_fa,
        })) ?? [];

      faOptionsMap[sat.name] = mutationOptions;
    }

    setFaResultOptions(faOptionsMap);
    setDefaultValues();
  }, [analisysByMethod, setFaResultOptions, setDefaultValues]);

  useEffect(() => {
    const subscription = watch((value: any, { name, type }) => {
      if (type === 'change' && name?.includes('microsatellite') && value) {
        let fieldValues = { ...value };
        //remove extra fields
        delete fieldValues.conclusionResult;
        delete fieldValues.analyticalSensitivity;
        delete fieldValues.description;
        //convert form field values to array
        const resultFieldsValues: ISelectOptions[] = Object.values(fieldValues);

        //all results filled
        const isAllResultsFilled = resultFieldsValues.every(
          (option) => !!option && !!option?.value
        );
        if (isAllResultsFilled) {
          setConclusionDisabled(false);
          setConclusionKey(Date.now());
        }

        //reset conclusion and description
        resetField('conclusionResult', undefined);
        setConclusionKey(Date.now());
      }
      if (
        type === 'change' &&
        (name?.includes('conclusionResult') || name?.includes('description'))
      ) {
        let hasConclResultValues = !!(value?.['conclusionResult'] ?? [])
          ?.length;
        let hasDescrResultValues =
          !!(value?.['description'] ?? [])?.length &&
          (value?.['description'] ?? [])?.length < 999;
        let isMaxDescrValue = (value?.['description'] ?? [])?.length > 999;
        if (
          hasConclResultValues ||
          (hasDescrResultValues && !isMaxDescrValue)
        ) {
          clearErrors(['conclusionResult', 'description']);
        }
        if (!hasConclResultValues && !hasDescrResultValues) {
          setError('conclusionResult', {
            type: 'custom',
            message: 'Заполните поле заключения аналитической чувствительности',
          });
          setError('description', {
            type: 'custom',
            message: 'Заполните поле заключения аналитической чувствительности',
          });
        }
        if (!!isMaxDescrValue) {
          setError('description', {
            type: 'custom',
            message: 'Заполните поле заключения аналитической чувствительности',
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [
    watch,
    setConclusionDisabled,
    isConclusionDisabled,
    clearErrors,
    errors,
    setError,
    conclusionOptions,
    setValue,
    resetField,
    setConclusionKey,
  ]);

  //send examination to server
  const handleSaveExperiment = useCallback(
    async (formData: any) => {
      clearErrors();
      const {
        analyticalSensitivity,
        conclusionResult,
        description,
        ...mutationResults
      } = formData;
      const analysis = analisysByMethod.id;
      const number = analisysByMethod.analysisExperiments.length + 1;
      const analytical_sensitivity: number = analyticalSensitivity.value;

      const сonclusionSelectedValues =
        conclusionResult?.map((option: ISelectOptions) => option.value) ?? [];

      let defaultIdForUpdateMap: { [index: string | number]: number } = {};

      if (!!defaultValues?.experimentRuns?.length) {
        let experimentRuns = defaultValues?.experimentRuns ?? [];
        for (let exp of experimentRuns) {
          defaultIdForUpdateMap[exp.microsatelliteFaId] = exp.id;
        }
      }

      const conclusion = [...сonclusionSelectedValues].join('\n\r');
      const notes = description;
      const experiment_runs = Object.values(mutationResults).map(
        (result: any) => {
          let microsatellite_fa = result?.microsatellite_fa ?? null;
          let result_fa = result.value;
          let id = defaultIdForUpdateMap?.[microsatellite_fa];
          if (id) return { id, microsatellite_fa, result_fa };
          return { microsatellite_fa, result_fa };
        }
      );

      if (!analytical_sensitivity)
        return setError('analyticalSensitivity', {
          type: 'custom',
          message: 'Заполните поле аналитической чувствительности',
        });

      const saveExperimentData: SaveAnalysisExperiment = {
        analysis,
        number,
        analytical_sensitivity,
        experiment_runs,
        conclusion,
        notes,
        date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };

      if (!defaultValues && tokens?.access) {
        try {
          reset({}, { keepValues: false });
          setResetFormKey(Date.now());
          await dispatch(
            postSaveExperiment(tokens?.access, 'fa', saveExperimentData)
          );
        } catch (e) {
          console.log(e);
        }
      }

      if (!!defaultValues && tokens?.access && ableToUpdate) {
        let data: { acceptance: boolean; [index: string]: any } = {
          ...saveExperimentData,
          acceptance: true,
          number: defaultValues?.number,
        };
        delete data['date_completed'];
        let dataToUpdate: SaveExperimentUpdate = data;
        await dispatch(
          patchExperimentUpdate(
            tokens?.access,
            'fa',
            defaultValues.id,
            dataToUpdate
          )
        );
        await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
      }

      dispatch(resetAnalysisByBluprint());
      tokens?.access &&
        setTimeout(
          () =>
            dispatch(
              fetchAnalysisByBluprint(
                tokens?.access,
                'fa',
                analisysByMethod?.blueprint
              )
            ),
          100
        );
      setEdit(false);
    },
    [
      analisysByMethod,
      setEdit,
      dispatch,
      setResetFormKey,
      reset,
      ableToUpdate,
      tokens?.access,
      setError,
      clearErrors,
      defaultValues,
      referralULID,
    ]
  );

  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    if (!ableToCreateFAExperement) return;
    if (isEdit) {
      if (!setDefaultValues()) {
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setEdit(true);
  }, [
    isEdit,
    setEdit,
    reset,
    setResetFormKey,
    ableToCreateFAExperement,
    setDefaultValues,
  ]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(
    () => setShowConfirmModal(!showConfirmModal),
    [setShowConfirmModal, showConfirmModal]
  );

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(
      new Date(defaultValues?.dateCompleted),
      'dd.MM.yyyy - HH:mm:ss'
    );
  }, [defaultValues]);

  return (
    <TDetails open={isActive || !!defaultValues?.acceptance}>
      <TSummary>
        <TSummaryContent isSelected={true}>
          <TCheckbox
            disabled={true}
            checked={defaultValues?.acceptance ?? false}
          />
          Попытка №{defaultValues?.number ?? flowStepsActiveIndex.index}
          <TSummaryIcon className='arrow' src={arrowSelectIcon} />
        </TSummaryContent>{' '}
        <TSummaryIcon
          src={
            !Object?.keys(defaultValues ?? {})?.length
              ? currentStatusIcon
              : defaultStatusIcon
          }
        />
      </TSummary>
      <TDetailsContent>
        {!isReferralComplite && (
          <InfoRowWrapper direction='space-between'>
            <TDateOfCompletion>
              {!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}
            </TDateOfCompletion>
            {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? (
              <TEditButton disabled={false} onClick={handleEditPage}>
                {!isEdit ? 'Редактировать' : 'Отменить'}
              </TEditButton>
            ) : (
              ableToCreateFAExperement && (
                <TEditButton disabled={!isActive} onClick={handleEditPage}>
                  {!isEdit ? 'Редактировать' : 'Отменить'}
                </TEditButton>
              )
            )}
          </InfoRowWrapper>
        )}
        <TWrapper
          onSubmit={handleSubmit((data) => handleSaveExperiment(data))}
          key={resertFormKey}
        >
          <Table>
            {/* analytical sensitivity */}
            <TBody>
              <tr>
                <td colSpan={3} className='head violet topRadius'>
                  Аналитическая чувствительность метода, %
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  className={
                    !!errors?.analyticalSensitivity
                      ? 'error noBorderRadius'
                      : 'noBorderRadius'
                  }
                >
                  <Controller
                    control={control}
                    name={'analyticalSensitivity'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={analyticalSensFa}
                        placeholder={'Выберите значение из выпадающего списка'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        defaultValue={getValues('analyticalSensitivity')}
                      />
                    )}
                  />
                </td>
              </tr>
            </TBody>
            {/* header */}
            <TBody>
              <tr className='borderTop violet'>
                <td className='head noRadius'>Маркер</td>
                <td className='head noRadius'>Микросателлитный маркер</td>
                <td className='head noRadius'>Результат исследования</td>
              </tr>
            </TBody>
            {/* genes */}
            <TBody>
              {!!analisysByMethod?.markerFa &&
                analisysByMethod.markerFa.microsatelliteFa.map(
                  (
                    satellite: MarkerGene,
                    index: number,
                    microsatellite: MarkerGene[]
                  ) => (
                    <tr key={index + 'microsatellite' + satellite.id}>
                      {!index && (
                        <td className='borderRight' rowSpan={5}>
                          {analisysByMethod.markerFa.name}
                        </td>
                      )}
                      <td>{satellite.name}</td>
                      <td
                        className={
                          !!errors?.[`microsatellite:${satellite.id}`]
                            ? 'error'
                            : ''
                        }
                      >
                        <Controller
                          control={control}
                          name={`microsatellite:${satellite.id}`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              selected={value}
                              options={
                                faResultOptions?.[satellite?.name ?? ''] ?? []
                              }
                              placeholder={'--'}
                              styles={customStylesOptions(defaultTheme)}
                              isOptionDisabled={(option: any) =>
                                option.disabled
                              }
                              isDisabled={!isEdit}
                              defaultValue={getValues(
                                `microsatellite:${satellite.id}`
                              )}
                            />
                          )}
                        />
                      </td>
                    </tr>
                  )
                )}
            </TBody>
            {/* conclusion */}
            <TBody>
              <tr>
                <td colSpan={3} className='head violet'>
                  Клинико-лабораторное заключение
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  className={
                    !!errors?.conclusionResult
                      ? 'error noBorderRadius'
                      : 'noBorderRadius'
                  }
                >
                  <Controller
                    key={`my_unique_select_key__${conclusionKey}`}
                    control={control}
                    name={'conclusionResult'}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isMulti
                        onChange={onChange}
                        selected={value}
                        options={
                          watchShowManualConclusion?.length === 2
                            ? []
                            : conclusionOptions
                        }
                        placeholder={'Выберите значение из выпадающего списка'}
                        isDisabled={isConclusionDisabled || !isEdit}
                        noOptionsMessage={() =>
                          'нет опций, возможно выбрать только 2 варианта'
                        }
                        isSearchable={watchShowManualConclusion?.length !== 2}
                        defaultValue={getValues('conclusionResult')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  className={
                    !!errors?.description && !watchShowManualConclusion?.length
                      ? 'error '
                      : ''
                  }
                >
                  <Textarea
                    {...register('description', {
                      required: !watchShowManualConclusion?.length,
                      maxLength: 999,
                    })}
                    key={conclusionKey}
                    placeholder={
                      'И/Или введите текст вручную, если это требуется'
                    }
                    className='conclusionDescription'
                    defaultValue={getValues('description')}
                    height={85}
                    readOnly={!isEdit}
                    disabled={!isEdit}
                    error={!!errors?.description}
                    maxLength={1000}
                  />
                </td>
              </tr>
            </TBody>
          </Table>

          {(!isActive || !!defaultValues) && !!executorfullName && (
            <TExecutor>
              <b>{executorfullName ?? 'Исполнитель не определен'}</b>
              {executorTitle ? `, ${executorTitle}` : ''}
            </TExecutor>
          )}
          {(!isActive || !!defaultValues) &&
            !!examExecutors?.blueprintAdvisory?.length && (
              <TOtherExecutors height='fit-content'>
                {examExecutors?.blueprintAdvisory?.map(
                  (advisor: IBlueprintAdvisors) => (
                    <div key={`advisorFA${advisor?.fullName}`}>
                      <b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
                      {advisor?.specialization
                        ? `, ${advisor?.specialization}`
                        : ''}
                    </div>
                  )
                )}
              </TOtherExecutors>
            )}

          {!isReferralComplite && (
            <TButtonWrapper>
              {/* {ableToCreateFAExperement && isActive && <Button size={SIZE.SMALL} variant={VARIANT.BLUE} type="submit" disabled={(!isConclusionDisabled && !isEdit) || !isActive}>
              Завершить попытку
            </Button>} */}
              {ableToCreateFAExperement && isActive && (
                <Button
                  size={SIZE.SMALL}
                  variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE}
                  type='submit'
                  disabled={!isEdit}
                >
                  Завершить попытку
                </Button>
              )}
              {!!Object?.keys(defaultValues ?? {})?.length &&
                !!ableToUpdate && (
                  <CreateExperimentMainButton
                    type='button'
                    onClick={onModalToggle}
                    disabled={!isEdit}
                  >
                    Подтвердить изменения
                  </CreateExperimentMainButton>
                )}
            </TButtonWrapper>
          )}
          <InfoModal
            title='Внесенные изменения будут отображены в заключении. '
            showModal={showConfirmModal}
            isWarning={true}
            icon={<WarnIcon />}
            onModalToggle={onModalToggle}
            buttons={
              <>
                <Button
                  size={SIZE.SMALL}
                  variant={VARIANT.TRANSPARENT}
                  onClick={onModalToggle}
                >
                  Вернуться
                </Button>
                <Button
                  size={SIZE.SMALL}
                  type='submit'
                  onClick={handleSubmit((data) => handleSaveExperiment(data))}
                >
                  Продолжить
                </Button>
              </>
            }
          />
        </TWrapper>
      </TDetailsContent>
    </TDetails>
  );
};

export default ExperimentFA;
