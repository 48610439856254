import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import { LocalizationsService } from "../../../services/Localizations";
import { setLocalizations } from "../redux/clinicalData";
import { useEffect } from "react";
import { getTokens } from "../../../store/auth/selectors";
import { researchTypeSelector } from "../redux/researchType";

export const useLocalizations = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const tokens = useSelector(getTokens);
  const researchType = useSelector(researchTypeSelector);

  const searchLocalizations = async () => {
    const localizationService = new LocalizationsService(httpClient);

    const _localizations = await localizationService.searchLocalizations(researchType?.code || 'IHC');

    if (_localizations?.length) {
      dispatch(setLocalizations(_localizations));
    }
  };

  const effect = async () => {
    await searchLocalizations();
  };

  useEffect(() => {
    if (!tokens?.access) {
      return;
    }

    effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens?.access]);
};
