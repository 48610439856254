/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useCallback, useMemo, useState } from 'react';
import ru from 'date-fns/locale/ru';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { TWrapper, TLabel } from './styled';
import { ReactComponent as CalendarIconNew } from '../../../icons/input-calendar-new.svg';
import DayPickerHeader from './DayPickerHeader';
import Input from '../Input';
import InputMask from 'react-input-mask';

export interface IInputDayPicker extends ReactDatePickerProps {
  className?: string;
  width?: string;
  showSelects?: boolean;
  bordered?: boolean;
  label?: string;
  error?: boolean;
  date: Date;
  isBirthDate?: boolean;
  marginTop?: number;
}

const InputDayPicker: FC<IInputDayPicker> = ({
  isBirthDate = false,
  disabled,
  bordered,
  error,
  label,
  showSelects = false,
  className,
  date,
  width,
  marginTop,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(
    () => (disabled ? null : setIsOpen(!isOpen)),
    [disabled, isOpen]
  );
  const onClose = useCallback(
    () => (disabled ? null : setIsOpen(false)),
    [disabled]
  );

  const yearsRange = useMemo(
    () =>
      isBirthDate
        ? {
            from: 100,
          }
        : {
            from: 10,
            to: 10,
          },
    [isBirthDate]
  );

  return (
    <TWrapper className={className} disabled={disabled}>
      {label && <TLabel marginBottom={marginTop}>{label}</TLabel>}
      <DatePicker
        open={isOpen}
        selected={date}
        dateFormat='dd.MM.yyyy'
        disabled={disabled}
        locale={ru}
        showPopperArrow={false}
        calendarClassName='datePickerCalendar'
        onCalendarClose={onClose}
        onCalendarOpen={onOpen}
        onClickOutside={onClose}
        isClearable={!disabled && !!date}
        shouldCloseOnSelect={true}
        onSelect={(date) => {
          setIsOpen(false);
          return date;
        }}
        readOnly={disabled}
        renderCustomHeader={({ changeYear, changeMonth, monthDate }) => (
          <DayPickerHeader
            month={monthDate}
            onMonthSelect={changeMonth}
            onYearSelect={changeYear}
            yearsRange={yearsRange}
          />
        )}
        customInput={
          <InputMask mask='99.99.9999'>
            {() => (
              <Input
                bordered={bordered}
                disabled={disabled}
                // iconRight={isOpen ? <CalendarActiveIcon /> : <CalendarIcon />}
                iconRight={isOpen ? <CalendarIconNew /> : <CalendarIconNew />}
                error={error}
                placeholder={restProps.placeholderText}
                onRightIconClick={onOpen}
              />
            )}
          </InputMask>
        }
        {...restProps}
      />
    </TWrapper>
  );
};

export default InputDayPicker;
