import { FC, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Input from '../../../../../components/shared/Input';
import InputSelect from '../../../../../components/shared/InputSelect';
import { getOrganizations, getOrganizationsSelectOptions } from '../../../../../store/organizations/selectors';
import {
  TFieldWr,
  TInputsWr,
  TPromptText,
  TTooltipPrompt,
  TMenuIcon,
  TMenuItem,
  ErrorMessage, HideInput, TFakeRadio,
} from './styled';
import { validations } from "../../../../../utils/validations";
import deleteIcon from '../../../../../icons/cross-gray-32x32.svg';

interface IAmbulatoryCard {
  disabledFields?: boolean;
  blockIndex: number;
  cardID: string;
  enableEdit: boolean;
  removeAmbulatoryCard: (index: number) => void;
  setPriorityAmbulatoryCard: (index: number, allFields: any[]) => void;
  canDelete?: boolean;
  errorContent: any;
}



const AmbulatoryCard: FC<IAmbulatoryCard> = ({ disabledFields, canDelete, removeAmbulatoryCard, blockIndex, cardID, enableEdit, errorContent, setPriorityAmbulatoryCard }) => {
  const { control, setValue, register, unregister, watch, getValues, formState: { errors } } = useFormContext();

  const organizationOptions = useSelector(getOrganizationsSelectOptions);
  const organizations = useSelector(getOrganizations);

  const organizationId = useWatch({ control, name: `ambulatoryCards.${blockIndex}.number` });
  const organization = useWatch({ control, name: `ambulatoryCards.${blockIndex}.organizationId` });
  const priority = useWatch({ control, name: `ambulatoryCards.${blockIndex}.priority` });
  const customOrganizationName = watch(`ambulatoryCards.${blockIndex}.customOrganization`, undefined)

  useEffect(() => {
    setValue(`ambulatoryCards.${blockIndex}.id`, cardID ?? '')
  }, [blockIndex, cardID, setValue]);

  const isCustom = useMemo(() => organizations.find(({ id }) => id === organization)?.nameOfficial === 'OTHER_CENTER',
    [organizations, organization]);

  useEffect(() => {
    if (!isCustom) {
      if (!!customOrganizationName?.length) {
        setValue(`ambulatoryCards.${blockIndex}.customOrganization`, '');
        setValue(`ambulatoryCards.${blockIndex}.number`, '');
        unregister(`ambulatoryCards.${blockIndex}.customOrganization`, { keepDefaultValue: false, keepValue: false, keepError: false });
      }

    }
  }, [isCustom, unregister, blockIndex, customOrganizationName, setValue])

  const onCardRemove = useCallback((e) => {
    e.preventDefault();
    if (!blockIndex) return;
    removeAmbulatoryCard(blockIndex);
  }, [blockIndex, removeAmbulatoryCard]);

  const onSetPriorityAmbulatoryCard = useCallback((e) => {
    e.preventDefault();
    let fieldsData = getValues(`ambulatoryCards`)
    setPriorityAmbulatoryCard(blockIndex, fieldsData);
  }, [blockIndex, setPriorityAmbulatoryCard, getValues]);

  const ambulatoryCardErrorMessage = useCallback((blockIndex: number) => {
    if (errors?.ambulatoryCards) {
      const item = errors?.ambulatoryCards[blockIndex];
      if (item?.number?.type) {
        const errorType = item?.number?.type;
        switch (errorType) {
          case 'required':
            return 'Обязательное поле';
          case 'maxLength':
            return 'Длина 10 символов';
          case 'pattern':
            return 'Номер амбулаторной карты должен быть 00000/00';
          default:
            return 'Что то пошло не так';
        }
      }
    }
    return ''
  }, [errors?.ambulatoryCards]);

  return (
    <>
      <TTooltipPrompt id='prompt' place='right' overridePosition={({ left, top }) => ({ left: left + 40, top: top })} >
        <TPromptText>
          <li>Укажите номер карты по формату: <b>0000/00</b></li>
          <li>Пример: <b>00345/23</b></li>
          <li>Выберите учреждение, выдавшее карту</li>
          <li>При выборе <b>“Иное УЗ”</b> укажите название в поле ниже</li>
        </TPromptText>
      </TTooltipPrompt>
      <TInputsWr data-for={disabledFields || organization ? '' : 'prompt'} data-tip=''>
        <TFakeRadio onClick={onSetPriorityAmbulatoryCard} active={priority}><div></div></TFakeRadio>
        <TFieldWr>
          {/* {errors?.['ambulatoryCards']?.[blockIndex] && <small>{errors?.['ambulatoryCards']?.[blockIndex]?.number?.message}</small>} */}
          {/* edit mode, organization selected */}
          {/* {enableEdit && !Number.isNaN(parseInt(organization)) && ( */}
          {enableEdit && (
            <Controller
              control={control}
              name={`ambulatoryCards.${blockIndex}.number`}
              rules={{
                required: true, validate: {
                  hasValue: (v) => !!v,
                  // maxLength: (v) => {
                  //   if (isCustom) return true;
                  //   return v?.length && v?.length < 9;
                  // },
                  // pattern: (v) => {
                  //   if (isCustom) return true;
                  //   return validations.idAmbulatoryCard.pattern.test(v)
                  // }
                }
              }}
              render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => {
                  return <Input
                    disabled={disabledFields}
                    value={value}
                    error={invalid}
                    onChange={(event) => {
                      const { value } = event.target;
                      return onChange(value)
                    }}
                    placeholder='№ карты *'
                    // errorText={ambulatoryCardErrorMessage(blockIndex)}
                  />
                }
              }
            />)
          }

          {/* never remove this controller. Its need to correct validate ambulatory cards!!! */}
          <Controller
            control={control}
            name={`ambulatoryCards.${blockIndex}.id`}
            render={({ field: { value } }) => (
              <HideInput
                value={value}
              />
            )}
          />

          {/* editing mode is off or no organization is selected */}
          {!enableEdit && (
              <Input disabled value={organizationId} placeholder='№ карты *' />
          )}

        </TFieldWr>

        <TFieldWr>
          {/*{(errors?.['ambulatoryCards']?.[blockIndex]) && <small>{errors?.['ambulatoryCards']?.[blockIndex]?.organizationId?.message}</small>}*/}
            <Controller
              control={control}
              name={`ambulatoryCards.${blockIndex}.organizationId`}
              rules={validations.idAmbulatoryCard}
              render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
                <InputSelect
                  disabled={disabledFields}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={organizationOptions}
                  placeholder='УЗ *'
                  error={invalid}
                />
              )}
            />
        </TFieldWr>

        {enableEdit && <TMenuItem onClick={onCardRemove} visible={canDelete} disabled={!blockIndex}>
          <TMenuIcon src={deleteIcon} />
        </TMenuItem>
        }
      </TInputsWr>
      {isCustom && (
        <Input
          {...register(`ambulatoryCards.${blockIndex}.customOrganization`, { required: true })}
          disabled={disabledFields}
          placeholder='Укажите УЗ  *'
          error={!!errors?.ambulatoryCards?.[blockIndex]?.customOrganization}
          errorText={ambulatoryCardErrorMessage(blockIndex)}
          style={{ marginLeft: '43px', width: '647px',marginTop: '2px', }}
        />
      )}
      {errorContent?.error && <ErrorMessage>{errorContent?.error_content}</ErrorMessage>}
    </>
  );
}

export default AmbulatoryCard;