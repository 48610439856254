import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  TPersonList,
  TPersonListElement,
  TPersonFormWr,
  TPersonFormButton,
  TPersonFormSelectWr,
  TPersonFormManualSelectBtnWr,
  TPersonFormManualInputWr,
  TCustomInput,
  TFormInputWr,
  TPersonFormButtonSecondary,
  TPersonFormButtonGroupWrapper,
  TPersonFormButtonWrapper,
  CrossIconWrapper,
} from './styled';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus-icon-brand.svg';
import InputSelect from '../../../components/shared/InputSelect';
import IconSquareButton from '../../../components/shared/IconSquareButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  manualFullNameSelector,
  manualSpecializationSelector,
  selectedAdvisorSelector,
  setManualFullName,
  setManualSpecialization,
  setSelectedAdvisor,
} from '../../../containers/MolecularProfilingDetailsPage/redux/consilium';

interface IAdvisorOptions {
  value: string;
  label: string;
  specialization: string;
  full_name: string;
  blueprint: number;
}

interface IAdvisorUpdate {
  advisor_ulid: string;
  full_name: string;
  specialization: string;
  blueprint: number;
}

interface IComponentProps {
  advisorOptions?: IAdvisorOptions[];
  setSelectedAdvisorList: (data: IAdvisorUpdate[]) => void;
  itemIDByDefault: number;
}

const MAX_ADVISORS = 6;

const AdvisorList: FC<{
  advisors: IAdvisorOptions[];
  onRemove: (index: number) => void;
}> = ({ advisors, onRemove }) => (
  <TPersonList active={true} hiddenSroll={advisors.length > 4} marginTop={34}>
    {advisors.map((advisor, index) => (
      <TPersonListElement key={advisor.label}>
        <div>
          {advisor.full_name ?? '--'}
          <span>{advisor.specialization ?? '--'}</span>
        </div>
        <CrossIconWrapper>
          <CrossIcon onClick={() => onRemove(index)} />
        </CrossIconWrapper>
      </TPersonListElement>
    ))}
  </TPersonList>
);

const Concilium: FC<IComponentProps> = ({
  advisorOptions = [],
  setSelectedAdvisorList,
  itemIDByDefault,
}) => {
  const [openManualInput, setOpenManualInput] = useState(false);
  const [advisorList, setAdvisorList] = useState<IAdvisorOptions[]>([]);
  const selectedAdvisor = useSelector(selectedAdvisorSelector);
  const manualFullName = useSelector(manualFullNameSelector);
  const manualSpecialization = useSelector(manualSpecializationSelector);

  const dispatch = useDispatch();

  const selectedAdvisorsUlid = useMemo(
    () => advisorList.map((option) => option.value),
    [advisorList]
  );
  const isAdvisorListFull = useMemo(
    () => advisorList.length >= MAX_ADVISORS,
    [advisorList]
  );

  const updatedAdvisorList = useMemo(
    () =>
      advisorList.map(
        ({ value: advisor_ulid, full_name, specialization, blueprint }) => ({
          advisor_ulid,
          full_name,
          specialization,
          blueprint,
        })
      ),
    [advisorList]
  );

  useEffect(() => {
    setSelectedAdvisorList(updatedAdvisorList);
  }, [updatedAdvisorList, setSelectedAdvisorList]);

  const toggleManualInput = () => {
    setOpenManualInput((prev) => !prev);
    dispatch(setManualFullName(''));
    dispatch(setManualSpecialization(''));
    dispatch(setSelectedAdvisor(''));
  };

  const handleSelectSubmit = useCallback(() => {
    const advisorData = advisorOptions.find(
      (option) => option.value === selectedAdvisor
    );
    if (advisorData) {
      setAdvisorList((prev) => [...prev, advisorData]);
      dispatch(setSelectedAdvisor(''));
    }
  }, [advisorOptions, selectedAdvisor, dispatch]);

  const handleManualSubmit = useCallback(() => {
    if (manualFullName && manualSpecialization) {
      setAdvisorList((prev) => [
        ...prev,
        {
          value: '',
          label: manualFullName,
          specialization: manualSpecialization,
          full_name: manualFullName,
          blueprint: itemIDByDefault,
        },
      ]);
      dispatch(setManualFullName(''));
      dispatch(setManualSpecialization(''));
      setOpenManualInput(false);
    }
  }, [manualFullName, manualSpecialization, dispatch, itemIDByDefault]);

  return (
    <div style={{ width: '100%' }}>
      <TPersonFormWr>
        <TPersonFormSelectWr>
          <InputSelect
            value={selectedAdvisor}
            options={advisorOptions.filter(
              (option) => !selectedAdvisorsUlid.includes(option.value)
            )}
            placeholder='--'
            label='Исполнитель'
            onChange={(value) => dispatch(setSelectedAdvisor(value as string))}
            bordered
            isSearchable
            disabled={isAdvisorListFull || openManualInput}
          />
        </TPersonFormSelectWr>

        {!openManualInput && (
          <TPersonFormManualSelectBtnWr>
            <IconSquareButton
              onClick={toggleManualInput}
              invert={isAdvisorListFull}
              disabled={isAdvisorListFull}
              isLightMode
            >
              <PlusIcon />
            </IconSquareButton>
            <span>Добавить исполнителя</span>
          </TPersonFormManualSelectBtnWr>
        )}
        <TPersonFormButtonWrapper>
          <TPersonFormButton
            disabled={isAdvisorListFull || !selectedAdvisor}
            type='button'
            onClick={handleSelectSubmit}
          >
            Добавить
          </TPersonFormButton>
        </TPersonFormButtonWrapper>

        {!!advisorList.length && (
          <AdvisorList
            advisors={advisorList}
            onRemove={(index) =>
              setAdvisorList((prev) => prev.filter((_, i) => i !== index))
            }
          />
        )}

        {openManualInput && (
          <TPersonFormManualInputWr>
            <TFormInputWr>
              <TCustomInput
                value={manualFullName}
                onChange={(e) => dispatch(setManualFullName(e.target.value))}
                bordered
                label='ФИО'
                placeholder='Введите ФИО'
              />
              <TCustomInput
                value={manualSpecialization}
                onChange={(e) =>
                  dispatch(setManualSpecialization(e.target.value))
                }
                bordered
                label='Должность'
                placeholder='Введите должность'
              />
              <TPersonFormButtonGroupWrapper>
                <TPersonFormButtonSecondary onClick={toggleManualInput}>
                  Отменить
                </TPersonFormButtonSecondary>
                <TPersonFormButton
                  disabled={!manualFullName || !manualSpecialization}
                  onClick={handleManualSubmit}
                >
                  Добавить
                </TPersonFormButton>
              </TPersonFormButtonGroupWrapper>
            </TFormInputWr>
          </TPersonFormManualInputWr>
        )}
      </TPersonFormWr>
    </div>
  );
};

export default Concilium;
