import styled from "styled-components";
import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreAmbulatoryCare,
  preAmbulatoryCareSelector,
  setPreAmbulatoryCareNonApplicable,
  preAmbulatoryCareNonApplicableSelector,
  setPreAmbulatoryCareUnknown,
  preAmbulatoryCareUnknownSelector,
} from "../../../../redux/clinicalData";
import Checkbox from "../../../../../../components/shared/Checkbox";

export const Treatment = () => {
  const dispatch = useDispatch();

  const preAbulatoryCare = useSelector(preAmbulatoryCareSelector);

  const preAmbulatoryCareNonApplicable = useSelector(preAmbulatoryCareNonApplicableSelector);
  const preAmbulatoryCareUnknown = useSelector(preAmbulatoryCareUnknownSelector);

  const handlePreAmbulatoryCareNonApplicableChange = () => {
    if (preAmbulatoryCareNonApplicable) {
      dispatch(setPreAmbulatoryCareNonApplicable(false));
    } else {
      dispatch(setPreAmbulatoryCareNonApplicable(true));
      dispatch(setPreAmbulatoryCareUnknown(false));
      dispatch(setPreAmbulatoryCare(""));
    }
  };
  
  const handlePreAmbulatoryCareUnknownChange = () => {
    if (preAmbulatoryCareUnknown) {
      dispatch(setPreAmbulatoryCareUnknown(false));
    } else {
      dispatch(setPreAmbulatoryCareUnknown(true));
      dispatch(setPreAmbulatoryCareNonApplicable(false));
      dispatch(setPreAmbulatoryCare(""));
    }
  };

  const popupText = "Вид лечения, его сроки, дозировка лекарственного препарата, доза облучения"

  return (
    <StyledContainer>
      <Textarea
        title="Предоперационное лечение"
        required={true}
        placeholder="Свободный ввод"
        value={preAbulatoryCare || ""}
        infoicon={true}
        popupText={popupText}
        onChange={(event) => {
          dispatch(setPreAmbulatoryCare(event.target.value));
        }}
        disabled={preAmbulatoryCareNonApplicable || preAmbulatoryCareUnknown}
      />
      <TCheckboxWr>
        <TCheckbox checked={preAmbulatoryCareNonApplicable} label='Неприменимо' onChange={handlePreAmbulatoryCareNonApplicableChange} />
        <TCheckbox checked={preAmbulatoryCareUnknown} label='Не установлена' onChange={handlePreAmbulatoryCareUnknownChange} />
      </TCheckboxWr>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
` 
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;
