import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { BIO_MATERIAL_TYPE__BLOCK } from "../../../constants/bioMaterial";

type Slide = {
  cypher: string;
};

type Block = {
  organ: string;
  cypher: string;
  slides: Slide[];
};

export type BiomaterialRegistrationState = {
  organization: number | null;
  bioType: string | null;
  bioSource: string | null;
  methodOfAcquisition: string | null;
  sampleType: string | null;
  dateOfAcquisition: string | null;
  blocks: Block[];
};

const initialState: BiomaterialRegistrationState = {
  organization: null,
  bioType: BIO_MATERIAL_TYPE__BLOCK,
  bioSource: null,
  methodOfAcquisition: null,
  sampleType: null,
  dateOfAcquisition: null,
  blocks: [{
    organ: "",
    cypher: "",
    slides: [{
      cypher: ""
    }]
  }],
};

export const biomaterialRegistrationSlice = createSlice({
  name: "biomaterialRegistration",
  initialState,
  reducers: {
    resetBiomaterialRegistration: () => initialState,

    setOrganization: (state, action: PayloadAction<number>) => {
      state.organization = action.payload;
    },
    setBioType: (state, action: PayloadAction<string>) => {
      state.bioType = action.payload;
    },
    setBioSource: (state, action: PayloadAction<string>) => {
      state.bioSource = action.payload;
    },
    setMethodOfAcquisition: (state, action: PayloadAction<string>) => {
      state.methodOfAcquisition = action.payload;
    },
    setSampleType: (state, action: PayloadAction<string>) => {
      state.sampleType = action.payload;
    },
    setDateOfAcquisition: (state, action: PayloadAction<string>) => {
      state.dateOfAcquisition = action.payload;
    },
    setBlocks: (state, action: PayloadAction<Block[]>) => {
      state.blocks = action.payload;
    },
    addBlock: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index >= 0 && index < state.blocks.length && index <= 3) {
        state.blocks.splice(index + 1, 0, {
          organ: "",
          cypher: "",
          slides: [{
            cypher: ""
          }]
        });
      } else if (index === state.blocks.length) {
          // allow adding at the end if index is equal to current length
          state.blocks.push({
            organ: "",
            cypher: "",
            slides: [{
              cypher: ""
            }]
          });
      }
    },
    removeBlock: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index >= 0 && index < state.blocks.length) {
        state.blocks.splice(index, 1);
      }
    },
    updateBlockOrgan: (
      state,
      action: PayloadAction<{ index: number; organ: string }>
    ) => {
      const { index, organ } = action.payload;
      if (index >= 0 && index < state.blocks.length) {
        state.blocks[index].organ = organ;
      }
    },
    updateBlockCypher: (
      state,
      action: PayloadAction<{ index: number; cypher: string }>
    ) => {
      const { index, cypher } = action.payload;
      if (index >= 0 && index < state.blocks.length) {
        state.blocks[index].cypher = cypher;
      }
    },
    clearBlockByIndex: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index >= 0 && index < state.blocks.length) {
        state.blocks[index] = {
          organ: "",
          cypher: "",
          slides: [{
            cypher: ""
          }]
        };
      }
    },
    updateSlideCypher: (
      state,
      action: PayloadAction<{ blockIndex: number; slideIndex: number; cypher: string }>
    ) => {
      const { blockIndex, slideIndex, cypher } = action.payload;
      const block = state.blocks[blockIndex];
      if (block && slideIndex >= 0) {
        block.slides[slideIndex].cypher = cypher;
      }
    },
    addSlide: (state, action: PayloadAction<number>) => {
      const blockIndex = action.payload;
      if (blockIndex >= 0 && blockIndex < state.blocks.length) {
        state.blocks[blockIndex].slides.push({
          cypher: ""
        });
      }
    },
    removeSlide: (
      state,
      action: PayloadAction<{ blockIndex: number; slideIndex: number }>
    ) => {
      const { blockIndex, slideIndex } = action.payload;
      const block = state.blocks[blockIndex];
      if (block && slideIndex >= 0 && slideIndex < block.slides.length) {
        block.slides.splice(slideIndex, 1);
      }
    },
  },
});

export const {
  resetBiomaterialRegistration,
  setOrganization,
  setBioType,
  setBioSource,
  setMethodOfAcquisition,
  setSampleType,
  setDateOfAcquisition,
  setBlocks,
  addBlock,
  addSlide,
  removeBlock,
  removeSlide,
  updateBlockOrgan,
  updateBlockCypher,
  updateSlideCypher,
  clearBlockByIndex,
} = biomaterialRegistrationSlice.actions;

export const biomaterialRegistrationReducer =
  biomaterialRegistrationSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const organizationSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.organization
);

export const bioTypeSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.bioType
);

export const bioSourceSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.bioSource
);

export const sampleTypeSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.sampleType
);

export const methodOfAcquisitionSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.methodOfAcquisition
);

export const dateOfAcquisitionSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.dateOfAcquisition
);

export const blocksSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration.blocks
);

export const bioMaterialRegistrationSelector = createSelector(
  getState,
  (state) => state.biomaterialRegistration
);

export const blockByIndexSelector = (index: number) =>
  createSelector(
    getState,
    (state) =>
      index >= 0 && index < state.biomaterialRegistration.blocks.length
        ? state.biomaterialRegistration.blocks[index]
        : null
  );

export const slideByBlockAndSlideIndexSelector = (
  blockIndex: number,
  slideIndex: number
) =>
  createSelector(
    getState,
    (state) => {
      const block = state.biomaterialRegistration.blocks[blockIndex];
      if (block && slideIndex >= 0 && slideIndex < block.slides.length) {
        return block.slides[slideIndex];
      }
      return null;
    }
  );