
import { FC, useCallback, useEffect, useMemo, useState, useRef, MutableRefObject, useLayoutEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { getYear } from 'date-fns';
import { TArrowWr, TCustomInput, TCustomSelect, TMenuButton,TSlideArrWr, TInputsWr, TMenuContainer, TMenuIcon, TMenuItem, TMenuWr, TResultWr, TWrapper, TIndexWr, TGap } from './styled';
import { validations } from '../../../../utils/validations';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';
import dottedIcon  from '../../../../icons/dotted.svg';
import deleteIcon from '../../../../icons/trash-bin.svg';
import clearIcon from '../../../../icons/cross-btn.svg';
import copyIcon from '../../../../icons/copy-done.svg';
import addIcon from '../../../../icons/plus-btn.svg';

interface ISlideBlock {
  slideIndex: number;
  blockIndex: number;
  onSlideRemove: (index: number) => void;
  onSlideAdd: () => void;
  disabledRemove: boolean;
  standardNumbering: boolean;
  isFirstSlide: boolean;
}

const currentYear = getYear(new Date());
const START_YEAR = 2000;

const SlideBlock: FC<ISlideBlock> = ({ isFirstSlide, standardNumbering, slideIndex, blockIndex, onSlideRemove, onSlideAdd }) => {
  const { control, register, setValue, formState, getValues, clearErrors, trigger,setError } = useFormContext();

  const blockPrefix = useMemo(() => `blocks.${blockIndex}`, [blockIndex]);
  const prefix = useMemo(() => `${blockPrefix}.glasses.${slideIndex}`, [blockPrefix, slideIndex]);

  const blockCode = useWatch({ control, name: `${blockPrefix}.code` });

  const startCodeBlockValue = useWatch({ control, name: `${blockPrefix}.startCode` });
  const endCodeBlockValue   = useWatch({ control, name: `${blockPrefix}.endCode`   });
  const yearBlockValue      = useWatch({ control, name: `${blockPrefix}.year`      });
  const amountBlockValue    = useWatch({ control, name: `${blockPrefix}.amount`    });
  const codeNumberBlockValue= useWatch({ control, name: `${blockPrefix}.codeNumber`});
  
  const startCodeFieldValue = useWatch({ control, name: `${prefix}.startCode` });
  const endCodeFieldValue   = useWatch({ control, name: `${prefix}.endCode`   });
  const yearFieldValue      = useWatch({ control, name: `${prefix}.year`      });
  const amountFieldValue    = useWatch({ control, name: `${prefix}.amount`    });
  const codeFieldValue      = useWatch({ control, name: `${prefix}.code`      });
  const codeNumberFieldValue= useWatch({ control, name: `${prefix}.codeNumber`});

  const [isOpenMenu, setOpenMenu] = useState(false);

  const node = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

  useOutsideClick(node, () => {
    if (isOpenMenu) {
      setOpenMenu(false);
    }
  })

  const yearOptions = useMemo(() => {
    let yearsList: { value: string; label: string }[] = [];
    let accYear = currentYear;
    while (accYear >= START_YEAR) {
      yearsList = [...yearsList, { value: `${accYear}`, label: `${accYear}` }];
      accYear -= 1;
    }
    return yearsList.reverse();
  }, []);

  const onRemove = useCallback((e) => {
    e.preventDefault();
    if(!slideIndex) return;
    onSlideRemove(slideIndex);
  }, [slideIndex, onSlideRemove]);

  const onAdd = useCallback((e) => {
    e.preventDefault();
    onSlideAdd();
  }, [onSlideAdd]);

  const onClearFields = useCallback((e) => {
    e.preventDefault();
    setValue(prefix, { amount: "1", code: "", codeNumber: "", endCode: "", organ: "", startCode: "", year: `${currentYear}` });
  },[prefix,setValue])

  const toggleMenu = useCallback((e) => {
    e.preventDefault();
    setOpenMenu(!isOpenMenu)
  }, [isOpenMenu,setOpenMenu]);

  const copyToClipboard = useCallback((e) => {
    e.preventDefault();
    try {
      navigator.clipboard.writeText(`${codeNumberFieldValue || codeFieldValue}`);
    } catch (err) {
      console.log("Copy Failed")
    }
  },[codeNumberFieldValue, codeFieldValue]);

  useEffect(() => {
    let errors = formState?.errors?.blocks?.[blockIndex]?.glasses?.[slideIndex];
    let isHaveCodeError = errors?.startCode || errors?.endCode || errors?.amount;
    let codeValue = getValues(`${prefix}.code`)


    if (standardNumbering && startCodeFieldValue && amountFieldValue && !isHaveCodeError) {
      setValue(`${prefix}.code`,
        `${startCodeFieldValue}${endCodeFieldValue ? '-' + endCodeFieldValue.slice(-2) : ''}${yearFieldValue ? '/' + yearFieldValue.toString().slice(-2) : ''}`
      );
    } else {
      codeValue !== '' && setValue(`${prefix}.code`, '');
    }
  }, [getValues,setValue,standardNumbering, startCodeFieldValue, prefix, endCodeFieldValue, yearFieldValue, amountFieldValue, formState,blockPrefix,slideIndex,blockIndex]);

  useEffect(() => {
    if (isFirstSlide && !getValues(`${prefix}.startCode`)) {
      setValue(`${prefix}.startCode`, startCodeBlockValue);
      setValue(`${prefix}.endCode`, endCodeBlockValue);
      setValue(`${prefix}.amount`, amountBlockValue);
      setValue(`${prefix}.year`, yearBlockValue);
      setValue(`${prefix}.codeNumber`, codeNumberBlockValue);
    }
  }, [isFirstSlide, setValue,getValues, prefix, startCodeBlockValue, endCodeBlockValue,
     amountBlockValue, yearBlockValue, codeNumberBlockValue]);

  useLayoutEffect(() => {
    clearErrors([`${prefix}.startCode`,`${prefix}.endCode`,`${prefix}.amount`,`${prefix}.year`,`${prefix}.codeNumber`]);
    setTimeout(()=>trigger([`${prefix}.startCode`,`${prefix}.endCode`,
    `${prefix}.amount`,`${prefix}.year`,`${prefix}.codeNumber`]),500)
  },[clearErrors,prefix,trigger]);

  const setStartCode = useCallback((value) => {
    //NEGATIVE
    if(endCodeFieldValue){
      if(+value > endCodeFieldValue)
      setValue(`${prefix}.endCode`, `${+value + +amountFieldValue - 1}`);
      setValue(`${prefix}.amount`, '1');
    } 

    //POSITIVE
    if (amountFieldValue && +amountFieldValue > 1) {
      setValue(`${prefix}.endCode`, `${+value + +amountFieldValue - 1}`);
      clearErrors(`${prefix}.endCode`);
    }

    return true;
  },[amountFieldValue,clearErrors,setValue,prefix,endCodeFieldValue]);


  const setEndCode = useCallback((value) => {
    //NEGATIVE
    if(!!value && isNaN(value)) return false;
    
    if((value && (+startCodeFieldValue >= +value)) || (value && (+value - +startCodeFieldValue) > 200)){
      setValue(`${prefix}.amount`, '');
      setError(`${prefix}.endCode`, { type: 'custom', message: 'endCode' });
      return false
    };

    //POSITIVE
    if(!value){ 
      setValue(`${prefix}.amount`, '1');
      clearErrors([`${prefix}.endCode`,`${prefix}.amount`]);
    };
    if (startCodeFieldValue && +value > +startCodeFieldValue){
      setValue(`${prefix}.amount`, `${+value - +startCodeFieldValue + 1}`)
      clearErrors(`${prefix}.amount`);
      clearErrors(`${prefix}.endCode`);
    };

    return true;
  },[clearErrors,prefix,setValue,startCodeFieldValue,setError]);


  const manualInputHandler = useCallback(({value}:any) => {
    let result = parseInt(value.trim())
    return isNaN(result) ? '' : result.toString();
  },[]);
  

  const manualNonStandartInputHandler = useCallback(({value}:any) => {
    let result = value.trimLeft();
    return !result ? '' : result.toString();
  },[]);
  
  return (
    <TWrapper>
      {standardNumbering ? (
        <TInputsWr>
          <TResultWr>
            {isFirstSlide && (
              <>
                <p>{`${blockIndex + 1}.`}</p>
                <TCustomInput
                  placeholder='Результат'
                  width='228px'
                  background='#CBECFE'
                  readOnly
                  defaultValue={blockCode}
                  textAlighn={'center'}
                />
                <TArrowWr>
                  <ArrowRight />
                </TArrowWr>
              </>
            )}
          </TResultWr>

          <TCustomInput
            {...register(`${prefix}.startCode`, {
              validate: value => setStartCode(value)
            })}
            placeholder='Начальный №'
            width='176px'
            type='number'
            min="1"
            step="1"
            maxLength={25}
            error={!!formState.errors?.blocks?.[blockIndex]?.glasses?.[slideIndex]?.startCode}
            onKeyUp={async ({target}) => setValue(`${prefix}.startCode`,await manualInputHandler(target))}
            textAlighn={'center'}
          />
          <TCustomInput
            {...register(`${prefix}.endCode`, { 
              validate: value => setEndCode(value) 
            })}
            placeholder='Конечный №'
            width='176px'
            type='number'
            min="1"
            step="1"
            maxLength={25}
            error={!!formState.errors?.blocks?.[blockIndex]?.glasses?.[slideIndex]?.endCode}
            onKeyUp={async ({target}) => setValue(`${prefix}.endCode`,await manualInputHandler(target))}
            textAlighn={'center'}
          />
          {/* <TCustomInput
            {...register(`${prefix}.amount`, {
               validate: value => setAmount(value) 
            })}
            placeholder='Кол-во'
            width='71px'
            type='number'
            min="1"
            step="1"
            maxLength={4}
            error={!!formState.errors?.blocks?.[blockIndex]?.glasses?.[slideIndex]?.amount}
            onKeyUp={async ({target}) => setValue(`${prefix}.amount`,await manualInputAmountHandler(target))}
          /> */}
          <TGap />
          <Controller
            control={control}
            name={`${prefix}.year`}
            defaultValue={`${currentYear}`}
            render={({ field: { onChange, value } }) => (
              <TCustomSelect
                onChange={(value) => onChange(value)}
                value={value?.toString()}
                options={yearOptions}
                placeholder='год'
                menuPlacement={'top'}
              />
            )}
          />
          <TArrowWr>
            <ArrowRight />
          </TArrowWr>
          <TCustomInput
            {...register(`${prefix}.code`)}
            
            placeholder='Результат'
            width='181px'
            background='#F1F0FF'
            readOnly
            textAlighn={'center'}
          />
        </TInputsWr>
      ) : (
        
        <>
        
        {isFirstSlide ? (
          <>
            <TIndexWr>{`${blockIndex + 1}.`}</TIndexWr>
            <TCustomInput
              {...register(`${blockPrefix}.codeNumber`)}
              placeholder='Результат'
              width='326px'
              background='#CBECFE'
              readOnly
              defaultValue={blockCode}
            />
            
            <TArrowWr margin={'10px'}>
              <ArrowRight />
            </TArrowWr>
          </>
          
          ) : (
          <TSlideArrWr>
            <TArrowWr>
              <ArrowRight />
            </TArrowWr>
          </TSlideArrWr>
          )}
          
          
          <TCustomInput
            {...register(`${prefix}.codeNumber`, {...validations.startCode, 
            validate: (value) => {
              if(!!value?.trimLeft()){ 
                clearErrors(`${prefix}.codeNumber`);
                return true
              };
              return false;
            }})}
            width='326px'
            placeholder='Номер'
            maxLength={25}
            background={'#F1F0FF'}
            error={!!formState.errors?.blocks?.[blockIndex]?.glasses?.[slideIndex]?.codeNumber}
            onKeyUp={async ({target}) => setValue(`${prefix}.codeNumber`,await manualNonStandartInputHandler(target))}
          />
        </>
      )}

      <TMenuWr onClick={toggleMenu} ref={node}>
        <TMenuButton
          onClick={toggleMenu}
          src={dottedIcon}
          active={isOpenMenu}
        /> 

        <TMenuContainer active={isOpenMenu}>
          <TMenuItem onClick={onAdd}>Добавить стекло к данному блоку <TMenuIcon src={addIcon}/></TMenuItem>
          <TMenuItem onClick={onClearFields}>Очистить содержимое <TMenuIcon src={clearIcon}/></TMenuItem>
          <TMenuItem onClick={onRemove} disabled={!slideIndex}>Удалить Гист. стекла<TMenuIcon src={deleteIcon}/></TMenuItem>
          <TMenuItem onClick={copyToClipboard}>Скопировать результат <TMenuIcon src={copyIcon}/></TMenuItem>
        </TMenuContainer>
      </TMenuWr>
    </TWrapper>
    
  );
}

export default SlideBlock;
