import { FC, ReactElement, ReactNode, useMemo, useState } from 'react';
import {
  components,
  ValueContainerProps,
  PlaceholderProps,
  IndicatorProps,
} from 'react-select';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow-select-black.svg';
import { customStyles, TSelect, TLabel } from './styled';
import defaultTheme from '../../../styles/theme';

export type SelectOption = {
  value: string | number | null;
  label: string;
};

export interface InputSelectProps {
  placeholder?: string;
  options: SelectOption[];
  value: string | number | null;
  onChange: (value: string | number) => void;
  hasEmptyValue?: boolean;
  disabled?: boolean;
  bordered?: boolean;
  error?: boolean;
  label?: string;
  isSearchable?: boolean;
  marginTop?: number;
  containerClassName?: string;
  classNamePrefix?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
}

const DropdownIndicator: FC<IndicatorProps<SelectOption, false>> = (props) =>
  !props.isDisabled ? (
    <components.DropdownIndicator {...props}>
      <ArrowIcon />
    </components.DropdownIndicator>
  ) : null;

const ClearIndicator: FC<IndicatorProps<SelectOption, false>> = (props) => (
  <components.ClearIndicator {...props}>
    <span style={{ cursor: 'pointer', background: 'red' }}>
      vbhtgrvhnyjhtgb✖
    </span>
  </components.ClearIndicator>
);

const CustomValueContainer: FC<
  ValueContainerProps<SelectOption, false> &
    PlaceholderProps<SelectOption, false>
> = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {(children as ReactNode[])?.map((child) =>
      !child || (child as ReactElement).type === components.Placeholder ? (
        <components.Placeholder {...props}>
          {props.selectProps.placeholder}
        </components.Placeholder>
      ) : (
        child
      )
    )}
  </components.ValueContainer>
);

export const InputSelect: FC<InputSelectProps> = ({
  placeholder = '',
  options,
  value,
  onChange,
  hasEmptyValue = false,
  disabled = false,
  bordered,
  label,
  error,
  isSearchable = false,
  marginTop,
  containerClassName,
  classNamePrefix = '',
  ...restProps
}) => {
  const optionsList = useMemo(
    () =>
      hasEmptyValue ? [{ label: 'None', value: null }, ...options] : options,
    [options, hasEmptyValue]
  );

  const selectedValue = useMemo(
    () => optionsList.find((item) => item.value === value) || null,
    [optionsList, value]
  );

  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);

  return (
    <div className={containerClassName ?? ''}>
      {label && <TLabel marginTop={marginTop}>{label}</TLabel>}
      <TSelect
        value={selectedValue}
        onChange={(item: SelectOption | null) =>
          onChange(item?.value as string)
        }
        onFocus={() => setCurrentPlaceholder('')}
        onBlur={() => setCurrentPlaceholder(placeholder)}
        styles={customStyles(defaultTheme, { bordered, error })}
        options={optionsList}
        components={{
          DropdownIndicator,
          ClearIndicator,
          ValueContainer: CustomValueContainer,
        }}
        isSearchable={isSearchable}
        blurInputOnSelect
        menuPlacement='auto'
        isDisabled={disabled}
        captureMenuScroll={false}
        noOptionsMessage={() => 'нет опций'}
        classNamePrefix={classNamePrefix}
        placeholder={currentPlaceholder}
        {...restProps}
      />
    </div>
  );
};

export default InputSelect;
