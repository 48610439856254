import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Input from '../../../shared/Input';
import { validations } from '../../../../utils/validations';
import InputSelect from '../../../shared/InputSelect';
import { getFromSessionStorage, setToSessionStorage } from '../../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../../constants/sessionStorageKeys';
import { Organization } from '../../../../store/organizations/model';

const { REGISTRAR } = SESSION_STORAGE_KEYS;

interface IRegistrarForm {
  showDepartmentField: boolean;
  showSubdepartmentField: boolean;
  isCreateNew: boolean;
  departmentOptions: { value: number, label: string }[];
  organization?: Organization | undefined;
  secialistLasName?: string | undefined;
  isCustomDoctorValid?: (isValid:boolean) => void;
}

function capitalize(s:string | undefined){
  return s && s[0].toUpperCase() + s.slice(1);
}

const NotFoundBlock: FC<IRegistrarForm> = (
  { showDepartmentField, showSubdepartmentField, departmentOptions, isCreateNew, organization, secialistLasName,isCustomDoctorValid }
) => {
  const { setValue, register, trigger, control, clearErrors, formState, getValues, watch } = useFormContext();

  const watchCustomFields = watch(['department','firstName','middleName'])

  useEffect(() => {
    const [organizationCustom, department, subdepartment, lastName, firstName, middleName, phoneNumber, email] = getFromSessionStorage(
      REGISTRAR.organizationCustom, REGISTRAR.department, REGISTRAR.subdepartment, REGISTRAR.lastName, REGISTRAR.firstName, REGISTRAR.middleName, REGISTRAR.phoneNumber, REGISTRAR.email
    );
    setValue('email', null);
    setValue('phoneNumber', '');

    // if (lastName && !isCreateNew) {
      setValue('organizationCustom', organizationCustom || '');
      setValue('department', +department || '');
      setValue('subdepartment', subdepartment || '');
      setValue('lastName', lastName || secialistLasName || '');
      setValue('firstName', firstName || '');
      setValue('middleName', middleName || '');
      setValue('phoneNumber', phoneNumber || '');
      setValue('email', email);
      setTimeout(() => clearErrors(), 1);
      //}
  }, [isCreateNew, setValue, clearErrors,secialistLasName]
  );

  useEffect(() => {
    let [department,firstName,middleName] = watchCustomFields;
    if(firstName || middleName || department){
    setToSessionStorage(
      [REGISTRAR.organizationCustom, getValues('organizationCustom')],
      [REGISTRAR.department, getValues('department')],
      [REGISTRAR.subdepartment, getValues('subdepartment')],
      [REGISTRAR.commonOrganization, getValues('commonOrganization')],
      [REGISTRAR.customFullName, getValues('lastName')],
      [REGISTRAR.fullName, getValues('lastName')],
      [REGISTRAR.lastName, getValues('lastName')],
      [REGISTRAR.firstName, getValues('firstName')],
      [REGISTRAR.middleName, getValues('middleName')],
      [REGISTRAR.isCreatedNewDoctorProfile, 'true'],
      );
      
    }

    if(isCustomDoctorValid){
      isCustomDoctorValid(showDepartmentField ? (!!department && !!firstName) : !!firstName);
    }
  },[watchCustomFields,getValues,isCustomDoctorValid,showDepartmentField]);

  return (
    <>
      {+(organization?.id ?? 0) !== 17 && <Input
        placeholder='Организация'
        type='text'
        error={!!formState.errors.subdepartment}
        defaultValue={organization?.nameLocalRus}
        value={organization?.nameLocalRus}
        disabled
        style={{margin:'2px 0'}}
      />}
      {+(organization?.id ?? 0) === 17 && (
        <Input
          {...register('organizationCustom', validations.subdepartment)}
            placeholder='Укажите УЗ *'
            type='text'
            error={!!formState.errors.organizationCustom}
            marginTop={2}
            style={{margin:'2px 0'}}
          />)}
      {
        showDepartmentField && (
          <Controller
            control={control}
            name='department'
            rules={{required:showDepartmentField}}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <InputSelect
                onChange={(value) => {
                  return onChange(value)
                }}
                value={value}
                options={departmentOptions}
                placeholder='Отделение *'
                error={invalid}
              />
            )}
          />
        )
      }
      {
        showSubdepartmentField && (
          <Input
            {...register('subdepartment', validations.subdepartment)}
            placeholder='Лабораторное отделение/Кабинет'
            type='number'
            error={!!formState.errors.subdepartment}
            style={{marginTop:'2px'}}
          />
        )
      }
      <Input
        // value={secialistLasName}
        defaultValue={secialistLasName}
        error={!!formState.errors.lastName}
        type='text'
        disabled={!!secialistLasName}
        style={{marginTop:'2px'}}
      />
      <Input
        {...register('firstName', validations.firstName)}
        placeholder='Имя *'
        error={!!formState.errors.firstName}
        onBlur={async ({ target }) => {
          await trigger("firstName");
        }}
        onChange={({target}) => {
          setValue('firstName', capitalize(target.value.trim()))
          // !!target.value.trim() && setCustomFirstName(target.value.trim())
        }}
        type='text'
        style={{marginTop:'2px'}}
      />
      <Input
        {...register('middleName', { ...validations.firstName, required: false })}
        placeholder='Отчество'
        error={!!formState.errors.middleName}
        onBlur={async ({ target }) => {
          await trigger("firstName");
        }}
        onChange={({target}) => {
          setValue('middleName', capitalize(target.value.trim()))
          // !!target.value.trim() && setCustomMiddleName(target.value.trim())
        }}
        type='text'
        style={{marginTop:'2px'}}
      />
    </>
  );
};

export default NotFoundBlock;
