import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { ApiLocalization } from "../../../services/Localizations";
import { ApiDiagnosis } from "../../../services/Diagnoses";

export type ClinicalDataState = {
  localizations: Array<ApiLocalization>;

  selectedLocalization: {
    label: string;
    value: string;
  } | null;

  selectedDiagnosis: {
    label: string;
    value: string;
  } | null;

  diagnoses: Array<ApiDiagnosis>;

  clinicalDiagnosis: string | null;  // description

  t: {
    label: string;
    value: string;
  } | null;

  tSub: {
    label: string;
    value: string;
  } | null;

  n: {
    label: string;
    value: string;
  } | null;

  nSub: {
    label: string;
    value: string;
  } | null;

  m: {
    label: string;
    value: string;
  } | null;

  mSub: {
    label: string;
    value: string;
  } | null;

  tnmNonApplicable: boolean;
  tnmUnknown: boolean;

  stage: {
    label: string;
    value: string;
  } | null;

  sub: {
    label: string;
    value: string;
  } | null;

  stageNonApplicable: boolean;
  stageUnknown: boolean;

  additionalClinicalData: string | null;

  additionalClinicalDataNonApplicable: boolean;
  additionalClinicalDataUnknown: boolean;

  previousAnalysisResults: string | null;

  previousAnalysisResultsNonApplicable: boolean;
  previousAnalysisResultsUnknown: boolean;

  preAmbulatoryCare: string | null;

  preAmbulatoryCareNonApplicable: boolean;
  preAmbulatoryCareUnknown: boolean;
};

const initialState: ClinicalDataState = {
  localizations: [],
  selectedLocalization: null,

  selectedDiagnosis: null,
  diagnoses: [],

  clinicalDiagnosis: null,

  t: null,
  tSub: null,

  n: null,
  nSub: null,

  m: null,
  mSub: null,

  tnmNonApplicable: false,
  tnmUnknown: false,

  stage: null,
  sub: null,

  stageNonApplicable: false,
  stageUnknown: false,

  additionalClinicalData: null,
  additionalClinicalDataNonApplicable: false,
  additionalClinicalDataUnknown: false,

  previousAnalysisResults: null,
  previousAnalysisResultsNonApplicable: false,
  previousAnalysisResultsUnknown: false,

  preAmbulatoryCare: null,
  preAmbulatoryCareNonApplicable: false,
  preAmbulatoryCareUnknown: false,
};

export const clinicalDataSlice = createSlice({
  name: "clinicalData",
  initialState,
  reducers: {
    resetClinicalData: () => initialState,

    setLocalizations: (
      state,
      action: PayloadAction<Array<ApiLocalization>>
    ) => {
      state.localizations = action.payload;
    },

    setDiagnoses: (state, action: PayloadAction<Array<ApiDiagnosis>>) => {
      state.diagnoses = action.payload;
    },

    setSelectedLocalization: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.selectedLocalization = action.payload;
    },

    setSelectedDiagnosis: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.selectedDiagnosis = action.payload;
    },

    setClinicalDiagnosis: (state, action: PayloadAction<string | null>) => {
      state.clinicalDiagnosis = action.payload;
    },

    setT: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.t = action.payload;
    },

    setTSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.tSub = action.payload;
    },

    setN: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.n = action.payload;
    },

    setNSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.nSub = action.payload;
    },

    setM: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.m = action.payload;
    },

    setMSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.mSub = action.payload;
    },

    setTnmNonApplicable: (state, action: PayloadAction<boolean>) => {
      state.tnmNonApplicable = action.payload;
    },

    setTnmUnknown: (state, action: PayloadAction<boolean>) => {
      state.tnmUnknown = action.payload;
    },

    setStage: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.stage = action.payload;
    },

    setSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.sub = action.payload;
    },

    setStageNonApplicable: (state, action: PayloadAction<boolean>) => {
      state.stageNonApplicable = action.payload;
    },

    setStageUnknown: (state, action: PayloadAction<boolean>) => {
      state.stageUnknown = action.payload;
    },

    setAdditionalClinicalData: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.additionalClinicalData = action.payload;
    },

    setAdditionalClinicalDataNonApplicable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.additionalClinicalDataNonApplicable = action.payload;
    },

    setAdditionalClinicalDataUnknown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.additionalClinicalDataUnknown = action.payload;
    },

    setPreviousAnalysisResults: (state, action: PayloadAction<string | null>) => {
      state.previousAnalysisResults = action.payload;
    },

    setPreviousAnalysisResultsNonApplicable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.previousAnalysisResultsNonApplicable = action.payload;
    },

    setPreviousAnalysisResultsUnknown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.previousAnalysisResultsUnknown = action.payload;
    },

    setPreAmbulatoryCare: (state, action: PayloadAction<string | null>) => {
      state.preAmbulatoryCare = action.payload;
    },

    setPreAmbulatoryCareNonApplicable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.preAmbulatoryCareNonApplicable = action.payload;
    },

    setPreAmbulatoryCareUnknown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.preAmbulatoryCareUnknown = action.payload;
    },
  },
});

export const {
  resetClinicalData,
  setLocalizations,
  setDiagnoses,
  setSelectedLocalization,
  setSelectedDiagnosis,
  setClinicalDiagnosis,
  setT,
  setTSub,
  setN,
  setNSub,
  setM,
  setMSub,
  setTnmNonApplicable,
  setTnmUnknown,
  setStage,
  setSub,
  setStageNonApplicable,
  setStageUnknown,
  setAdditionalClinicalData,
  setAdditionalClinicalDataNonApplicable,
  setAdditionalClinicalDataUnknown,
  setPreviousAnalysisResults,
  setPreviousAnalysisResultsNonApplicable,
  setPreviousAnalysisResultsUnknown,
  setPreAmbulatoryCare,
  setPreAmbulatoryCareNonApplicable,
  setPreAmbulatoryCareUnknown,
} = clinicalDataSlice.actions;

export const clinicalDataReducer = clinicalDataSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

// Selectors to match the newly defined state properties
export const localizationsSelector = createSelector(
  getState,
  (state) => state.clinicalData.localizations
);

export const diagnosesSelector = createSelector(
  getState,
  (state) => state.clinicalData.diagnoses
);

export const selectedLocalizationSelector = createSelector(
  getState,
  (state) => state.clinicalData.selectedLocalization
);

export const selectedDiagnosisSelector = createSelector(
  getState,
  (state) => state.clinicalData.selectedDiagnosis
);

export const clinicalDiagnosisSelector = createSelector(
  getState,
  (state) => state.clinicalData.clinicalDiagnosis
);

export const tSelector = createSelector(getState, (state) => state.clinicalData.t);

export const tSubSelector = createSelector(getState, (state) => state.clinicalData.tSub);

export const nSelector = createSelector(getState, (state) => state.clinicalData.n);

export const nSubSelector = createSelector(getState, (state) => state.clinicalData.nSub);

export const mSelector = createSelector(getState, (state) => state.clinicalData.m);

export const mSubSelector = createSelector(getState, (state) => state.clinicalData.mSub);

export const tnmNonApplicableSelector = createSelector(
  getState,
  (state) => state.clinicalData.tnmNonApplicable
);

export const tnmUnknownSelector = createSelector(
  getState,
  (state) => state.clinicalData.tnmUnknown
);

export const stageSelector = createSelector(
  getState,
  (state) => state.clinicalData.stage
);

export const subSelector = createSelector(getState, (state) => state.clinicalData.sub);

export const stageNonApplicableSelector = createSelector(
  getState,
  (state) => state.clinicalData.stageNonApplicable
);

export const stageUnknownSelector = createSelector(
  getState,
  (state) => state.clinicalData.stageUnknown
);

export const additionalClinicalDataSelector = createSelector(
  getState,
  (state) => state.clinicalData.additionalClinicalData
);

export const additionalClinicalDataNonApplicableSelector = createSelector(
  getState,
  (state) => state.clinicalData.additionalClinicalDataNonApplicable
);

export const additionalClinicalDataUnknownSelector = createSelector(
  getState,
  (state) => state.clinicalData.additionalClinicalDataUnknown
);

export const previousAnalysisResultsSelector = createSelector(
  getState,
  (state) => state.clinicalData.previousAnalysisResults
);

export const previousAnalysisResultsNonApplicableSelector = createSelector(
  getState,
  (state) => state.clinicalData.previousAnalysisResultsNonApplicable
);

export const previousAnalysisResultsUnknownSelector = createSelector(
  getState,
  (state) => state.clinicalData.previousAnalysisResultsUnknown
);

export const preAmbulatoryCareSelector = createSelector(
  getState,
  (state) => state.clinicalData.preAmbulatoryCare
);

export const preAmbulatoryCareNonApplicableSelector = createSelector(
  getState,
  (state) => state.clinicalData.preAmbulatoryCareNonApplicable
);

export const preAmbulatoryCareUnknownSelector = createSelector(
  getState,
  (state) => state.clinicalData.preAmbulatoryCareUnknown
);

export const clinicalDataSelector = createSelector(
  getState,
  (state) => state.clinicalData
);
