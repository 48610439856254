import styled from "styled-components";
import {defaultThemeType} from "../../../../../../styles/theme";
import {CSSProperties} from "react";


export const customStylesFishLocalisationOptions = (theme: defaultThemeType): Record<string, unknown> => ({
  control: (state: CSSProperties, {isDisabled}: { isDisabled: boolean }) => ({
    ...state,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    boxShadow: 'none',
    cursor: 'default',
    padding: '10px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '28px',
    height: '60px',
    backgroundColor: '#fff',
    border: 'none',
    'div[class$="singleValue"]': {
      color: theme.colors.gray[500],
    },
    '&:hover': {
      borderColor: isDisabled ? 'transparent' : theme.colors.alert.border,
      cursor: isDisabled ? 'default' : 'pointer',
    },
  }),
  option: (state: CSSProperties, {isSelected}: { isSelected: boolean }) => ({
    ...state,
    height: 'auto',
    textAlign: 'left',
    backgroundColor: theme.colors.white,
    border: 'none',
    outline: 'none',
    color: isSelected ? theme.colors.product.primaryActive : theme.colors.black[200],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out 0s',
    padding: '8px 14px 8px 12px',
    fontSize: '12px',
    lineHeight: '20px',
    width: '100%',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.colors.product.primaryHover,
      color: theme.colors.white,
    },
  }),
  dropdownIndicator: (
    state: CSSProperties,
    {selectProps: {menuIsOpen}}: { selectProps: { menuIsOpen: boolean } },
  ) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out 0s',
  }),
  container: () => ({
    position: 'relative',
    // textAlign: 'left !important',
  }),
  indicatorsContainer: (base: CSSProperties) => ({
    ...base,
    marginRight: 15
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: '0',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#777777 !important'
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: theme.colors.gray[200],
    textAlign: 'center !important',
  }),
  indicatorSeparator: () => ({
    display: 'none !important'
  }),
});

export const TBlockFieldButton = styled.div<{ active?: boolean, show?: boolean}>`
  width: 16px;
  height: 16px;
  position: ${({ show }) => show ? 'absolute' : 'block'};
  right: ${({ show }) => show ? '16px' : 'auto'};
  bottom: ${({ show }) => show ? '40%' : 'auto'};
  svg {
    path {
    fill: ${({ theme, active }) => active ? theme.colors.violet[500] : theme.colors.gray[300]};
    }
  }

  :hover {
    svg path {
      fill: ${({ theme }) => theme.colors.violet[300]};
    }
  }

`