import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../icons/logo-name.svg';
import { ReactComponent as SmallLogoIcon } from '../../icons/logo.svg';
import { ReactComponent as LastIcon } from '../../icons/icon-last.svg';
import { ReactComponent as LastIconHover } from '../../icons/last-icon-hover.svg';


export const TMenuWr = styled.div`
  margin-top: 20px;
  position: relative;
  cursor: default;
  width: 56px;
  display: flex;
  flex-direction: column;
`;

export const TUserItemLinkWr = styled(NavLink)`
  text-decoration: none;
  position: relative;
`;

const TTitleTemplate = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  display: block;
  transition: left .3s;
`;

export const TTitle = styled(TTitleTemplate)`
  display: none;
`;

export const TSmallTitle = styled(TTitleTemplate)`
  line-height: 40px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 6px 6px 2px 6px;
  padding: 4px 2px;
  width: 56px;
  height: 52px;
  text-align: center;
  font-size: 18px;
`;

export const TDivider = styled.div`
  margin: 20px 0 12px;
  background-color: rgba(255, 255, 255, 0.2)};
`;

export const TMenuItemsContainer = styled.div`
  min-height: 512px;
`;

export const TMenuItemsContainer2 = styled.div`
  min-height: 100px;
`;

export const TIconWrapper = styled.div`
  margin-right: 0;
  width: 52px;
  min-width: 52px;
  height: 52px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 2px 6px;
`;

const TMenuItem = css`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  
  cursor: pointer;

  span {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TMenuLink = styled(NavLink)`
  ${TMenuItem};
  text-decoration: none;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    width: 200px;
    border-radius: 6px;
  }
`;

export const TMenuButton = styled.div`
  ${TMenuItem};
  
    &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    width: 200px;
    border-radius: 6px;
  }
`;

export const TMenuButtonHover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TLogo = styled(LogoIcon)`
  height: auto;
  display: none;
  margin-bottom: 30px;
`;

export const TSmallLogo = styled(SmallLogoIcon)`
  margin-bottom: 30px;
`;

export const TLastIcon = styled(LastIcon)`
  display: block;
`;

export const TLastIconHover = styled(LastIconHover)`
  display: none; 
  padding-left: 20px;
`;

export const TLine = styled.div`
  border: 1px solid #FFF;
  opacity: 0.2;
  margin-bottom: 18px;
`;

export const TLine2 = styled.div`
  border: 1px solid #FFF;
  opacity: 0.2;
`;

export const TWrapper = styled.div<{ isMenuOpen: boolean }>`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px;
  width: ${({ isMenuOpen }) => (isMenuOpen ? '216px' : '72px')};
  height: 100vh;
  border-radius: 0 6px 6px 0;
  background: #3e6aff;
  box-sizing: border-box;
  transition: width 0.6s;
  will-change: width;
  z-index: 20;

  ${TTitle} {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
    left: 0;
    border: 1px solid #ffffff;
    border-radius: 6px 6px 2px 6px;
    padding: 9px 13px 9px 13px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    height: 52px;
  }

  ${TSmallTitle} {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'none' : 'block')};
  }

  ${TLogo}, ${TLastIconHover}, ${TMenuLink} span, ${TMenuButton} span {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  }

  ${TSmallLogo}, ${TLastIcon} {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'none' : 'block')};
  }

  ${TIconWrapper} {
    margin-right: ${({ isMenuOpen }) => (isMenuOpen ? '5px' : '0')};
  }

  ${TLine} {
    width: ${({ isMenuOpen }) => (isMenuOpen ? '198px' : 'auto')};
  }
`;
export const TMenuUserContainer = styled.div`
  min-height: 52px;
  margin-bottom: 10px;
  `;
