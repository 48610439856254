import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../styles/themeNew';
import { TDetailsContent } from '../../../../components/shared/Details/styled';

export const AnalisysInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 16px;
`;

export const AnalisysInfoBlock = styled.div`
  width: 48.8%;
`;

export const AnalisysInfoLabel = styled.div`
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px
  margin-bottom: 4px;
`;

export const AnalisysInfoValue = styled.div`
  border: 1px solid ${Colors.MONOCHROM[200]};
  padding: 12px 16px;
  color: ${Colors.MONOCHROM[600]};
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
`;

interface AnalisysInfoProps {
  data: {
    marker?: string;
    drugList?: string;
    referral_number?: string;
    bioMaterial?: {
      block?: string;
      blockNumber?: string;
    };
  };
}

export const AnalisysInfo: React.FC<AnalisysInfoProps> = ({ data }) => {
  return (
    <TDetailsContent>
      <AnalisysInfoWrapper>
        <AnalisysInfoBlock>
          <AnalisysInfoLabel>Маркер</AnalisysInfoLabel>
          <AnalisysInfoValue>{data?.marker || '--'}</AnalisysInfoValue>
        </AnalisysInfoBlock>

        <AnalisysInfoBlock>
          <AnalisysInfoLabel>Лекарственное средство</AnalisysInfoLabel>
          <AnalisysInfoValue>{data?.drugList || '--'}</AnalisysInfoValue>
        </AnalisysInfoBlock>
        {data?.bioMaterial?.block && (
          <>
            <AnalisysInfoBlock>
              <AnalisysInfoLabel>Номер парафинового блока</AnalisysInfoLabel>
              <AnalisysInfoValue>
                {data.bioMaterial.blockNumber || '--'}
              </AnalisysInfoValue>
            </AnalisysInfoBlock>
            <AnalisysInfoBlock>
              <AnalisysInfoLabel>Номер направления</AnalisysInfoLabel>
              <AnalisysInfoValue>
                {data?.referral_number || '--'}
              </AnalisysInfoValue>
            </AnalisysInfoBlock>
          </>
        )}
      </AnalisysInfoWrapper>
    </TDetailsContent>
  );
};
