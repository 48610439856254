import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { 
  RESEARCH_TYPE__IHC,
  getResearchTypeName,
} from "../../../constants/referral";

export type ResearchTypeState = {
  code: string | null;
  name: string | null;
  markersUrl: string | null;
};

export const DEFAULT_RESEARCH_TYPE = {
  code: RESEARCH_TYPE__IHC,
  name: getResearchTypeName(RESEARCH_TYPE__IHC) || '',
  markersUrl: 'ihc',
};

const initialState: ResearchTypeState = DEFAULT_RESEARCH_TYPE;

export const researchTypeSlice = createSlice({
  name: "researchType",
  initialState,
  reducers: {
    resetResearchType: () => initialState,

    setResearchType: (
      state,
      action: PayloadAction<{ code: string; name: string; markersUrl: string }>
    ) => {
      state.code = action.payload.code;
      state.name = action.payload.name;
      state.markersUrl = action.payload.markersUrl;
    },
  },
});

export const { setResearchType, resetResearchType } = researchTypeSlice.actions;

export const researchTypeReducer = researchTypeSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const researchTypeSelector = createSelector(
  getState,
  (state) => state.researchType
);
