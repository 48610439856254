import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Radio } from "../../../../componentsNew/Radio";
import { Select } from "../../../../componentsNew/Select";
import { Datepicker } from "../../../../componentsNew/Datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  organizationSelector,
  bioTypeSelector,
  bioSourceSelector,
  sampleTypeSelector,
  methodOfAcquisitionSelector,
  dateOfAcquisitionSelector,
  setOrganization,
  setBioType,
  setBioSource,
  setMethodOfAcquisition,
  setSampleType,
  setDateOfAcquisition,
} from "../../redux/biomaterialRegistration";
import { getOrganizations } from "../../../../store/organizations/selectors";

import { Notification } from "../../../../componentsNew/Notification";
import { HistologicalMaterial } from "./components/HistologicalMaterial";

import {
  BIO_MATERIAL_TYPE__BLOCK,
  BIO_MATERIAL_SOURCE__SURGERY,
  BIO_MATERIAL_SOURCE__BIOPSY,
  BIO_SAMPLE_TYPE__PRIMARY,
  BIO_SAMPLE_TYPE__METASTASIS,
  METHOD_OF_ACQUISITION_SURGICAL_BIOPSY,
  METHOD_OF_ACQUISITION_SURGICAL_MATERIAL,
  METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS,
  METHOD_OF_ACQUISITION_ENDOSCOPIC_BIOPSY,
  METHOD_OF_ACQUISITION_PUNCTURE_BIOPSY,
  METHOD_OF_ACQUISITION_ASPIRATION_BIOPSY,
  METHOD_OF_ACQUISITION_MICISIONAL_BIOPSY,
  getMethodOfAcquisitionName,
  getBioMaterialTypeName,
  getBioMaterialSourceName,
  getBioSampleTypeName,
} from "../../../../constants/bioMaterial";

export const BiomaterialRegistration = () => {
  const dispatch = useDispatch();

  const clinics = useSelector(getOrganizations);

  const bioType = useSelector(bioTypeSelector);
  const bioSource = useSelector(bioSourceSelector);
  const sampleType = useSelector(sampleTypeSelector);
  const methodOfAcquisition = useSelector(methodOfAcquisitionSelector);
  const dateOfAcquisition = useSelector(dateOfAcquisitionSelector);
  const organization = useSelector(organizationSelector);

  const procedureBiopsyList = [
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_SURGICAL_BIOPSY) || '', value: METHOD_OF_ACQUISITION_SURGICAL_BIOPSY, selected: false },
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_SURGICAL_MATERIAL) || '', value: METHOD_OF_ACQUISITION_SURGICAL_MATERIAL, selected: false },
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS) || '', value: METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS, selected: false }
  ];

  const biopsyList = [
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_ENDOSCOPIC_BIOPSY) || '', value: METHOD_OF_ACQUISITION_ENDOSCOPIC_BIOPSY, selected: false },
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_PUNCTURE_BIOPSY) || '', value: METHOD_OF_ACQUISITION_PUNCTURE_BIOPSY, selected: false },
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_ASPIRATION_BIOPSY) || '', value: METHOD_OF_ACQUISITION_ASPIRATION_BIOPSY, selected: false },
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_MICISIONAL_BIOPSY) || '', value: METHOD_OF_ACQUISITION_MICISIONAL_BIOPSY, selected: false },
      { label: getMethodOfAcquisitionName(METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS) || '', value: METHOD_OF_ACQUISITION_SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS, selected: false }
  ];

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Регистрация биоматериала</Title>
      </StyledTitleContainer>
      <Notification type="warning" title="Внимание!" description="Ошибка регистрации номеров блоков может быть основанием для отмены направления"></Notification>
      <StyledRadioContainer>
        <Radio
          title="Вид материала"
          selected={bioType === BIO_MATERIAL_TYPE__BLOCK}
          text={getBioMaterialTypeName(BIO_MATERIAL_TYPE__BLOCK) || ''}
          onSelect={() => {
            dispatch(setBioType(BIO_MATERIAL_TYPE__BLOCK));
          }}
          tooltip={false}
          required={true}
        />
      </StyledRadioContainer>

      <StyledRadioGroupContainer>
        <StyledRadioContainer>
          <Radio
            title="Тип материала"
            selected={bioSource === BIO_MATERIAL_SOURCE__SURGERY}
            text={getBioMaterialSourceName(BIO_MATERIAL_SOURCE__SURGERY) || ''}
            onSelect={() => {
              dispatch(setBioSource(BIO_MATERIAL_SOURCE__SURGERY));
              dispatch(setMethodOfAcquisition(''));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>

        <StyledRadioContainer>
          <Radio
            selected={bioSource === BIO_MATERIAL_SOURCE__BIOPSY}
            text={getBioMaterialSourceName(BIO_MATERIAL_SOURCE__BIOPSY) || ''}
            onSelect={() => {
              dispatch(setBioSource(BIO_MATERIAL_SOURCE__BIOPSY));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>
      </StyledRadioGroupContainer>

      <StyledSelectContainer>
        <Select
          title="Способ получения"
          required={true}
          placeholder="Выберите значение"
          options={(bioSource === BIO_MATERIAL_SOURCE__SURGERY ? procedureBiopsyList : biopsyList).map((items) => ({
              label: items.label,
              value: items.value,
              selected: `${items.value}` === `${methodOfAcquisition}`,
          }))}
          onSelect={(option) => {
              dispatch(setMethodOfAcquisition(option.value));
          }}
        />
      </StyledSelectContainer>

      <StyledRadioGroupContainer>
        <StyledRadioContainer>
          <Radio
            title="Тип образца"
            selected={sampleType === BIO_SAMPLE_TYPE__PRIMARY}
            text={getBioSampleTypeName(BIO_SAMPLE_TYPE__PRIMARY) || ''}
            onSelect={() => {
              dispatch(setSampleType(BIO_SAMPLE_TYPE__PRIMARY));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>

        <StyledRadioContainer>
          <Radio
            selected={sampleType === BIO_SAMPLE_TYPE__METASTASIS}
            text={getBioSampleTypeName(BIO_SAMPLE_TYPE__METASTASIS) || ''}
            onSelect={() => {
              dispatch(setSampleType(BIO_SAMPLE_TYPE__METASTASIS));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>
      </StyledRadioGroupContainer>

      <StyledDatepickerContainer>
        <Datepicker
          required={true}
          title="Дата забора материала"
          placeholder=""
          value={dateOfAcquisition || ""}
          onChange={(event) => {
            dispatch(setDateOfAcquisition(event.target.value));
          }}
        />
      </StyledDatepickerContainer>

      <StyledRowContainer>
          <Title level="3">Зарегистрируйте ОРИГИНАЛЬНЫЕ номера блоков и стекол:</Title>
      </StyledRowContainer>
      <StyledInputContainer>
          <Select
              title="Учреждение, присвоившее оригинальные номера блокам, стеклам"
              placeholder="--"
              required={true}
              options={clinics.map((clinic) => ({
                  label: clinic.nameLocalRus,
                  value: String(clinic.id),
                  selected: `${clinic.id}` === `${organization}`,
              }))}
              onSelect={(event) => dispatch(setOrganization(Number(event.value)))}
          />

          <HistologicalMaterial/>

        </StyledInputContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRadioContainer = styled.div`
  width: 100%;
`;

const StyledRadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSelectContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledDatepickerContainer = styled.div``;
