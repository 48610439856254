import { useSelector } from "react-redux";
import { currentStepSelector } from "../../redux/currentStep";

import { ResearchType } from "./../../components/ResearchType";
import { Laboratory } from "./../../components/Laboratory";
import { Patient } from "./../../components/Patient";
import { Referrer } from "./../../components/Referrer";
import { ClinicalData } from "./../../components/ClinicalData";
import { BiomaterialRegistration } from "./../../components/BiomaterialRegistration";
import { Markers } from "./../../components/Markers";
import { 
  REFERRAL_STEP__RESEARCH_TYPE,
  REFERRAL_STEP__LABORATORY,
  REFERRAL_STEP__PATIENT,
  REFERRAL_STEP__REFERRER,
  REFERRAL_STEP__CLINICAL_DATA,
  REFERRAL_STEP__BIO_MATERIAL_REGISTRATION,
  REFERRAL_STEP__MARKERS,
} from "../../constants";

export const Content = () => {
  const currentStep = useSelector(currentStepSelector);

  return (
    <>
      {currentStep.code === REFERRAL_STEP__RESEARCH_TYPE && <ResearchType />}
      {currentStep.code === REFERRAL_STEP__LABORATORY && <Laboratory />}
      {currentStep.code === REFERRAL_STEP__PATIENT && <Patient />}
      {currentStep.code === REFERRAL_STEP__REFERRER && <Referrer />}
      {currentStep.code === REFERRAL_STEP__CLINICAL_DATA && <ClinicalData />}
      {currentStep.code === REFERRAL_STEP__BIO_MATERIAL_REGISTRATION && <BiomaterialRegistration />}
      {currentStep.code === REFERRAL_STEP__MARKERS && <Markers />}
    </>
  );
};
