import styled from 'styled-components';
import { Colors } from '../../styles/themeNew';

export const Table = styled.table`
  table-layout: fixed;
  border-spacing: 0;
  border: 1px solid ${Colors.MONOCHROM[200]};
  border-collapse: collapse;
  width: 100%;
`;

export const TableBody = styled.tbody`
  box-sizing: border-box;
  position: relative;
`;

export const TableHeaderRow = styled.tr`
  box-sizing: border-box;
  position: relative;
  background-color: ${Colors.BRAND[200]};
`;

export const TableHeaderCell = styled.td`
  box-sizing: border-box;
  position: relative;
  padding: 10px 16px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.MONOCHROM[950]};
`;

export const TableCell = styled.td`
  box-sizing: border-box;
  position: relative;
  padding: 10px 16px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.MONOCHROM[600]};
`;