import styled, { css } from 'styled-components';
import { Colors } from '../../../../styles/themeNew';
import Input from '../../../../components/shared/Input';

export const Container = styled.div``;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: ${Colors.BRAND[500]};
  margin-bottom: 16px;
  text-align: center;
`;

export const BlockContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

export const BlockHeader = styled.div<{ isOpen?: boolean }>`
  border: 1px solid
    ${({ isOpen }) => (isOpen ? Colors.BRAND[400] : Colors.MONOCHROM[200])};
  border-radius: 6px;
  padding: 18px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: ${Colors.OTHER.white};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${({ isOpen }) => (isOpen ? '8px' : 0)};
`;

export const BlockContent = styled.div`
  padding: 24px 16px;
  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background-color: #ffffff;
`;

export const MorphInfo = styled.div`
  width: 100%;
  font-size: 12px; 
  line-height: 14px; 
  display:flex;
  align-items: center;
`;

export const StyledMorphRow = styled.tr`
  height: 54px;
`;

export const BlockIconWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
`;

export const BlockIcon = styled.img<{ $isOpen: boolean }>`
  padding: 0;
  width: 24px;
  height: 24px;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    `}

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transform: rotate(0deg);
      transition: transform 0.2s ease-in-out;
    `}
`;

export const StyledInput = styled(Input)<{
  readOnly?: boolean;
}>`
  border: 1px solid ${Colors.MONOCHROM[200]};
  border-radius: 6px;
  width: 100%;
  margin-bottom: 16px;

  &:hover {
    border: 1px solid ${Colors.MONOCHROM[200]};
  }
`;