import { FC, useMemo } from "react";
import { ExperimentSteps, IAnalisysByMethod } from "../../../../../../store/analysis/model";
import { TCheckbox, TDetails, TSummary, TSummaryContent, TSummaryIcon } from "../../../../../../components/shared/Details/styled";
import arrowSelectIcon from '../../../../../../icons/arrow-select.svg';
import currentStatusIcon from '../../../../../../icons/attention.svg';
import defaultStatusIcon from '../../../../../../icons/done-gray.svg';
import BioPanel from "./BioPanel";
import { useSelector } from "react-redux";
import { getServiceUsers } from "../../../../../../store/serviceUsers/selectors";
import TablelFISHReange from "./TablelFISHReange";
import TablelFISHAmplific from "./TablelFISHAmplific";
import FishLocalization from "./FishLocalization";
import { checkPermitCreateFISHExperement, checkPermitUpdateFISHBio } from "../../../../../../store/auth/selectors";
import { format } from "date-fns";
import { IExamExecutors } from '../../../model';

interface IExecutorOptions {
  value: string;
  label: string;
  disabled: boolean;
  title: string;
  fullName: string;
}

interface IComponentProps {
  flowStepsActiveIndex: any;
  isSelectedOptions: number[];
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  executorOptions?: IExecutorOptions[];
  defaultExecutorOptions?: any;
  isActive: boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

const stepDate = (date: Date) => format(new Date(date), 'dd.MM.yyyy - HH:mm:ss');

const ExperimentFISH: FC<IComponentProps> = ({ referralULID, isReferralComplite,defaultExecutorOptions, flowStepsActiveIndex, analisysByMethod, isActive, defaultValues, ableToUpdate, examExecutors}) => {
  const doctorsList = useSelector(getServiceUsers);
  //Permissions
  const ableToCreateFISHExperement = useSelector(checkPermitCreateFISHExperement);
  const ableToUpdateFISHBio = useSelector(checkPermitUpdateFISHBio);

  const experementBioSteps = useMemo(() => {
    const BioSteps: ExperimentSteps[] = defaultValues?.experimentIhcSteps || defaultValues?.experimentIhcAlkSteps || defaultValues?.experimentFishSteps;
    if (!BioSteps) return <></>;
    return BioSteps
    ?.filter(((value: ExperimentSteps) => value.step === 'bio_acquired'))
    ?.map((value: ExperimentSteps) => {
      return (
        <div key={value.id}>
          <span>Материал получен: <b> {stepDate(value.dateCreated)}</b></span>
        </div>
      )
    });
  }, [defaultValues]);


  const [labAssistantList, labAssistantMap] = useMemo(() => {
    if (!doctorsList) return [[],{}];
    const labMap:{ [index: string]: {fullname:string,specialization:string} } = {}

    const options = doctorsList
      .filter((user: any) => {
        return user?.userRegistryPermissions?.labAssistant
      })
      .map((genetic: any) => {
        let { firstName, lastName, middleName, title } = genetic?.userProfile ?? {};
        let label = `${lastName} ${firstName} ${middleName}, ${title}`.trim();
        labMap[genetic.ulid] = {fullname:`${lastName} ${firstName} ${middleName}`,specialization: title};
        return { value: genetic.ulid, label };
      });

    return [options, labMap]
  }, [doctorsList]);

  const isRearrangement = useMemo(() => {
    return analisysByMethod?.mode === 'rearrangement';
  }, [analisysByMethod]);


  if (isRearrangement) return (
    <TDetails open={true}>
      <TSummary>
          <TSummaryContent isSelected={true}>
            <TCheckbox disabled={true} checked={defaultValues?.acceptance ?? false}/>
            Попытка №{`${defaultValues?.number ?? flowStepsActiveIndex.index}`}
            <TSummaryIcon className="arrow" src={arrowSelectIcon} />
          </TSummaryContent> <TSummaryIcon src={!Object?.keys(defaultValues ?? {})?.length ? currentStatusIcon : defaultStatusIcon} />
        </TSummary>

      {!defaultValues?.acceptance && <BioPanel
        analisysByMethod={analisysByMethod}
        labAssistantList={labAssistantList}
        flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
        isActive={isActive}
        hasPermit={ableToUpdateFISHBio}
      />}


      <TablelFISHReange
        analisysByMethod={analisysByMethod}
        flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
        isActive={isActive}
        hasPermit={ableToCreateFISHExperement}
        defaultValues={defaultValues}
        ableToUpdate={ableToUpdate}
        labAssistantMap={labAssistantMap}
        experementBioSteps={experementBioSteps}
        isReferralComplite={isReferralComplite}
        referralULID={referralULID ?? ''}
        defaultExecutorOptions={defaultExecutorOptions}
        examExecutors={examExecutors}
      />

    </TDetails>
  );

  if (!isRearrangement && analisysByMethod?.localization) {
    return (
      <TDetails open={isActive || !!defaultValues?.acceptance}>
        <TSummary>
          <TSummaryContent isSelected={true}>
            <TCheckbox disabled={true} checked={defaultValues?.acceptance ?? false}/>
            Попытка №{defaultValues?.number ?? flowStepsActiveIndex.index}
            <TSummaryIcon className="arrow" src={arrowSelectIcon} />
          </TSummaryContent> <TSummaryIcon src={currentStatusIcon} />
        </TSummary>

        {!defaultValues?.acceptance && <BioPanel
          analisysByMethod={analisysByMethod}
          labAssistantList={labAssistantList}
          flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
          isActive={isActive}
          hasPermit={ableToUpdateFISHBio}
        />}

        <TablelFISHAmplific
          analisysByMethod={analisysByMethod}
          flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
          isActive={isActive}
          hasPermit={ableToCreateFISHExperement}
          defaultValues={defaultValues}
          ableToUpdate={ableToUpdate}
          labAssistantMap={labAssistantMap}
          experementBioSteps={experementBioSteps}
          isReferralComplite={isReferralComplite}
          referralULID={referralULID ?? ''}
          defaultExecutorOptions={defaultExecutorOptions}
          examExecutors={examExecutors}
        />

      </TDetails>)
  }

  return <FishLocalization analisysByMethod={analisysByMethod} isActive={isActive} hasPermit={ableToCreateFISHExperement}/>;
}

export default ExperimentFISH;