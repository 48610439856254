import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useFormContext, useFormState} from 'react-hook-form';
import { useSelector } from 'react-redux';
import Radio from '../../shared/Radio';
import InputSelect from '../../shared/InputSelect';
import { getOrganizationsSelectOptions } from '../../../store/organizations/selectors';
import BlockRegistration from './BlockRegistration';
import GlassRegistration from './GlassRegistration';
import BloodRegistration from './BloodRegistration';
import { getFromSessionStorage } from '../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../constants/sessionStorageKeys';
import { validations } from '../../../utils/validations';
import InputDayPicker from '../../shared/InputDayPicker';
import Tooltip from '../../shared/Tooltip';
import {
  TFormContainer,
  TBoxWithLabel,
  TCheckbox,
  TLabel,
  TTooltipContainer,
  TInputsWr,
  TIcon,
  TTitle,
  TTabPanel,
  TPanelButton,
  TMaterialFormWrap,
  TInputsAltWr} from './styled';
import { disableBeforeAndFutureDay } from '../../../utils/dateHelpers';
import NumberingRadioBlock from './NumberingRadioBlock';
import { getYear } from 'date-fns';
import Input from '../../shared/Input';

const { BIO_MATERIAL, PATIENT } = SESSION_STORAGE_KEYS;
const { REGISTRAR } = SESSION_STORAGE_KEYS;

// const CREATION_TYPE = {
//   newMaterial: 'newMaterial',
//   existingMaterial: 'existingMaterial',
// }

interface IBiomaterialForm {
  onFetchBloodId: () => void;
  onResetBloodId: () => void;
  setButtonDisabled: (value: boolean) => void;
}

interface IGlass {
  amount: String;
  code: String;
  codeNumber: String;
  endCode: String;
  organ: String;
  startCode: String;
  year: String;
}
interface IBlock {
  amount: String;
  code: String;
  codeNumber: String;
  endCode: String;
  organ: String;
  startCode: String;
  year: String;
  glasses: IGlass[];
}

let timeoutID:any;



const BiomaterialForm: FC<IBiomaterialForm> = ({ setButtonDisabled, onFetchBloodId, onResetBloodId }) => {
  const { control, setValue, getValues, watch, clearErrors, trigger} = useFormContext();
  const { errors } = useFormState({control: control});
  const watchNumbering = watch('numbering');
  const watchBloodId = watch('bloodId');
  const watchBloodDateOfAcquisition = watch('bloodDateOfAcquisition');
  const watchDoctorOrganization:number | null = watch('doctorOrganization',null);
  const watchBioRegisterOrganization:number | null = watch('organization',null);
  //currently not used but on the future will be needed
  //const [selectedCreationType, setSelectedCreationType] = useState(CREATION_TYPE.newMaterial);
  const [isBlockSelected, setIsBlockSelected] = useState(false);
  const [isBloodSelected, setIsBloodSelected] = useState(false);

  const organizationsSelectOptions = useSelector(getOrganizationsSelectOptions);
  const [isAllBlocksValid,setAllBlocksValid] = useState(false);
  const [isAllGlassValid,setAllGlassValid] = useState(false);
  const [isBloodValid,setBloodValid] = useState(false);
  //check patient birthday date
  const [patientBirthDate,setPatientBirthDate] = useState<number>();

  //Tabs
  const [isTabSelected, setTabSelected ] = useState<string>('block');
  const [isBlockResult, setBlockResult ] = useState(false);
  const [isTabAnimation,setTabAnimation] = useState('');

  const checkBlockGlassBlood = useCallback(() => {
    //blocks
    const formBlockValue = getValues('blocks') ?? [];
    const blockResult = watchNumbering === 'standardNumbering' ? 'code' : 'codeNumber';
    const allBlckResultsDone = (formBlockValue ?? []).every((block: IBlock) => {
      let result = !!block?.[blockResult] && !!block?.organ;
      return result;
    });
    setAllBlocksValid(allBlckResultsDone);
    
    //glasses
    const allGlsResultsDone = (formBlockValue ?? []).every((block: IBlock) => {
      if(!block?.glasses) return false;
      let result = !!(block?.glasses ?? [])?.every((glass: IGlass) => !!glass?.[blockResult])
    return result
  });
    setAllGlassValid(allGlsResultsDone);
    
    //blood
    const bloodDateOfAcquisition = getValues('bloodDateOfAcquisition');
    const bloodId = getValues('bloodId');
    let isBloodValid = false;
    if(isBloodSelected && bloodDateOfAcquisition && bloodId) isBloodValid = true;
    if(!isBloodSelected) isBloodValid = true;
    setBloodValid(isBloodValid);
    
    return {allBlckResultsDone,isBloodValid}
  },[getValues,setAllBlocksValid,setAllGlassValid,isBloodSelected,setBloodValid,watchNumbering]);


  useEffect(() => {
    if (!isBlockSelected) {
      setValue('blocks', []);
      setTabSelected('block');
      checkBlockGlassBlood();
    }
    if (!isBlockSelected && isBloodSelected) {
      setIsBloodSelected(false);
      setTabSelected('block');
      checkBlockGlassBlood();
    }
    if (!isBloodSelected){
      setValue('bloodId', '');
      setValue('bloodDateOfAcquisition', '');
      setTabSelected('block');
      checkBlockGlassBlood();
    }
    if(isBloodSelected) checkBlockGlassBlood();
    if(watchBloodId || watchBloodDateOfAcquisition) checkBlockGlassBlood();
  }, [isBlockSelected,isBloodSelected, setValue,watchBloodId,watchBloodDateOfAcquisition,checkBlockGlassBlood,setTabSelected]);

  const handleBloodSelect = useCallback(() => {
    if(!isBlockSelected) return;
    return setIsBloodSelected(!isBloodSelected);

  },[isBlockSelected,isBloodSelected, setIsBloodSelected]);


  useLayoutEffect(() => {
      const [organization,organizationCustom, doctorOrganization, doctorCustomOrganization, dateOfAcquisition, blocksChecked, bloodChecked, source, blocks, numbering] = getFromSessionStorage(
        BIO_MATERIAL.organization,
        BIO_MATERIAL.organizationCustom,
        BIO_MATERIAL.doctorOrganization,
        BIO_MATERIAL.doctorCustomOrganization,
        BIO_MATERIAL.dateOfAcquisition,
        BIO_MATERIAL.blocksChecked,
        BIO_MATERIAL.bloodChecked,
        BIO_MATERIAL.source,
        BIO_MATERIAL.blocks,
        BIO_MATERIAL.numbering
      );

      const [patientDateOfBirth] = getFromSessionStorage(PATIENT.dateOfBirth);

      if(Date.parse(patientDateOfBirth)){
        setPatientBirthDate(Date.parse(patientDateOfBirth));
      }

      if(numbering) setValue('numbering',numbering)
      
      if (organization) {
        const organizationValue = organizationsSelectOptions.find(option => option.label === organization)?.value;
        setValue('source', source);
        setValue('organization', organizationValue);
        setValue('organizationCustom', organizationCustom);

        setIsBlockSelected(blocksChecked === 'true');
        setIsBloodSelected(bloodChecked === 'true');
      }

      if (dateOfAcquisition && new Date(dateOfAcquisition).toString() !==  'Invalid Date') {
        setValue('materialDateOfAcquisition', new Date(Date.parse(dateOfAcquisition)));
      }

      if(doctorOrganization){
        setValue('doctorOrganization', +doctorOrganization)
        setValue('doctorCustomOrganization', doctorCustomOrganization)
      } else {
        const [organization, commonOrganization, organizationCustom] = getFromSessionStorage(REGISTRAR.organization, REGISTRAR.commonOrganization, REGISTRAR.organizationCustom);
        const existValue = getValues('doctorOrganization');
        if(!existValue) {
          setTimeout(() => {
            setValue('doctorOrganization', +(organization || commonOrganization))
            setValue('doctorCustomOrganization', organizationCustom)
          },100);
        }
      }

      if(blocks){
        const existValue = getValues('blocks');
        
        if(!existValue?.length){
          const blocksRaw = JSON.parse(blocks) ?? [];
          let blockSavedValues:IBlock[] = [];

          if(blocksRaw.length && blocksRaw[0]){
            for (let block of blocksRaw){
              const {organ, blockCypher, slides} = block.material;

              const {startCode,endCode,amount,year,code,codeNumber} = blockCypher[0];
  
              const glasses = slides.map((slide:any) => {
                const {startCode,endCode,amount,year,code,codeNumber} = slide.material.slideCypher[0];
                return {startCode,endCode,amount,year,code,codeNumber};
              })
              blockSavedValues.push({organ,startCode,endCode,amount,year,code,codeNumber,glasses})
            }
          }

          if(blockSavedValues?.length){
            setTimeout(() => setValue('blocks', blockSavedValues),100);
          };
        }else{
          setValue('blocks', existValue);
        }
      }      
      return () => {
        clearTimeout(timeoutID);
      }
    },
    [
      clearErrors,
      setValue,getValues,
      organizationsSelectOptions,
      setPatientBirthDate
    ]
  );





    
  const handleTabClick = useCallback(async (e) => {
    const tabIndex = e.target as HTMLInputElement;
    const checkResult = checkBlockGlassBlood();

    if(tabIndex.id === 'glass' && !checkResult.allBlckResultsDone){
      setBlockResult(false)
      return;
    }
    if(tabIndex.id === 'glass' && checkResult.allBlckResultsDone && !isBlockResult){
      setBlockResult(true)
    }

    let prevTab = 'block'
      
    setTabSelected( prev => {
      prevTab = prev;
      return tabIndex.id
    })

    if(tabIndex.id === 'block' && prevTab === 'glass') setTabAnimation('right')
    if(tabIndex.id === 'block' && prevTab === 'blood') setTabAnimation('right')

    if(tabIndex.id === 'glass' && prevTab === 'blood') setTabAnimation('rightward')
    if(tabIndex.id === 'glass' && prevTab === 'block') setTabAnimation('leftward')

    if(tabIndex.id === 'blood' && prevTab === 'block') setTabAnimation('left')
    if(tabIndex.id === 'blood' && prevTab === 'glass') setTabAnimation('left')

    clearTimeout(timeoutID);
    timeoutID = setTimeout(()=> checkBlockGlassBlood(),100);
  
  },[isBlockResult,checkBlockGlassBlood])

  useEffect(() => {
    if(!Object.keys(errors).length && isAllBlocksValid && isAllGlassValid && isBloodValid){
      setButtonDisabled(!isBlockSelected && !isBloodSelected);
    }else{
      setButtonDisabled(true);
    }
    if(!isBloodSelected && isTabSelected === 'blood') setTabSelected('block');
    clearTimeout(timeoutID);
    timeoutID = setTimeout(()=> checkBlockGlassBlood(),100);
    
  }, [setButtonDisabled, isBlockSelected, isBloodSelected, isTabSelected, errors,
    isAllBlocksValid,isAllGlassValid,isBloodValid,checkBlockGlassBlood]);

  const [resetFormKey, setResetFormKey] = useState<number>(Date.now());
  
  const clearBlocksIfNumberingChenge = useCallback(() => {
    setValue('blocks', []);
    setTabSelected('block');
    clearErrors();    
  }, [setValue,clearErrors]);

  const numberingTypeHandler = useCallback((numberingType:string) => {
    clearBlocksIfNumberingChenge()
    const currentYear = getYear(new Date());
    setValue('numbering',numberingType)
    setValue('blocks', [{
      organ: '',
      startCode: '',
      endCode: '',
      amount: '',
      year: `${currentYear}`,
      code: '',
      codeNumber: '',
      glasses: [
        {
          startCode: '',
          endCode: '',
          amount: '',
          year: `${currentYear}`,
          code: '',
          codeNumber: '',
        }
      ]
    }]);
    setTabSelected('block');
    setResetFormKey(Date.now())
  },[setValue,setTabSelected,setResetFormKey,clearBlocksIfNumberingChenge]);

  useEffect(() => {
    let timerID:any = setTimeout(()=>{
      if(watchDoctorOrganization !== 17){
        clearErrors('doctorCustomOrganization')
        setValue('doctorCustomOrganization','')
      }else{
        let value = getValues('doctorCustomOrganization')
        !value?.trim() && trigger('doctorCustomOrganization')
      }

      if(watchBioRegisterOrganization !== 17){
        clearErrors('organizationCustom')
        setValue('organizationCustom','')
      }else{
        let value = getValues('organizationCustom')
        !value?.trim() && trigger('organizationCustom')
      }
    },200);
    return () => {
      clearTimeout(timerID)
    }

  },[watchDoctorOrganization,watchBioRegisterOrganization,clearErrors,getValues,setValue,trigger]);




//commit TabsSwitcher currently not used but on the future will be needed
  return (
    <TFormContainer key={resetFormKey}>
      {/*<TabsSwitcher*/}
      {/*  tabSize='300px'*/}
      {/*  disabled*/}
      {/*  selected={selectedCreationType}*/}
      {/*  items={[*/}
      {/*    {*/}
      {/*      value: CREATION_TYPE.newMaterial,*/}
      {/*      title: 'Зарегистрировать новый материал',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      value: CREATION_TYPE.existingMaterial,*/}
      {/*      title: 'Ранее зарегистрированный материал ',*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*  onSelect={setSelectedCreationType}*/}
      {/*/>*/}
      <TBoxWithLabel>
        <TLabel>Вид материала</TLabel>
        <TCheckbox checked={isBlockSelected} label='Парафиновый блок + Гист.стекло' onChange={() => setIsBlockSelected(!isBlockSelected)} />
        <TCheckbox checked={isBloodSelected} disabled={!isBlockSelected} label='Кровь (для маркеров MS-статус, DPYD, UGT1A1)' onChange={handleBloodSelect}/>
      </TBoxWithLabel>
      <TBoxWithLabel>
        <TLabel>Тип материала *</TLabel>
        <Controller
          control={control}
          name='source'
          rules={validations.source}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <>
              <Radio error={invalid} selected={value} value='Операционный' label='Операционный' onChange={onChange} />
              <Radio error={invalid} selected={value} value='Биопсийный' label='Биопсийный' onChange={onChange} />
            </>
          )}
        />
      </TBoxWithLabel>
      <TInputsAltWr>
        <Tooltip id='warning' place='left'>
          <TTooltipContainer>
            <TIcon/>
            <span><b>Место и дата фактического </b> <br/> проведения операции пациента</span>
          </TTooltipContainer>
        </Tooltip>
        <Controller
            control={control}
            name='doctorOrganization'
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div data-tip='' data-for='warning' className='half-width'>
              <InputSelect
                onChange={(value) => onChange(value)}
                value={value}
                options={organizationsSelectOptions}
                placeholder='УЗ'
                label='Место забора материала'
                error={invalid}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name='materialDateOfAcquisition'
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <InputDayPicker
              id='dateOfAcquisition'
              date={value}
              onChange={onChange}
              popperPlacement='right-start'
              placeholderText={`дд.мм.гг`}
              label='Дата забора материала'
              error={invalid || !!errors.creationDate}
              marginTop={12}
              className='half-width ml'
              filterDate={(selectedDate) => disableBeforeAndFutureDay(selectedDate, patientBirthDate)}
            />
            )}
            />
          {!!(watchDoctorOrganization === 17) && <Controller
            control={control}
            key={`doctorCustomOrganization_${watchDoctorOrganization}`}
            name='doctorCustomOrganization'
            rules={{required:!!(watchDoctorOrganization === 17)}}
            render={({ field: { onChange, value}, fieldState: { invalid } }) => (
              <Input
                onChange={onChange}
                value={value}
                placeholder='Укажите УЗ'
                error={invalid}
                className='custom-input'
              />
          )}
        />}
      </TInputsAltWr>
      {isBlockSelected && (
        <>
          <TTitle>Зарегистрируйте ОРИГИНАЛЬНЫЕ номера блоков и стекол:</TTitle>
          <TInputsAltWr>
          <Controller
            control={control}
            name='organization'
            rules={validations.organization}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div data-tip='' data-for='warning' className='custom-input'>
                <InputSelect
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={organizationsSelectOptions}
                  placeholder='УЗ'
                  label='Учреждение, приcвоившее оригинальные номера блокам/стеклам *'
                  error={invalid}
                />
                </div>
                )}
            />
            {!!(watchBioRegisterOrganization === 17) && (
              <Controller
              control={control}
              name='organizationCustom'
              key={`organizationCustom_${watchBioRegisterOrganization}`}
              rules={{required:!!(watchBioRegisterOrganization === 17)}}
              render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  placeholder='Укажите УЗ'
                  error={invalid}
                  className='custom-input'
                />
                )}
              />
            )}
          </TInputsAltWr>
          <TInputsWr>
            <NumberingRadioBlock numberingTypeHandler={numberingTypeHandler} numberingType={watchNumbering}/>
          </TInputsWr>
          <Tooltip 
            id='tabHints' 
            place='top' 
            overridePosition={({ left, top }) => {
              if(!isBlockResult) return { left: left - 240, top: top } 
              return { left: left , top: top }
          }}>
              {!isBlockResult ? <span>Укажите сначала <b>номера блоков</b></span> :
              <span>Данные <b>номера блоков скопированы <br />в номера Гист. стекла</b>, <br />для редактирования нажмите на поле</span>
              }
          </Tooltip>
          <TTabPanel>
            <TPanelButton
              id={'block'}
              active={isTabSelected === 'block'} 
              activeBg='#CBECFE'
              onClick={handleTabClick} 
              >1.  Блоки *</TPanelButton>
            <TPanelButton 
              id={'glass'}
              data-tip='' 
              data-for='tabHints'
              active={isTabSelected === 'glass'} 
              activeBg='#F1F0FF'
              onClick={(e) =>  handleTabClick(e)}
              >2.  Гист. стеклa *</TPanelButton>
            <TPanelButton 
              id={'blood'} 
              active={isTabSelected === 'blood'} 
              activeBg='#FFCFEC'
              disabled={!isBloodSelected} 
              onClick={(e) => isBloodSelected && isAllBlocksValid && isAllGlassValid && handleTabClick(e)}
            >3.  Кровь {isBloodSelected ? '*' : ''}</TPanelButton>
          </TTabPanel>

          <TMaterialFormWrap className={isTabAnimation && `${isTabAnimation}`}>
            { isTabSelected === 'block' && <BlockRegistration checkErrors={isTabSelected} />}
            { isTabSelected === 'glass' && <GlassRegistration />}
            { isTabSelected === 'blood' && <BloodRegistration onFetchBloodId={onFetchBloodId} onResetBloodId={onResetBloodId} />}
          </TMaterialFormWrap>
        </>
      )}
      
    </TFormContainer>
  );
}

export default BiomaterialForm;
