import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

interface ITInput {
  error?: boolean | null;
  hasIconRight?: boolean;
  hasIconLeft?: boolean;
  bordered?: boolean;
  className?: string;
  marginTop?: number;
  readOnly?: boolean;
}

const TInputWr = styled.div`
  width: 100%;
  position: relative;
  font: inherit;
`;

const TInput = styled.input<ITInput>`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  border-radius: 6px;
  background-color: ${Colors.OTHER.white};
  box-sizing: border-box;
  padding: 12px 16px;
  padding-left: ${({ hasIconLeft }) => (hasIconLeft ? '40px' : '16px')};
  border: 1px solid
    ${({ error, bordered, theme }) =>
      error
        ? theme.colors.alert.warning
        : bordered
        ? Colors.MONOCHROM[200]
        : 'transparent'};
  outline: none;
  color: ${({ readOnly }) => (readOnly ? Colors.MONOCHROM[300] : Colors.MONOCHROM[950])};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};

  &::placeholder {
    color: ${Colors.MONOCHROM[300]};
    opacity: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &:disabled {
    color: #777777;
  }

  &:not(:disabled):not(:read-only):hover {
    border-color: ${Colors.BRAND[500]};
  }

  &:not(:disabled):not(:read-only):active,
  &:not(:disabled):not(:read-only):focus {
    border-color: ${Colors.BRAND[500]};
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover,
    &:focus {
      -moz-appearance: number-input;
    }
  }

  &:read-only {
    cursor: default;
  }
`;

const TLabel = styled.label`
  margin-bottom: 12px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${Colors.MONOCHROM[950]};
`;

const TIconWr = styled.div<{ withLabel: boolean; isLeft?: boolean }>`
  position: absolute;
  left: ${({ isLeft }) => (isLeft ? '14px' : 'unset')};
  right: ${({ isLeft }) => (isLeft ? 'unset' : '24px')};
  top: ${({ withLabel }) => (withLabel ? '45%' : 0)};
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 30px;
`;

const TError = styled.span`
  padding: 0 12px;
  color: ${({ theme }) => theme.colors.alert.warning};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export { TInputWr, TIconWr, TError, TInput, TLabel };
