import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const TReactTooltip = styled(ReactTooltip)`
  width: 318px;
  max-width: 318px;
  border-radius: 10px !important;
  padding: 26px 21px 35px 30px !important;
`;

export const TTooltip = styled(ReactTooltip)<{ maxWidth?: string }>`
  width: max-content;
  max-width: ${({ maxWidth }) => maxWidth};
  padding: 8px 12px !important;
  border-radius: 3px !important;
  font-size: 12px;
  box-shadow: 0px 0px 6px 0px #0000000d, 0px 3px 12px 0px #0000001a,
    0px 6px 12px 0px #0000000d;
`;

export const TTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  // color: ${({ theme }) => theme.colors.black[200]};
`;
export const TContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* color: ${({ theme }) => theme.colors.black[200]}; */
  /* color:red; */
`;
