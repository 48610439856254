import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  useLayoutEffect,
} from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../components/shared/PageContainer';
import {
  THeader,
  TLeftBlock,
  TRightBlock,
  TInput,
  TTable,
  EmptyReferralMessageBlock,
  TSearchOptions,
  TSearchOptionsText,
  TIcon,
  TCloseIcon,
  TSearchOptionsArea,
  TSearchOptionsAreaHeader,
  TSearchOptionsAreaHeaderText,
  TFilterWr,
  TFilterItem,
  TFilterDetailsWr,
  TButtonBlock,
  TFilterButton,
  TFilterDetails,
  TFilterTitle,
  TCheckboxWr,
  TFilterResultList,
  TFilterResultItem,
  TInputDayPicker,
  TSearchOptionsInputsWr,
  TBackLink,
  TFilterOptionsInFlow,
  ControllerWrapper,
  TFilterButtonReset,
} from './styled';
import PageTitle from '../../components/shared/PageTitle';
import { columns } from './constants';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross-gray-32x32.svg';
import IconSquareButton from '../../components/shared/IconSquareButton';
import { ROUTES } from '../../constants/routes';
import { fetchReferrals } from '../../store/referral/thunkActions';
import {
  checkPermitCreateREFERRAL,
  checkPermitViewREFERRAL,
  getTokens,
} from '../../store/auth/selectors';
import {
  getFilterData,
  getReferralCurrentPage,
  getReferralLoading,
  getReferrals,
  getReferralsTabTypes,
  getReferralsTotalPages,
} from '../../store/referral/selectors';
import { getOrganizations } from '../../store/organizations/selectors';
import {
  resetReferralSearchData,
  setFilterQuery,
  setNextPage,
  setPageSettings,
  setPageSize,
  setSearchQuery,
  setTabFilter,
} from '../../store/referral';
import Loader from '../../components/shared/Loader';
import { ReactComponent as ArrowIcon } from '../../icons/arrow-select-black.svg';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';
import { ReactComponent as CloseIcon } from '../../icons/cross.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Organization } from '../../store/organizations/model';
import { disableFutureDays } from '../../utils/dateHelpers';
import useWindowSize from '../../hooks/useWindowSize';
import { resetSearch } from '../CreateReferralPageNew/redux/patient';

import {
  DEFAULT_RESEARCH_TYPE,
  setResearchType,
} from '../CreateReferralPageNew/redux/researchType';
import { setCurrentStep } from '../CreateReferralPageNew/redux/currentStep';
import { setLaboratory } from '../CreateReferralPageNew/redux/laboratory';

import { Colors } from '../../styles/themeNew';

const defaultSearchState = {
  cypher: '',
  referral_id: '',
  patient_full_name: '',
  patient__id_passport: '',
};
const defaultFilterState = {
  dateCreatedGte: '',
  dateCreatedLte: '',
  status: '',
  priority: '',
  organization: '',
};
// const priorityFields = [
//   { value: 'Common', label: 'Планово' },
//   { value: 'Urgent', label: 'Срочно' },
// ];

const defaultMainFilterTabs = [
  { value: 'all', label: 'Все' },
  { value: 'work', label: 'В работе' },
  { value: 'complete', label: 'Закрыты' },
  { value: 'cancelled', label: 'Отменены' },
  { value: 'self', label: 'Мои' },
  { value: 'new', label: 'Новые' },
  { value: 'noted', label: 'Упоминания' },
  { value: 'available', label: 'Доступные' },
  { value: 'important', label: 'Важные' },
  { value: 'request', label: 'Запросы' },
];

const statusFields = [
  { value: 'bio_pending', label: 'Прием биоматериала' },
  { value: 'morphology_pending', label: 'Морфологическая характеристика' },
  { value: 'markers_validation_pending', label: 'Валидация маркеров' },
  { value: 'analysis_pending', label: 'Реализация исследований' },
  { value: 'conclusion_pending', label: 'Формирование заключения' },
  { value: 'referral_completed', label: 'Направление закрыто' },
  { value: 'referral_cancelled', label: 'Направление отменено' },
];

const dataValuesMap: { [key: string]: string } = {
  bio_pending: 'Прием биоматериала',
  morphology_pending: 'Морфологическая характеристика',
  markers_validation_pending: 'Валидация маркеров',
  analysis_pending: 'Реализация исследований',
  conclusion_pending: 'Формирование заключения',
  referral_completed: 'Направление закрыто',
  referral_cancelled: 'Направление отменено',
  Common: 'Планово',
  Urgent: 'Срочно',
};

type FormValues = {
  dateCreatedGte: Date;
  dateCreatedLte: Date;
  organization: string[];
  priority: string[];
  status: string[];
};

// Close all the details that are not targetDetail.
function closeDetailTabs(detailTabs: NodeListOf<HTMLDetailsElement>) {
  detailTabs.forEach((detail) => detail.removeAttribute('open'));
}

let offsetHeightValue: number;

const ReferralPage: FC = () => {
  const { control, register, handleSubmit, reset, watch } = useForm<FormValues>(
    {
      defaultValues: {
        dateCreatedGte: undefined,
        dateCreatedLte: undefined,
        organization: [],
        status: [],
        priority: [],
      },
    }
  );
  const resizeTime = useWindowSize()?.resizeTime;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector(getReferralLoading);
  const organizations = useSelector(getOrganizations);
  const filterData = useSelector(getFilterData);
  const referrals = useSelector(getReferrals);
  const tokens = useSelector(getTokens);
  const totalPages = useSelector(getReferralsTotalPages);
  const currentPage = +useSelector(getReferralCurrentPage);
  const referralsTabTypes = useSelector(getReferralsTabTypes);

  //Permissions
  const ableToCreateREFERRAL = useSelector(checkPermitCreateREFERRAL);
  const ableToViewREFERRAL = useSelector(checkPermitViewREFERRAL);

  //Search
  const [searchOptionsOpen, setSearchOptionsOpen] = useState(false);
  const [searchInputvalue, setSearchInputvalue] = useState<{
    [key: string]: string;
  }>({ ...defaultSearchState });
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');

  //Filter tabs
  const [activeFilterItem, setActiveFilterItem] = useState<string>(
    filterData?.tab ?? 'all'
  );

  const [searchOrganization, setSearchOrganization] = useState<string>('');

  //Filter advanced options
  const [filterFormKey, setFilterFormKey] = useState<number>(Date.now());
  const [allPanelKey, setAllPanelKey] = useState<number>(Date.now());
  const [openFilterOption, setOpenFilterOption] = useState<string>('');
  const watchPriorityFilter = watch('priority');
  const watchStatusFilter = watch('status');
  const watchOrganizationFilter = watch('organization');
  const watchDateCreatedGTEFilter = watch('dateCreatedGte');
  const watchDateCreatedLTEFilter = watch('dateCreatedLte');

  const isSearchOptionSelected = useMemo(() => {
    return !!Object.values(searchInputvalue).join('');
  }, [searchInputvalue]);

  const isFilterOptionSelected = useMemo(() => {
    let isSelected = !![
      watchPriorityFilter,
      watchStatusFilter,
      watchOrganizationFilter,
      watchDateCreatedGTEFilter,
      watchDateCreatedLTEFilter,
    ].join('');
    if (isSelected && isSearchOptionSelected)
      setSearchInputvalue({ ...defaultSearchState });
    return isSelected;
  }, [
    watchPriorityFilter,
    watchStatusFilter,
    watchOrganizationFilter,
    watchDateCreatedGTEFilter,
    watchDateCreatedLTEFilter,
    setSearchInputvalue,
    isSearchOptionSelected,
  ]);

  const hasFilterOrSearchDataSended = useMemo(() => {
    let {
      searchQuery,
      dateCreatedGte,
      dateCreatedLte,
      status,
      priority,
      type,
      organization,
    } = filterData;
    return [
      searchQuery,
      dateCreatedGte,
      dateCreatedLte,
      status,
      priority,
      type,
      organization,
    ].join('');
  }, [filterData]);

  const goToPage = useCallback(
    (page) => {
      dispatch(setNextPage(page));
    },
    [dispatch]
  );

  const setSearchFilter = useCallback(
    (value) => {
      dispatch(setFilterQuery(defaultFilterState));
      dispatch(setSearchQuery(value));
      setSearchFilterValue(value);
    },
    [dispatch, setSearchFilterValue]
  );

  const queryInputHandler = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;
      let defaultState: { [key: string]: string } = { ...defaultSearchState };

      defaultState[name] = value;
      setSearchInputvalue(defaultState);
      if (value.length < 1) return setSearchFilter('');
    },
    [setSearchInputvalue, setSearchFilter]
  );

  const onSearch = useCallback(
    ({ target, code, type }) => {
      let searchValue,
        searchGroup = target?.name ?? '';
      if (type === 'mousedown') {
        let eventData = target?.parentNode?.previousElementSibling;
        if (!eventData) return;
        searchValue = eventData?.value ?? '';
        searchGroup = eventData?.name;
      }
      if (isFilterOptionSelected) {
        reset();
        setFilterFormKey(Date.now());
      }

      if (code) searchValue = target?.value;

      let searchRequest = `${searchGroup}=${searchValue}`;

      if (code === 'Enter' || code === 'NumpadEnter' || type === 'mousedown') {
        setSearchFilter(searchRequest);
      }
    },
    [setSearchFilter, reset, setFilterFormKey, isFilterOptionSelected]
  );

  const onClear = useCallback(() => {
    let defaultState: { [key: string]: string } = { ...defaultSearchState };
    setSearchInputvalue(defaultState);
    setSearchFilter('');
  }, [setSearchInputvalue, setSearchFilter]);

  const onCreateReferral = useCallback(() => {
    navigate(ROUTES.CREATE_REFERRAL, { replace: true });
  }, [navigate]);

  const goToMolecularProfilingDetails = useCallback(
    (item?: any) => {
      if (ableToViewREFERRAL) {
        setTimeout(
          () => navigate(`/molecular-profiling/detail/${item?.ulid}/referral`),
          100
        );
      }
      return;
    },
    [ableToViewREFERRAL, navigate]
  );

  const nodeSearchOptions =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeSearchOptionsArea =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;

  const nodeFilterOrgContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterDateContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterStatusContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterPriorityContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;

  useOutsideClick(nodeSearchOptions, (event) => {
    const nodeFilterContentList = [
      nodeFilterPriorityContent,
      nodeFilterStatusContent,
      nodeFilterDateContent,
      nodeFilterOrgContent,
    ];

    const isNodeFilterContent = nodeFilterContentList.some(
      (value: MutableRefObject<HTMLDivElement>) =>
        !!value?.current && value?.current?.contains(event.target as Node)
    );

    if (
      !nodeSearchOptionsArea.current.contains(event.target as Node) &&
      !isNodeFilterContent
    ) {
      if (searchOptionsOpen) setSearchOptionsOpen(false);
      return;
    }

    // Fetch all the details element.
    const detailTabs = document.querySelectorAll('details');

    if (!isNodeFilterContent) {
      closeDetailTabs(detailTabs);
      setOpenFilterOption('');
    }
  });

  const resetPatientStepperState = (): void => {
    dispatch(resetSearch());

    dispatch(
      setCurrentStep({
        code: 'RESEARCH_TYPE',
        name: 'Тип направления',
      })
    );
    dispatch(
      setLaboratory({
        // @ts-ignore
        id: null,
        // @ts-ignore
        name: null,
      })
    );
    dispatch(setResearchType(DEFAULT_RESEARCH_TYPE));
  };

  const resizeTableRows = useCallback(() => {
    let bodyEl = document.getElementById('app-body')?.offsetHeight;

    if (!bodyEl) return;
    if (offsetHeightValue !== bodyEl) {
      offsetHeightValue = bodyEl - 100;
      bodyEl = bodyEl - 100;
    }
    let tableRows = (bodyEl - (bodyEl % 56)) / 56 - 3;
    let filterConfig = Object.keys(filterData) ? { ...filterData } : {};
    filterConfig.pageSize = tableRows;
    if (!filterConfig?.currentPage) filterConfig.currentPage = 1;
    if (!filterConfig?.nextPage) filterConfig.nextPage = 1;
    if (!filterConfig?.tab) filterConfig.tab = 'all';
    if (filterData?.pageSize && filterData?.pageSize !== tableRows) {
      filterConfig.pageSize = tableRows;
      // dispatch(setPageSize(tableRows));
    }
    tokens?.access && dispatch(fetchReferrals(tokens?.access, filterConfig));
    return tableRows;
  }, [dispatch, tokens?.access, filterData]);

  // to clear filtered data if open page
  useLayoutEffect(() => {
    let {
      searchQuery,
      dateCreatedGte,
      dateCreatedLte,
      organization,
      priority,
      status,
      cancelled,
    } = filterData;

    let statusValues: string[] = [];

    if (searchQuery) {
      let [name, value] = searchQuery.split('=');
      let defaultState: { [key: string]: string } = { ...defaultSearchState };
      defaultState[name] = value;
      setSearchInputvalue(defaultState);
      setSearchFilterValue(searchQuery);
    }
    if (status) {
      statusValues = status.split(',');
      if (cancelled) statusValues.push('referral_cancelled');
    }
    let resumeValues = {
      dateCreatedGte: dateCreatedGte ? new Date(dateCreatedGte) : undefined,
      dateCreatedLte: dateCreatedLte ? new Date(dateCreatedLte) : undefined,
      organization: organization?.split(',') ?? [],
      status: statusValues,
      priority: priority?.split(',') ?? [],
    };
    reset(resumeValues);
  }, [
    filterData,
    setSearchFilterValue,
    setSearchInputvalue,
    reset,
    activeFilterItem,
  ]);

  useEffect(() => {
    let rows = resizeTableRows();
    !!rows && dispatch(setPageSize(rows));
  }, [resizeTime, resizeTableRows, dispatch]);

  useEffect(() => {
    dispatch(setTabFilter(activeFilterItem));
  }, [activeFilterItem, dispatch]);

  const isSearchInputvalue = useMemo(() => {
    let { cypher, referral_id, patient_full_name, patient__id_passport } =
      searchInputvalue;
    let value =
      cypher || referral_id || patient_full_name || patient__id_passport;
    return !!value;
  }, [searchInputvalue]);

  const referralTabs = useMemo(() => {
    if (!referralsTabTypes) return [];
    return defaultMainFilterTabs.filter(
      (data: { value: string; label: string }) =>
        referralsTabTypes.includes(data.value)
    );
  }, [referralsTabTypes]);

  const filterOrganizationValues = useMemo(() => {
    return organizations?.map((data: Organization) => ({
      value: data.nameLocalRus,
      label: data.nameLocalRus,
      id: data.id,
    }));
  }, [organizations]);

  const filterDateIntervalValues = useMemo(() => {
    let dateResult = '';
    if (watchDateCreatedGTEFilter)
      dateResult = format(new Date(watchDateCreatedGTEFilter), 'dd.MM.yyyy');
    if (watchDateCreatedLTEFilter)
      dateResult = format(new Date(watchDateCreatedLTEFilter), 'dd.MM.yyyy');
    if (watchDateCreatedGTEFilter && watchDateCreatedLTEFilter)
      dateResult = `
    ${format(new Date(watchDateCreatedGTEFilter), 'dd.MM.yyyy')} - ${format(
        new Date(watchDateCreatedLTEFilter),
        'dd.MM.yyyy'
      )}`;
    return dateResult;
  }, [watchDateCreatedGTEFilter, watchDateCreatedLTEFilter]);

  //Advanced Filter Form
  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      let priority = data?.priority?.join() ?? '';
      let status = '';
      let cancelled = '';
      if (data?.status?.includes('referral_cancelled')) {
        cancelled = 'True';
        status =
          data?.status
            ?.filter((value: string) => value !== 'referral_cancelled')
            ?.join() ?? '';
      } else status = data?.status?.join() ?? '';

      let organization = data?.organization?.join() ?? '';
      let dateCreatedLte = data?.dateCreatedLte
        ? format(new Date(data.dateCreatedLte), 'yyyy-MM-dd')
        : '';
      let dateCreatedGte = data?.dateCreatedGte
        ? format(new Date(data.dateCreatedGte), 'yyyy-MM-dd')
        : '';
      dispatch(setSearchQuery(''));
      dispatch(
        setFilterQuery({
          dateCreatedGte,
          dateCreatedLte,
          status,
          priority,
          organization,
          cancelled,
        })
      );
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(resetReferralSearchData());
    setSearchInputvalue({ ...defaultSearchState });
    dispatch(setPageSettings({ currentPage: 1, nextPage: 1, tab: 'all' }));
    dispatch(setSearchQuery(''));
    dispatch(setFilterQuery(defaultFilterState));

    setSearchFilter('');
    setActiveFilterItem('all');
    reset();
    setAllPanelKey(Date.now());
  }, [reset, dispatch, setAllPanelKey, setSearchFilter, setActiveFilterItem]);

  const resetController = () => {};

  const resetOrganisation = () => {};

  const resetStatus = () => {};

  // const resetPriority = () => {};

  const filterOptionHandler = useCallback(
    (tabName: string) => {
      if (isSearchOptionSelected) return;
      setOpenFilterOption(tabName);
    },
    [setOpenFilterOption, isSearchOptionSelected]
  );

  const filteredOrganizations = useMemo(() => {
    if (!searchOrganization) return filterOrganizationValues;
    return filterOrganizationValues.filter((org) =>
      org.label.toLowerCase().includes(searchOrganization.toLowerCase())
    );
  }, [filterOrganizationValues, searchOrganization]);

  const handleOrganizationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchOrganization(value); 
    setSearchInputvalue((prev) => ({ ...prev, organization: value })); 
  };

  return (
    <>
      <Loader enabled={loading}>
        <PageContainer
          header={
            <THeader>
              <TLeftBlock>
                <PageTitle>Направления</PageTitle>
                {!!hasFilterOrSearchDataSended && (
                  <TBackLink onClick={resetForm}>
                    <span>&lsaquo;</span> К списку направлений
                  </TBackLink>
                )}
                {ableToCreateREFERRAL && (
                  <IconSquareButton
                    onClick={() => {
                      resetPatientStepperState();
                      onCreateReferral();
                    }}
                  >
                    <PlusIcon />
                  </IconSquareButton>
                )}
              </TLeftBlock>
              <TRightBlock>
                <TInput
                  bordered={true}
                  name='cypher'
                  placeholder='Поиск по номеру блока / стекла'
                  iconLeft={
                    !searchFilterValue.includes('cypher') ? (
                      <SearchIcon />
                    ) : (
                      <CrossIcon />
                    )
                  }
                  onChange={(v) => {
                    setSearchFilterValue(
                      searchFilterValue.replaceAll('cypher', '')
                    );
                    return queryInputHandler(v);
                  }}
                  onKeyDown={onSearch}
                  onRightIconMouseDown={
                    searchFilterValue.includes('cypher') ? onClear : onSearch
                  }
                  value={searchInputvalue['cypher']}
                  onFocus={() => {
                    if (isFilterOptionSelected) {
                      return resetForm();
                    }
                  }}
                />

                <TSearchOptions
                  key={allPanelKey}
                  ref={nodeSearchOptions}
                  onClick={() => setSearchOptionsOpen(!searchOptionsOpen)}
                  active={searchOptionsOpen}
                >
                  <TIcon
                    fill='none'
                    active={
                      /referral_id|patient_full_name|patient__id_passport/.test(
                        searchFilterValue
                      ) || isFilterOptionSelected
                    }
                  >
                    <FilterIcon />
                  </TIcon>
                  <TSearchOptionsText>Фильтры</TSearchOptionsText>
                </TSearchOptions>

                <TSearchOptionsArea
                  open={searchOptionsOpen}
                  ref={nodeSearchOptionsArea}
                >
                  <TSearchOptionsAreaHeader>
                    <TSearchOptionsAreaHeaderText>
                      Расширенный поиск
                    </TSearchOptionsAreaHeaderText>
                    <TCloseIcon
                      fill={Colors.MONOCHROM[500]}
                      onClick={() => setSearchOptionsOpen(!searchOptionsOpen)}
                    >
                      <CloseIcon />
                    </TCloseIcon>
                  </TSearchOptionsAreaHeader>
                  <TSearchOptionsInputsWr>
                    <TInput
                      name='referral_id'
                      label='По номеру направления'
                      placeholder='Введите номер '
                      iconLeft={
                        !searchFilterValue.includes('referral_id') ? (
                          <SearchIcon />
                        ) : (
                          <CrossIcon />
                        )
                      }
                      onChange={(v) => {
                        setSearchFilterValue(
                          searchFilterValue.replaceAll('referral_id', '')
                        );
                        return queryInputHandler(v);
                      }}
                      onKeyDown={onSearch}
                      onRightIconMouseDown={
                        searchFilterValue.includes('referral_id')
                          ? onClear
                          : onSearch
                      }
                      value={searchInputvalue['referral_id']}
                      // disabled={isFilterOptionSelected}
                    />

                    <TInput
                      name='patient_full_name'
                      label='По фамилии пациента'
                      placeholder='Введите данные'
                      iconLeft={
                        !searchFilterValue.includes('patient_full_name') ? (
                          <SearchIcon />
                        ) : (
                          <CrossIcon />
                        )
                      }
                      onChange={(v) => {
                        setSearchFilterValue(
                          searchFilterValue.replaceAll('patient_full_name', '')
                        );
                        return queryInputHandler(v);
                      }}
                      onKeyDown={onSearch}
                      onRightIconMouseDown={
                        searchFilterValue.includes('patient_full_name')
                          ? onClear
                          : onSearch
                      }
                      value={searchInputvalue['patient_full_name']}
                      // disabled={isFilterOptionSelected}
                    />

                    <TInput
                      name='patient__id_passport'
                      label='По личному номеру / паспорту / ID'
                      placeholder='Введите номер '
                      iconLeft={
                        !searchFilterValue.includes('patient__id_passport') ? (
                          <SearchIcon />
                        ) : (
                          <CrossIcon />
                        )
                      }
                      onChange={(v) => {
                        setSearchFilterValue(
                          searchFilterValue.replaceAll(
                            'patient__id_passport',
                            ''
                          )
                        );
                        return queryInputHandler(v);
                      }}
                      onKeyDown={onSearch}
                      onRightIconMouseDown={
                        searchFilterValue.includes('patient__id_passport')
                          ? onClear
                          : onSearch
                      }
                      value={searchInputvalue['patient__id_passport']}
                    />
                  </TSearchOptionsInputsWr>

                  <TFilterDetailsWr
                    onSubmit={handleSubmit(onSubmit)}
                    key={filterFormKey}
                  >
                    <TFilterDetails
                      id='dateCreatedTab'
                      // disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('dateCreated')}
                      ref={nodeFilterDateContent}
                    >
                      <TFilterTitle>
                        <span>Дата создания</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'dateCreated' &&
                          searchOptionsOpen
                        }
                        height={168}
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <ControllerWrapper>
                          <div>
                            <span>C..</span>
                            <Controller
                              control={control}
                              name='dateCreatedGte'
                              render={({ field: { onChange, value } }) => (
                                <TInputDayPicker
                                  date={value}
                                  onChange={(v) => {
                                    return onChange(v);
                                  }}
                                  popperPlacement='bottom'
                                  // placeholderText='С ...'
                                  filterDate={disableFutureDays}
                                  bordered
                                />
                              )}
                            />
                          </div>
                          <div>
                            <span>По..</span>
                            <Controller
                              control={control}
                              name='dateCreatedLte'
                              render={({ field: { onChange, value } }) => (
                                <TInputDayPicker
                                  date={value}
                                  onChange={onChange}
                                  popperPlacement='bottom'
                                  // placeholderText='По ...'
                                  filterDate={disableFutureDays}
                                  bordered
                                />
                              )}
                            />
                          </div>
                        </ControllerWrapper>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      <TFilterResultItem>
                        {filterDateIntervalValues}
                      </TFilterResultItem>
                    </TFilterResultList>

                    <TFilterDetails
                      id='organizationTab'
                      // disabled={isSearchOptionSelected}
                      ref={nodeFilterOrgContent}
                    >
                      <TFilterTitle
                        onClick={() => filterOptionHandler('organization')}
                      >
                        <span>Направившее учреждение</span>
                        <ArrowIcon />
                      </TFilterTitle>

                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'organization' &&
                          searchOptionsOpen
                        }
                        height={352}
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetOrganisation}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TInput
                          name='organization'
                          placeholder='Найти в списке '
                          iconLeft={
                            !searchFilterValue.includes('organization') ? (
                              <SearchIcon />
                            ) : (
                              <CrossIcon />
                            )
                          }
                          onChange={handleOrganizationInputChange}
                          // onKeyDown={onSearch}
                          onLeftIconMouseDown={
                            searchFilterValue.includes('organization')
                              ? onClear
                              : onSearch
                          }
                          value={searchInputvalue['organization']}
                        />
                        <TCheckboxWr>
                          {filteredOrganizations.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('organization')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchOrganizationFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {value ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    <TFilterDetails
                      id='statusTab'
                      // disabled={isSearchOptionSelected}
                      ref={nodeFilterStatusContent}
                      onClick={() => filterOptionHandler('status')}
                    >
                      <TFilterTitle>
                        <span>Статус</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'status' && searchOptionsOpen
                        }
                        height={234}
                      >
                        <TFilterButtonReset type='reset' onClick={resetStatus}>
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr>
                          {statusFields.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('status')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchStatusFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {dataValuesMap?.[value] ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    {/* <TFilterDetails
                      id='priorityTab'
                      // disabled={isSearchOptionSelected}
                      ref={nodeFilterPriorityContent}
                      onClick={() => filterOptionHandler('priority')}
                    >
                      <TFilterTitle>
                        <span>Приоритет</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'priority' && searchOptionsOpen
                        }
                        height={106}
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetPriority}
                        >
                          Сбросить
                        </TFilterButtonReset>

                        <TCheckboxWr>
                          {priorityFields.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('priority')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {priorityFields
                        ?.filter((data: { value: string; label: string }) =>
                          watchPriorityFilter.includes(data.value)
                        )
                        ?.map((data: { value: string; label: string }) => (
                          <TFilterResultItem key={data.value}>
                            {data.label}
                          </TFilterResultItem>
                        ))}
                    </TFilterResultList> */}

                    <TButtonBlock>
                      <TFilterButton type='reset' onClick={resetForm}>
                        Сбросить все
                      </TFilterButton>
                      <TFilterButton
                        isMain={true}
                        type='submit'
                        disabled={
                          !isFilterOptionSelected || isSearchOptionSelected
                        }
                      >
                        Применить
                      </TFilterButton>
                    </TButtonBlock>
                  </TFilterDetailsWr>
                </TSearchOptionsArea>
              </TRightBlock>
            </THeader>
          }
        >
          <TFilterWr>
            {referralTabs.map((data: { value: string; label: string }) => (
              <TFilterItem
                key={data.value}
                isActive={activeFilterItem === data.value}
                onClick={() => setActiveFilterItem(data.value)}
              >
                {data.label}
              </TFilterItem>
            ))}
          </TFilterWr>
          {!referrals?.length &&
            !loading &&
            (!!isSearchInputvalue || !!isFilterOptionSelected) && (
              <EmptyReferralMessageBlock>
                К сожалению, по Вашему запросу ничего не найдено
              </EmptyReferralMessageBlock>
            )}
          {!referrals?.length &&
            !loading &&
            !isSearchInputvalue &&
            !isFilterOptionSelected &&
            activeFilterItem === 'all' && (
              <EmptyReferralMessageBlock>
                Здесь пока ничего не создано. Создайте первое направление,{' '}
                <span> нажав на кнопку “ПЛЮС”</span>
              </EmptyReferralMessageBlock>
            )}
          {!referrals?.length &&
            !loading &&
            !isSearchInputvalue &&
            !isFilterOptionSelected &&
            activeFilterItem !== 'all' && (
              <EmptyReferralMessageBlock>
                На данный момент здесь нет ни одной записи
              </EmptyReferralMessageBlock>
            )}
          {!!referrals?.length && (
            <TTable
              items={referrals}
              columns={columns({ organizations })}
              goToPage={goToPage}
              totalPages={totalPages}
              currentPage={currentPage}
              onClick={goToMolecularProfilingDetails}
              headerColor={'#EEF3FF'}
              textColor={'#3E6AFF'}
            />
          )}
        </PageContainer>
      </Loader>
    </>
  );
};

export default ReferralPage;
