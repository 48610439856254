import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Select } from "../../../../../../componentsNew/Select";
import { useLocalizations } from "../../../../hooks/useLocalizations";
import { localizationsSelector } from "../../../../redux/clinicalData";
import {
  selectedLocalizationSelector,
  setSelectedLocalization,
} from "../../../../redux/clinicalData";

export const Localization = () => {
  const dispatch = useDispatch();

  useLocalizations();

  const localizations = useSelector(localizationsSelector);
  const selectedLocalization = useSelector(selectedLocalizationSelector);

  const onSelect = (option: { label: string; value: string }) => {
    dispatch(setSelectedLocalization(option));
  };

  return (
    <StyledRowContainer>
      <StyledSelectContainer>
        <Select
          title="Локализация"
          required={true}
          placeholder="Локализация"
          options={localizations.map((localization) => ({
            label: localization.type_rus,
            value: String(localization.id),
            selected: String(localization.id) === selectedLocalization?.value,
          }))}
          onSelect={onSelect}
        />
      </StyledSelectContainer>
    </StyledRowContainer>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledSelectContainer = styled.div`
  width: 100%;
`;
