import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TEditButton, TRowWr, TTableWrapper } from "../styled";
import InputSelect from "../../../../components/shared/InputSelect";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../../../store/auth/selectors";
import { fetchConclusionModuleDefault, postOverWriteConclusionModules } from "../../../../store/molProConclusion/thunkActions";
import { getConclModuleDefaultDepartment, getConclModuleDefaultTitle } from "../../../../store/molProConclusion/selectors";
import { IConclusionData } from "../../../../store/molProConclusion/model";


interface IComponentProps {
  stepComplite: boolean;
  hasPermit: boolean;
  referralID: string;
  defaultData?: IConclusionData | null;
  [index: string]: any;
}
interface UseFormInputs {
  formDepartment: any | undefined
  formTitle: any | undefined
  phoneNumber: any | undefined
}

export const FormHeaders: FC<IComponentProps> = ({stepComplite, hasPermit, referralID, defaultData}) => {
  const { control, handleSubmit, setValue, reset } = useForm<UseFormInputs>({ mode: "onSubmit", defaultValues : {
    formDepartment: '',
    formTitle:      '',
    phoneNumber:    '',
  } });
  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const moduleDefaultTitleOptions = useSelector(getConclModuleDefaultTitle);
  const moduleDefaultDepartmentOptions = useSelector(getConclModuleDefaultDepartment);
  
  const titleOptions = useMemo(() => {
    if(!!moduleDefaultTitleOptions && moduleDefaultTitleOptions?.length) return moduleDefaultTitleOptions;
    return [{value: 0, label:'данные загружаются ..', id:0}];
  },[moduleDefaultTitleOptions]);

  const departmentOptions = useMemo(() => {
    if(!!moduleDefaultDepartmentOptions && Object.keys(moduleDefaultDepartmentOptions ?? {})?.length) return moduleDefaultDepartmentOptions;
    return {  
              '':{value: 0, label:'данные загружаются ..', id:0, phoneNumber: ''},
              undefined:{value: 0, label:'данные загружаются ..', id:0,phoneNumber: ''}
            };
  },[moduleDefaultDepartmentOptions]);


    //Load saved options
    const restoreDefaults = useCallback(async () => {
      if(defaultData?.moduleDefault?.restoreDefaultData){
        let {formDepartment, formTitle, phoneNumber} = {...defaultData.moduleDefault.restoreDefaultData} 
        setValue('formDepartment',formDepartment ?? '');
        setValue('formTitle',formTitle ?? '');
        setValue('phoneNumber',phoneNumber ?? '');
        setResetFormKey(Date.now())
      }
    }, [defaultData?.moduleDefault?.restoreDefaultData, setValue, setResetFormKey]);

  const handleSaveData = useCallback(async (formData: any) => {
    if (stepComplite || !hasPermit) return;
    
    const dataForSave: { [index: string]: any; } = {...defaultData ?? {}} ;
    dataForSave['moduleDefault'] = {restoreDefaultData: {...formData}};

    if(tokens?.access) dispatch(postOverWriteConclusionModules(tokens?.access,referralID,dataForSave));

    setEdit(false);
  }, [referralID, setEdit, hasPermit, stepComplite, defaultData,dispatch,tokens?.access]);


  useLayoutEffect(() => {
    if (tokens?.access && !moduleDefaultTitleOptions?.length){
      dispatch(fetchConclusionModuleDefault(tokens?.access));
    }
    if(defaultData?.moduleDefault?.restoreDefaultData){
      restoreDefaults()
    }
  }, [tokens,dispatch,moduleDefaultTitleOptions?.length,setValue,defaultData,restoreDefaults]);
  

  const handleEditPage = useCallback(async () => {
    if (stepComplite || !hasPermit) return;
    if (isEdit) {
      // await deleteSavedData()
      reset()
      setEdit(false);
      restoreDefaults();
      return setResetFormKey(Date.now())
    }
    setEdit(true)
  },[isEdit, setEdit, stepComplite, hasPermit,setResetFormKey, restoreDefaults, reset]);

  
  return (
    <TTableWrapper key={`TTableWrapper_${resertFormKey}`} onSubmit={handleSubmit(data => handleSaveData(data))}>
      <TRowWr direction="flex-end">
      {!stepComplite && hasPermit && <TEditButton type="button" onClick={handleEditPage} active={!isEdit} disabled={stepComplite}>
          {!isEdit ? 'Редактировать' : 'Отменить'}
        </TEditButton>}
        {isEdit  && !stepComplite &&
        <TEditButton type="submit" disabled={!isEdit} active={isEdit}>
          Сохранить
        </TEditButton>
        }
      </TRowWr>
      <Controller
        control={control}
        name="formDepartment"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <InputSelect
            onChange={(value) => {
              setValue('phoneNumber',departmentOptions?.[value]?.phoneNumber ?? '');
              return onChange(value)}}
            value={value}
            options={Object.values(departmentOptions)}
            placeholder='Выберите отделение для “шапки” документа'
            disabled={!isEdit}
            error={invalid}
            label='Отделение'
            containerClassName={`formSelect ${invalid ? 'error' : ''}`}
          /> )}
      />
      <Controller
        control={control}
        name="formTitle"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <InputSelect
            onChange={(value) => onChange(value)}
            value={value}
            options={titleOptions}
            placeholder='Выберите название документа'
            disabled={!isEdit}
            error={invalid}
            label='Название'
            containerClassName={`formSelect ${invalid ? 'error' : ''}`}
          /> )}
      />
    </TTableWrapper>
  )
}

export default FormHeaders;