import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

export const TBottomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 46px;

  & > * {
    margin-right: 40px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TModalContent = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .error {
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.alert.warning};
  }
`;

export const TModalTitle = styled.h1<{ iconExist: boolean }>`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
  text-align: center;
  flex: ${({ iconExist }) => iconExist ? 2 : 'unset'};
  white-space: pre-wrap;
`;

export const TAltModalTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${Colors.MONOCHROM[900]};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 40px;
`;

export const TModalDescription = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: ${Colors.MONOCHROM[900]};
`;

export const TAltModalDescription = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: ${Colors.MONOCHROM[900]};
  margin-bottom: 16px;
`;

export const TModalButtonsContainer = styled(TBottomButtonsContainer)`
  margin-top: 40px;
  width: 100%;
`;
