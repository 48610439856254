import styled from "styled-components";
import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviousAnalysisResults,
  previousAnalysisResultsSelector,
  setPreviousAnalysisResultsNonApplicable,
  previousAnalysisResultsNonApplicableSelector,
  setPreviousAnalysisResultsUnknown,
  previousAnalysisResultsUnknownSelector,
} from "../../../../redux/clinicalData";
import Checkbox from "../../../../../../components/shared/Checkbox";

export const Results = () => {
  const dispatch = useDispatch();

  const previousResults = useSelector(previousAnalysisResultsSelector);
  
  const previousAnalysisResultsNonApplicable = useSelector(previousAnalysisResultsNonApplicableSelector);
  const previousAnalysisResultsUnknown = useSelector(previousAnalysisResultsUnknownSelector);

  const handlePreviousAnalysisResultsNonApplicableChange = () => {
    if (previousAnalysisResultsNonApplicable) {
      dispatch(setPreviousAnalysisResultsNonApplicable(false));
    } else {
      dispatch(setPreviousAnalysisResultsNonApplicable(true));
      dispatch(setPreviousAnalysisResultsUnknown(false));
      dispatch(setPreviousAnalysisResults(""));
    }
  };
  
  const handlePreviousAnalysisResultsUnknownChange = () => {
    if (previousAnalysisResultsUnknown) {
      dispatch(setPreviousAnalysisResultsUnknown(false));
    } else {
      dispatch(setPreviousAnalysisResultsUnknown(true));
      dispatch(setPreviousAnalysisResultsNonApplicable(false));
      dispatch(setPreviousAnalysisResults(""));
    }
  };

  const popupText="Наименование медицинской организации, дата, регистрационный номер, заключение"

  return (
    <StyledContainer>
      <Textarea
        title="Результаты предыдущих патолого-анатомических исследований"
        required={true}
        placeholder="Свободный ввод"
        value={previousResults || ""}
        infoicon={true}
        popupText={popupText}
        onChange={(event) => {
          dispatch(setPreviousAnalysisResults(event.target.value));
        }}
        disabled={previousAnalysisResultsNonApplicable || previousAnalysisResultsUnknown}
      />
      <TCheckboxWr>
        <TCheckbox checked={previousAnalysisResultsNonApplicable} label='Неприменимо' onChange={handlePreviousAnalysisResultsNonApplicableChange} />
        <TCheckbox checked={previousAnalysisResultsUnknown} label='Не установлена' onChange={handlePreviousAnalysisResultsUnknownChange} />
      </TCheckboxWr>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
` 
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;
