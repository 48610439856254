import Loader from '../../components/shared/Loader';
import PageContainer from '../../components/shared/PageContainer';
import {
  ControllerWrapper,
  TButtonBlock,
  TCheckboxWr,
  TFilterButton,
  TFilterButtonReset,
  TFilterDetails,
  TFilterDetailsWr,
  TFilterItem,
  TFilterOptionsInFlow,
  TFilterResultItem,
  TFilterResultList,
  TFilterTitle,
  TFilterWr,
  THeader,
  TIcon,
  TInputDayPicker,
  TSearchOptions,
  TSearchOptionsArea,
  TSearchOptionsAreaHeader,
  TSearchOptionsAreaHeaderText,
  TSearchOptionsInputsWr,
  TWarningMsg,
} from '../ReferralPage/styled';
import PageTitle from '../../components/shared/PageTitle';
import {
  EmptyResearchMessageBlock,
  TBackLink,
  TInput,
  TLeftBlock,
  TRightBlock,
  TTable,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPermitViewExperement,
  getTokens,
} from '../../store/auth/selectors';
import {
  ChangeEvent,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fetchResearch } from '../../store/research/thunkActions';
import {
  getResearchCurrentPage,
  getResearchesList,
  getResearchFilterData,
  getResearchListLoading,
  getResearchTabTypes,
  getResearchTotalPages,
} from '../../store/research/selectors';
import { useNavigate } from 'react-router-dom';
import { researchTableColumns } from './constants';
import {
  resetResearchSearchData,
  setResearchFilterQuery,
  setResearchNextPage,
  setResearchPageSettings,
  setResearchPageSize,
  setResearchSearchQuery,
  setResearchTabFilter,
} from '../../store/research';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as ArrowIcon } from '../../icons/arrow-select-black.svg';
import { ReactComponent as CloseIcon } from '../../icons/cross.svg';
import { ReactComponent as CrossBigIcon } from '../../icons/cross-gray-32x32.svg';
import { getServiceUsers } from '../../store/serviceUsers/selectors';
import { fetchServiceUsers } from '../../store/serviceUsers/thunkActions';
import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import { format } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { disableFutureDays } from '../../utils/dateHelpers';
import { fetchRegistryMarkerList } from '../../store/molecularProfiling/thunkActions';
import { registryMarkerNames } from '../../store/molecularProfiling/selectors';
import { Colors } from '../../styles/themeNew';
import { ReactComponent as Filter } from '../../icons/filter-icon.svg';

const defaultSearchState = {
  cypher: '',
  patient_full_name: '',
  blueprint_id: '',
};
const defaultFilterState = {
  dateCreatedGte: '',
  dateCreatedLte: '',
  bio: '',
  method: '',
  executor: '',
  status: '',
  priority: '',
};
const priorityFields = [
  { value: 'Common', label: 'Планово' },
  { value: 'Urgent', label: 'Срочно' },
];

export const methodsForUrl: { [index: string]: string } = {
  'Sanger sequencing': 'ss',
  'Fragment analysis': 'fa',
  FISH: 'fish',
  PCR: 'pcr',
  IHC: 'ihc',
};

const defaultMainFilterTabs = [
  { value: 'all', label: 'Все' },
  { value: 'new', label: 'Новые' },
  { value: 'work', label: 'В работе' },
  { value: 'complete', label: 'Закрыты' },
  { value: 'cancelled', label: 'Отменены' },
  { value: 'self', label: 'Мои' },
  { value: 'noted', label: 'Упоминания' },
  { value: 'available', label: 'Доступные' },
  { value: 'important', label: 'Важные' },
  { value: 'request', label: 'Запросы' },
];

const statusFields = [
  { value: 'New', label: 'Новое' },
  { value: 'In Progress', label: 'В процессе' },
  { value: 'Complete', label: 'Завершено' },
  { value: 'Cancelled', label: 'Отменено' },
];
const bioFields = [
  { value: 'block_1', label: 'Блок К1' },
  { value: 'block_2', label: 'Блок К2' },
  { value: 'block_3', label: 'Блок К3' },
  { value: 'blood', label: 'Кровь' },
];

const methodFields = [
  { value: '1', label: 'ИГХ' },
  { value: '2', label: 'FISH' },
  { value: '3', label: 'ПЦР' },
  { value: '4', label: 'Секвенирование по Сэнгеру' },
  { value: '5', label: 'Фрагментный анализ' },
];

const dataValuesMap: { [key: string]: string } = {
  '1': 'ИГХ',
  '2': 'FISH',
  '3': 'ПЦР',
  '4': 'Секвенирование по Сэнгеру',
  '5': 'Фрагментный анализ',
  New: 'Новое',
  'In Progress': 'В процессе',
  Complete: 'Завершено',
  Cancelled: 'Отменено',
  Common: 'Планово',
  Urgent: 'Срочно',
  block_1: 'Блок К1',
  block_2: 'Блок К2',
  block_3: 'Блок К3',
  blood: 'Кровь',
};

function shortName(fName: string, lName: string, mName: string) {
  let lastName = lName ? lName[0].toUpperCase() + '.' : '';
  let midName = mName ? mName[0].toUpperCase() + '.' : '';
  return `${fName} ${lastName} ${midName}`;
}

type FormValues = {
  dateCreatedGte: Date;
  dateCreatedLte: Date;
  bio: string[];
  method: string[];
  marker: string[];
  executor: string[];
  status: string[];
  priority: string[];
};

// Close all the details that are not targetDetail.
function closeDetailTabs(detailTabs: NodeListOf<HTMLDetailsElement>) {
  detailTabs.forEach((detail) => detail.removeAttribute('open'));
}

let offsetHeightValue: number;

const ResearchPage: FC = () => {
  const { control, register, handleSubmit, reset, watch } = useForm<FormValues>(
    {
      defaultValues: {
        dateCreatedGte: undefined,
        dateCreatedLte: undefined,
        bio: [],
        method: [],
        marker: [],
        executor: [],
        status: [],
        priority: [],
      },
    }
  );
  const resizeTime = useWindowSize()?.resizeTime;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(getResearchListLoading);
  const tokens = useSelector(getTokens);

  const markerNamesList = useSelector(registryMarkerNames);
  const researchList = useSelector(getResearchesList);
  const doctorsList = useSelector(getServiceUsers);
  const researchTotalPages = useSelector(getResearchTotalPages);
  const filterData = useSelector(getResearchFilterData);
  const currentPage = useSelector(getResearchCurrentPage);
  const researchTabTypes = useSelector(getResearchTabTypes);

  //Permissions
  const ableToViewExperement = useSelector(checkPermitViewExperement);

  //Search
  const [searchInputvalue, setSearchInputvalue] = useState<{
    [key: string]: string;
  }>({ ...defaultSearchState });
  const [searchOptionsOpen, setSearchOptionsOpen] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');

  //Filter tabs
  const [activeFilterItem, setActiveFilterItem] = useState<string>(
    filterData?.tab ?? 'all'
  );

  //Filter advanced options
  const [filterFormKey, setFilterFormKey] = useState<number>(Date.now());
  const [allPanelKey, setAllPanelKey] = useState<number>(Date.now());
  const [openFilterOption, setOpenFilterOption] = useState<string>('');
  const watchDateCreatedGTEFilter = watch('dateCreatedGte');
  const watchDateCreatedLTEFilter = watch('dateCreatedLte');
  const watchExecutorFilter = watch('executor');
  const watchPriorityFilter = watch('priority');
  const watchMethodFilter = watch('method');
  const watchMarkerFilter = watch('marker');
  const watchStatusFilter = watch('status');
  const watchBioFilter = watch('bio');

  const isSearchOptionSelected = useMemo(() => {
    return !!Object.values(searchInputvalue).join('');
  }, [searchInputvalue]);

  const isFilterOptionSelected = useMemo(() => {
    let isSelected = !![
      watchPriorityFilter,
      watchStatusFilter,
      watchExecutorFilter,
      watchMethodFilter,
      watchBioFilter,
      watchDateCreatedGTEFilter,
      watchDateCreatedLTEFilter,
      watchMarkerFilter,
    ].join('');
    if (isSelected && isSearchOptionSelected)
      setSearchInputvalue({ ...defaultSearchState });
    return isSelected;
  }, [
    watchPriorityFilter,
    watchStatusFilter,
    watchExecutorFilter,
    watchMethodFilter,
    watchDateCreatedGTEFilter,
    watchDateCreatedLTEFilter,
    setSearchInputvalue,
    watchBioFilter,
    isSearchOptionSelected,
    watchMarkerFilter,
  ]);

  const hasFilterOrSearchDataSended = useMemo(() => {
    let {
      searchQuery,
      dateCreatedGte,
      dateCreatedLte,
      status,
      priority,
      type,
      organization,
    } = filterData;
    return [
      searchQuery,
      dateCreatedGte,
      dateCreatedLte,
      status,
      priority,
      type,
      organization,
    ].join('');
  }, [filterData]);

  const goToPage = useCallback(
    (page) => {
      dispatch(setResearchNextPage(page));
    },
    [dispatch]
  );

  const [doctorWhoCompleted, executorsList] = useMemo(() => {
    if (!doctorsList) return [{}, []];
    let doctorWhoCompleted: {
      [key: string]: { fullname: string; shortName: string; title: string };
    } = {};
    let executorsList: string[] = [];

    for (let value of doctorsList) {
      let ulid = value.ulid;
      let {
        firstName = '',
        lastName = '',
        middleName = '',
        title = '',
      } = value.userProfile;
      let { geneticistCoordinator, geneticistExecutor, morphologistExecutor } =
        value.userRegistryPermissions;
      if (geneticistCoordinator || geneticistExecutor || morphologistExecutor)
        executorsList.push(ulid);
      doctorWhoCompleted[ulid] = {
        fullname: `${lastName} ${firstName} ${middleName}`.trim(),
        shortName: shortName(lastName, firstName, middleName),
        title,
      };
    }

    return [doctorWhoCompleted, executorsList];
  }, [doctorsList]);

  const setSearchFilter = useCallback(
    (value) => {
      dispatch(setResearchFilterQuery(defaultFilterState));
      dispatch(setResearchSearchQuery(value));
      setSearchFilterValue(value);
    },
    [dispatch, setSearchFilterValue]
  );

  const queryInputHandler = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;
      let defaultState: { [key: string]: string } = { ...defaultSearchState };

      defaultState[name] = value;
      setSearchInputvalue(defaultState);
      if (value.length < 1) return setSearchFilter('');
    },
    [setSearchInputvalue, setSearchFilter]
  );

  const onSearch = useCallback(
    ({ target, code, type }) => {
      let searchValue,
        searchGroup = target?.name ?? '';
      if (type === 'mousedown') {
        let eventData = target?.parentNode?.previousElementSibling;
        if (!eventData) return;
        searchValue = eventData?.value ?? '';
        searchGroup = eventData.name;
      }

      if (isFilterOptionSelected) {
        reset();
        setFilterFormKey(Date.now());
      }

      if (code) searchValue = target?.value;

      let searchRequest = `${searchGroup}=${searchValue}`;

      if (code === 'Enter' || code === 'NumpadEnter' || type === 'mousedown') {
        setSearchFilter(searchRequest);
      }
    },
    [setSearchFilter, reset, setFilterFormKey, isFilterOptionSelected]
  );

  const onClear = useCallback(() => {
    let defaultState: { [key: string]: string } = { ...defaultSearchState };
    setSearchInputvalue(defaultState);
    setSearchFilter('');
  }, [setSearchInputvalue, setSearchFilter]);

  const goToResearch = useCallback(
    (item: any) => {
      if (!ableToViewExperement) return;

      let pathToExamination = `${item?.id}_${
        methodsForUrl[item?.method?.name]
      }_${item?.marker.toLowerCase().replace('/', '#')}`;
      if (item.status === 'New') pathToExamination = 'referral';
      setTimeout(
        () =>
          navigate(
            `/molecular-profiling/detail/${item?.examination?.referral?.ulid}/${pathToExamination}`
          ),
        100
      );
      return;
    },
    [navigate, ableToViewExperement]
  );

  const nodeSearchOptions =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeSearchOptionsArea =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;

  const nodeFilterExecutorContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterPriorityContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterMethodContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterMarkerContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterStatusContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterDateContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;
  const nodeFilterBioContent =
    (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>) ?? null;

  useOutsideClick(nodeSearchOptions, (event) => {
    const nodeFilterContentList = [
      nodeFilterPriorityContent,
      nodeFilterStatusContent,
      nodeFilterDateContent,
      nodeFilterMarkerContent,
      nodeFilterExecutorContent,
      nodeFilterMethodContent,
      nodeFilterBioContent,
    ];

    const isClickInsideModal = nodeSearchOptions.current?.contains(
      event.target as Node
    );

    if (isClickInsideModal) {
      return;
    }

    const isNodeFilterContent = nodeFilterContentList.some(
      (value: MutableRefObject<HTMLDivElement>) =>
        !!value?.current && value.current?.contains(event.target as Node)
    );

    if (
      !nodeSearchOptionsArea.current?.contains(event.target as Node) &&
      !isNodeFilterContent
    ) {
      if (searchOptionsOpen) setSearchOptionsOpen(false);
      return;
    }

    if (!isNodeFilterContent) {
      const detailTabs = document.querySelectorAll('details');
      closeDetailTabs(detailTabs);
      setOpenFilterOption('');
    }
  });

  const resizeTableRows = useCallback(() => {
    let bodyEl = document.getElementById('app-body')?.offsetHeight;
    if (!bodyEl) return;
    if (offsetHeightValue !== bodyEl) {
      offsetHeightValue = bodyEl - 100;
      bodyEl = bodyEl - 100;
    }
    let tableRows = (bodyEl - (bodyEl % 56)) / 56 - 3;
    let filterConfig = Object.keys(filterData) ? { ...filterData } : {};
    filterConfig.pageSize = tableRows;
    if (!filterConfig?.currentPage) filterConfig.currentPage = 1;
    if (!filterConfig?.nextPage) filterConfig.nextPage = 1;
    if (!filterConfig?.tab) filterConfig.tab = 'all';
    if (filterData?.pageSize && filterData?.pageSize !== tableRows) {
      filterConfig.pageSize = tableRows;
    }
    tokens?.access && dispatch(fetchResearch(tokens?.access, filterConfig));

    return tableRows;
  }, [dispatch, tokens?.access, filterData]);

  // to clear filtered data if open page
  useLayoutEffect(() => {
    let {
      searchQuery,
      bio,
      dateCreatedGte,
      dateCreatedLte,
      executor,
      marker,
      method,
      priority,
      status,
      cancelled,
    } = filterData;
    let statusValues: string[] = [];

    if (tokens?.access) {
      !markerNamesList?.length &&
        dispatch(fetchRegistryMarkerList(tokens?.access));
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
    }

    if (searchQuery) {
      let [name, value] = searchQuery.split('=');
      let defaultState: { [key: string]: string } = { ...defaultSearchState };
      defaultState[name] = value;
      setSearchInputvalue(defaultState);
      setSearchFilterValue(searchQuery);
    }
    if (status) {
      statusValues = status.split(',');
      if (cancelled) statusValues.push('referral_cancelled');
    }
    let resumeValues = {
      dateCreatedGte: dateCreatedGte ? new Date(dateCreatedGte) : undefined,
      dateCreatedLte: dateCreatedLte ? new Date(dateCreatedLte) : undefined,
      bio: bio?.split(',') ?? [],
      method: method?.split(',') ?? [],
      marker: marker?.split(',') ?? [],
      executor: executor?.split(',') ?? [],
      status: statusValues,
      priority: priority?.split(',') ?? [],
    };
    reset(resumeValues);
  }, [
    dispatch,
    tokens?.access,
    filterData,
    setSearchFilterValue,
    setSearchInputvalue,
    reset,
    markerNamesList,
  ]);

  useEffect(() => {
    let rows = resizeTableRows();
    !!rows && dispatch(setResearchPageSize(resizeTableRows()));
  }, [resizeTime, resizeTableRows, dispatch]);

  useEffect(() => {
    dispatch(setResearchTabFilter(activeFilterItem));
  }, [activeFilterItem, dispatch]);

  const researchTabs = useMemo(() => {
    if (!researchTabTypes) return [];
    return defaultMainFilterTabs.filter(
      (data: { value: string; label: string }) =>
        researchTabTypes.includes(data.value)
    );
  }, [researchTabTypes]);

  const filterDateIntervalValues = useMemo(() => {
    let dateResult = '';
    if (watchDateCreatedGTEFilter)
      dateResult = format(new Date(watchDateCreatedGTEFilter), 'dd.MM.yyyy');
    if (watchDateCreatedLTEFilter)
      dateResult = format(new Date(watchDateCreatedLTEFilter), 'dd.MM.yyyy');
    if (watchDateCreatedGTEFilter && watchDateCreatedLTEFilter)
      dateResult = `
    ${format(new Date(watchDateCreatedGTEFilter), 'dd.MM.yyyy')} - ${format(
        new Date(watchDateCreatedLTEFilter),
        'dd.MM.yyyy'
      )}`;
    return dateResult;
  }, [watchDateCreatedGTEFilter, watchDateCreatedLTEFilter]);

  const filterExecutorValues = useMemo(() => {
    let optionsData =
      executorsList?.map((ulid: string) => ({
        value: ulid,
        label: doctorWhoCompleted[ulid].shortName,
      })) ?? [];
    let filterList = optionsData.sort(
      (
        a: { value: string; label: string },
        b: { value: string; label: string }
      ) => {
        return (
          a.label.toLocaleLowerCase().charCodeAt(0) -
          b.label.toLocaleLowerCase().charCodeAt(0)
        );
      }
    );
    return filterList;
  }, [doctorWhoCompleted, executorsList]);

  //Advanced Filter Form
  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      let priority = data?.priority?.join() ?? '';
      let marker = data?.marker?.join() ?? '';
      let method = data?.method?.join() ?? '';
      let executor = data?.executor?.join() ?? '';
      let bio = data?.bio?.join() ?? '';

      let status = '';
      let cancelled = '';
      if (data?.status?.includes('referral_cancelled')) {
        cancelled = 'True';
        status =
          data?.status
            ?.filter((value: string) => value !== 'referral_cancelled')
            ?.join() ?? '';
      } else status = data?.status?.join() ?? '';

      let dateCreatedLte = data?.dateCreatedLte
        ? format(new Date(data.dateCreatedLte), 'yyyy-MM-dd')
        : '';
      let dateCreatedGte = data?.dateCreatedGte
        ? format(new Date(data.dateCreatedGte), 'yyyy-MM-dd')
        : '';

      dispatch(setResearchSearchQuery(''));
      dispatch(
        setResearchFilterQuery({
          dateCreatedGte,
          dateCreatedLte,
          status,
          priority,
          marker,
          method,
          executor,
          bio,
          cancelled,
        })
      );
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(resetResearchSearchData());
    setSearchInputvalue({ ...defaultSearchState });
    dispatch(
      setResearchPageSettings({ currentPage: 1, nextPage: 1, tab: 'all' })
    );
    dispatch(setResearchSearchQuery(''));
    dispatch(setResearchFilterQuery(defaultFilterState));

    setSearchFilter('');
    setActiveFilterItem('all');
    reset();
    setAllPanelKey(Date.now());
  }, [reset, dispatch, setAllPanelKey, setSearchFilter, setActiveFilterItem]);

  const filterOptionHandler = useCallback(
    (tabName: string) => {
      if (isSearchOptionSelected) return;
      setOpenFilterOption(tabName);
    },
    [setOpenFilterOption, isSearchOptionSelected]
  );

  const resetController = () => {};

  return (
    <>
      <Loader enabled={loading}>
        <PageContainer
          header={
            <THeader>
              <TLeftBlock>
                <PageTitle>Исследования</PageTitle>
                {!!hasFilterOrSearchDataSended && (
                  <TBackLink onClick={resetForm}>
                    <span>&lsaquo;</span> К списку исследований
                  </TBackLink>
                )}
              </TLeftBlock>
              <TRightBlock>
                <TInput
                  name='patient_full_name'
                  placeholder='Поиск по фамилии пациента'
                  iconLeft={
                    !searchFilterValue.includes('patient_full_name') ? (
                      <SearchIcon />
                    ) : (
                      <CrossBigIcon />
                    )
                  }
                  onChange={(v) => {
                    setSearchFilterValue(
                      searchFilterValue.replaceAll('patient_full_name', '')
                    );
                    return queryInputHandler(v);
                  }}
                  onKeyDown={onSearch}
                  onRightIconMouseDown={
                    searchFilterValue.includes('patient_full_name')
                      ? onClear
                      : onSearch
                  }
                  value={searchInputvalue['patient_full_name']}
                  width={382}
                  onFocus={() => {
                    if (isFilterOptionSelected) {
                      return resetForm();
                    }
                  }}
                />
                {/* </TRightBlock> */}

                <TSearchOptions
                  key={allPanelKey}
                  ref={nodeSearchOptions}
                  onClick={() => setSearchOptionsOpen(!searchOptionsOpen)}
                  active={searchOptionsOpen}
                >
                  <Filter />
                  <span>Фильтры</span>
                </TSearchOptions>

                <TSearchOptionsArea
                  open={searchOptionsOpen}
                  ref={nodeSearchOptionsArea}
                >
                  <TSearchOptionsAreaHeader>
                    <TSearchOptionsAreaHeaderText>
                      Расширенный поиск
                    </TSearchOptionsAreaHeaderText>
                    <TIcon
                      size={8}
                      onClick={() => setSearchOptionsOpen(!searchOptionsOpen)}
                    >
                      <CloseIcon />
                    </TIcon>
                  </TSearchOptionsAreaHeader>
                  <TSearchOptionsInputsWr>
                    <TInput
                      name='cypher'
                      label='По номеру биоматериала'
                      placeholder='Введите номер '
                      iconLeft={
                        !searchFilterValue.includes('cypher') ? (
                          <SearchIcon />
                        ) : (
                          <CrossBigIcon />
                        )
                      }
                      onChange={(v) => {
                        setSearchFilterValue(
                          searchFilterValue.replaceAll('cypher', '')
                        );
                        return queryInputHandler(v);
                      }}
                      onKeyDown={onSearch}
                      onRightIconMouseDown={
                        searchFilterValue.includes('cypher')
                          ? onClear
                          : onSearch
                      }
                      value={searchInputvalue['cypher']}
                      disabled={isFilterOptionSelected}
                    />
                    <TInput
                      name='blueprint_id'
                      label='По номеру исследования'
                      placeholder='Введите номер '
                      iconLeft={
                        !searchFilterValue.includes('blueprint_id') ? (
                          <SearchIcon />
                        ) : (
                          <CrossBigIcon />
                        )
                      }
                      onChange={(v) => {
                        setSearchFilterValue(
                          searchFilterValue.replaceAll('blueprint_id', '')
                        );
                        return queryInputHandler(v);
                      }}
                      onKeyDown={onSearch}
                      onRightIconMouseDown={
                        searchFilterValue.includes('blueprint_id')
                          ? onClear
                          : onSearch
                      }
                      value={searchInputvalue['blueprint_id']}
                      disabled={isFilterOptionSelected}
                    />
                  </TSearchOptionsInputsWr>

                  <TFilterDetailsWr
                    onSubmit={handleSubmit(onSubmit)}
                    key={filterFormKey}
                  >
                    <TFilterDetails
                      id='dateCreatedTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('dateCreated')}
                      ref={nodeFilterDateContent}
                    >
                      <TFilterTitle>
                        <span>Дата создания</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'dateCreated' &&
                          searchOptionsOpen
                        }
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <ControllerWrapper>
                          <div>
                            <span>C..</span>
                            <Controller
                              control={control}
                              name='dateCreatedGte'
                              render={({ field: { onChange, value } }) => (
                                <TInputDayPicker
                                  date={value}
                                  onChange={onChange}
                                  popperPlacement='bottom'
                                  placeholderText='С ...'
                                  filterDate={disableFutureDays}
                                  bordered
                                />
                              )}
                            />
                          </div>
                          <div>
                            <span>По..</span>
                            <Controller
                              control={control}
                              name='dateCreatedLte'
                              render={({ field: { onChange, value } }) => (
                                <TInputDayPicker
                                  date={value}
                                  onChange={onChange}
                                  popperPlacement='bottom'
                                  placeholderText='По ...'
                                  filterDate={disableFutureDays}
                                  bordered
                                />
                              )}
                            />
                          </div>
                        </ControllerWrapper>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      <TFilterResultItem>
                        {filterDateIntervalValues}
                      </TFilterResultItem>
                    </TFilterResultList>

                    <TFilterDetails
                      id='bioTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('bio')}
                      ref={nodeFilterBioContent}
                    >
                      <TFilterTitle>
                        <span>Биоматериал</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={openFilterOption === 'bio' && searchOptionsOpen}
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr>
                          {bioFields.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('bio')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchBioFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {dataValuesMap?.[value] ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    <TFilterDetails
                      id='methodTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('method')}
                      ref={nodeFilterMethodContent}
                    >
                      <TFilterTitle>
                        <span>Метод</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'method' && searchOptionsOpen
                        }
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr>
                          {methodFields.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('method')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchMethodFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {dataValuesMap?.[value] ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    <TFilterDetails
                      id='markerTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('marker')}
                      ref={nodeFilterMarkerContent}
                    >
                      <TFilterTitle>
                        <span>Маркер</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'marker' && searchOptionsOpen
                        }
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr className='gridColumns'>
                          {markerNamesList.map((value: string) => {
                            return (
                              <label key={value} className='container'>
                                {value}
                                <input
                                  {...register('marker')}
                                  type='checkbox'
                                  value={value}
                                />
                                <span className='checkmark'></span>
                              </label>
                            );
                          })}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchMarkerFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {value ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    <TFilterDetails
                      id='executorTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('executor')}
                      ref={nodeFilterExecutorContent}
                    >
                      <TFilterTitle>
                        <span>Исполнитель</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'executor' && searchOptionsOpen
                        }
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr className='gridColumns'>
                          {filterExecutorValues.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('executor')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchExecutorFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {doctorWhoCompleted?.[value]?.shortName ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    <TFilterDetails
                      id='statusTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('status')}
                      ref={nodeFilterStatusContent}
                    >
                      <TFilterTitle>
                        <span>Статус</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'status' && searchOptionsOpen
                        }
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr>
                          {statusFields.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('status')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {watchStatusFilter?.map((value: string) => (
                        <TFilterResultItem key={value}>
                          {dataValuesMap?.[value] ?? ''}
                        </TFilterResultItem>
                      ))}
                    </TFilterResultList>

                    <TFilterDetails
                      id='priorityTab'
                      disabled={isSearchOptionSelected}
                      onClick={() => filterOptionHandler('priority')}
                      ref={nodeFilterPriorityContent}
                    >
                      <TFilterTitle>
                        <span>Приоритет</span>
                        <ArrowIcon />
                      </TFilterTitle>
                      <TFilterOptionsInFlow
                        isOpen={
                          openFilterOption === 'priority' && searchOptionsOpen
                        }
                      >
                        <TFilterButtonReset
                          type='reset'
                          onClick={resetController}
                        >
                          Сбросить
                        </TFilterButtonReset>
                        <TCheckboxWr>
                          {priorityFields.map(
                            (data: { value: string; label: string }) => {
                              return (
                                <label key={data.value} className='container'>
                                  {data.label}
                                  <input
                                    {...register('priority')}
                                    type='checkbox'
                                    value={data.value}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              );
                            }
                          )}
                        </TCheckboxWr>
                      </TFilterOptionsInFlow>
                    </TFilterDetails>
                    <TFilterResultList>
                      {priorityFields
                        ?.filter((data: { value: string; label: string }) =>
                          watchPriorityFilter.includes(data.value)
                        )
                        ?.map((data: { value: string; label: string }) => (
                          <TFilterResultItem key={data.value}>
                            {data.label}
                          </TFilterResultItem>
                        ))}
                    </TFilterResultList>

                    <TButtonBlock>
                      <TFilterButton
                        isMain={true}
                        type='submit'
                        disabled={
                          !isFilterOptionSelected || isSearchOptionSelected
                        }
                      >
                        Применить
                      </TFilterButton>
                      <TFilterButton type='reset' onClick={resetForm}>
                        Сбросить{' '}
                      </TFilterButton>
                    </TButtonBlock>
                  </TFilterDetailsWr>
                  <TWarningMsg>
                    <div>
                      “Расширенный поиск” осуществляется по всем доступным вам
                      Исследованиям.
                    </div>
                    <div>“Фильтры” работают на заданной вкладке.</div>
                    <div>
                      Данные функциональности работают изолированно друг от
                      друга.
                    </div>
                  </TWarningMsg>
                </TSearchOptionsArea>
              </TRightBlock>
            </THeader>
          }
        >
          <TFilterWr>
            {researchTabs.map((data: { value: string; label: string }) => (
              <TFilterItem
                key={data.value}
                isActive={activeFilterItem === data.value}
                onClick={() => setActiveFilterItem(data.value)}
              >
                {data.label}
              </TFilterItem>
            ))}
          </TFilterWr>
          {!researchList.length &&
            !loading &&
            (!!isSearchOptionSelected || !!isFilterOptionSelected) && (
              <EmptyResearchMessageBlock>
                К сожалению, по Вашему запросу ничего не найдено
              </EmptyResearchMessageBlock>
            )}
          {!researchList.length &&
            !loading &&
            !isSearchOptionSelected &&
            !isFilterOptionSelected && (
              <EmptyResearchMessageBlock>
                На данный момент здесь нет ни одной записи
              </EmptyResearchMessageBlock>
            )}
          {!!researchList.length && !loading && (
            <TTable
              items={researchList}
              totalPages={researchTotalPages}
              columns={researchTableColumns(doctorWhoCompleted ?? {})}
              goToPage={goToPage}
              onClick={goToResearch}
              currentPage={currentPage}
              headerColor={Colors.GREEN[100]}
              textColor={Colors.MONOCHROM[950]}
            />
          )}
        </PageContainer>
      </Loader>
    </>
  );
};

export default ResearchPage;
