import React from 'react';
import styled from 'styled-components';

// Определяем тип для пропсов
interface CancelRequestMessageProps {
  dateCreated?: string;
  user?: string;
  comment: string;
}

// Стилизованный блок для сообщения
const TCancelRequestBlock = styled.div<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 14px;

  b {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }

  span {
    display: block;
    margin-bottom: 4px;

    i {
      font-style: italic;
      color: #666;
    }
  }
`;

export const CancelRequestMessage: React.FC<CancelRequestMessageProps> = ({
  dateCreated,
  user,
  comment,
}) => {
  return (
    <TCancelRequestBlock marginLeft={1}>
      <b>Запрос на отмену направления.</b>
      <span>
        <i>Дата формирования запроса: </i> {dateCreated}
      </span>
      <span>
        <i>Инициатор: </i> {user}
      </span>
      <span>
        <i>Причина: </i> {comment}
      </span>
    </TCancelRequestBlock>
  );
};
