import styled from 'styled-components';
import Paginate from 'react-paginate';

export const TPaginate = styled(Paginate)`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 20px 0 20px;
  padding: 0;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;

  & > li > a {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BCBFCD;
    position: relative;
    padding: 0 4px 6px;
    margin-right: 12px;

    &::after {
      content: '';
      position: absolute;
      background-color: transparent;
      height: 1px;
      border-radius: 10px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  & .selected > a {
    color: #8A8FA8;
    
    &::after {
      background-color: #8A8FA8;
    }
  }

  & > li:last-child a {
    margin-right: 0;
  }

`;

export const TPage = styled.span<{ active: boolean }>`
  
`;
