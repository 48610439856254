import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import { Colors } from "../../../../../../styles/themeNew";
import { ArrowRight } from "./icons/Arrow";
import { MeatballsMenuDefault } from "./icons/MeatballsMenu";
import { AddBlockIcon } from "./icons/AddBlock";
import { RemoveBlockIcon } from "./icons/RemoveBlock";
import { ClearBlockIcon } from "./icons/ClearBlock";
import {
  blocksSelector,
  addBlock,
  addSlide,
  removeBlock,
  removeSlide,
  updateBlockOrgan,
  updateBlockCypher,
  updateSlideCypher,
  clearBlockByIndex,
} from "../../../../redux/biomaterialRegistration";

const INPUT_AREA__BLOCK = "blockArea";
const INPUT_AREA__SLIDE = "slideArea";

type InputAreaType = typeof INPUT_AREA__BLOCK | typeof INPUT_AREA__SLIDE;

const BLOCKS_COLOR = '#CBECFE';
const SLIDES_COLOR = '#D9E4FF';


export const HistologicalMaterial: React.FC = () => {
  const popupRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const blocks = useSelector(blocksSelector);

  const [selectedInputArea, setSelectedInputArea] = useState<InputAreaType>(INPUT_AREA__BLOCK);
  const [popupVisible, setPopupVisible] = useState<{ block: number | null, slide: number | null }>({ block: null, slide: null });

  const handleAddItem = (inputArea: InputAreaType, blockIndex: number, slideIndex: number) => {
    if (inputArea === INPUT_AREA__BLOCK) {
      if (blocks.length < 5) {
        dispatch(addBlock(blockIndex));
        setPopupVisible({ block: null, slide: null });
      }
    } else {
      dispatch(addSlide(blockIndex));
      setPopupVisible({ block: null, slide: null });
    }
  };

  const handleRemoveItem = (inputArea: InputAreaType, blockIndex: number, slideIndex: number) => {
    if (inputArea === INPUT_AREA__BLOCK) {
      if (blocks.length > 1) {
        dispatch(removeBlock(blockIndex));
        setPopupVisible({ block: null, slide: null });
      }
    } else { 
      if (slideIndex !== 0) {
        dispatch(removeSlide({ blockIndex, slideIndex }));
        setPopupVisible({ block: null, slide: null });
      }
    }
  };

  const handleClearItem = (inputArea: InputAreaType, blockIndex: number, slideIndex: number) => {
    if (inputArea === INPUT_AREA__BLOCK) {
      dispatch(clearBlockByIndex(blockIndex));
    } else { 
      dispatch(updateSlideCypher({blockIndex, slideIndex, cypher: ""}));
    }
    setPopupVisible({ block: null, slide: null });
  };

  const renderPopup = (inputArea: InputAreaType, blockIndex: number, slideIndex: number) => (
    <StyledPopup ref={popupRef} isGlass={inputArea === INPUT_AREA__SLIDE}>
      <StyledPopupItem color={(blocks.length >= 5 ? Colors.GRAY[500] : '')} onClick={() => handleAddItem(inputArea, blockIndex, slideIndex)}>
        {inputArea === INPUT_AREA__SLIDE ? "Добавить Гист. стекло к данному блоку" : "Добавить блок"} <AddBlockIcon color={(blocks.length >= 5 ? Colors.GRAY[500] : 'black')} />
      </StyledPopupItem>
      <StyledPopupItem color={(blocks.length === 1 || slideIndex === 0 ? Colors.GRAY[500] : '')} onClick={() => handleRemoveItem(inputArea, blockIndex, slideIndex)}>
        {inputArea === INPUT_AREA__SLIDE ? "Удалить Гист. стекло" : "Удалить блок"} <RemoveBlockIcon color={(blocks.length === 1 || slideIndex === 0 ? Colors.GRAY[500] : 'black')} />
      </StyledPopupItem>
      <StyledPopupItem onClick={() => handleClearItem(inputArea, blockIndex, slideIndex)}>
        Очистить <ClearBlockIcon />
      </StyledPopupItem>
    </StyledPopup>
  );

  const handleSelectInputArea = (inputArea: InputAreaType) => {
    setSelectedInputArea(inputArea);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setPopupVisible({ block: null, slide: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <StyledContainer>
      <TTabPanel>
        <StyledHistologicalBtn
          selected={selectedInputArea === INPUT_AREA__BLOCK}
          inputArea={INPUT_AREA__BLOCK}
          onClick={() => handleSelectInputArea(INPUT_AREA__BLOCK)}
        >
          1. Блоки *
        </StyledHistologicalBtn>
        <StyledHistologicalBtn
          selected={selectedInputArea === INPUT_AREA__SLIDE}
          inputArea={INPUT_AREA__SLIDE}
          onClick={() => handleSelectInputArea(INPUT_AREA__SLIDE)}
        >
          2. Гист. стекла *
        </StyledHistologicalBtn>
      </TTabPanel>

      {selectedInputArea === INPUT_AREA__BLOCK ?
        (blocks.map((block, index) => {
          return (
            <StyledBlockContainer key={index}>
              <StyledInput
                type="text"
                placeholder="Орган"
                value={block?.organ || ""}
                onChange={(e) => dispatch(updateBlockOrgan({ index, organ: e.target.value }))}
              />
              <ArrowRight />
              <StyledInput
                type="text"
                placeholder="Номер"
                value={block?.cypher || ""}
                backgroundColor={BLOCKS_COLOR}
                onChange={(e) => {
                  dispatch(updateBlockCypher({ index, cypher: e.target.value }));
                  dispatch(updateSlideCypher({ blockIndex: index, slideIndex: 0, cypher: e.target.value }));
                }}
              />
              <StyledOptionsButtonContainer>
                <StyledOptionsButton onClick={() => setPopupVisible({ ...popupVisible, block: index })}>
                  <MeatballsMenuDefault />
                </StyledOptionsButton>
                {popupVisible.block === index && renderPopup(selectedInputArea, index, 0)}
              </StyledOptionsButtonContainer>
            </StyledBlockContainer>
          );
        }))
        :
        (blocks.map((block, blockIndex) => {
          return (
            <React.Fragment key={blockIndex}>
              <StyledBlockContainer>
                <StyledInput
                  type="text"
                  placeholder="Номер"
                  value={block.cypher || ""}
                  backgroundColor={BLOCKS_COLOR}
                  disabled={true}
                />
                <ArrowRight />
                <StyledInput
                  type="text"
                  placeholder="Номер"
                  value={block.slides?.[0]?.cypher || ""}
                  backgroundColor={SLIDES_COLOR}
                  onChange={(e) => dispatch(updateSlideCypher({ blockIndex, slideIndex: 0, cypher: e.target.value }))}
                />
                <StyledOptionsButtonContainer>
                  <StyledOptionsButton onClick={() => setPopupVisible({ ...popupVisible, block: blockIndex, slide: 0 })}>
                    <MeatballsMenuDefault />
                  </StyledOptionsButton>
                  {popupVisible.block === blockIndex && popupVisible.slide === 0 && renderPopup(selectedInputArea, blockIndex, 0)}
                </StyledOptionsButtonContainer>
              </StyledBlockContainer>
              {block.slides.slice(1).map((slide, slideIndex0) => {
                const slideIndex = slideIndex0 + 1;
                return (
                  <StyledBlockContainer key={slideIndex}>
                    <StyledInput
                      type="text"
                      value={""}
                      border='none'
                      backgroundColor='white'
                      disabled={true}
                    />
                    <ArrowRight color="white" />
                    <StyledInput
                      type="text"
                      placeholder="Номер"
                      value={slide.cypher || ""}
                      backgroundColor={SLIDES_COLOR}
                      onChange={(e) => dispatch(updateSlideCypher({ blockIndex, slideIndex, cypher: e.target.value }))}
                    />
                    <StyledOptionsButtonContainer>
                      <StyledOptionsButton onClick={() => setPopupVisible({ ...popupVisible, block: blockIndex, slide: slideIndex })}>
                        <MeatballsMenuDefault />
                      </StyledOptionsButton>
                      {popupVisible.block === blockIndex && popupVisible.slide === slideIndex && renderPopup(selectedInputArea, blockIndex, slideIndex)}
                    </StyledOptionsButtonContainer>
                  </StyledBlockContainer>

                )
              })}
            </React.Fragment>
          )
        }))
      }
    </StyledContainer>
  );
};

const StyledHistologicalBtn = styled.div<{
  selected?: boolean;
  inputArea?: InputAreaType;
}>`
  display: flex;
  width: 260px;
  height: 45px;
  cursor: pointer;
  border: 1px solid rgb(216, 219, 223);
  box-sizing: border-box;
  border-radius: 10px;
  background-color:  ${(props) => props.selected ? (props.inputArea === INPUT_AREA__BLOCK ? BLOCKS_COLOR : SLIDES_COLOR) : 'white'};
  justify-content: center;
  align-items: center;
`;

const TTabPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width:100%;
  border: 1px solid ${Colors.MONOCHROM[200]};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`;

const StyledBlockContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input<
  { backgroundColor?: string; border?: string }
>`
  flex: 1;
  padding: 10px;
  border: ${(props) => props.border ? `${props.border}` : `1px solid ${Colors.MONOCHROM[200]}`};
  font-size: 14px;
  background-color: ${(props) => props.backgroundColor || "white"};
`;

const StyledOptionsButtonContainer = styled.div`
  position: relative;
`;

const StyledOptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${Colors.MONOCHROM[950]};
`;

const StyledPopup = styled.div<{
  isGlass: boolean;
}>`
  position: absolute;
  top: 0px;
  left: 0;
  background: white;
  width:  ${(props) => props.isGlass ? '370px' : '260px'};
  border: 1px solid ${Colors.MONOCHROM[200]};
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const StyledPopupItem = styled.div<{ color?: string }>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.color || "inherit"};
  &:hover {
    background: ${Colors.BRAND[50]};
  }
`;
