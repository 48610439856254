import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../../../store';

export interface AnalisysData {
  antibodyClone: string;
  protocol: string;
  score: number | null;
  positivityThreshold: number | null;
  percentageTumorCells: number | null;
  researchResult: number | null;
  commentary: string;
}

const initialState: AnalisysData = {
  antibodyClone: '',
  protocol: '',
  score: null,
  positivityThreshold: null,
  percentageTumorCells: null,
  researchResult: null,
  commentary: '',
};

const analisysNew = createSlice({
  name: 'analisysNew',
  initialState,
  reducers: {
    setAntibodyClone: (state, action: PayloadAction<string>) => {
      state.antibodyClone = action.payload;
    },
    setProtocol: (state, action: PayloadAction<string>) => {
      state.protocol = action.payload;
    },
    setScore: (state, action: PayloadAction<number | null>) => {
      state.score = action.payload;
    },
    setPositivityThreshold: (state, action: PayloadAction<number | null>) => {
      state.positivityThreshold = action.payload;
    },
    setPercentageTumorCells: (state, action: PayloadAction<number | null>) => {
      state.percentageTumorCells = action.payload;
    },
    setResearchResult: (state, action: PayloadAction<number | null>) => {
      state.researchResult = action.payload;
    },
    setCommentary: (state, action: PayloadAction<string>) => {
      state.commentary = action.payload;
    },
  },
});

export const {
  setAntibodyClone,
  setProtocol,
  setScore,
  setPositivityThreshold,
  setPercentageTumorCells,
  setResearchResult,
  setCommentary,
} = analisysNew.actions;

export const analisysNewReducer = analisysNew.reducer;

export const getAnalisysNewState = (state: IAppState) =>
  state.molecularProfilingDetails.analisysNew;

export const antibodyCloneSelector = createSelector(
  getAnalisysNewState,
  (state) => state.antibodyClone
);

export const protocolSelector = createSelector(
  getAnalisysNewState,
  (state) => state.protocol
);

export const scoreSelector = createSelector(
  getAnalisysNewState,
  (state) => state.score
);

export const positivityThresholdSelector = createSelector(
  getAnalisysNewState,
  (state) => state.positivityThreshold
);

export const percentageTumorCellsSelector = createSelector(
  getAnalisysNewState,
  (state) => state.percentageTumorCells
);

export const researchResultSelector = createSelector(
  getAnalisysNewState,
  (state) => state.researchResult
);

export const commentarySelector = createSelector(
  getAnalisysNewState,
  (state) => state.commentary
);