import { FC, useCallback, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { TDetailsContent } from "../../../../../../components/shared/Details/styled";
import { IAnalisysByMethod, Localization } from "../../../../../../store/analysis/model";
import defaultTheme from "../../../../../../styles/theme";
import {
  TWrapper,
  TButtonWrapper,
  CreateExperimentMainButton,
  TLocalizationWR,
} from "../../../styled";
import { TEditButton } from "../../../../MarkersValidation/styled";
import { TSectionTitle } from "../../../styled";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../../../../../store/auth/selectors";
import { fetchAnalysisByBluprint, patchAnalisisExperementUpdate, resetAnalysisByBluprint } from "../../../../../../store/analysis/thunkActions";
import {customStylesFishLocalisationOptions} from "./styles";
import { InfoRowWrapper } from '../../../../styled';


interface IComponentProps {
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  isActive: boolean;
  hasPermit: boolean;
  [index: string]: any;
}
interface ISelectOptions {
  value: string | number;
  label: string;
}

const FishLocalization: FC<IComponentProps> = ({ analisysByMethod, isActive, hasPermit }) => {
  const { control, handleSubmit, formState: { errors }, reset } = useForm();
  const tokens = useSelector(getTokens);
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [localizationOptions, setLocalizationOptions] = useState<ISelectOptions[]>([]);

  useLayoutEffect(() => {
    if(analisysByMethod?.markerFish?.localizations){
      let localizOptions:ISelectOptions[] = analisysByMethod?.markerFish?.localizations?.map((val:Localization) => ({value:val.id, label:val.name}));
      if(localizOptions?.length) setLocalizationOptions(localizOptions);
    }
    
  }, [analisysByMethod, setLocalizationOptions]);
  

  //handle edit tab button
  const handleEditForm = useCallback(async () => {
    if (!isActive || !hasPermit) return;
    if (isEdit) {
      await reset({}, { keepValues: false })
      await setResetFormKey(Date.now())
      return setEdit(false);
    }
    setEdit(true)
  }, [isEdit, setEdit, isActive, reset, setResetFormKey, hasPermit]);

  const onSubmitTable = useCallback(async (data: any) => {
    let blueprint = analisysByMethod?.blueprint;
    let localization = data?.localizationSelect?.value;

    if(tokens?.access && localization){
      await handleEditForm();
      await dispatch(patchAnalisisExperementUpdate(tokens?.access,blueprint,{localization},'fish'));
      await dispatch(resetAnalysisByBluprint());
      dispatch(fetchAnalysisByBluprint(tokens?.access,'fish',blueprint));

      setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'fish', blueprint))
      ,300);
    }
  }, [tokens?.access,dispatch, analisysByMethod,handleEditForm]);

  return (
    <TDetailsContent>
      <InfoRowWrapper direction={hasPermit ? "space-between" : "flex-start"}>
        <TSectionTitle width="60%" color={true ? "#7A78E9" : "#777"}>Локализация</TSectionTitle>
        {hasPermit && <TEditButton disabled={!isActive} onClick={handleEditForm}>
          {!isEdit ? 'Редактировать' : 'Отменить'}
        </TEditButton>}
      </InfoRowWrapper>
      <TWrapper onSubmit={handleSubmit(onSubmitTable)} key={resertFormKey}>
        <TLocalizationWR error={!!errors?.['localizationSelect']}>
        <Controller
          control={control}
          name={'localizationSelect'}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={onChange}
              selected={value}
              options={localizationOptions}
              placeholder={'--'}
              styles={customStylesFishLocalisationOptions(defaultTheme)}
              isDisabled={!isEdit}
              noOptionsMessage={() => "нет опций"}
              isSearchable={false}
              components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}

            />
            )}
        />
        <TButtonWrapper>
           {hasPermit && <CreateExperimentMainButton type="submit" disabled={!isEdit}>
              Сохранить изменения
            </CreateExperimentMainButton>}
          </TButtonWrapper>
        </TLocalizationWR>
      </TWrapper>
    </TDetailsContent>)
}

export default FishLocalization;