import styled, { css } from 'styled-components';
import { Colors } from '../../styles/themeNew';
import { useState, useRef, useEffect } from 'react';

export type OptionType = { label: string; value: string; selected: boolean };

type Props = {
  title?: string;
  placeholder?: string;
  required?: boolean;
  options: OptionType[];
  onSelect: (option: { label: string; value: string }) => void;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  optionWidth?: string;
};

const styleMap = {
  small: {
    fontSize: '12px',
    border: 'none',
    height: '34px',
    padding: '0',
    paddingOption: '0 6px',
    top: '34px',
    selectedOptionColor: Colors.BRAND[50],
  },
  medium: {
    fontSize: '16px',
    border: `1px solid ${Colors.MONOCHROM[200]}`,
    padding: '16px 14px',
    height: '50px',
    paddingOption: '6px 16px',
    top: '58px',
    selectedOptionColor: Colors.BRAND[50],
  },
  large: {
    fontSize: '16px',
    border: `1px solid ${Colors.MONOCHROM[200]}`,
    padding: '18px 16px',
    height: '60px',
    paddingOption: '6px 16px',
    top: '68px',
    selectedOptionColor: Colors.BRAND[50],
  },
};

export const Select = (props: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const selectedOption = props.options.find((option) => option.selected);
  const size = props.size || 'large';

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <StyledContainer
      onClick={() => {
        if (props.disabled) {
          return;
        } else {
          setDropdownVisible((value) => !value);
        }
      }}
      ref={dropdownRef}
    >
      {props.title && (
        <StyledLabel htmlFor='textarea'>
          {props.title}{' '}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledLabel>
      )}

      <StyledMainContainer disabled={props.disabled} size={size}>
        {!Boolean(selectedOption) && props.placeholder && (
          <StyledPlaceholderContainer size={size}>
            {props.placeholder}
          </StyledPlaceholderContainer>
        )}

        {Boolean(selectedOption) && (
          <StyledSelectedOptionContainer disabled={props.disabled} size={size}>
            {selectedOption?.label}
          </StyledSelectedOptionContainer>
        )}

        <StyledIconContainer $isOpen={dropdownVisible}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.2081 14.6224C11.6008 15.1259 12.3992 15.1259 12.7919 14.6224L16.8074 9.47397C17.2843 8.86257 16.8209 8 16.0156 8H7.98442C7.17909 8 6.71569 8.86257 7.19256 9.47397L11.2081 14.6224Z'
              fill='#25272C'
            />
          </svg>
        </StyledIconContainer>
      </StyledMainContainer>

      {dropdownVisible && (
        <StyledDropdownContainer
          withTitle={Boolean(props.title)}
          size={size}
          width={props.optionWidth ? props.optionWidth : '100%'}
        >
          {props.options.map((option) => (
            <StyledOptionContainer
              key={String(option.value)}
              selected={option.selected}
              onClick={() => props.onSelect(option)}
              size={size}
            >
              {option.label}
            </StyledOptionContainer>
          ))}
        </StyledDropdownContainer>
      )}
    </StyledContainer>
  );
};

const getBackgroundColor = (disabled?: boolean) => {
  if (disabled) {
    return Colors.MONOCHROM[100];
  }

  return 'transparent';
};

// Стандартные стили
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`;

const StyledPlaceholderContainer = styled.div<{
  size: 'large' | 'medium' | 'small';
}>`
  color: ${Colors.MONOCHROM[400]};
  font-size: ${(props) => styleMap[props.size].fontSize};
  font-weight: 400;
`;

const StyledIconContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    `}

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transform: rotate(0deg);
      transition: transform 0.2s ease-in-out;
    `}
`;

const StyledMainContainer = styled.div<{
  disabled?: boolean;
  size: 'large' | 'medium' | 'small';
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.disabled ? Colors.MONOCHROM[100] : 'transparent'};
  width: 100%;
  padding: ${(props) => styleMap[props.size].padding};
  border-radius: 6px;
  border: ${(props) => styleMap[props.size].border};
  height: ${(props) => styleMap[props.size].height};
  box-sizing: border-box;
`;

const StyledDropdownContainer = styled.div<{
  withTitle: boolean;
  size: 'large' | 'medium' | 'small';
  width: string,
}>`
  position: absolute;
  z-index: 5;
  width: ${(props) => props.width};
  top: ${(props) => (props.withTitle ? '88px' : styleMap[props.size].top)};
  left: 0;
  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: ${Colors.OTHER.white};
  box-sizing: border-box;
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 200px;
`;

const StyledOptionContainer = styled.div<{
  size: 'large' | 'medium' | 'small';
  selected: boolean;
}>`
  background: transparent;
  color: ${Colors.MONOCHROM[950]};

  &:hover {
    background: ${Colors.BRAND[50]};
    color: ${Colors.MONOCHROM[950]};
  }

  background: ${(props) => props.selected && styleMap[props.size].selectedOptionColor};
  padding: ${(props) => styleMap[props.size].paddingOption};
  min-height: 40px;
  font-size: ${(props) => styleMap[props.size].fontSize};
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
`;

const StyledSelectedOptionContainer = styled.div<{
  size: 'large' | 'medium' | 'small';
  disabled?: boolean;
}>`
  color: ${Colors.MONOCHROM[950]};
  background: ${(props) => getBackgroundColor(props.disabled)};
  font-size: ${(props) => styleMap[props.size].fontSize};
  font-weight: 400;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  gap: 4px;
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;
