import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppState } from '../../../store';

interface ConsiliumState {
  selectedAdvisor: string;
  manualFullName: string;
  manualSpecialization: string;
}

const initialState: ConsiliumState = {
  selectedAdvisor: '',
  manualFullName: '',
  manualSpecialization: '',
};

const consiliumSlice = createSlice({
  name: 'consilium',
  initialState,
  reducers: {
    setSelectedAdvisor: (state, action: PayloadAction<string>) => {
      state.selectedAdvisor = action.payload;
    },
    setManualFullName: (state, action: PayloadAction<string>) => {
      state.manualFullName = action.payload;
    },
    setManualSpecialization: (state, action: PayloadAction<string>) => {
      state.manualSpecialization = action.payload;
    },
    resetAdvisorForm: () => initialState,
  },
});

export const {
  setSelectedAdvisor,
  setManualFullName,
  setManualSpecialization,
  resetAdvisorForm,
} = consiliumSlice.actions;

export const consiliumReducer = consiliumSlice.reducer;

export const getConsiliumState = (state: IAppState) =>
  state.molecularProfilingDetails.consilium;

export const selectedAdvisorSelector = createSelector(
  getConsiliumState,
  (state) => state.selectedAdvisor
);

export const manualFullNameSelector = createSelector(
  getConsiliumState,
  (state) => state.manualFullName
);

export const manualSpecializationSelector = createSelector(
  getConsiliumState,
  (state) => state.manualSpecialization
);
