import { createSlice, createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../../../store';

export interface MenuState {
  isMenuOpen: boolean;
}

const initialState: MenuState = {
  isMenuOpen: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isMenuOpen = true;
    },
    closeMenu: (state) => {
      state.isMenuOpen = false;
    },
  },
});

export const { openMenu, closeMenu } = menuSlice.actions;

export const menuReducer = menuSlice.reducer;

export const getMenuState = (state: IAppState) => state.menu;

export const isMenuOpenSelector = createSelector(
  getMenuState,
  (state) => state.isMenuOpen
);
