import { optionsQuality, optionsSingsViolation } from './data';

export const getSingsViolationLabel = (value: string | undefined) => {
  return (
    optionsSingsViolation.find((option) => option.value === value)?.label ||
    'Неизвестно'
  );
};
export const getQualityLabel = (value: string | undefined) => {
  return (
    optionsQuality.find((option) => option.value === value)?.label ||
    '--'
  );
};

export const checkTableData = function (tableData: any[], description: string) {
  let tableIsComplite: boolean[] = [];
  let tableFields =
    tableData?.map((data: any) => {
      let tableRow = { ...data };
      if (tableRow.cancerousCellPercentage === 0 && tableRow.quality === 'none')
        tableRow.cancerousCellPercentage = null;
      if (Number.isNaN(tableRow.cancerousCellPercentage))
        tableRow.cancerousCellPercentage = null;
      if (
        tableRow?.cancerousCellPercentage >= 0 &&
        (tableRow?.quality ?? '') === 'bad' &&
        (tableRow?.priority ?? -1) === 0 &&
        !!description
      )
        tableIsComplite.push(true);
      if (
        tableRow?.cancerousCellPercentage >= 0 &&
        (tableRow?.quality ?? '') === 'good' &&
        (tableRow?.priority ?? -1) >= 0
      )
        tableIsComplite.push(true);
      return tableRow;
    }) ?? [];
  let isTableIsComplite =
    !!tableIsComplite?.length &&
    !!tableIsComplite?.every((val: boolean) => !!val);
  let normalizedTable = tableFields;
  return { isTableIsComplite, normalizedTable };
};