import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '..';

const getState = (state: IAppState) => state.molecularProfiling;

export const getMolecularProfiling = createSelector(
  getState,
  (state) => state.data
);
export const getMolecularProfilingErrors = createSelector(
  getState,
  (state) => state.errors
);
export const getMolecularProfilingLoading = createSelector(
  getState,
  (state) => state.loading
);

export const getMolProMarkerUpdatedList = createSelector(
  getState,
  (state) => state.markersListUpdate
);

export const getMolProPatientReferrals = createSelector(
  getState,
  (state) => state.patientRefferals
);
export const getMolProPatientReferralsData = createSelector(
  getMolProPatientReferrals,
  (patientRefferals) => patientRefferals?.data ?? []
);

export const registryMarkerList = createSelector(
  getState,
  (state) => state.registryMarkerList
);

export const registryMarkerNames = createSelector(getState, (state) => {
  const data = state.registryMarkerList ?? [];
  const markerCollection: Set<string> = new Set();

  for (let marker of data) {
    markerCollection.add(marker.name);
  }

  return Array.from(markerCollection).sort(
    (a: string, b: string) =>
      a.toLocaleLowerCase().charCodeAt(0) - b.toLocaleLowerCase().charCodeAt(0)
  );
});

export const getUpdatedBlueprints = createSelector(
  getState,
  (state) => state.examinationBlueprintsUpdated
);

export const getCreatedSubBlocks = createSelector(
  getState,
  (state) => state.createdSubBlockList
);

export const getComplitedReferralNumber = createSelector(
  getState,
  (state) => state.сomplitedReferralNumber
);

export const getIsReferralReadyToComplite = createSelector(
  getState,
  (state) => state.referralIsRedyToComplite
);