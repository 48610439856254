import styled from 'styled-components';
import Tooltip from '../../../components/shared/Tooltip';
import { CSSProperties } from "react";
import { defaultThemeType } from "../../../styles/theme";
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as CheckIcon } from '../../../icons/check.svg';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow-select.svg';


export const TMorphologicalCharacteristicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  td {
    text-align: center;
  }

  table{
    table-layout: fixed;
    width: 100%;
  }
  td{
    width: 25%;
  }
  .error{
    border: 1px solid ${({ theme }) => theme.colors.alert.warning};
    /* border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    will-change: auto;
    animation: error_border 10s normal linear;
    -webkit-animation: error_border 10s normal linear; 
    -moz-animation:    error_border 10s normal linear;
    -o-animation:      error_border 10s normal linear; */
  }

  @keyframes error_border {
    from {border-color: ${({ theme }) => theme.colors.gray[100]};}
    50% { border-color: ${({ theme }) => theme.colors.alert.warning};}
    to {border-color: ${({ theme }) => theme.colors.gray[100]};}
  }

  @-webkit-keyframes error_border {
    from {border-color: ${({ theme }) => theme.colors.gray[100]};}
    50% { border-color: ${({ theme }) => theme.colors.alert.warning};}
    to {border-color: ${({ theme }) => theme.colors.gray[100]};}
  }

  @-moz-keyframes error_border {
    from {border-color: ${({ theme }) => theme.colors.gray[100]};}
    50% { border-color: ${({ theme }) => theme.colors.alert.warning};}
    to {border-color: ${({ theme }) => theme.colors.gray[100]};}
  }
`
export const TRowWr = styled.div<{ direction?: string }>`
  display: flex;
  justify-content:  ${({ direction }) => direction ? direction : 'space-between'};
  width: 100%;
  padding-bottom: 16px;
`

export const TEditButton = styled.button<{ active?: boolean, nocolored?: boolean,warning?: boolean }>`
  width: max-content;
  padding: 0 10px;
  margin-right: 16px;
  height: 20px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme, nocolored }) => nocolored ? theme.colors.gray[400] : theme.colors.white};
  background-color: ${({ theme, nocolored }) => nocolored ? theme.colors.gray[100] : theme.colors.blue[200]};
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  border: 1px solid ${({ theme, nocolored }) => nocolored ? theme.colors.gray[400] : theme.colors.blue[200]};
  /* border: 0 !important; */
  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }
  &:hover:enabled {
    background-color: ${({ theme, warning }) => warning ? theme.colors.red[500] : theme.colors.product.primaryHover};
    color: ${({ theme }) =>  theme.colors.white};
    border: 1px solid ${({ theme, warning }) => warning ? theme.colors.red[500] :  theme.colors.product.primaryHover};
  }
  &:active, &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;
  }

`

export const TMenuButton = styled.img<{ active?: boolean, disabled?: boolean }>`
  display: inline-block;
  width: 18px;
  height: 14px;
  filter: ${({ active }) => active ? 'none' : 'grayscale(100%)'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  :hover {
    filter: none;
  }
`

export const TInfoTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`
export const TGlassMessage = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
`
export const TPromptMessage = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[500]};
`

export const TMultiSelectWrapper = styled.form`
  width: 100%;
  margin-top: 20px;
  .select__clear-indicator{
    display:none;
  };
  .select__multi-value{
    margin-right: 16px;
  };
`
export const TButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
  margin-top: 30px;
`

export const CustomButton = styled.button<{ disabled: boolean, primary?: boolean }>`
  width: fit-content;
  height: 54px;
  border-radius: 10px;
  border: ${({ theme, disabled }) => disabled ? `2px solid ${theme.colors.gray[300]}` : `2px solid ${theme.colors.blue[200]}`};
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  padding: 10px 45px;
  background-color: ${({ theme, primary, disabled }) => primary ? (disabled ? theme.colors.gray[300] : theme.colors.blue[200]) : 'transparent'};
  color: ${({ theme, primary, disabled }) => primary ? theme.colors.white : (disabled ? theme.colors.gray[300] : theme.colors.blue[200])};
  &:hover {
    border-color: ${({ theme, disabled }) => disabled ? theme.colors.gray[300] : theme.colors.blue[200]};
  }
`

export const TTableWr = styled.div`
  margin-top: 16px;
  
  select,input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    text-align: center;
    -webkit-appearance: none !important;  /* Remove style Chrome */
    -moz-appearance: none !important;     /* Remove style FireFox */
    appearance: none !important;          /* Remove style FireFox*/
    text-indent: 0.01px;
    text-overflow: '';
    :focus-visible{
      outline: none;
    }
  }

  select option {
    background-color: #fff !important;
    padding: 15px !important;
    text-align: center;
    height: 39px !important;
    width: 100%;
    font-size: 12px;
    -webkit-appearance: none !important;  /* Remove style Chrome */
    -moz-appearance: none !important;     /* Remove style FireFox */
    appearance: none !important;          /* Remove style FireFox*/
    text-indent: 0.01px;
    text-overflow: '';
      :hover{
        background-color:  ${({ theme }) => theme.colors.product.primaryActive};
      }
    }
`

export const TDescriptionWr = styled.div<{ column: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  position: relative;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 20px;
  textarea {
    border: 1px solid ${({ theme }) => theme.colors.gray[500]};
    
  }
  .description{
    width: ${({ column }) => column ? '100%' : '70%'};
  }
  .nothovered{
    textarea{
        :focus-visible,:hover,:active {
        border: 1px solid ${({ theme }) => theme.colors.gray[500]};
        outline: none;
      }
    }
  }
  .textarea-error{
    /* width: 70%; */
    textarea{
      border-color: ${({ theme }) => theme.colors.alert.warning};
      :focus-visible,:hover,:active {
        border-color: ${({ theme }) => theme.colors.alert.warning};
        outline: none;
    }
  }
  }
  .implementer {
    position: relative;
    min-height: fit-content;
    border: 1px solid ${({theme}) => theme.colors.gray[500]};
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.gray[500]};
    margin-top: 52px;
    font-weight: 400;
    padding: 18px 16px;

    b {
      font-weight: 600;
    }

    ::before {
      content: 'Исполнитель';
      position: absolute;
      top: -26px;
      left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({theme}) => theme.colors.gray[500]};
    }
  }
`

export const TImplementorInfo = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 30px 18px 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[500]};
  margin-top: 52px;
  ::before {
    content: 'Исполнитель';
    position: absolute;
    top: -26px;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.gray[500]};
  }
`

export const TTooltip = styled(Tooltip)`
  padding: 17px 30px !important;
  width: max-content;`

export const CustomStyles = {
  control: () => ({
    width: '100%',
    border: '1px solid gray',
    display: 'flex',
    height: '60px',
    borderRadius: '10px !important',
    marginBottom: '0 !important',
    'div[class$=ValueContainer]': {
      marginBottom: '0 !important'
    }
  }),
  option: () => ({
    padding: '10px',
    borderRadius: '10px',
    margin: '0 5px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#7A78E9',
      color: '#fff',
      cursor: 'pointer',
    },
  }),
  multiValue: () => ({
    minWidth: '163px',
    height: '38px',
    display: 'flex',
    border: '1px solid #F7F7F8',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: '22px',
    marginBottom: '0 !important',
    backgroundColor: '#F7F7F8',
    'div[class$=select__multi-value__label]': {
      fontSize: '16px !important',
      lineHeight: '24px !important',
      color: '#1A1A22 !important',
      fontWeight: 500,
      marginBottom: '0 !important',
    },
    'div[class$=select__multi-value__remove]': {
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      border: '1px solid #96999C',
      color: '#96999C !important',
      '&:hover': {
        backgroundColor: '#F7F7F8 !important'
      }
    }
  }),
  indicatorSeparator: () => ({
    display: 'none !important'
  }),
  indicatorContainer: () => ({}),
};

export const customStylesOptions = (theme: defaultThemeType): Record<string, unknown> => ({
  control: (state: CSSProperties, { isDisabled }: { isDisabled: boolean }) => ({
    ...state,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    boxShadow: 'none',
    cursor: 'default',
    padding: '0',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '28px',
    height: '60px',
    backgroundColor: '#fff',
    border: 'none',
    'div[class$="singleValue"]': {
      color: isDisabled ? theme.colors.gray[500] : theme.colors.black[200],
    },
    '&:hover': {
      borderColor: isDisabled ? 'transparent' : theme.colors.alert.border,
      cursor: isDisabled ? 'default' : 'pointer',
    },
  }),
  option: (state: CSSProperties, { isSelected }: { isSelected: boolean }) => ({
    ...state,
    height: 'auto',
    textAlign: 'center',
    backgroundColor: theme.colors.white,
    border: 'none',
    outline: 'none',
    color: isSelected ? theme.colors.product.primaryActive : theme.colors.black[200],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out 0s',
    padding: '8px 14px 8px 12px',
    fontSize: '12px',
    lineHeight: '20px',
    width: '162px',
    margin: 'auto',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.colors.product.primaryHover,
      color: theme.colors.white,
    },
  }),
  dropdownIndicator: (
    state: CSSProperties,
    { selectProps: { menuIsOpen } }: { selectProps: { menuIsOpen: boolean } },
  ) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out 0s',
    textAlign: 'center',
  }),
  container: () => ({
    position: 'relative',
    textAlign: 'center !important',
  }),
  indicatorsContainer: () => ({}),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: 0,
    textAlign: 'center !important',
    justifyContent: 'center !important',
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: theme.colors.gray[200],
    textAlign: 'center !important',
  }),
});

export const TTBodyMorphological = styled.tbody<{ isBorderCancerousCellPercentage?: boolean, isBorderQuality?: boolean, isBorderPriority?: boolean }>`
  box-sizing: border-box;
  tr {
    width: 100%;
  }
  td {
    padding: 0 16px;
  }
`
export const TDateOfCompletion = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[500]};
`

export const TDate = styled.span`
  font-weight: 500;
`

export const TInfoWr = styled.div`
  width: 100%;
  height: fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  color: ${({ theme }) => theme.colors.gray[500]};
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  &.recomendation {
    min-height: 120px;
  }
`

export const TInvertPlusIcon = styled(PlusIcon)<{ active?: string }>`
  path {
    stroke: ${({ theme, active }) => !!active ? theme.colors.gray[300] : theme.colors.white };
  }
`;

export const TInvertCheckIcon = styled(CheckIcon)<{ active?: string }>`
  width:16px;
  height:16px;
  path {
    stroke: ${({ theme, active }) => !!active ? theme.colors.gray[300] : theme.colors.white  };
  }
`;

export const TInvertCrossIcon = styled(CrossIcon)`
  width:8px;
  height:8px;
  path {
    fill: ${({ theme }) => theme.colors.gray[500]};
  }
`;

export const TErrorCrossIcon = styled(CrossIcon)`
  width:16px;
  height:16px;
  path {
    fill: ${({ theme }) => theme.colors.alert.warning};
  }
`;

export const TSubBlockTab = styled.details`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  /* margin-top: 12px;  */
  details[disabled] summary,
  &.disabled summary {
    pointer-events: none; /* prevents click events */
    user-select: none; /* prevents text selection */
  }
`;

export const TSubBlockTabNumber = styled.summary`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  ::marker {
    display: none;
    content: "";
  } 
`;

export const TSubBlockTabNumberContent = styled.div`
  display: inline-flex;
  align-items: center;
  height: 60px;
  width: 96%;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: 22px;
  padding-left: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[200]};
  border-radius: 10px;
  box-sizing: border-box;
`;

export const TSubBlockFormWr = styled.div`
  position: relative;
  margin-top: 20px;

  :not(details[open]) .arrow  {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  } 

  details[open] .arrow  {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  } 
`;

export const TArrowIcon = styled(ArrowIcon)`
  path {
    fill: ${({ theme }) => theme.colors.gray[300]};
  }
`;

export const TSubBlockTabContentList = styled.ul`
  list-style: none;

  max-height: 500px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-align: start end;
  scroll-snap-stop: always;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #B7B5F1;
    box-shadow: inset 0 0 1px #EFEEFF;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  scrollbar-color: #B7B5F1 #EFEEFF;

  li:first-child {
    margin-bottom: 16px;
  }

  li {
    margin-bottom: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
`;

export const TSubBlockTabContent = styled.div`
  display: inline-flex;
  align-items: center;
  height: 60px;
  width: 95%;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: 22px;
  padding-left: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[500]};
  border-radius: 10px;
  box-sizing: border-box;
`;


const Checkbox = styled.input.attrs({ type: 'checkbox' }) <{ disabled?: boolean }>`
  transform: scale(1.6);
  -ms-transform: scale(1.6); 
  -moz-transform: scale(1.6);
  -webkit-transform: scale(1.6); 
  -o-transform: scale(1.6); 
  transform: scale(1.6);
  accent-color:${({ disabled }) => disabled ? 'red' : '#7A78E9'};
   /* #7A78E9; */
  cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

  /* [disabled] {
    accent-color: red;
    pointer-events: none; 
    user-select: none; 
  } */
`;

export const TCheckbox = styled(Checkbox)`
  input[disabled] {
    accent-color:red;
  }
`;

export const TAddnewSubWr = styled.li`
  display: flex;
  flex-wrap: wrap;
  
`;

export const TAddNewSubInput = styled.input<{error?:boolean}>`
    background-color: azure;
    width: 95%;
    height: 60px;
    background-color: ${({ theme }) => theme.colors.white};
    margin-left: 22px;
    padding-left: 16px;
    border: 1px solid ${({ theme, error }) => error ? theme.colors.alert.warning : theme.colors.gray[500]};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black[200]};
    border-radius: 10px;
    box-sizing: border-box;
    -moz-appearance: textfield;
    
    :focus-visible,:hover,:active {
        border: 1px solid ${({ theme, error }) => error ? theme.colors.alert.warning : theme.colors.product.primaryActive};
        outline: none;
    }
    & :read-only, :disabled {
      cursor:default;
      border: 1px solid ${({ theme }) => theme.colors.gray[500]};
    }
`;

export const TAddNewSubControlWr = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  :first-child {
    margin-bottom: 16px;
  }
`;

export const TAddNewSubRemoveItem = styled.div`
  position: absolute;
  right: 22px;
  top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #EDEFF1;
  border-radius: 50%;
  :hover {
    background-color: ${({ theme }) => theme.colors.alert.warning};
    color: white;
    path {
      fill: ${({ theme }) => theme.colors.gray[100]};
    }
  }
`;

export const TAddNewSubButtonWr = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5%;
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;

export const TAddNewSubButtonText = styled.span<{active?:boolean}>`
  color: ${({ theme, active }) => active ? theme.colors.product.primaryActive : theme.colors.gray[500]};
  padding-left: 12px;
`;
export const TAddNewSubButtonErrorText = styled.span`
  color: ${({ theme }) => theme.colors.alert.warning};
  padding-left: 12px;
`;