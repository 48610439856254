import AnalysisExecution from './AnalysisExecution';
import BioMaterialData from './BioMaterialData';
import ClinicalData from './ClinicalData';
import MarkersValidation from './MarkersValidation';
import { IDetailsData } from './MolecularProfilingDetailsPage';
import MorphologicalCharacteristic from './MorphologicalCharacteristic';
import NoDataToShow from './NoDataToShow';
import ReferralSideData from './ReferralSideData';
import Сonclusion from './Сonclusion';
import defaultStatusIcon from '../../icons/cross-in-circle-default-filled.svg';
import pendingStatusIcon from '../../icons/attention.svg';
import doneStatusIcon from '../../icons/done.svg';
import { REFERRAL_STEPS_DATA } from '../../fixtures/StepsMolecularProfilingPage';
import { RefferalStepStateChecker } from './utils/RefferalStepStateChecker';

export const AbleToUdateDefault = {
  updateClinicalData: false,
  updateBiologicalMaterial: false,
  updateMorphology: false,
  updateMarkerValidation: false,
  updateExamination: false,
  updateCoclusion: false,
};

export const detailDataComponents = [
  ReferralSideData,
  ClinicalData,
  MarkersValidation,
  BioMaterialData,
  MorphologicalCharacteristic,
  AnalysisExecution,
  Сonclusion,
];

export const detailsData: IDetailsData[] = [
  {
    id: 1,
    title: 'Данные направляющей стороны',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
  },
  {
    id: 2,
    title: 'Клинические данные',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
  },
  {
    id: 3,
    title: 'Маркеры',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
  },
  {
    id: 4,
    title: 'Биоматериал',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
    hasStatus: true,
  },
  {
    id: 5,
    title: 'Морфологическая характеристика',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
    hasStatus: true,
  },
  {
    id: 6,
    title: 'Исследования',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
    hasStatus: true,
  },
  {
    id: 7,
    title: 'Заключение',
    content: {},
    status: '',
    component: NoDataToShow,
    ableToUdatePermit: AbleToUdateDefault,
    hasStatus: true,
  },
];

export const iconAndStatus = {
  progress: pendingStatusIcon,
  pending: pendingStatusIcon,
  default: defaultStatusIcon,
  done: doneStatusIcon,
  finish: doneStatusIcon,
};

export const stepsWithState = (
  stepCurrentIndex: number
) => {
  return [
    {
      ...REFERRAL_STEPS_DATA[0],
      state: RefferalStepStateChecker.getBioMaterilReceipt(stepCurrentIndex),
    },
    {
      ...REFERRAL_STEPS_DATA[1],
      state: RefferalStepStateChecker.getMorphologicalCharacterization(stepCurrentIndex),
    },
    {
      ...REFERRAL_STEPS_DATA[2],
      state: RefferalStepStateChecker.getResearchImplementation(stepCurrentIndex),
    },
    {
      ...REFERRAL_STEPS_DATA[3],
      state: RefferalStepStateChecker.getConclusionFormation(stepCurrentIndex),
    },
    {
      ...REFERRAL_STEPS_DATA[4],
      state: RefferalStepStateChecker.getReferralClosed(stepCurrentIndex)
    }
  ];
};
