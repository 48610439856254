import { FC, useCallback, useLayoutEffect, useState } from "react";
import { TEditButton, TImplementor, TMorphConclusionCol, TMorphConclusionWr, TTableWrapper } from "../styled";
import { Controller, useForm } from "react-hook-form";
import { IConclusionData } from "../../../../store/molProConclusion/model";
import { postOverWriteConclusionModules } from "../../../../store/molProConclusion/thunkActions";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../../../store/auth/selectors";
import { InfoRowWrapper } from '../../styled';
interface IComponentProps {
  defaultData?: IConclusionData | null;
  stepComplite: boolean;
  hasPermit: boolean;
  referralID: string;
  [index: string]: any;
}

export const MorphlogyConclusion: FC<IComponentProps> = ({ referralID, stepComplite, defaultData, hasPermit }) => {
  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    mode: "onSubmit", defaultValues: {
      conclusion: defaultData?.moduleMorphology?.restoreDefaultData?.conclusion ?? '',
      implementer: defaultData?.moduleMorphology?.implementer ?? ''
    }
  });
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());

  const restoreDefaults = useCallback(async () => {
    if (defaultData?.moduleMorphology?.restoreDefaultData) {
      let { restoreDefaultData, implementer } = { ...defaultData?.moduleMorphology }
      if(restoreDefaultData?.conclusion) setValue('conclusion',restoreDefaultData?.conclusion ?? '');
      setValue('implementer', implementer ?? '');
      setResetFormKey(Date.now());
    }
  }, [defaultData, setValue, setResetFormKey]);

  const handleSaveData = useCallback(async (formData: any) => {
    if (stepComplite || !hasPermit) return;
    const dataForSave: { [index: string]: any; } = {...defaultData ?? {}} ;
    dataForSave['moduleMorphology'] = { restoreDefaultData: {conclusion:formData?.conclusion}, implementer:formData?.implementer };
    if(tokens?.access) dispatch(postOverWriteConclusionModules(tokens?.access,referralID,dataForSave));

    setEdit(false);
  }, [referralID, defaultData, setEdit, stepComplite, hasPermit,dispatch,tokens]);

  const handleEditPage = useCallback(async () => {
    if (stepComplite || !hasPermit) return;
    if (isEdit) {
      setEdit(false);
      restoreDefaults()
      return setResetFormKey(Date.now())
    }
    setEdit(true)
  }, [isEdit, setEdit, stepComplite, hasPermit, setResetFormKey, restoreDefaults]);

  useLayoutEffect(() => {
    restoreDefaults();
    return () => {
      reset({
        conclusion: '',
        implementer: ''
      })
    }
  }, [restoreDefaults,reset]);

  return <TTableWrapper key={resertFormKey} onSubmit={handleSubmit(data => handleSaveData(data))}>
    <InfoRowWrapper direction="flex-end" >
      {!stepComplite && hasPermit && <TEditButton type="button" onClick={handleEditPage} active={!isEdit} disabled={stepComplite}>
        {!isEdit ? 'Редактировать' : 'Отменить'}
      </TEditButton>}
      {isEdit && !stepComplite &&
        <TEditButton type="submit" disabled={!isEdit} active={isEdit}>
          Сохранить
        </TEditButton>
      }
    </InfoRowWrapper>
    <TMorphConclusionWr key={resertFormKey} isEdit={isEdit}>
      <TMorphConclusionCol isTitle={true}>
        Клинико-лабораторное заключение
      </TMorphConclusionCol>
      <TMorphConclusionCol>
        {isEdit &&  
        <Controller
          control={control}
          name="conclusion"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
            <textarea
              placeholder={'Свободный ввод'}
              onChange={onChange}
              disabled={!isEdit}
              readOnly={!isEdit}
              className={`description nothovered ${invalid ? 'error' : ''}`}
              defaultValue={getValues('conclusion') ?? '' }
              maxLength={900}
              autoFocus={true}
            />)}
      /> }
      {!isEdit && getValues('conclusion') }
      </TMorphConclusionCol>
    </TMorphConclusionWr>
      <TImplementor>
        <b>Исполнитель: </b> {getValues('implementer') ?? '--'} 
      </TImplementor>
  </TTableWrapper>
}

export default MorphlogyConclusion;